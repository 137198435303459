.fhl-investor-page-wrapper {
  width: 100%;
  p {
    font-family: "Opensans-Regular";
    color: $footer-base;
    line-height: convert-unit(21px, "rem");
    font-size: convert-unit(14px, "rem");
    margin-bottom: convert-unit(25px, "rem");
  }

  .dynamic-investor-cntr {
    p {
      font-family: "Opensans-Regular";
      font-size: convert-unit(14px, "rem");
      color: $footer-base;
      line-height: convert-unit(21px, "rem");
      letter-spacing: convert-unit(0px, "rem");
    }
    .investor-block-header {
      font-family: "HarmoniaSansW05-Regular";
      font-size: convert-unit(18px, "rem");
      color: $footer-base;
      letter-spacing: convert-unit(0px, "rem");
      line-height: convert-unit(25.2px, "rem");
    }
    .investor-page {
      padding: convert-unit(0px, "rem");
      table {
        border: 0px;
        width: 100%;
        table-layout: fixed;
      }
      caption {
        caption-side: top;
      }
      .col-header-th-form {
        font-family: "HarmoniaSansW05-Semibold";
        font-size: convert-unit(14px, "rem");
        color: get("color.primary-green.100");
        letter-spacing: convert-unit(2px, "rem");
        text-align: center;

        height: convert-unit(35px, "rem");
        padding-top: 10px;
        width: auto;
      }
      .col-header-th-quarter {
        font-family: "HarmoniaSansW05-Semibold";
        font-size: convert-unit(14px, "rem");
        color: $footer-base;
        letter-spacing: convert-unit(2px, "rem");
        text-align: center;

        height: convert-unit(35px, "rem");
        padding-top: 10px;
        width: auto;
      }
      .header-gray-border {
        border-bottom: 1px solid $borderLine;
      }
      .header-green-border {
        border-bottom: 1px solid get("color.primary-green.100");
      }
      .col-value-td {
        font-family: "Opensans-Regular";
        font-size: convert-unit(12px, "rem");
        color: get("color.gray.50");
        letter-spacing: convert-unit(1.71px, "rem");
        text-align: center;
        line-height: convert-unit(15px, "rem");
        width: auto;
        padding: convert-unit(10px, "rem") convert-unit(0px, "rem");
        a {
          color: get("color.gray.50");
        }
        .zip-button {
          cursor: pointer;
          background: none;
          border: 0;
          color: get("color.gray.50");
        }
        .zip-button:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .fhl-investor-page-wrapper {
    .dynamic-investor-cntr {
      .investor-page {
        overflow-x: auto;
        overflow-y: visible;
        table {
          min-width: convert-unit(500px, "rem");
        }
      }
    }
  }
}
@media only screen and (min-width: 992px) {
  .fhl-investor-page-wrapper {
    .dynamic-investor-cntr {
      .investor-page {
        table {
          width: 100%;
        }
      }
    }
  }
  .fhl-investor-page-wrapper {
    margin-left: convert-unit(0px, "rem");
  }
}
