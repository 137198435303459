////
/// FHLB Mixins
/// @group helpers
////

@mixin fontfamilyOpenSans {
  p {
    font-family: "Opensans-Regular";
    font-weight: normal;
    font-style: normal;
    em {
      font-family: "OpenSans-Italic";
      font-weight: normal;
      font-style: italic;
      strong {
        font-family: "OpenSans-SemiboldItalic";
        font-weight: 300;
        font-style: italic;
      }
    }
    strong {
      font-family: "OpenSans-Semibold";
      font-weight: 300;
      font-style: normal;
      em {
        font-family: "OpenSans-SemiboldItalic";
        font-weight: 300;
        font-style: italic;
      }
    }
    a {
      color: inherit;
    }
  }
}

@mixin fontfamilyHarmoniaSans {
  p {
    font-family: "HarmoniaSansW05-Regular";
    font-weight: normal;
    font-style: normal;
    em {
      font-family: "HarmoniaSansW05-Italic";
      font-weight: normal;
      font-style: italic;
      strong {
        font-family: "HarmoniaSansW05-SemiboldItalic";
        font-weight: 300;
        font-style: italic;
      }
    }
    strong {
      font-family: "HarmoniaSansW05-Semibold";
      font-weight: 600;
      font-style: normal;
      em {
        font-family: "HarmoniaSans-SemiboldIt";
        font-weight: 600;
        font-style: italic;
      }
    }
    a {
      color: inherit;
    }
  }
}
