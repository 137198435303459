.promo-content-container {
    position: relative;
    min-height: 525px;
    padding: 60px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .promo-content-img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 0;
        min-height: convert-unit(525px, "rem");
        overflow: hidden;
        img {
            width: 100%;
        }
    }
    .promo-main-content {
        text-align: left;
        display: flex;
        width: convert-unit(1140px, "rem");
        .promo-col1,
        .promo-col2 {
            display: flex;
            flex-direction: column;
            text-align: left;
            max-width: 24%;
            flex: 2;
            .promo-tag-cntr {
                position: relative;
                width: 100%;
                font-size: convert-unit(18px, "rem");
                margin: convert-unit(115px, "rem") 0 0 0;
                font-family: "HarmoniaSansW05-Italic";
                font-style: italic;
                font-weight: normal;
                line-height: convert-unit(21px, "rem");
                color: inherit;
            }
            p {
                margin: 0;
                color: inherit;
                a {
                    color: inherit;
                    span {
                        color: inherit;
                    }
                }
            }
            .promo-tag-name {
                position: relative;
                margin-top: convert-unit(10px, "rem");
                font-family: "Opensans-Regular";
                font-size: convert-unit(16px, "rem");
                color: inherit;
                line-height: convert-unit(22.4px, "rem");
            }
            a {
                position: relative;
            }
            a.promo-tag-link {
                position: relative;
                font-size: convert-unit(14px, "rem");
                margin-top: convert-unit(10px, "rem");
                letter-spacing: convert-unit(1px, "rem");
                line-height: convert-unit(16.8px, "rem");
                display: table !important;
                vertical-align: middle;
                i {
                    font-size: convert-unit(18px, "rem");
                    width: 21px;
                    display: inline-table;
                    vertical-align: sub;
                }
                i:hover {
                    text-decoration: none !important;
                    display: inline-block !important;
                }
            }
        }
        .promo-col1 {
            max-width: 37%;
            margin-right: convert-unit(80px, "rem");
            flex: 1;
            .promo-content-header {
                position: relative;
                margin: convert-unit(25px, "rem") 0 0 0;
                width: 100%;
                font-size: convert-unit(42px, "rem");
                line-height: convert-unit(50.4px, "rem");
            }
            .promo-content {
                position: relative;
                width: 100%;
                margin: convert-unit(15px, "rem") 0 0 0;
                font-size: convert-unit(18px, "rem");
                color: inherit;
                line-height: convert-unit(25.2px, "rem");
                @include fontfamilyHarmoniaSans;
                p {
                    a {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 767px) {
    .extra-space-whiteBg {
        padding: 0px 20px;
    }
    // override for Affordable Housing program promo banner
    .ahp-main-promo-banner {
        .promo-content-container {
            .promo-main-content {
                .promo-col1 {
                    .promo-content-header {
                        font-size: convert-unit(32px, "rem");
                        line-height: convert-unit(36px, "rem");
                    }
                }
            }
        }
    }
    .promo-content-container {
        padding: convert-unit(30px, "rem") 0px 0px 0px;
        min-height: 100%;
        background-color: $lightGray;
        overflow: hidden;
        flex-direction: column-reverse;
        margin-top: convert-unit(74px, "rem");
        .promo-content-img {
            top: auto;
            width: 100%;
            margin-left: 0;
            min-height: 100%;
            bottom: 0;
            position: relative;
            img {
                width: 100%;
                margin-left: 0;
                left: 0;
                max-width: 100%;
                margin-top: convert-unit(5px, "rem");
            }
        }
        .promo-main-content {
            flex-direction: column;
            width: 100%;
            .promo-col1 {
                max-width: 100%;
                margin: 0px 5%;
                width: 90%;
                min-height: auto;
                .promo-content-header {
                    margin: 0px;
                    font-size: convert-unit(28px, "rem");
                    line-height: convert-unit(30px, "rem");
                    p {
                        display: block;
                        font-size: convert-unit(28px, "rem");
                        max-height: auto;
                        position: relative;
                        line-height: convert-unit(30px, "rem");
                    }
                    p > {
                        span {
                            display: block;
                            font-size: convert-unit(32px, "rem");
                            max-height: auto;
                            position: relative;
                            margin-top: convert-unit(20px, "rem");
                            line-height: convert-unit(35px, "rem");
                        }
                    }
                }
                .promo-content {
                    font-size: convert-unit(16px, "rem");
                    max-width: auto;
                    p {
                        height: auto;
                        font-size: convert-unit(16px, "rem");
                    }
                }
            }
            .promo-col2 {
                max-width: 90%;
                margin: 0px 5%;
                .promo-tag-cntr {
                    font-size: convert-unit(16px, "rem");
                    margin: convert-unit(15px, "rem") 0 0;
                    max-height: auto;
                    a.promo-tag-link {
                        font-size: convert-unit(13px, "rem");
                        width: convert-unit(150px, "rem");
                        line-height: convert-unit(21px, "rem");
                        margin-top: 0px;
                        i {
                            height: convert-unit(21px, "rem");
                        }
                    }
                }
                .promo-tag-name {
                    p {
                        display: inline;
                        margin: 0px convert-unit(3px, "rem") 0px 0px;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 768px) {
    .promo-content-container {
        background-color: #f3f3f3;
        min-height: convert-unit(390px, "rem");
        padding: convert-unit(30px, "rem") 0px;
        flex-direction: column;
        margin-top: 4.5rem;
        .promo-content-img {
            width: 127%;
            min-height: 100%;
            top: 0;
            position: absolute;
            img {
                margin: 0;
                height: auto;
                width: 100%;
                max-width: 100%;
                position: absolute;
                left: -15%;
            }
        }
        .promo-main-content {
            width: convert-unit(708px, "rem");
            flex-direction: column;
            .promo-col1 {
                max-width: 55%;
                margin: convert-unit(0px, "rem");
                width: 100%;
                .promo-content-header {
                    margin: 0 0 0 0;
                    font-size: convert-unit(32px, "rem");
                    line-height: convert-unit(36px, "rem");
                }
                .promo-content {
                    margin-bottom: convert-unit(30px, "rem");
                    p {
                        height: 100%;
                        font-size: convert-unit(16px, "rem");
                    }
                }
            }
            .promo-col2 {
                max-width: 60%;
                margin: 0px;
                padding: 0px;
                p {
                    margin: 0px 2px 0px 0px;
                    display: inline-block;
                    font-size: convert-unit(16px, "rem");
                }
                .promo-tag-cntr {
                    margin: 0;
                    overflow: visible;
                }
            }
        }
    }
    .ahp-main-promo-banner {
        .promo-content-container {
            margin-top:convert-unit(74px, "rem");
            .promo-main-content {
                .promo-col1 {
                    .promo-content-header {
                        font-size: convert-unit(32px, "rem");
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 992px) {
    .promo-content-container {
        min-height: convert-unit(525px, "rem");
        padding: convert-unit(60px, "rem") 0px;
        background-color: transparent;
        margin-top:0px;
        .promo-content-img {
            min-height: convert-unit(525px, "rem");
            width: 125%;
            top: 0;
            img {
                margin: 0;
                left: -11%;
            }
        }
        .promo-main-content {
            width: convert-unit(964px, "rem");
            flex-direction: row;
            .promo-col1 {
                margin: 0 convert-unit(20px, "rem") 0 0;
                max-width: 42%;
                .promo-content-header,
                .promo-content-header p {
                    margin: convert-unit(25px, "rem") 0 0 0;
                    font-size: convert-unit(42px, "rem");
                    line-height: convert-unit(50.4px, "rem");
                }
                .promo-content,
                .promo-content p {
                    max-height: convert-unit(100px, "rem");
                    margin: convert-unit(15px, "rem") 0 0 0;
                    font-size: convert-unit(18px, "rem");
                    line-height: convert-unit(25.2px, "rem");
                }
            }
            .promo-col2 {
                max-width: 25%;
                margin: 0;
                p {
                    display: block;
                }
                .promo-tag-cntr {
                    margin: convert-unit(115px, "rem") 0 0 0;
                }
                .promo-tag-cntr,
                .promo-tag-cntr p {
                    font-size: convert-unit(18px, "rem");
                    line-height: convert-unit(21px, "rem");
                    margin-bottom: 0;
                }
                a {
                    position: relative;
                }
                a.promo-tag-link {
                    font-size: convert-unit(14px, "rem");
                    margin-top: convert-unit(10px, "rem");
                    letter-spacing: convert-unit(1px, "rem");
                    line-height: convert-unit(16.8px, "rem");
                    i {
                        font-size: convert-unit(18px, "rem");
                        line-height: convert-unit(19px, "rem");
                        width: 21px;
                    }
                }
            }
        }
    }
    .ahp-content-wrapper {
        .promo-content-container {
         margin-top:convert-unit(0px, "rem");
            .promo-main-content {
                .promo-col1 {
                    .promo-content {
                        p {
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }
    .ahp-main-promo-banner {
        .promo-content-container {
            .promo-main-content {
                .promo-col1 {
                    .promo-content-header {
                        font-size: convert-unit(42px, "rem");
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 1200px) {
    .promo-content-container {
        .promo-content-img {
            width: 100%;
            top: 0;
            img {
                left: 0;
                margin: 0;
                width: 100%;
            }
        }
        .promo-main-content {
            width: convert-unit(768px, "rem");
            .promo-col1 {
                max-width: 55%;
                margin: 0 convert-unit(100px, "rem") 0 0;
            }
            .promo-col2 {
                max-width: 35%;
            }
        }
    }
}
