.error-message-wrapper {
    max-width: 650px;
    .error-message-space{
        margin: 25% 0 25% -15px;
    }
    
    .advance-page-max-error {
        .alert {
            margin-bottom: 0px !important;
            margin-left: -15px;
            .alert-labeled-row {
                span {
                    padding: 10px !important;
                }
                p {
                    padding: 0px 0px 0px 5px !important;
                }
            }
        }
    }
    .errormsg-wrapper {
        .alert {
            border-radius: 0;
        }
        .alert-labeled {
            padding: 0px;
        }
        .alert-info {
            border-color: get("color.danger.100");
        }
        .alert-labeled-row {
            display: table-row;
            padding: convert-unit(0px, "rem");
            .alert-labelled-cell {
                padding: convert-unit(20px, "rem");
                display: table-cell;
                vertical-align: middle;
            }

            .alert-label {
                vertical-align: middle;
                background-color: get("color.danger.100");
                width: auto;
                padding: convert-unit(10px, "rem"), convert-unit(15px, "rem");
                min-height: convert-unit(75px, "rem");
                font-size: 1.1em;
                i {
                    color: $white;
                    margin-top: convert-unit(3px, "rem");
                }
            }
            .alert-body {
                align-items: center;
                font-family: "Opensans-Regular";
                font-size: convert-unit(14px, "rem");
                color: get("color.danger.100");
                line-height: convert-unit(21px, "rem");
                padding: convert-unit(24px, "rem");
            }
        }
    }
}
