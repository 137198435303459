//
// Modals
//
$modal-lg: 800px;
$modal-md: 600px;
$modal-sm: 300px;

$modal-max-height: 450px;

$modal-header-border-width: 0;
$modal-header-padding: 1.5rem 1.5rem 1rem 1.5rem;
$modal-header-margin: -1.5rem -1.5rem -1.5rem auto;

$modal-inner-padding: 1.5rem;

$modal-footer-border-width: 0;
