.image-caption-template {
    text-align: left;

    .image-content {
        img {
            width: 100%;
        }
    }
    .image-caption {
        font-size: convert-unit(12px, "rem");
        letter-spacing: 0;
        line-height: convert-unit(22.4px, "rem");
        margin-top: convert-unit(15px, "rem");
    }
}
.mpf-direct-title {
    .fhl-medium-titlebar-wrapper {
        h2 {
            font-size: convert-unit(28px, "rem");
            line-height: convert-unit(33.6px, "rem");
            text-align: left;
            font-family: "HarmoniaSansW05-Semibold";
            font-weight: 600;
            font-style: normal;
        }
    }
}
.fhl-simple-bullet-list-text {
    .fhl-richtext-wrapper {
        font-family: "OpenSans-Regular";
        font-size: convert-unit(16px, "rem");
        margin-left: convert-unit(20px, "rem");
        line-height: convert-unit(22.4px, "rem");
        color: inherit;
        em {
            font-family: "OpenSans-Italic";
            font-weight: normal;
            font-style: italic;
            strong {
                font-family: "OpenSans-SemiboldItalic";
                font-weight: 300;
                font-style: italic;
            }
        }
        strong {
            font-family: "OpenSans-Semibold";
            font-weight: 300;
            font-style: normal;
            em {
                font-family: "OpenSans-SemiboldItalic";
                font-weight: 300;
                font-style: italic;
            }
        }
    }
}
.tagline-area-bottom-container {
    margin: convert-unit(110px, "rem") 0px convert-unit(40px, "rem");
}
@media only screen and (max-width: 767px) {
    .mpf-direct-title {
        .fhl-medium-titlebar-wrapper {
            h2 {
                margin-bottom: convert-unit(60px, "rem");
            }
        }
    }
    .fhl-simple-bullet-list-text {
        .fhl-richtext-wrapper {
            margin-left: convert-unit(5px, "rem");
        }
    }
    .tagline-area-bottom-container {
        margin: convert-unit(30px, "rem") 0px;
    }
}
