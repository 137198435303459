/********************************************************
 Overwritten the Base Bootstrap Origin Class for FHL Bank
*********************************************************/
/* .btn-green / .btn-info / .btn-success  */
a.btn:focus {
  //outline: 0 !important;
  box-shadow: 0 0 0 0.1rem #2b9be0 !important;
}
.btn-green {
  background-color: get("color.secondary-green.100") !important;
  color: get("color.white");
}
.btn-green:hover {
  color: get("color.white") !important;
  background-color: get("color.secondary-green.100") !important;
  border-color: transparent !important;
}
.btn-green:focus {
  //box-shadow: 0 0 0 0 transparent !important;
  //outline: 0px !important;
  background-color: get("color.secondary-green.100") !important;
}
.btn-darkgreen {
  background-color: get("color.primary-green.100") !important;
  color: get("color.white");
}
.btn-darkgreen:hover {
  color: get("color.white") !important;
  background-color: get("color.primary-green.100") !important;
  border-color: transparent !important;
}
.btn-darkgreen:focus {
  //box-shadow: 0 0 0 0 transparent !important;
  //outline: 0px !important;
  background-color: get("color.primary-green.100") !important;
}

.btn-blue {
  background-color: get("color.bright-blue.100") !important;
  color: get("color.white");
}
.btn-blue:hover {
  color: get("color.white") !important;
  background-color: get("color.bright-blue.100") !important;
  border-color: transparent !important;
}
.btn-blue:focus {
  // box-shadow: 0 0 0 0 transparent !important;
  // outline: 0px !important;
  background-color: get("color.bright-blue.100") !important;
}
.btn-info {
  background-color: get("color.bright-blue.100") !important;
  color: get("color.white");
}
.btn-info:hover {
  color: get("color.white");
  background-color: get("color.bright-blue.100") !important;
  border-color: transparent;
}
.btn-info:focus {
  // box-shadow: 0 0 0 0 transparent !important;
  // outline: 0px !important;
  background-color: get("color.bright-blue.100") !important;
}
.btn-success {
  background-color: get("color.primary-green.100") !important;
  color: get("color.white") !important;
}
.btn-success:hover {
  color: get("color.white") !important;
  background-color: get("color.primary-green.100") !important;
  border-color: transparent;
}
.btn-success:focus {
  //box-shadow: 0 0 0 0 transparent !important;
  //outline: 0px !important;
  box-shadow: 0 0 0 0.1rem #2b9be0 !important;
  background-color: get("color.primary-green.100") !important;
}
.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  border-color: transparent !important;
}
/*
button[type="button"]:focus {
  box-shadow: none !important;
}
*/
/*
.fhl-hamburger-button {
    height: convert-unit(40px, "rem");
    width: convert-unit(40px, "rem");
    padding: convert-unit(5px, "rem") convert-unit(9px, "rem") !important;
    border: 0 !important;
}
.fhl-hamburger-button:focus {
    outline: 0;
}
.hambuger-icon {
    width: 26px;
    height: 22px;
    position: relative;
    margin: 0px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    margin-top: convert-unit(5px, "rem");
    span {
        background: get("color.primary-green.100");
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.25s ease-in-out;
        -moz-transition: 0.25s ease-in-out;
        -o-transition: 0.25s ease-in-out;
        transition: 0.25s ease-in-out;
    }
}
.hambuger-icon span:nth-child(1) {
    top: 0px;
}

.hambuger-icon span:nth-child(2),
.hambuger-icon span:nth-child(3) {
    top: 8px;
}

.hambuger-icon span:nth-child(4) {
    top: 16px;
}

.hambuger-icon.open span:nth-child(1) {
    top: 11px;
    width: 0%;
    left: 50%;
}

.hambuger-icon.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.hambuger-icon.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.hambuger-icon.open span:nth-child(4) {
    top: 11px;
    width: 0%;
    left: 50%;
}
*/
.hamburger-menu-button {
  width: 30px;
  height: 30px;
  padding: 6px;
  display: block;
  position: relative;
  z-index: 100;
  background: $white;
  box-sizing: content-box;
  border-radius: 50%;
  text-indent: 100%;
  color: transparent;
  white-space: nowrap;
  cursor: pointer;
  overflow: hidden;
  border: 1px solid $white;
  outline: 0;
}
.hamburger-menu-button-open {
  top: 50%;
  margin-top: -1px;
  left: 50%;
  margin-left: -12px;
}
.hamburger-menu-button-open,
.hamburger-menu-button-open::before,
.hamburger-menu-button-open::after {
  position: absolute;
  width: 24px;
  height: 2px;
  background: get("color.primary-green.100");
  border-radius: 4px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.hamburger-menu-button-open::before,
.hamburger-menu-button-open::after {
  left: 0;
  content: "";
}
.hamburger-menu-button-open::before {
  top: 6px;
}
.hamburger-menu-button-open::after {
  bottom: 6px;
}
.hamburger-menu-button-close {
  background: transparent;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.hamburger-menu-button-close::before {
  -webkit-transform: translateY(-6px) rotate(45deg);
  transform: translateY(-6px) rotate(45deg);
}
.hamburger-menu-button-close::after {
  -webkit-transform: translateY(6px) rotate(-45deg);
  transform: translateY(6px) rotate(-45deg);
}

#ham-navigation[open]:not(:focus-within) {
  //background-color: rgb(255, 255, 254);
  transition: background-color 0.1s ease;
}
