.partnership-content-article {
    height: auto;
    flex-direction: column;
    word-break: break-word;
    .partnership-content-title {
        // margin: convert-unit(40px, "rem") 0 0 0;
        margin: 8px 0px 0px 0px;
        color: get("color.primary-green.100");
        font-size: convert-unit(24px, "rem");
        font-weight: 400;
        line-height: convert-unit(28.8px, "rem");
    }
    .partership-bottom-container {
        flex-direction: row;
        align-content: space-between;
        overflow: visible;
        .partnership-column1,
        .partnership-column2 {
            overflow: visible;
            flex-direction: row;
            max-width: 45%;
            margin-top: convert-unit(40px, "rem");
            margin-right: convert-unit(30px, "rem");
            h4 {
                font-size: convert-unit(21px, "rem");
                font-family: "HarmoniaSansW05-Semibold";
                font-weight: 600;
                font-style: normal;
                color: inherit;
                line-height: convert-unit(25.2px, "rem");
                margin: 0 !important;
                word-break: break-word;
            }
            .partnership-content-contentbody {
                margin-top: convert-unit(10px, "rem");
                margin-bottom: convert-unit(25px, "rem");
                font-family: "Opensans-Regular";
                font-weight: normal;
                font-style: normal;
                font-size: convert-unit(14px, "rem");
                color: inherit;
                line-height: convert-unit(21px, "rem");
                word-break: break-word;
                @include fontfamilyOpenSans;
                p {
                    a {
                        text-decoration: underline;
                    }
                }
            }
            .partnership-content-link {
                color: inherit;
                word-break: break-word;
            }
            p {
                color: inherit;
                a {
                    color: inherit;
                }
            }
        }
    }
    p {
        a.partnership-anchor {
            color: inherit;
            text-decoration: none;
        }
    }
    a.partnership-anchor {
        color: inherit;
        text-decoration: none;
    }
}
@media only screen and (min-width: 320px) {
    .partnership-content-article {
        margin: 0px;
        .partnership-content-title {
            margin: 0px;
            font-size: convert-unit(28px, "rem");
        }
        .partership-bottom-container {
            flex-direction: column;
            padding-right: convert-unit(10px, "rem");
            .partnership-column1,
            .partnership-column2 {
                margin-top: convert-unit(25px, "rem");
                max-width: 100%;
                margin-right: 0;
            }
        }
    }
    .partnershiparrow {
        height: convert-unit(25px, "rem");
        width: convert-unit(25px, "rem");
    }
}
@media only screen and (min-width: 768px) {
    .partnership-content-article {
        margin-bottom: 0px;
        .partnership-content-title {
            line-height: 2.4rem;
        }
        .partership-bottom-container {
            flex-direction: row;
            .partnership-column1,
            .partnership-column2 {
                max-width: 45%;
                margin-right: convert-unit(30px, "rem");
                margin-top: convert-unit(40px, "rem");
            }
        }
    }
    .ahp-content-wrapper {
        .partnership-content-article {
            .partership-bottom-container {
                .partnership-column1 {
                    margin-top: convert-unit(32px, "rem");
                    .partnership-content-contentbody {
                        margin-bottom: convert-unit(18px, "rem");
                    }
                }
                .partnership-column2 {
                    margin-top: convert-unit(32px, "rem");
                    .partnership-content-contentbody {
                        margin-bottom: convert-unit(18px, "rem");
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 992px) {
    .partnership-content-article {
        .partnership-content-title {
            font-size: convert-unit(24px, "rem");
            line-height: convert-unit(28.8px, "rem");
        }
    }
    .ahp-workwithus-cntr {
        margin-top: convert-unit(5px, "rem");
    }
}
