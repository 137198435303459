@import "../../helpers/mixins";

$fhl--base-fontSize: 16px !default;

.fhl-medium-titlebar-wrapper h2,
.fhl-mediumLevel-h1-wrapper h1 {
  font-size: convert-unit(32px, "rem");
  font-weight: normal;
  font-style: normal;
  height: auto;
  margin-bottom: convert-unit(15px, "rem");
  text-align: left;
  color: $footer-base;
  line-height: convert-unit(38.4px, "rem");
}

.fhl-large-titlebar-wrapper h1 {
  font-size: convert-unit(42px, "rem");
  height: auto;
  text-align: left;
  margin: 0 0 convert-unit(15px, "rem") 0;
  color: get("color.primary-green.100");
  line-height: convert-unit(50.4px, "rem");
}

.fhl-small-titlebar-wrapper h2 {
  font-weight: 400;
  height: auto;
  margin: 0 0 convert-unit(15px, "rem") 0;
  text-align: left;
  font-size: convert-unit(24px, "rem");
  color: get("color.primary-green.100");
  line-height: convert-unit(28.8px, "rem");
}

.fhl-extrasmall-title-wrapper h3,
.fhl-h2-medium-title-wrapper h2 {
  font-weight: 400;
  height: auto;
  margin: 0 0 convert-unit(15px, "rem") 0;
  text-align: left;
  font-size: convert-unit(18px, "rem");
  line-height: convert-unit(28.8px, "rem");
}

.fhl-large-bold-titlebar-wrapper h3 {
  font-size: convert-unit(32px, "rem");
  font-weight: 400;
  height: auto;
  margin: 0px 0px convert-unit(15px, "rem") 0px;
  text-align: left;
}

.fhl-extrasmall-titlebar-wrapper h3,
.fhl-smallLevel-h2-wrapper h2 {
  color: inherit;
  font-family: "OpenSans-Semibold";
  font-weight: 300;
  font-style: normal;
  font-size: convert-unit(16px, "rem");
  line-height: convert-unit(21px, "rem");
}
.fhl-smallLevel-h3-wrapper h3{
font-family: "OpenSans-Semibold";
font-size: convert-unit(16px, "rem");
color: inherit;
letter-spacing: 0;
line-height: convert-unit(21px, "rem");

}
.fhl-smallLevel-h4-wrapper h4{
font-family: "OpenSans-Semibold";
font-size: convert-unit(16px, "rem");
color: inherit;
letter-spacing: 0;
line-height: convert-unit(21px, "rem");

}


@media only screen and (max-width: 767px) {
  .fhl-large-titlebar-wrapper {
    h1 {
      font-size: convert-unit(36px, "rem");
    }
  }
}
.fhl-medium-title-para-text-wrapper {
  flex-direction: column;
  width: 100%;
  h3 {
    font-family: "HarmoniaSansW05-Semibold";
    font-style: normal;
    font-weight: 600;
    font-size: convert-unit(21px, "rem");
    color: inherit;
    line-height: convert-unit(25.2px, "rem");
    word-break: break-word;
  }
  @include fontfamilyOpenSans;
  p {
    font-size: convert-unit(14px, "rem");
    color: inherit;
    line-height: convert-unit(21px, "rem");
    margin-bottom: 0;
    word-break: break-word;
  }
}
