footer {
    a {
        color: $white;
    }
    a:hover {
        color: $white;
        text-decoration: none;
    }
    color: white;
    .footer-primary {
        background-color: $footer-primary;
        padding: convert-unit(30px, "rem") 0;
        .footer-secondary {
            .fhl-logo-footer > img {
                margin: convert-unit(-180px, "rem") 0 0 0;
                max-width: convert-unit(86px, "rem");
                max-height: convert-unit(86px, "rem");
            }
            p {
                color: $white;
                line-height: convert-unit(19.6px, "rem");
                font-family: "Opensans-Regular";
                color: #ffffff;
                font-size: convert-unit(14px, "rem");
            }
            .footer-logo-desc {
                margin-top: convert-unit(-30px, "rem");
                font-family: "OpenSans-Bold";
                font-weight: 500;
                font-style: normal;
                line-height: convert-unit(19.6px, "rem");
            }
            ul {
                padding-left: 0;
            }
            li {
                border-top: 1px solid #6e7c7c;
                padding: convert-unit(10px, "rem");
                list-style: none;
                a {
                    font-size: convert-unit(14px, "rem");
                    color: get("color.white");
                    letter-spacing: convert-unit(1.5px, "rem");
                    text-transform: uppercase;
                    img {
                        width: convert-unit(40px, "rem");
                        margin-right: convert-unit(10px, "rem");
                    }
                }
            }
            li:last-child {
                border-bottom: 1px solid #6e7c7c;
            }
            .mob-top-30 {
                margin-top: convert-unit(0px, "rem");
            }
            .display-bottom {
                a {
                    text-decoration: none !important;
                }
            }
        }
    }
    .footer-base {
        background-color: $footer-base;
        padding: convert-unit(25px, "rem") 0;
        .footer-copyright {
            font-family: "Opensans-Regular";
            color: get("color.white");
            line-height: convert-unit(19.6px, "rem");
            font-size: convert-unit(14px, "rem");
            span {
                display: inline-block;
                line-height: 30px;
                ul {
                    margin: 0;
                    width: auto;
                    li {
                        display: inline-block;
                        margin-right: convert-unit(5px, "rem");
                    }
                }
            }
            span:first-child {
                margin-right: convert-unit(30px, "rem");
            }
        }
    }
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
    footer {
        .footer-primary {
            padding-right: 16px;
            padding-left: 16px;
            .container {
                //margin: 0px convert-unit(20px, "rem");
                //width: 90%;
                .footer-secondary {
                    .fhl-logo-footer {
                        img {
                            margin-top: convert-unit(-180px, "rem");
                        }
                    }
                    .mob-top-30 {
                        margin-top: convert-unit(30px, "rem");
                    }
                    li:last-child {
                        border-bottom: 0px solid #6e7c7c;
                    }
                }
            }
        }
        .footer-base {
            .container {
                margin: 0px convert-unit(15px, "rem");
            }
        }
    }
}
@media only screen and (min-width: 768px) {
    footer {
        .footer-primary {
            .container {
                .footer-secondary {
                    .fhl-logo-footer > img {
                        margin-top: convert-unit(-180px, "rem");
                    }
                    .mob-top-30 {
                        margin-top: convert-unit(0px, "rem");
                    }
                    li:last-child {
                        border-bottom: 1px solid #6e7c7c;
                    }
                    li .bor-bot-0 {
                        border-bottom: 0px solid #6e7c7c;
                    }
                    .display-bottom {
                        position: absolute;
                        bottom: 0px;
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 992px) {
    footer {
        .footer-primary {
            .container {
                .footer-secondary {
                    .fhl-logo-footer {
                        max-width: 20%;
                        img {
                            margin-top: convert-unit(-170px, "rem");
                        }
                    }
                    .mob-top-30 {
                        margin-top: convert-unit(0px, "rem");
                    }
                    li:last-child {
                        border-bottom: 1px solid #6e7c7c;
                    }
                    li .bor-bot-0 {
                        border-bottom: 0px solid #6e7c7c;
                    }
                    .display-bottom {
                        position: absolute;
                        bottom: 0px;
                    }
                }
            }
        }
    }
}
