/* FHL Navigation General Styles Starts Here  */
ul.navbar-nav {
  li.main-menu:first-child {
    display: none;
  }
}
.mobile-login-header-panel {
  display: none;
  .mob-header-login-btn {
    color: get("color.primary-green.100");
    cursor: pointer;
  }
}
.mob-arrow-link {
  display: none;
  cursor: pointer;
}
.navbar-nav .nav-link i {
  float: right;
  margin-top: convert-unit(-5px, "rem");
}
/* adds some margin below the link sets  */
.navbar .dropdown-menu div[class*="col"] {
  margin-bottom: convert-unit(16px, "rem");
}

.navbar .dropdown-menu {
  border: none;
  background-color: get("color.white") !important;
}
.mob-nav-section {
  display: none;
  justify-content: space-between;
}
.navbar .main-menu {
  width: auto;
  padding: 0;
}
.nav-link {
  display: inline-block;
  color: $footer-base !important;
  font-size: convert-unit(16px, "rem");
  i {
    display: inline-block;
    padding-right: convert-unit(10px, "rem");
    line-height: convert-unit(35px, "rem");
  }
}
.navbar-dark .navbar-nav .show > .nav-link {
  color: get("color.bright-blue.50") !important;
}
.navbar-expand-lg .navbar-nav .dropdown-toggle::after {
  display: none;
}
li.main-menu:hover {
  .dropdown-nav a >,
  a > {
    span {
      color: get("color.primary-green.100") !important;
    }
  }
}
.mob-nav-link {
  display: none;
}

/*** Skip to mainmenu**/
.skip {
  position: absolute;
  top: -1000px;
  left: -1000px;
  height: 1px;
  width: 1px;
  text-align: left;
  overflow: hidden;
}

a.skip:active,
a.skip:focus,
a.skip:hover {
  left: 0;
  top: 0;
  width: auto;
  height: auto;
  overflow: visible;
  color: get("color.white") !important;
  padding-top: convert-unit(8px, "rem");
  font-size: convert-unit(14px, "rem");
}

/*** Skip to mainmenu**/

/****** FHL Navigation General Styles Ends Here  ********/
/* breakpoint and up - mega dropdown styles */
@media only screen and (min-width: 320px) {
  .secondary-header {
    .container {
      .row {
        padding: 0px;
        ul.nav {
          margin: convert-unit(-10px, "rem") 0px 0px convert-unit(-10px, "rem");
        }
      }
    }
    .dropdown-menu .container .row {
      float: left;
    }
    .mob-logo {
      margin-top: convert-unit(5px, "rem");
      margin-right: 0 !important;
      img {
        width: 80%;
      }
    }
    .mob-arrow-link {
      display: flex;
      margin-top: -35px;
      float: right;
    }
    .mob-nav-link {
      display: block;
      width: 100%;
      border-bottom: convert-unit(1px, "rem") solid #f3f3f3;
      text-align: left;
    }
    .mobile-login-header-panel {
      display: flex;
      button {
        border: 0;
        background-color: transparent;
        padding: 0 convert-unit(10px, "rem") 0 0;
      }
      button::after {
        display: none;
      }
      ul.header-login-dropdown-content {
        margin: 0 0 0 convert-unit(15px, "rem");
        right: convert-unit(5px, "rem");
        left: auto;
        box-shadow: 0px convert-unit(1px, "rem") convert-unit(1px, "rem") 0px
          #cccccc;
        position: absolute;
        top: 70px;
        right: 20px;
        border: convert-unit(1px, "rem") solid get("color.primary-green.100");
      }
    }
    .navbar {
      width: 100%;
      margin-top: convert-unit(5px, "rem");
      padding: convert-unit(10px, "rem") convert-unit(22px, "rem");
      .mob-nav-section {
        display: flex;
      }
    }
    .dropdown-nav {
      .dropdown-menu {
        margin-top: convert-unit(-15px, "rem") !important;
      }
    }
    ul.navbar-nav {
      padding: 0 convert-unit(15px, "rem");
      margin-top: convert-unit(20px, "rem");
      [class*="col-"] {
        width: 100%;
      }
      li {
        text-align: left;
        padding: convert-unit(5px, "rem") 0;
        border-bottom: convert-unit(1px, "rem") solid #cccccc;
        line-height: convert-unit(35px, "rem");
        a.dropbtn::after {
          display: none;
        }
      }
      li.main-menu {
        padding-bottom: 0px;
        padding-top: 0;
      }
      li.mob-nav-link:last-child {
        border-bottom: 0px;
      }
      li.nav-column {
        display: flex;
        width: 100%;
        border-bottom: convert-unit(1px, "rem") solid #ccc;
        padding: 0px 0px 0px convert-unit(10px, "rem");
        line-height: convert-unit(35px, "rem");
        a {
          color: $footer-base;
          .nav-dropbox {
            font-size: convert-unit(16px, "rem");
            img {
              display: none;
            }
          }
          p {
            display: none;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 576px) {
  .secondary-header {
    .navbar {
      padding: convert-unit(10px, "rem") convert-unit(0px, "rem");
    }
  }
}
@media only screen and (min-width: 768px) {
  .mob-arrow-link {
    display: block;
    margin-top: convert-unit(-30px, "rem");
    float: right;
  }
  .mob-logo {
    margin-top: convert-unit(5px, "rem");
    margin-right: 0 !important;
  }
  .mobile-login-header-panel {
    display: flex;
    button {
      border: 0;
      background-color: transparent;
    }
    button::after {
      display: none;
    }
    ul.header-login-dropdown-content {
      margin: convert-unit(5px, "rem") 0 0 convert-unit(15px, "rem");
      right: convert-unit(5px, "rem");
      left: auto;
      box-shadow: 0px convert-unit(2px, "rem") convert-unit(4px, "rem") 0px
        $default-prime-gray;
      top: convert-unit(60px, "rem");
      min-width: auto;
    }
  }
  .secondary-header .navbar {
    padding: convert-unit(10px, "rem") convert-unit(8px, "rem");
    margin-top: convert-unit(5px, "rem");
    .mob-nav-section {
      display: flex;
    }
  }
  ul.navbar-nav {
    padding: 0 convert-unit(15px, "rem");
    margin-top: convert-unit(20px, "rem");
    [class*="col-"] {
      width: 100%;
    }
    li {
      text-align: left;
      padding: convert-unit(5px, "rem") 0;
      border-bottom: convert-unit(1px, "rem") solid #cccccc;
      a.dropbtn::after {
        display: none;
      }
    }
    li.main-menu {
      padding-bottom: 0px;
      padding-top: 0;
    }
    li.nav-column {
      display: flex;
      width: 100%;
      border-bottom: 1px solid #ccc;
      padding-left: 25px;
      a {
        .nav-dropbox {
          font-size: 14px;
          img {
            display: none;
          }
        }
        p {
          display: none;
        }
      }
    }
    .dropdown-nav {
      .dropdown-menu {
        padding-top: 0;
        margin-top: 0;
        .container {
          margin: 0 0 0 0;
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .secondary-header {
    .mob-arrow-link {
      display: none;
    }
    .navbar {
      padding: 0px !important;
      margin: 0 0 0 0;
      .mob-nav-section {
        display: none;
      }
      .dropdown {
        position: static;
      }
      li.nav-item {
        .dropdown-nav {
          display: inline-block;
          .dropdown-menu {
            width: 100%;
            left: convert-unit(-256px, "rem");
            min-width: convert-unit(1006px, "rem");
            top: convert-unit(83px, "rem");
            box-shadow: 0px 0px 1px #ccc;
            border-top: convert-unit(1px, "rem") solid #d8d8d8;
            background-color: get("color.white");
            border-radius: 0px;
            .container {
              padding: convert-unit(50px, "rem") convert-unit(20px, "rem")
                convert-unit(10px, "rem") convert-unit(40px, "rem") !important;
              margin: 0px;
              .nav {
                li.nav-item {
                  padding: 0 0.5rem 0 0;
                  margin: 2px 0 0 0;
                  list-style-type: none;
                  width: 33.33%;
                  border-bottom: 0;
                  a.nav-link {
                    width: 100%;
                    padding-top: 0px !important;
                    padding-bottom: 20px !important;
                    .nav-dropbox {
                      img {
                        display: block;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      li.mob-nav-link {
        display: none;
      }
      li.fhl-nav-link::after {
        content: "|";
        display: inline-block;
        margin: 0px 0px 0 0;
        color: #76bc21 !important;
      }
      li.fhl-nav-link:nth-last-child(6):after {
        display: none;
        content: "";
      }
      li.fhl-nav-link:last-child {
        display: none;
      }
      /*li.fhl-nav-link:nth-of-type(5) {
                &::after 
                {
                content: "";                
                }
            }*/
      ul.navbar-nav {
        margin-top: 3px;
        margin-bottom: 0;
        margin-left: 0;
        margin-right: 0;
        padding: 0 !important;
        li.main-menu {
          padding-bottom: 0px;
          padding-top: 0;
          border-bottom: 0px;
          width: auto;
          a.nav-link {
            padding-top: convert-unit(14px, "rem");
            padding-bottom: convert-unit(16px, "rem");
            display: inline-block;
            i {
              display: none;
            }
          }
        }
      }
    }
    .dropdown .dropdown-nav > a {
      border-top: convert-unit(1px, "rem") solid get("color.white");
      border-left: convert-unit(1px, "rem") solid get("color.white");
      border-right: convert-unit(1px, "rem") solid get("color.white");
    }
    .dropdown:hover .dropdown-nav > a,
    .dropdown-focus .dropdown-nav > a {
      z-index: 1001;
      position: relative;
      background-color: get("color.white");
      border-top: convert-unit(1px, "rem") solid #cccccc;
      border-top-left-radius: convert-unit(10px, "rem");
      border-top-right-radius: convert-unit(10px, "rem");
      border-left: convert-unit(1px, "rem") solid #cccccc;
      border-right: convert-unit(1px, "rem") solid #cccccc;
    }
  }

  /* shows the dropdown menu on hover */
  /*   .navbar .dropdown .dropdown-menu:hover,  
  .navbar .dropdown:hover .dropdown-menu,  */
  .navbar .dropdown-focus .dropdown-menu,
  .navbar .dropdown .dropdown-menu-focus {
    display: block !important;
  }

  .show {
    display: block !important;
  }
  .hidden {
    display: none !important;
  }

  .nav-dropbox {
    border-bottom: convert-unit(1px, "rem") solid $borderLine;
    padding-bottom: convert-unit(10px, "rem");
    margin-bottom: convert-unit(10px, "rem");
    display: flex;
    align-items: center;
    img {
      margin-right: convert-unit(8px, "rem");
      width: convert-unit(50px, "rem");
      height: convert-unit(50px, "rem");
    }
    .nav-dropdown-title {
      font-size: convert-unit(18px, "rem");
      font-weight: normal;
      font-style: normal;
      line-height: convert-unit(21.6px, "rem");
    }
  }
  li.nav-column {
    a.nav-link {
      color: inherit !important;
      p {
        font-size: convert-unit(14px, "rem");
        color: $footer-base;
        line-height: convert-unit(21px, "rem");
        font-family: "Opensans-Regular";
        font-weight: normal;
        font-style: normal;
        display: block !important;
      }
    }
  }
  .mob-logo {
    display: none !important;
  }
  .secondary-header {
    .container {
      padding-top: 3px !important;
    }
  }
}
@media only screen and (min-width: 1200px) {
  .secondary-header {
    min-height: convert-unit(72px, "rem");
    .navbar {
      .mob-nav-section {
        display: none;
      }
      li.nav-item {
        .dropdown-nav {
          .dropdown-menu {
            width: 100%;
            left: convert-unit(-287px, "rem");
            min-width: convert-unit(1160px, "rem");
            top: convert-unit(83px, "rem");
            box-shadow: 0px 0px 1px #ccc;
            border-top: convert-unit(1px, "rem") solid #d8d8d8;
            background-color: get("color.white");
            border-radius: 0px;
          }
        }
      }
      ul.navbar-nav {
        margin-top: convert-unit(6px, "rem");
        li.main-menu {
          padding-bottom: 0px;
          padding-top: 0;
          a.nav-link {
            padding-bottom: convert-unit(17px, "rem");
            padding-top: convert-unit(10px, "rem");
          }
        }
      }
    }
  }
}
