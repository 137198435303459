@import "../helpers/fhl-deep-map-merge";

/*********************************************
        FHL Bank ADA Compilance Colors
*********************************************/
/***************Green Pattern****************/
$fhl--green-base: #00662e !default;
$fhl--green-larger: #00833c !default;

/***************Gray Pattern****************/
$fhl--gray-base: #58585a !default;
$fhl--gray-larger: #747476 !default;
$fhl--gray-secondary: #98989a !default;

/***************Bright Green Pattern****************/
$fhl--brightgreen-base: #3c610f !default;
$fhl--brightgreen-larger: #68a51d !default;
$fhl--brightgreen-secondary: #528316 !default;

/***************Bright Blue Pattern****************/
$fhl--brightblue-base: #005f85 !default;
$fhl--brightblue-larger: #009ddb !default;
$fhl--brightblue-secondary: #007cad !default;

/***************Danger/Error Pattern****************/
$fhl--danger-base: #9f1c33 !default;

$fhl--green-palette: (
    "50": $fhl--green-base,
    "100": $fhl--green-larger
);

$fhl--gray-palette: (
    "50": $fhl--gray-base,
    "100": $fhl--gray-larger,
    "200": $fhl--gray-secondary
);

$fhl--brightgreen-palette: (
    "50": $fhl--brightgreen-base,
    "100": $fhl--brightgreen-larger,
    "200": $fhl--brightgreen-secondary
);

$fhl--brightblue-palette: (
    "50": $fhl--brightblue-base,
    "100": $fhl--brightblue-larger,
    "200": $fhl--brightblue-secondary
);

$fhl--danger-palette: (
    "100": $fhl--danger-base
);

$fhl-config: deep-map-merge(
    (
        "color": (
            "primary-green": map-merge($fhl--green-palette, ()),
            "secondary-green": map-merge($fhl--brightgreen-palette, ()),
            "bright-blue": map-merge($fhl--brightblue-palette, ()),
            "gray": map-merge($fhl--gray-palette, ()),
            "danger": map-merge($fhl--danger-palette, ()),
            "white": $white,
            "black": $black
        )
    ),
    $fhl-config,
    true
);

//
// TO DO : Color system
//
// FHL Bank Misc Colors will follows here
$smokyWhite: #fcfcfc;
$lightGray: #f3f3f3;
$footer-primary: #415364;
$footer-base: #002a3a;
$black: #000;
$white: #fff;
$searchboxbg: #a4cbb5;
$borderLine: #d8d8d8;
$taglineGreen: #007a37;
$borderBlue: #00a0df;
$dark-blue: #004987;
$shadow-smokywhite-color: #e5e5e5;
$default-prime-gray: #454545;
$btn-disabled: #767676;
$border-dark-line: #939395;
// Body
//
// Settings for the `<body>` element.

$body-bg: get("color.white") !default;

// Links
//
// Style anchor elements.
