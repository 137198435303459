.fhl-button-content-container {
  background-color: get("color.white");
  border-width: convert-unit(1px, "rem");
  border-style: solid;
  border-color: inherit;
  box-shadow: convert-unit(2px, "rem") convert-unit(2px, "rem")
    convert-unit(4px, "rem") 0 $shadow-smokywhite-color;
  border-radius: convert-unit(4px, "rem");
  // flex-direction: row;
  // align-content: space-between;
  width: 85%;
  // width: convert-unit(260px, "rem");
  height: auto;
  overflow: visible;
  padding: convert-unit(10px, "rem");
  float: right;
  cursor: pointer;
  .left-section {
    width: 80%;
    p {
      font-family: "OpenSans-Regular";
      font-size: convert-unit(16px, "rem");
      color: inherit;
      margin: 0;
      line-height: convert-unit(25px, "rem");
      text-transform: none;
      letter-spacing: 0;
    }
  }
  .right-section {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    a {
      margin-right: convert-unit(-20px, "rem");
    }
  }
  .left-section,
  .right-section {
    display: table-cell;
    vertical-align: middle;
  }
}
a.left-arrow-icon:hover {
  .fhl-button-content-container {
    .left-section {
      p {
        text-decoration: underline;
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .compare-advance-mobile-wrapper {
    .fhl-large-titlebar-wrapper {
      h1 {
        font-size: convert-unit(32px, "rem");
      }
    }
  }
  .fhl-button-content-container {
    margin-top: 0px;
    overflow-x: hidden;
    width: 100%;
    .left-section {
      width: 85%;
    }
    .right-section {
      width: 15%;
    }
  }
  .com-adv-button-wrapper {
    .fhl-button-content-container {
      width: convert-unit(310px, "rem");
      float: left;
    }
    a.left-arrow-icon {
      margin-top: convert-unit(-40px, "rem");
      float: left;
    }
  }
  .compare-advances-products-wrapper {
    margin: 0px convert-unit(15px, "rem");
  }
}
@media only screen and (min-width: 768px) {
  .fhl-button-content-container {
    width: 100%;
  }
  .com-adv-button-wrapper {
    a.left-arrow-icon i {
      text-align: left;
      float: left;
      margin-left: 20px;
    }
  }
}
