.news-article-wrapper {
    flex-direction: column;
    overflow: visible;
    h2,
    .newsContent-article-date,
    .news-article-content-block h4,
    .news-article-content-block h3,
    .news-article-content-block .news-content {
        word-break: break-word;
    }
    h2 {
        // margin: convert-unit(25px, "rem") 0 0 0;
        color: inherit;
        font-size: convert-unit(32px, "rem");
        line-height: convert-unit(38.4px, "rem");
    }
    .newsContent-article-date {
        font-size: convert-unit(14px, "rem");
        color: inherit;
        margin-bottom: convert-unit(10px, "rem");
        letter-spacing: convert-unit(2.33px, "rem");
    }
    .news-article-content-block {
        h4, h3 {
            font-size: convert-unit(21px, "rem");
            font-family: "HarmoniaSansW05-Semibold";
            font-weight: 600;
            font-style: normal;
            color: inherit;
            line-height: convert-unit(25.2px, "rem");
            a,
            a:hover {
                text-decoration: none;
                color: inherit;
            }
        }
        div {
            @include fontfamilyOpenSans;
            p {
                font-size: convert-unit(14px, "rem");
                color: inherit;
                line-height: convert-unit(21px, "rem");
            }
        }
        > p {
            font-family: "Opensans-Regular";
            margin-top: convert-unit(5px, "rem");
            font-size: convert-unit(14px, "rem");
            color: inherit;
            line-height: convert-unit(21px, "rem");
            margin-bottom: 0px;
            a.newsContent-anchor {
                font-size: convert-unit(14px, "rem");
                color: inherit;
                letter-spacing: convert-unit(1px, "rem");
                line-height: convert-unit(16.8px, "rem");
            }
        }
        p {
            color: inherit;
            a {
                color: inherit;
            }
        }
    }
}
@media only screen and (min-width: 320px) {
    .news-article-wrapper {
        margin: 0px;
        width: 100%;
    }
}
@media only screen and (min-width: 768px) {
    .news-article-wrapper {
        margin-left: 10%;
        width: 90%;
    }
}
