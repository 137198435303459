////
/// Typography
/// Overall styling of Text and Icons
/// @type Typography
/// @group typography-helpers
/// @access public
////

/// For path partial reuse when defining @font-face's
$fonts-path: "../assets/fonts" !default;

/// Body Font Families [BS4]
$font-family-sans-serif: "HarmoniaSansW05-Regular", "Helvetica Neue", Arial, sans-serif !default;
/// Header Font Families [BS4]
$headings-font-family: "HarmoniaSansW05-Regular", "Helvetica Neue", Arial, sans-serif !default;

//Font-size (base, headings, small) [BS4]
// TODO: font-base-size should be 1rem:16px (March 2019)
$font-size-base: 0.875rem !default;
$font-size-lg: $font-size-base * 1.25 !default; //20px
$font-size-sm: $font-size-base * 0.875 !default; //14px
$h1-font-size: $font-size-base * 3 !default; // 48px
$h2-font-size: $font-size-base * 2.5 !default; //40px
$h3-font-size: $font-size-base * 2 !default; //32px
$h4-font-size: $font-size-base * 1.75 !default; //28px
$h5-font-size: $font-size-base * 1.5 !default; //24px
$h6-font-size: $font-size-base * 1.375 !default; //22px
$small-font-size: $font-size-base * 0.8125 !default; //13px
$extra-small-font-size: $font-size-base * 0.75 !default; //12px
$lead-font-size: $font-size-lg !default;

//Margins [BS4]
$headings-margin-bottom: ($spacer / 2) !default;

//Font-colors [BS4]
$headings-color: color("neutrals", "darkest") !default;
$text-muted: color("neutrals", "dark") !default;
$body-color: color("neutrals", "darkest") !default;
$text-support: color("neutrals", "darker");

//Font-weight [BS4]
$font-weight-regular: normal !default; //400
$font-weight-light: 100 !default;
$font-weight-medium: 300 !default;
$font-weight-bold: 500 !default;
$lead-font-weight: 700 !default;

//Line-height [BS4]
//Divide the absolute line height number by the font size number. 24 (line-height) divided by 16 (font-size-base) gives us 1.5
$line-height-base: 1.4 !default;
$headings-line-height: 1.2 !default;
