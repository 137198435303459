.si-side-article-content-container {
  position: relative;
  text-align: left;
  margin-top: 58px;
  h1 {
    font-size: convert-unit(42px, "rem");
    line-height: convert-unit(50.4px, "rem");
    margin-bottom: convert-unit(30px, "rem");
  }
  h3 {
    font-size: convert-unit(24px, "rem");
    line-height: convert-unit(28.8px, "rem");
    margin: convert-unit(10px, "rem") 0px;
  }
  .side-article-type {
    font-size: convert-unit(16px, "rem");
    line-height: convert-unit(19.2px, "rem");
    letter-spacing: convert-unit(2px, "rem");
    color: get("color.gray.50");
  }
  .side-article-title {
    font-size: convert-unit(24px, "rem");
    line-height: convert-unit(28.8px, "rem");
    margin-top: convert-unit(10px, "rem");
    margin-bottom: convert-unit(15px, "rem");
  }
  .side-article-description {
    font-size: convert-unit(16px, "rem");
    line-height: convert-unit(22.4px, "rem");
    margin-top: convert-unit(0px, "rem");
    font-family: "Opensans-Regular";
  }
  .si-card-layout-anchor-btn {
    min-height: convert-unit(30px, "rem");
    min-width: convert-unit(140px, "rem");
    border-radius: convert-unit(4px, "rem");
    font-size: convert-unit(15px, "rem");
    line-height: convert-unit(16.8px, "rem");
    letter-spacing: convert-unit(0px, "rem");
    margin: convert-unit(20px, "rem") 0 convert-unit(32px, "rem");
    display: inline-block;
    text-align: center;
    font-family: "HarmoniaSansW05-Regular";
    padding: 0.375rem 0.75rem;
  }
  .si-card-layout-anchor-btn:hover {
    text-decoration: none;
  }
  a {
    color: inherit;
  }
}
