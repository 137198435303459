.fhl-title-subTitle-wrapper {
  h2 {
    font-size: convert-unit(32px, "rem");
    color: inherit;
    line-height: convert-unit(38.4px, "rem");
  }
  h3 {
    font-size: convert-unit(24px, "rem");
    color: inherit;
    line-height: convert-unit(28.8px, "rem");
  }
}
.fhl-mediumTitle-subTitle-wrapper {
  h2 {
    font-size: convert-unit(32px, "rem");
    color: inherit;
    line-height: convert-unit(38.4px, "rem");
  }
  div {
    font-size: convert-unit(24px, "rem");
    color: inherit;
    line-height: convert-unit(28.8px, "rem");
  }
}
.fundingGoal-container {
  button {
    background-color: $dark-blue;
    border-radius: convert-unit(4px, "rem");
    font-size: convert-unit(16px, "rem");
    color: get("color.white");
    text-align: center;
    width: convert-unit(139px, "rem");
    height: convert-unit(35px, "rem") !important;
    float: left;
    margin-top: convert-unit(60px, "rem");
    cursor: pointer;
  }
  button:focus {
    outline: 0;
    border: 0;
    box-shadow: 0 0 0 0.1rem #2b9be0 !important;
  }
  button:disabled {
    background-color: $btn-disabled !important;
    color: $white !important;
    cursor: not-allowed;
  }
}

.fundingGoal-dropdown-container {
  button {
    background-color: $dark-blue;
    border-radius: convert-unit(4px, "rem");
    font-size: convert-unit(16px, "rem");
    color: get("color.white");
    text-align: center;
    width: convert-unit(139px, "rem");
    height: convert-unit(35px, "rem") !important;
    float: left;
    cursor: pointer;
    line-height: convert-unit(16.8px, "rem");
    word-break: break-word;
    display: block;
    margin-top: convert-unit(60px, "rem");
  }
   button:focus {
    box-shadow: 0 0 0 0.1rem #2b9be0 !important;
   }
  button:disabled {
    background-color: $btn-disabled !important;
    color: $white !important;
    cursor: not-allowed;
  }
  button:hover {
    text-decoration: none;
  }
}

.form-control-funding:focus {
  color: #495057;
  background-color: #fff;
  border-color: #212223;
  outline: 0;
  box-shadow: 0 0 0 0.1rem #58585a;
}

.form-control-funding {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.mpf-horizontal-select {
  margin: 0px !important;
  width: 96% !important;
  .fhl-select {
    width: 97% !important;
    .select-side {
      i {
        color: get("color.primary-green.100");
      }
    }
  }
  button {
    background-color: get("color.primary-green.100");
    margin: 0px 0px 0px convert-unit(15px, "rem") !important;
    height: convert-unit(39px, "rem") !important;
  }
}
.mpf-manage-membership {
  .fhl-title-subTitle-wrapper {
    width: 100% !important;
  }
  .fhl-mediumTitle-subTitle-wrapper {
    width: 100% !important;
  }
}
.mpf-horizontal-select-container {
  height: 100%;
  align-self: flex-end;
  > div {
    float: left;
    width: 100% !important;
  }

  button:focus {
    box-shadow: 0 0 0 0.1rem #2b9be0 !important;
   }
}
.different-option-container {
  .fhl-select {
    width: 90%;
    .select-side {
      top: 0px;
      bottom: 0;
      height: convert-unit(38px, "rem");
      i {
        color: get("color.primary-green.100");
      }
    }
  }
  button[type="submit"] {
    background-color: get("color.primary-green.100");
    border-radius: convert-unit(4px, "rem");
    font-size: convert-unit(15px, "rem");
    color: get("color.white");
    text-align: center;
    width: convert-unit(120px, "rem");
    height: convert-unit(35px, "rem") !important;
    float: left;
    margin-top: convert-unit(60px, "rem");
    cursor: pointer;
  }
  button:focus {
    outline: 0;
    border: 0;
    box-shadow: 0 0 0 0.1rem #2b9be0 !important;
  }
  button:disabled {
    background-color: $btn-disabled !important;
    color: $white !important;
    cursor: not-allowed;
  }
}

@media only screen and (max-width: 374px) {
  .fundingGoal-container {
    .fhl-select {
      width: 100%;
      select {
        padding-left: convert-unit(3px, "rem");
      }
    }
  }
}
@media only screen and (min-width: 375px) {
  .fundingGoal-container {
    margin: 0px convert-unit(10px, "rem");
    h2 {
      width: 90%;
    }
    .fhl-select {
      width: 90%;
    }
  }
}
@media only screen and (min-width: 375px) and (max-width: 767px) {
  .products-promo-media-wrapper {
    padding-left: 5px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mpf-horizontal-select {
    width: 100% !important;
    .fhl-select {
      width: 100% !important;
    }
    button {
      margin: convert-unit(20px, "rem") 0px 0px 0px !important;
    }
  }
  .template-mpf-bdr {
    margin: convert-unit(25px, "rem") 0rem convert-unit(30px, "rem");
  }
}
@media only screen and (min-width: 768px) {
  .fundingGoal-container {
    .fhl-select {
      width: 100%;
    }
  }
  .mpf-manage-membership {
    max-width: 610px !important;
    .fhl-title-subTitle-wrapper {
      h2 {
        margin-bottom: 10px !important;
      }
    }
    .fhl-mediumTitle-subTitle-wrapper {
      h3 {
        margin-bottom: 10px !important;
      }
    }
    .mpf-horizontal-select {
      .fhl-select {
        width: 70% !important;
      }
      button {
        margin: convert-unit(20px, "rem") 0px 0px 0px !important;
      }
    }
  }
}
@media only screen and (min-width: 992px) {
  .mpf-manage-membership {
    max-width: 960px !important;
    .fhl-title-subTitle-wrapper {
      h2 {
        margin-bottom: convert-unit(24px, "rem") !important;
      }
      h3 {
        margin-bottom: convert-unit(8px, "rem") !important;
      }
    }
    .fhl-mediumTitle-subTitle-wrapper {
      h2 {
        margin-bottom: convert-unit(24px, "rem") !important;
      }
      div {
        margin-bottom: convert-unit(8px, "rem") !important;
      }
    }
    .mpf-horizontal-select {
      .fhl-select {
        width: 90% !important;
      }
      button {
        margin: 0px 0px 0px convert-unit(-10px, "rem") !important;
      }
    }
  }
}
@media only screen and (min-width: 1200px) {
  .mpf-manage-membership {
    max-width: 1140px !important;
    .mpf-horizontal-select {
      .fhl-select {
        width: 100% !important;
      }
      button {
        margin: 0px 0px 0px convert-unit(16px, "rem") !important;
      }
    }
  }
}
