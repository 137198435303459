.si-promo-content-container {
    position: relative;
    min-height: 325px;
    padding: 60px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .si-promo-content-img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 0;
        min-height: convert-unit(325px, "rem");
        overflow: hidden;
        img {
            width: 100%;
        }
    }
}
@media only screen and (max-width: 767px) {
    .si-promo-content-container {
        padding: convert-unit(10px, "rem");
        min-height: convert-unit(65px, "rem");
        background-color: $lightGray;
        overflow: hidden;
        .si-promo-content-img {
            top: auto;
            width: 100%;
            margin-left: convert-unit(0px, "rem");
            min-height: 100%;
            bottom: 0;
            img {
                width: 100%;
                margin-left: convert-unit(0px, "rem");
                left: 0;
                max-width: 100%;
            }
        }
    }
}

@media only screen and (min-width: 768px) {
    .si-promo-content-container {
        background-color: $lightGray;
        padding: convert-unit(30px, "rem") 0px;
        min-height: convert-unit(90px, "rem");
        .si-promo-content-img {
            width: 130%;
            min-height: 100%;
            top: 0;
            img {
                margin: 0;
                height: auto;
                width: 100%;
                max-width: 100%;
                position: absolute;
                left: -15%;
            }
        }
    }
}

@media only screen and (min-width: 992px) {
    .si-promo-content-container {
        min-height: convert-unit(325px, "rem");
        padding: convert-unit(60px, "rem") 0px;
        background-color: transparent;
        .si-promo-content-img {
            min-height: convert-unit(325px, "rem");
            width: 125%;
            top: 0;
            img {
                margin: 0;
                left: -12%;
            }
        }
    }
}

@media only screen and (min-width: 1200px) {
    .si-promo-content-container {
        .si-promo-content-img {
            width: 110%;
            top: 0;
            img {
                left: 0;
                margin: 0;
                width: 100%;
            }
        }
    }
}
