.narrow-list-wrapper {
  .result-count{
    font-family: "Opensans-Regular";
    font-size: convert-unit(12px, "rem");
    color: #58585A;
    letter-spacing: 0;
    line-height: convert-unit(14px, "rem");
    padding-bottom: convert-unit(18px, "rem");
  }
  max-width: 225px;
  input[type="checkbox"]:checked {
    background-color: get("color.primary-green.100");
  }
  h5,
  h3 {
    // font-size: convert-unit(20px, "rem") !important;
    font-size: convert-unit(18px, "rem") !important;
    color: $footer-base;
    line-height: convert-unit(25.2px, "rem");
    button.clearall {
      font-family: "Opensans-Regular";
      font-size: convert-unit(13px, "rem");
      color: get("color.gray.50");
      letter-spacing: convert-unit(1px, "rem");
      float: right;
      cursor: pointer;
      background: none;
      border: 0;
      padding: 0;
      outline: inherit;
      margin-top: convert-unit(2px, "rem");
    }
    button.clearall:focus {
      outline: auto;
      box-shadow: 0 0 0 0.1rem #2b9be0 !important;
    }
  }
  hr {
    border-bottom: convert-unit(1px, "rem") solid get("color.primary-green.100");
    margin: 0;
  }
  .accordion {
    .card-header-button {
      .card-title-content {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0;
        font-family: "HarmoniaSansW05-Regular";
        color: get("color.gray.50");
        letter-spacing: convert-unit(2px, "rem");
        font-size: convert-unit(11px, "rem");
        //line-height: 19.2px;
      }
      button {
        background: none;
        border: 0;
        padding: 0;
      }
      button:focus,
      .card-title-content:focus {
        outline: none;
      }
      button:focus > .card-title-content {
        box-shadow: 0 0 0 0.1rem #2b9be0 !important;
      }
    }
    .card-header {
      width: 100%;
      display: flex;
      height: 35px;
      text-align: left;
      justify-content: space-between;
      padding: 0 10px;
      line-height: 35px;
      color: #454545;
      background-color: transparent;
      border: 0px;

      a,
      button,
      .card-title-content {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0;
        font-family: "HarmoniaSansW05-Regular";
        color: get("color.gray.50");
        letter-spacing: convert-unit(2px, "rem");
        font-size: convert-unit(11px, "rem");
        //line-height: 19.2px;
      }
      button {
        background: none;
        border: 0;
        padding: 0;
      }
      button:focus,
      .card-title-content:focus {
        outline: none;
      }
      button:focus > .card-title-content {
        box-shadow: 0 0 0 0.1rem #2b9be0 !important;
      }
      a:hover {
        text-decoration: none !important;
      }
    }
  }
  .accordion-title-bar {
    button.categorytopic {
      // font-size: convert-unit(14px, "rem");
      font-size: convert-unit(11px, "rem");
      color: get("color.gray.50");
      letter-spacing: convert-unit(2px, "rem");
      text-align: left;
      line-height: convert-unit(19.2px, "rem");
      display: block;
      width: 100%;
      padding: convert-unit(10px, "rem");
      text-transform: uppercase;
      i {
        float: right;
      }
    }
    button:focus {
      outline: none;
      border: 0;
    }
    i.add {
      font-size: convert-unit(16px, "rem");
    }
  }
  .accordion-content-box {
    .card-body {
      padding: convert-unit(10px, "rem");
      .filter-list {
        display: flex;

        label {
          display: flex;
          position: relative;
          margin-bottom: convert-unit(10px, "rem");
          cursor: pointer;
          input {
            opacity: 0;
            cursor: pointer;
            margin-right: convert-unit(10px, "rem");
          }

          span.filter-item:active,
          span.filter-item:hover,
          span.filter-item:focus,
          p.filter-item:active,
          p.filter-item:hover,
          p.filter-item:focus {
            outline: none !important;
            box-shadow: none !important;
          }
          input:focus ~ span.filter-item,
          input:focus ~ p.filter-item {
            outline: auto;
            box-shadow: auto;
          }

          .filter-item {
            display: inline;
            font-family: "Opensans-Regular";
            //font-size: convert-unit(14px, "rem");
            font-size: convert-unit(12px, "rem");
            color: get("color.gray.50");
            line-height: convert-unit(14px, "rem");
          }
          .checkmark {
            position: absolute;
            height: convert-unit(13px, "rem");
            width: convert-unit(13px, "rem");
            z-index: 1;
            border: convert-unit(0.5px, "rem") solid
              get("color.primary-green.100");
            margin-right: 10px;
            left: 0;
          }
          .checkmarkradio {
            z-index: 1;
            border: convert-unit(0.5px, "rem") solid
              get("color.primary-green.100");
            position: absolute;
            height: convert-unit(15px, "rem");
            width: convert-unit(15px, "rem");
            border-radius: convert-unit(8px, "rem");
            left: 0;
          }
          /* When the checkbox is checked, add a green background */
          input:checked ~ .checkmark {
            background-color: get("color.primary-green.100");
          }
          /* When the radio is clicked, add a green background */
          input:checked ~ .checkmarkradio {
            background-color: get("color.primary-green.100");
          }
          /* Create the checkmark/indicator (hidden when not checked) */
          .checkmark:after,
          .checkmarkradio:after {
            content: "";
            position: absolute;
            display: none;
          }
          /* Create the checkmarkradio/indicator (hidden when not clicked) */
          //  {
          //     content: "";
          //     position: absolute;
          //     display: none;
          // }

          /* Show the checkmark when checked */
          input:checked ~ .checkmark:after,
          input:checked ~ .checkmarkradio:after {
            display: block;
          }
          /* Show the checkmark when checked */
          input:checked ~ .checkmarkradio:after {
            display: block;
          }

          /* Style the checkmark/indicator */
          .checkmark:after {
            left: 2px;
            top: -1px;
            width: convert-unit(7px, "rem");
            height: convert-unit(10px, "rem");
            border: convert-unit(1px, "rem") solid $white;
            border-width: 0 convert-unit(2px, "rem") convert-unit(2px, "rem") 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
          /* Style the checkmarkradio/indicator */
          .checkmarkradio:after {
            left: 3px;
            top: 2px;
            width: convert-unit(9px, "rem");
            height: convert-unit(9px, "rem");
            border-radius: 50%;
            background-color: $white;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1366px) {
  .narrow-list-wrapper {
    .accordion-content-box {
      .card-body {
        .filter-list label {
          .checkmarkradio:after {
            left: 2px;
          }
        }
      }
    }
  }
}
