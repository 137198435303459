.strategies-media-wrapper {
  padding: convert-unit(50px, "rem") 0 0 !important;
}
.strategies-results-wrapper {
  .strategies-result-page {
    min-height: convert-unit(765px, "rem");
  }
  li {
    list-style-type: none;
  }
  overflow: visible;
  /* Hide the browser's default checkbox */

  .strategies-result-card-box {
    margin: convert-unit(5px, "rem") 0 convert-unit(40px, "rem");
    .contentType {
      color: $btn-disabled;
      font-size: convert-unit(12px, "rem");
      letter-spacing: convert-unit(2px, "rem");
      line-height: convert-unit(16px, "rem");
      text-transform: uppercase;
    }
    img {
      width: convert-unit(61px, "rem");
      height: convert-unit(61px, "rem");
    }
    h3 {
      font-size: convert-unit(24px, "rem");
      color: get("color.primary-green.100");
      line-height: convert-unit(28.8px, "rem");
      letter-spacing: convert-unit(0px, "rem");
      margin-bottom: convert-unit(10px, "rem");
      a {
        color: $dark-blue !important;
      }
    }
    .strategies-date {
      font-size: convert-unit(14px, "rem");
      color: get("color.gray.50");
      line-height: convert-unit(18px, "rem");
      letter-spacing: convert-unit(2.33px, "rem");
      text-transform: uppercase;
    }
    a.si-result-title,
    a {
      font-size: convert-unit(14px, "rem");
      color: get("color.primary-green.100");
      line-height: convert-unit(23px, "rem");
      letter-spacing: convert-unit(1px, "rem");
      display: table;
      span {
        display: inline-block;
        float: right;
      }
    }
    a.learn-more-link {
      padding-top: convert-unit(20px, "rem");
      line-height: convert-unit(16.8px, "rem");
      span {
        margin-top: -3px;
      }
    }
  }
}
.si-video-main-wrapper,
.si-infographic-main-wrapper {
  display: block;
  overflow: visible;
  min-height: convert-unit(150px, "rem");
  background-color: $white;
  .fhlb-split-content-left-section {
    .fhl-extrasmall-title-wrapper {
      h3 {
        padding-left: convert-unit(35px, "rem");
      }
    }
    .fhl-h2-medium-title-wrapper {
      h2 {
        padding-left: convert-unit(35px, "rem");
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .fhl-si-video-richtext-wrapper,
  .si-side-article-content-container {
    margin-bottom: convert-unit(40px, "rem");
  }
  .mob-mb-0 .si-side-article-content-container {
    margin-bottom: 0px !important;
  }
  .si-side-article-content-container {
    margin-top: convert-unit(35px, "rem");
    .side-article-title {
      margin-bottom: 0px;
    }
    .si-card-layout-anchor-btn {
      margin: convert-unit(20px, "rem") 0 convert-unit(40px, "rem");
    }
    .side-article-description {
      margin-top: convert-unit(15px, "rem");
      margin-bottom: convert-unit(10px, "rem");
    }
  }
  .si-video-wrapper,
  .si-infographic-main-wrapper {
    .fhlb-split-content-right-section {
      .fhl-button-content-container,
      .left-arrow-icon {
        width: convert-unit(260px, "rem");
      }
    }
  }

  .strategies-more-results-wrapper {
    .strategies-results-wrapper {
      .col-md-4 {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: convert-unit(52px, "rem");
      }
    }
    .col-md-3 {
      max-width: 100%;
      flex: 0 0 100%;
      margin-bottom: convert-unit(10px, "rem");
      padding: 0;
    }
    .col-md-9 {
      max-width: 90%;
      flex: 0 0 90%;
      padding: 0;
    }
  }
  .strategies-media-wrapper {
    padding: convert-unit(30px, "rem") convert-unit(16px, "rem") !important;
    .narrow-list-wrapper {
      max-width: 100%;
      margin-bottom: convert-unit(30px, "rem");
    }
    .strategies-results-wrapper {
      .strategies-result-page {
        .col-md-6 {
          padding: 0;
          margin-bottom: convert-unit(52px, "rem");
        }
        .col-md-12 {
          padding: 0;
        }
        .strategies-result-card-box {
          .col-md-3 {
            margin-bottom: convert-unit(10px, "rem");
          }
        }
      }
    }
  }
  .strategies-result-card-box {
    margin-bottom: 0px !important;
  }
  .si-article-main-wrapper {
    .fhlb-split-content-right-area,
    .strategies-more-title-wrapper,
    .strategies-more-results-wrapper {
      padding-left: convert-unit(15px, "rem") !important;
      .error-message-wrapper {
        .error-message-space {
          margin: 3% 0px -5%;
        }
      }
    }
    .fhlb-split-content-right-section {
      .fhlb-split-content-right-area {
        a,
        .fhl-button-content-container {
          max-width: convert-unit(260px, "rem");
        }
      }
    }
  }
  .min-screen-992px-pl-pr-0 {
    .white-bg.extra-space-whiteBg {
      padding-top:15px !important;
      padding-bottom:15px !important;
    }
  }
}
@media only screen and (max-width: 1024px) {
  .min-screen-992px-pl-pr-0 {
    .white-bg.extra-space-whiteBg {
      padding-top:20px !important;
      padding-bottom:15px !important;
    }
  }
}
@media only screen and (min-width: 768px) {
  .si-article-main-wrapper,
  .si-video-wrapper,
  .si-infographic-main-wrapper {
    .fhlb-split-content-right-area {
      margin-top: convert-unit(15px, "rem");
    }
  }
  .strategies-media-wrapper {
    .row {
      .col-md-3 {
        max-width: 35%;
        .narrow-list-wrapper {
          width: 225px;
        }
      }
      .col-md-9 {
        max-width: 65%;
        .strategies-result-page {
          .strategies-result-card-box {
            .col-md-3 {
              margin-bottom: convert-unit(10px, "rem");
            }
            .col-md-3,
            .col-md-9 {
              max-width: 100%;
              flex: 0 0 100%;
            }
          }
        }
      }
    }
  }
  .strategies-more-results-wrapper {
    .col-md-3 {
      max-width: 100%;
      flex: 0 0 100%;
      margin-bottom: convert-unit(10px, "rem");
      padding: 0;
    }
    .col-md-9 {
      max-width: 90%;
      flex: 0 0 90%;
      padding: 0;
    }
    .error-message-wrapper {
      .error-message-space {
        margin: 3% 0px -5%;
      }
    }
  }
}
@media only screen and (min-width: 992px) {
  .strategies-media-wrapper,
  .strategies-more-results-wrapper {
    .row {
      .col-md-3 {
        max-width: 30%;
        padding: 0 0 0 15px;
        flex: 0 0 30%;
      }
      .col-md-9 {
        max-width: 70%;
        padding: 0px 15px 0px 0px;
        flex: 0 0 70%;
        .strategies-result-page {
          .strategies-result-card-box {
            .col-md-3 {
              max-width: 30%;
              flex: 0 0 30%;
            }
            .col-md-9 {
              max-width: 70%;
              flex: 0 0 70%;
            }
          }
        }
      }
    }
  }
  .si-article-main-wrapper {
    .strategies-more-title-wrapper {
      padding: 0px convert-unit(15px, "rem") !important;
    }
  }
  .si-article-main-wrapper,
  .si-video-wrapper,
  .si-infographic-main-wrapper {
    .fhlb-split-content-right-area {
      margin-top: convert-unit(0px, "rem");
    }
  }
  .si-video-editable-templates,
  .si-video-main-wrapper,
  .si-infographic-main-wrapper {
    .strategies-more-title-wrapper,
    .strategies-more-results-wrapper {
      padding: 0px convert-unit(35px, "rem") !important;
    }
    .strategies-more-results-wrapper {
      .row {
        .col-md-3 {
          max-width: 25%;
          padding: 0 0 0 0px;
          flex: 0 0 25%;
        }
        .col-md-9 {
          max-width: 75%;
          padding: 0px 15px 0px 0px;
          flex: 0 0 75%;
        }
      }
    }
  }
  .si-article-main-wrapper {
    max-width: convert-unit(990px, "rem") !important;
  }
  .si-article-main-wrapper {
    .fhlb-split-content-left-section {
      margin-top: convert-unit(-230px, "rem");
      margin-right: 85px;
    }
    .fhlb-split-content-right-section {
      flex: 0 0 30% !important;
      max-width: 250px !important;
      .fhlb-split-content-right-area {
        .fhl-button-content-container {
          max-width: 100%;
        }
      }
    }
  }
  .min-screen-992px-pl-pr-0{
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media only screen and (min-width: 1200px) {
  .white-bg.si-article-main-wrapper {
    max-width: convert-unit(1180px, "rem") !important;
    padding: 0px convert-unit(30px, "rem") !important;
  }
  .strategies-media-wrapper,
  .strategies-more-results-wrapper {
    .row {
      .col-md-9 {
        .strategies-result-page {
          .strategies-result-card-box {
            .col-md-3 {
              max-width: 25%;
              flex: 0 0 25%;
            }
            .col-md-9 {
              max-width: 75%;
              flex: 0 0 75%;
            }
          }
        }
      }
    }
  }
}
