.promo-content-without-title-container {
    position: relative;
    min-height: convert-unit(525px, "rem");
    padding: convert-unit(60px, "rem") 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .promo-content-without-img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 0;
        min-height: convert-unit(525px, "rem");
        overflow: hidden;
        img {
            width: 100%;
            margin-left: 0%;
        }
    }
    .promo-main-without-content {
        text-align: left;
        display: flex;
        width: convert-unit(1140px, "rem");
        .promo-col1 {
            display: flex;
            flex-direction: column;
            text-align: left;
            max-width: 55%;
            flex: 2;
            .promo-tag-cntr {
                position: relative;
                width: 100%;
                //color: $taglineGreen;
                font-size: convert-unit(18px, "rem");
                margin: convert-unit(50px, "rem") 0 0 0;
                font-family: "HarmoniaSansW05-Italic";
                font-style: italic;
                font-weight: normal;
                line-height: convert-unit(21px, "rem");
            }
            p {
                margin: 0 !important;
            }
            .promo-tag-name {
                position: relative;
                margin-top: convert-unit(25px, "rem");
                font-family: "Opensans-Regular";
                font-size: convert-unit(16px, "rem");
                //color: $footer-base;
                line-height: convert-unit(22.4px, "rem");
            }
        }
        .promo-col1 {
            max-width: 37%;
            margin-right: convert-unit(80px, "rem");
            flex: 1;
        }
    }
}
@media only screen and (max-width: 767px) {
    .promo-content-without-title-container {
        padding: convert-unit(10px, "rem") convert-unit(10px, "rem") 0px;
        min-height: 100%;
        background-color: $lightGray;
        overflow: hidden;
        flex-direction: column-reverse;
        margin-top: convert-unit(74px, "rem");
        .promo-content-without-img {
            width: 100%;
            min-height: 100%;
            position: relative;
        }
        .promo-main-without-content {
            flex-direction: column;
            width: 100%;
            .promo-col1 {
                max-width: 100% !important;
                margin: 0px 5%;
                width: 90%;
                min-height: 100%;
                .promo-tag-cntr {
                    font-size: convert-unit(18px, "rem") !important;
                    margin: convert-unit(60px, "rem") 0 0 !important;
                    overflow: hidden;
                }
                .promo-tag-name {
                    margin-top: convert-unit(25px, "rem");
                    width: 90%;
                }
            }
        }
    }
}
@media only screen and (min-width: 768px) {
    .promo-content-without-title-container {
        background-color: transparent;
        min-height: convert-unit(450px, "rem");
        margin-top: convert-unit(50px, "rem");
        .promo-content-without-img {
            left: convert-unit(-140px, "rem");
            min-height: convert-unit(450px, "rem");
            img {
                width: 150%;
                margin-left: -30%;
            }
        }
        .promo-main-without-content {
            width: convert-unit(708px, "rem");
            .promo-col1 {
                max-width: 58%;
                .promo-tag-cntr {
                    margin-top: 0;
                    font-size: convert-unit(16px, "rem");
                    p {
                        color: inherit;
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 992px) {
    .emp-bio-ipad {
        margin-left: convert-unit(15px, "rem");
    }
    .promo-content-without-title-container {
        margin-top: convert-unit(0px, "rem");
        .promo-main-without-content {
            width: convert-unit(964px, "rem");
            .promo-col1 {
                max-width: 45%;
                .promo-tag-cntr {
                    margin-top: convert-unit(50px, "rem");
                    font-size: convert-unit(18px, "rem");
                    p {
                        color: inherit;
                    }
                }
            }
        }
        .promo-content-without-img {
            min-height: convert-unit(525px, "rem");
            img {
                width: 122%;
                margin-left: -10%;
            }
        }
    }
}
@media only screen and (min-width: 1200px) {
    .emp-bio-ipad {
        margin-left: convert-unit(0px, "rem");
    }
    .promo-content-without-title-container {
        .promo-main-without-content {
            .promo-col1 {
                max-width: 55%;
            }
        }
        .promo-content-without-img img {
            width: 100%;
        }
        .promo-content-without-img {
            img {
                margin-left: 0%;
            }
        }
    }
}
