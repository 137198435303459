@import "../helpers/mixins";

.fhlb-split-content-left-section {
  .fhlb-split-content-left-area {
    .fhl-title-content-btn-wrapper {
      h2 {
        font-family: "HarmoniaSansW05-Semibold";
        font-size: convert-unit(28px, "rem");
        color: inherit;
        letter-spacing: 0;
        line-height: convert-unit(33.6px, "rem");
      }
      div {
        @include fontfamilyHarmoniaSans;
        p {
          font-size: convert-unit(18px, "rem");
          color: inherit;
          letter-spacing: 0;
          line-height: convert-unit(21.6px, "rem");
          margin-bottom: -12px;
        }
      }
    }
    .fhl-richtext-wrapper,
    .fhl-lists-wrapper {
      font-family: "OpenSans-Regular";
      font-size: convert-unit(16px, "rem");
      color: inherit;
      line-height: convert-unit(22.4px, "rem");
      text-align: left;
      p {
        margin-bottom: 0px !important;
        a {
          color: inherit;
        }
        span {
          em {
            font-family: "OpenSans-Italic";
            font-weight: normal;
            font-style: italic;
            color: inherit;
          }
        }
        em {
          font-family: "OpenSans-Italic";
          font-weight: normal;
          font-style: italic;
          color: inherit;
        }
        strong,
        b {
          font-family: "OpenSans-Semibold";
          font-weight: 300;
          font-style: normal;
          color: inherit;
          em {
            font-family: "OpenSans-SemiboldItalic";
            font-weight: 300;
            font-style: italic;
            color: inherit;
          }
        }
      }
    }
  }
}

.fhlb-split-content-right-section {
  a.left-arrow-icon {
    width: 100%;
  }
  .fhlb-split-content-right-area {
    .fhl-button-content-container {
      width: 100% !important;
      padding: convert-unit(5px, "rem") convert-unit(10px, "rem");
      max-width: convert-unit(225px, "rem");
      float: left;
    }
    .fundingGoal-container,
    .fhl-title-subTitle-wrapper {
      width: 100% !important;
      float: none;
      h3 {
        font-size: convert-unit(21px, "rem");
        font-family: "HarmoniaSansW05-Semibold";
        font-weight: 600;
        font-style: normal;
        line-height: convert-unit(25.2px, "rem");
        margin-top: convert-unit(20px, "rem");
      }
      h5 {
        font-family: "Opensans-Regular";
        font-size: convert-unit(14px, "rem");
        color: inherit;
        line-height: convert-unit(21px, "rem");
      }
    }
  }
}
@media only screen and (min-width: 320px) {
  .employee-bio-left-section {
    .fhl-extrasmall-titlebar-wrapper {
      h3 {
        margin-bottom: convert-unit(13px, "rem");
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .fhlb-split-content-right-section {
    .fhl-button-content-container {
      .left-section {
        width: 100%;
        p {
          font-size: convert-unit(14px, "rem");
        }
      }
    }
  }
}
@media only screen and (max-width: 991px) {
  .fhlb-split-content-left-section {
    .fhlb-split-content-left-area {
      .fhl-richtext-wrapper {
        table {
          table-layout: fixed;
          width: 100% !important;
          border: 1px solid $white;
        }
        tr {
          display: table;
          width: 100%;
          margin-bottom: convert-unit(10px, "rem");
        }
        td {
          display: table-row;
          line-height: convert-unit(27px, "rem");
          word-break: break-all;
        }
      }
    }
  }
}
@media only screen and (min-width: 992px) {
  .fhlb-split-content-right-section {
    .fhl-button-content-container {
      .left-section {
        width: 100%;
        p {
          font-size: convert-unit(16px, "rem");
        }
      }
    }
  }
  .fhlb-split-si-left-section {
    flex: 0 0 65% !important;
    max-width: 65% !important;
    margin-right: 60px;
    .fhl-extrasmall-title-wrapper {
      h3 {
        line-height: convert-unit(21.6px, "rem");
      }
    }
    .fhl-h2-medium-title-wrapper {
      h2 {
        line-height: convert-unit(21.6px, "rem");
      }
    }
  }
  .fhlb-split-si-right-section {
    flex: 0 0 25% !important;
    max-width: 244px !important;
  }
}
@media only screen and (min-width: 1200px) {
  .fhlb-split-si-left-section {
    flex: 0 0 70% !important;
    max-width: 70% !important;
  }
}
