a.left-arrow-icon {
  text-transform: uppercase;
  //display: flex;
  font-size: convert-unit(14px, "rem");
  letter-spacing: convert-unit(1px, "rem");
  line-height: convert-unit(16.8px, "rem");
  //align-items: center;
  text-decoration: none;
  display: inline-block;
  vertical-align: middle;
  i {
    //display: inline-block;
    margin-left: convert-unit(10px, "rem") !important;
    border: 1px solid;
    border-radius: 50%;
    font-size: 20px;
    line-height: convert-unit(19px, "rem");
    width: convert-unit(22px, "rem");
    display: inline-block;
    vertical-align: sub;
  }
  i:hover {
    display: inline-block;
    text-decoration: none;
  }
}
a.left-arrow-icon:hover {
  outline: 0;
  text-decoration: none;
}
a.left-arrow-icon:focus,
a.left-arrow-icon:visited {
}
