.circular-container .col-* {
    overflow: visible;
}
.product-circular-anchor a {
    margin-top: 240px;
}
.products-circular-item {
    height: convert-unit(230px, "rem");
    margin: convert-unit(50px, "rem") 0 convert-unit(30px, "rem") 0;
    display: block;
    > a {
        float: left;
        height: convert-unit(230px, "rem");
        width: convert-unit(140px, "rem");
        overflow: visible;
    }
    a:hover {
        .products-circular-bottom-block {
            text-decoration: underline;
        }
    }
    .product-promo-image-adjust-bottom {
        height: convert-unit(235px, "rem");
    }
    .products-circular-image-container {
        z-index: 1;
        position: absolute;
        padding-left: 3px;
        img {
            border-radius: 50%;
            width: 142px !important;
            margin-top: -15px;
            border: 5px solid white;
            background-color: white;
            margin-left: -5px;
        }
    }
    .products-circular-bottom-container {
        height: 180px;
        width: 138px;
        z-index: 0;
        position: absolute;
        margin-top: 50px;
        border-radius: 5px;
        padding-top: 40px;
        .products-circular-bottom-block {
            text-align: center;
            font-size: convert-unit(16px, "rem");
            font-family: "HarmoniaSansW05-Semibold";
            font-weight: 600;
            font-style: normal;
            margin-top: 25px;
        }
    }
}
@media only screen and (max-width: 374px) {
    .products-promo-media-wrapper {
        .col-md-2 {
            max-width: 45%;
        }
    }
}
@media only screen and (min-width: 375px) {
    .products-promo-media-wrapper {
        .col-md-2 {
            max-width: 50%;
        }
    }
}
@media only screen and (min-width: 320px) {
    .products-promo-media-wrapper {
        .fhl-medium-titlebar-wrapper {
            h2 {
                padding: 0;
                text-align: center;
                font-size: convert-unit(30px, "rem");
            }
        }
        .col-md-2 {
            .products-circular-item {
                margin: convert-unit(20px, "rem") convert-unit(10px, "rem") convert-unit(20px, "rem")
                    convert-unit(5px, "rem");
            }
        }
    }
}
@media only screen and (min-width: 768px) {
    .products-promo-media-wrapper {
    .fhl-medium-titlebar-wrapper {
                    h2 {
                        padding: 0;
                        text-align: left;
                        font-size: convert-unit(30px, "rem");
                    }
                }
        margin-bottom: 30px;
        .row {
            .col-md-2 {
                max-width: 33%;
                flex: auto;
                .products-circular-item {
                    margin: convert-unit(25px, "rem") 0 0 -3px;
                    text-align: center;
                    a {
                        float: none;
                        height: auto;
                        width: convert-unit(140px, "rem");
                        overflow: visible;
                        display: inline-block;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 992px) {
    .products-promo-media-wrapper {
        .row {
            .col-md-2 {
                max-width: 16.66666667%;
                flex: 0 0 16.66666667%;
            }
        }
        .fhl-medium-titlebar-wrapper {
                        h2 {
                            padding: 0;
                            text-align: left;
                            font-size: convert-unit(30px, "rem");
                        }
                    }
    }
    .white-bg.extra-space-whiteBg {
        margin-top: convert-unit(-90px, "rem");
    }
}
@media only screen and (min-width: 1200px) {
    .white-bg.extra-space-whiteBg {
        margin-top: convert-unit(-100px, "rem");
        padding: 30px 60px 0px;
        max-width: 1280px !important;
    }
      .products-promo-media-wrapper {
            .fhl-medium-titlebar-wrapper {
                h2 {
                    padding: 0;
                    text-align: left;
                    font-size: convert-unit(30px, "rem");
                }
            }
      }
}