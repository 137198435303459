.fhl-rates-promo-wrapper {
  flex-direction: column;
  overflow: visible;
  align-items: flex-start;
  max-width: 100%;
  min-height: convert-unit(525px, "rem");
  justify-content: center;
  h6,.member-header {
    font-size: convert-unit(16px, "rem");
    color: get("color.gray.50");
    letter-spacing: convert-unit(2px, "rem");
    line-height: convert-unit(19.2px, "rem");
    margin-bottom: 0px;
  }
  h2 {
    font-size: convert-unit(32px, "rem");
    color: get("color.primary-green.100");
    line-height: convert-unit(38.4px, "rem");
    border-bottom: 1px solid get("color.primary-green.100");
    width: 100%;
    margin-bottom: convert-unit(25px, "rem");
  }

  p {
    font-family: "Opensans-Regular";
    color: $footer-base;
    line-height: convert-unit(21px, "rem");
    font-size: convert-unit(14px, "rem");
    margin-bottom: convert-unit(25px, "rem");
  }
  img {
    width: convert-unit(220px, "rem");
    height: convert-unit(150px, "rem");
  }
}

.fhl-rates-dynamic-promo-wrapper {
  flex-direction: column;
  overflow: visible;
  align-items: flex-start;
  max-width: 100%;
  min-height: convert-unit(525px, "rem");
  justify-content: center;
  width: 100%;
  h2 {
    font-size: convert-unit(32px, "rem");
    color: get("color.primary-green.100");
    line-height: convert-unit(38.4px, "rem");
    border-bottom: 1px solid get("color.primary-green.100");
    width: 100%;
    margin-bottom: convert-unit(25px, "rem");
  }
  .rate-header {
    margin-bottom: convert-unit(5px, "rem");
  }
  .fhl-select {
    .select-side {
      background-color: $lightGray;
      border-right: solid 0.0625rem #c7c7c7;
      border-top: solid 0.0625rem #c7c7c7;
      border-bottom: solid 0.0625rem #c7c7c7;
      text-align: center;
      i {
        color: get("color.primary-green.100");
      }
    }

    select {
      border: 1px solid #c7c7c7 !important;
      border-radius: convert-unit(4px, "rem");
      font-size: convert-unit(16px, "rem");
      color: $footer-base !important;
      text-align: left;
      background-color: $lightGray;
      padding: 0px 5px !important;
    }
    .current-rates {
      text-transform: uppercase;
    }
  }
  .dynamic-rates-cntr {
    p {
      font-family: "Opensans-Regular";
      font-size: convert-unit(14px, "rem");
      color: $footer-base;
      line-height: convert-unit(21px, "rem");
    }
    h6, .reg-header {
      font-family: "HarmoniaSansW05-Semibold";
      font-weight: 600;
      font-style: normal;
      font-size: convert-unit(14px, "rem");
      color: $footer-base;
      letter-spacing: convert-unit(2px, "rem");
      text-align: left;
      margin: convert-unit(15px, "rem") 0px;
    }
    p.sub-header {
      margin-bottom: convert-unit(3px, "rem");
      font-family: "HarmoniaSansW05-Semibold";
      font-size: convert-unit(14px, "rem");
      color: $footer-base;
      letter-spacing: convert-unit(2px, "rem");
    }
    .rates-display-section {
      width: 100%;
      margin-bottom: convert-unit(30px, "rem");
      font-size: convert-unit(90px, "rem");
      color: get("color.primary-green.100");
      text-align: center;
      line-height: convert-unit(60px, "rem");
    }
    .dynamic-dropdown-height {
      min-height: convert-unit(76px, "rem");
    }
    .fhl-select {
      width: 80%;
    }
    table {
      border: 0px;
      width: 100%;
      th {
        font-family: "HarmoniaSansW05-Semibold";
        font-weight: 600;
        font-style: normal;
        font-size: convert-unit(14px, "rem");
        color: $footer-base;
        letter-spacing: convert-unit(2px, "rem");
        text-align: center;
        height: convert-unit(35px, "rem");
        border-bottom: 1px solid get("color.primary-green.100");
      }
      td {
        font-size: convert-unit(14px, "rem");
        color: $footer-base;
        letter-spacing: convert-unit(2px, "rem");
        text-align: center;
        height: convert-unit(35px, "rem");
        line-height: convert-unit(40px, "rem");
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .fhl-rates-dynamic-promo-wrapper {
    min-height: convert-unit(300px, "rem");
    margin-bottom: convert-unit(30px, "rem");
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dynamic-rate-block {
    max-width: 100% !important;
    flex: auto !important;
    min-height: 50px !important;
    margin-left: 15px;
    .fhl-select {
      margin-left: -15px;
    }
  }
  .classic-advance-feature-wrapper {
    .advance-sub-header {
      max-width: 30%;
    }
    .advance-features-content-wrapper {
      max-width: 70%;
    }
  }
}
@media only screen and (min-width: 992px) {
  .fhl-rates-dynamic-promo-wrapper {
    .fhl-select {
      .current-rates {
        padding-left: 3px;
        font-size: 13px;
      }
    }
    .dynamic-rates-cntr {
      p {
        font-size: 13px;
      }
    }
  }
}
