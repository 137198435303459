.investor-need-help-container {
    word-break: break-word;
    word-wrap: break-word;
    background-color: #ffffff;
    border: convert-unit(1px, "rem") solid #979797;
    padding: convert-unit(20px, "rem") convert-unit(16px, "rem");
    border-radius: convert-unit(4px, "rem");
    margin: convert-unit(20px, "rem") 0px convert-unit(20px, "rem") auto;
    max-width: convert-unit(225px, "rem");
    h2 {
        font-size: convert-unit(32px, "rem");
        line-height: convert-unit(38.4px, "rem");
        color: inherit;
    }
    .investor-need-help-image {
        width: 100%;
    }
    .investor-need-help-content {
        font-family: "OpenSans-Regular";
        font-size: convert-unit(14px, "rem");
        color: inherit;
        line-height: convert-unit(21px, "rem");
    }
    .investor-need-help-btn,
    a.investor-need-help-btn {
        width: 100%;
        margin: auto;
        background-color: inherit;
        border-radius: convert-unit(4px, "rem");
        font-size: convert-unit(15px, "rem");
        color: inherit;
        letter-spacing: 0;
        line-height: convert-unit(20px, "rem");
        padding-top: convert-unit(10px, "rem");
        padding-bottom: convert-unit(10px, "rem");
        a {
            color: inherit;
        }
    }
}
.investor-relations-left-section {
    word-break: break-word;
    word-wrap: break-word;
    display: block;
    margin: auto 0px;
    min-height: convert-unit(525px, "rem") !important;
}
.investor-banner-section {
    min-height: convert-unit(525px, "rem");
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.investor-relations-content-wrapper {
    .investor-relations-content-title-text {
        .fhl-richtext-wrapper {
            font-family: "OpenSans-Regular";
            font-size: convert-unit(14px, "rem");
            color: inherit;
            line-height: convert-unit(21px, "rem");
            div {
                p {
                    em {
                        font-style: italic;
                        font-family: "OpenSans-Italic";
                        font-weight: normal;
                    }
                    strong {
                        font-weight: 300;
                        font-style: normal;
                        font-family: "OpenSans-Semibold";
                    }
                    strong {
                        font-weight: 300;
                        font-style: normal;
                        font-family: "OpenSans-Semibold";
                        em {
                            font-style: italic;
                            font-family: "OpenSans-Italic";
                            font-weight: normal;
                        }
                    }
                    em {
                        font-style: italic;
                        font-family: "OpenSans-Italic";
                        font-weight: normal;
                        strong {
                            font-weight: 300;
                            font-style: normal;
                            font-family: "OpenSans-Semibold";
                        }
                    }
                }
            }
            div {
                p {
                    a {
                        display: inline !important;
                    }
                }
            }
        }
    }
    .investor-top-table-section {
        .col-md-4,
        .col-md-2 {
            flex: 0 0 20%;
            max-width: 20%;
        }
    }
    .investor-top-table-section {
        .investor-tabular-border-green {
            border-bottom: 1px solid get("color.primary-green.100");
        }
        .investor-tabular-border {
            border-bottom: 1px solid $borderLine;
        }
        .col-md-4,
        .col-md-2 {
            padding: convert-unit(10px, "rem");
        }
    }
    .fhl-extrasmall-column-title-wrapper,
    .fhl-extrasmall-column-responsive-title {
        h2 {
            font-family: "HarmoniaSansW05-Semibold";
            font-weight: 600;
            font-style: normal;
            font-size: convert-unit(14px, "rem");
            color: inherit;
            letter-spacing: convert-unit(2px, "rem");
            text-align: center;
            margin: 0px auto;
        }
    }
    .simple-smallText-wrapper {
        color: inherit;
        font-size: convert-unit(12px, "rem") !important;
        p {
            color: inherit;
            font-size: convert-unit(12px, "rem") !important;
            a {
                color: inherit;
            }
        }
    }
    .editable-template-border {
        border-bottom: 1px solid #d8d8d8;
        margin: 1.875rem 0rem 1.5rem;
    }
    .simple-smallText-wrapper,
    .link-content-container {
        p,
        p.content-anchor-link,
        p.content-anchor-link a {
            font-size: convert-unit(12px, "rem") !important;
            letter-spacing: convert-unit(1.71px, "rem");
            text-align: center;
            font-family: "OpenSans-Regular";
            font-size: convert-unit(14px, "rem");
            color: inherit;
            margin-bottom: 0;
        }
        p.pdf-content-anchor-link,
        p.pdf-content-anchor-link a {
            letter-spacing: convert-unit(0px, "rem");
            text-align: left;
            font-family: "OpenSans-Semibold";
            font-size: convert-unit(16px, "rem");
            line-height: convert-unit(22.4px, "rem");
            color: inherit;
            margin-bottom: convert-unit(5px, "rem");
            text-decoration: underline;
        }
    }
    .investor-relations-editable-templates {
        .advance-features-content-wrapper {
            .fhl-richtext-wrapper {
                font-family: "OpenSans-Regular";
                div {
                    p {
                        margin: 0;
                    }
                }
            }
        }
    }
}
.simple-smallText-wrapper,
.simple-mediumText-wrapper {
    color: inherit;
    p {
        color: inherit;
        letter-spacing: 0px;
        text-align: left;
        font-family: "OpenSans-Regular";
        margin-bottom: 0;
        a {
            color: inherit;
            text-decoration: underline;
        }
        span {
            em {
                font-family: "OpenSans-Italic";
                font-weight: normal;
                font-style: italic;
                color: inherit;
            }
        }
        em {
            font-family: "OpenSans-Italic";
            font-weight: normal;
            font-style: italic;
            color: inherit;
            strong {
                font-family: "OpenSans-SemiboldItalic";
                font-weight: 300;
                font-style: italic;
                color: inherit;
            }
        }
        strong,
        b {
            font-family: "OpenSans-Semibold";
            font-weight: 300;
            font-style: normal;
            color: inherit;
            em {
                font-family: "OpenSans-SemiboldItalic";
                font-weight: 300;
                font-style: italic;
                color: inherit;
            }
        }
    }
}
.simple-smallText-wrapper {
    font-size: convert-unit(12px, "rem") !important;
    p {
        font-size: convert-unit(12px, "rem") !important;
    }
}
.simple-mediumText-wrapper {
    font-size: convert-unit(14px, "rem") !important;
    line-height: convert-unit(21px, "rem") !important;
    p {
        font-size: convert-unit(14px, "rem") !important;
        line-height: convert-unit(21px, "rem") !important;
    }
}
@media only screen and (max-width: 767px) {
    .investor-need-help-container {
        margin: convert-unit(48px, "rem") auto convert-unit(32px, "rem") !important;
        max-width: 75%;
    }
    .investor-relations-left-section {
        min-height: auto !important;
    }
    .investor-relation-mobile-topmargin {
        margin-top: convert-unit(-40px, "rem");
    }
    .investor-top-table-section {
        overflow-x: auto;
        margin-bottom: convert-unit(60px, "rem") !important;
        .row {
            min-width: convert-unit(600px, "rem");
        }
    }
    .investor-relations-right-section {
        display: flex;
        justify-content: center;
    }
    .investor-relations-editable-templates {
        .fhl-extrasmall-titlebar-wrapper {
            margin-bottom: convert-unit(28px, "rem");
        }
    }
}
@media only screen and (min-width: 320px) and (max-width: 375px) {
    .investor-need-help-container {
        max-width: convert-unit(250px, "rem") !important;
    }
}
@media only screen and (min-width: 768px) {
    .investor-relations-editable-templates {
        .advance-sub-header {
            flex: 0 0 35%;
            max-width: 35%;
        }
        .advance-features-content-wrapper {
            flex: 0 0 65%;
            max-width: 65%;
        }
    }

    .investor-top-table-section {
        margin-bottom: convert-unit(80px, "rem");
    }
    .fhl-extrasmall-column-responsive-title {
        h2 {
            width: 80%;
        }
    }
}
@media only screen and (min-width: 992px) {
    .investor-relations-editable-templates {
        .advance-sub-header {
            flex: 0 0 25%;
            max-width: 25%;
        }
        .advance-features-content-wrapper {
            flex: 0 0 75%;
            max-width: 75%;
        }
    }
    .investor-top-table-section {
        margin-bottom: convert-unit(90px, "rem");
    }
    .fhl-extrasmall-column-responsive-title {
        h2 {
            width: 100%;
        }
    }
}
