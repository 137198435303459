@import "../helpers/mixins";

.inspire-article-container,
.title-text-article-container {
    flex-direction: row;
    overflow: visible;
    font-family: "HarmoniaSansW05-Regular";
    font-weight: normal;
    font-style: normal;
    .inspire-right-section,
    .title-text-right-section {
        flex-direction: column;
        .inspire-article-title,
        .title-text-article-title {
            color: inherit;
            font-size: convert-unit(32px, "rem");
            line-height: convert-unit(38.4px, "rem");
            text-align: left;
            margin: 0;
        }
        .inspire-article-content,
        .title-text-article-content {
            font-size: convert-unit(18px, "rem");
            margin: convert-unit(25px, "rem") 0 convert-unit(25px, "rem") 0;
            color: inherit;
            line-height: convert-unit(25.2px, "rem");
        }
        .inspire-article-content {
            @include fontfamilyHarmoniaSans;
            p {
                a {
                    text-decoration: underline;
                }
            }
        }
        p {
            a.inspire-anchor,
            a.title-text-anchor {
                word-break: break-word;
                display: table !important;
                vertical-align: middle;
                color: inherit;
                i {
                    font-size: convert-unit(18px, "rem");
                    line-height: convert-unit(19px, "rem");
                    width: 21px;
                    display: inline-table;
                    vertical-align: sub;
                }
                i:hover {
                    text-decoration: none !important;
                    display: inline-block !important;
                }
            }
        }
        a.inspire-anchor,
        a.title-text-anchor {
            text-decoration: none;
            display: table !important;
            vertical-align: middle;
            color: inherit;
            i {
                font-size: convert-unit(18px, "rem");
                line-height: convert-unit(19px, "rem");
                width: 21px;
                display: inline-table;
                vertical-align: sub;
            }
            i:hover {
                text-decoration: none !important;
                display: inline-block !important;
            }
        }
    }
}
.title-text-article-container {
    .title-text-right-section {
        .title-text-article-content {
            @include fontfamilyOpenSans;
        }
    }
}
@media only screen and (max-width: 767px) {
    .inspire-article-container {
        flex-direction: column;
        margin: convert-unit(20px, "rem") 0px 0px;
        width: 100%;
        .inspire-right-section {
            .inspire-article-title {
                margin-top: convert-unit(-10px, "rem");
            }
            .inspire-article-content {
                font-size: convert-unit(16px, "rem");
            }
        }
    }
    .title-text-article-container {
        flex-direction: column;
        margin: convert-unit(20px, "rem") 0px 0px;
        width: 100%;
        .title-text-right-section {
            .title-text-article-title {
                margin-top: convert-unit(-10px, "rem");
            }
            .title-text-article-content {
                font-size: convert-unit(16px, "rem");
            }
        }
    }
    .ahp-content-wrapper {
        .inspire-article-container {
            .inspire-right-section {
                .inspire-article-title {
                    margin-top: convert-unit(10px, "rem");
                }
            }
        }
    }

    .ahp-content-wrapper {
        .member-row-middle-mt {
            margin-top: 0;
            padding-top: 0 !important;
        }
        .displayImg-desktop-right {
            margin-bottom: convert-unit(32px, "rem");
        }
    }
}
@media only screen and (min-width: 768px) {
    .inspire-article-container,
    .title-text-article-container {
        margin: convert-unit(0px, "rem");
    }
    .ahp-content-wrapper {
        .inspire-article-container {
            .inspire-right-section {
                .inspire-article-title {
                    margin-top: 0;
                }
            }
        }
    }
}
@media only screen and (min-width: 992px) {
    .inspire-article-container {
        .inspire-right-section {
            .inspire-article-content {
                font-size: convert-unit(18px, "rem");
            }
        }
    }
    .title-text-article-container {
        .title-text-right-section {
            .title-text-article-content {
                font-size: convert-unit(18px, "rem");
            }
        }
    }
}
