.pagenation-content {
  display: flex;
  list-style-type: none;
  margin: 0px 0px convert-unit(50px, "rem");
  padding: 0;
  li {
    border-radius: convert-unit(4px, "rem");
    font-size: convert-unit(18px, "rem");
    line-height: convert-unit(30px, "rem");
    padding: convert-unit(4px, "rem") convert-unit(15px, "rem") 0px;
    margin: 0px convert-unit(3px, "rem");
    color: get("color.primary-green.100");
    text-align: center;
    button {
      cursor: pointer;
      background-color: transparent;
      border: 0px;
    }
  }
  li:first-child {
    margin-left: 0px;
  }
  li:last-child {
    margin-right: 0px;
  }
  button {
    background-color: transparent;
    border: 0;
    color: inherit;
  }
  .pagenationArrowDisabled {
    background-color: $btn-disabled;
    padding: convert-unit(6px, "rem") convert-unit(10px, "rem") 0px;
    line-height: convert-unit(30px, "rem");
    font-size: convert-unit(18px, "rem");
    color: $white;
    button {
      pointer-events: none;
      cursor: not-allowed;
    }
  }
  .pagenationArrowEnabled {
    border: 1px solid #c7c7c7;
    padding: convert-unit(6px, "rem") convert-unit(10px, "rem") 0px;
    line-height: convert-unit(30px, "rem");
    button {
      cursor: pointer;
    }
  }
  .selectedPage {
    color: white;
    background: get("color.primary-green.100");
    button {
      cursor: not-allowed;
      pointer-events: none;
    }
  }
  .unSelectedPage {
    button {
      cursor: pointer;
    }
  }
}
@media only screen and (max-width: 767px) {
  .desktopPagination {
    display: none;
  }
  .mobilePagination {
    display: block;
  }
  .iPadPagination {
    display: none;
  }
  .pagenation-content {
    margin: 0px !important;
  }
}
@media only screen and (min-width: 768px) {
  .desktopPagination {
    display: none;
  }
  .mobilePagination {
    display: none;
  }
  .iPadPagination {
    display: block;
  }
}
@media only screen and (min-width: 992px) {
  .desktopPagination {
    display: block;
  }
  .mobilePagination {
    display: none;
  }
  .iPadPagination {
    display: none;
  }
  .pagenation-content {
    li {
      padding: 8px 12px 0px;
      line-height: 24px;
    }
    .pagenationArrowDisabled {
      padding: 0.375rem convert-unit(7px, "rem") 0px;
    }
    .pagenationArrowEnabled {
      padding: 6px convert-unit(6px, "rem") 0px;
      line-height: 24px;
    }
  }
}
@media only screen and (min-width: 1200px) {
  .desktopPagination {
    display: block;
  }
  .mobilePagination {
    display: none;
  }
  .iPadPagination {
    display: none;
  }
  .pagenation-content {
    li {
      padding: 6px 15px 0px;
      line-height: 30px;
    }
    .pagenationArrowEnabled {
      padding: 6px convert-unit(6px, "rem") 0px;
      line-height: 30px;
    }
    .pagenationArrowDisabled {
      padding: 0.375rem convert-unit(10px, "rem") 0px;
    }
  }
}
