@import "../helpers/mixins";

.secondary-promo-banner-container {
    position: relative;
    min-height: convert-unit(525px, "rem");
    display: flex;
    flex-direction: column;
    align-items: center;
    .secondary-promo-content-img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 0;
        min-height: convert-unit(525px, "rem");
        overflow: hidden;
        img {
            width: 103%;
        }
    }
    .secondary-promo-main-content {
        text-align: left;
        display: flex;
        width: convert-unit(1140px, "rem");
        .secondary-promo-col {
            max-width: 40%;
            margin-right: convert-unit(100px, "rem");
            flex: 1;
            .secondary-promo-content-header {
                position: relative;
                margin: convert-unit(25px, "rem") 0 0 0;
                color: inherit;
                width: 100%;
                font-size: convert-unit(42px, "rem");
                line-height: convert-unit(50.4px, "rem");
            }
            .secondary-promo-content {
                position: relative;
                width: 100%;
                margin: convert-unit(15px, "rem") 0 0 0;
                font-size: convert-unit(18px, "rem");
                color: inherit;
                line-height: convert-unit(25.2px, "rem");
                @include fontfamilyHarmoniaSans;
                p {
                    a {
                        text-decoration: underline;
                    }
                }
            }
            a.secondary-promo-tag-link {
                position: relative;
                font-size: convert-unit(14px, "rem");
                margin-top: convert-unit(10px, "rem");
                letter-spacing: convert-unit(1px, "rem");
                line-height: convert-unit(16.8px, "rem");
                display: table !important;
                vertical-align: middle;
                color: inherit;
                i {
                    font-size: convert-unit(18px, "rem");
                    line-height: convert-unit(19px, "rem");
                    width: 21px;
                    display: inline-table;
                    vertical-align: sub;
                }
                i:hover {
                    text-decoration: none !important;
                    display: inline-block !important;
                }
            }
        }
    }
}
@media only screen and (max-width: 767px) {
    .secondary-promo-banner-container {
        margin: convert-unit(0px, "rem");
        padding: convert-unit(25px, "rem") 0 0;
        min-height: convert-unit(350px, "rem");
        flex-direction: column-reverse;
        .secondary-promo-content-img {
            min-height: 100%;
            width: 100%;
            position: relative;
            img {
                width: 100%;
                min-height: 101%;
                margin-left: 0%;
                margin-top: convert-unit(30px, "rem");
                margin-bottom: convert-unit(-2px, "rem");
            }
        }
        .secondary-promo-main-content {
            width: 90%;
            margin: 0 0 0 convert-unit(15px, "rem");
            .secondary-promo-col {
                max-width: 100%;
                margin-right: 0;
                a.secondary-promo-tag-link {
                    margin-top: convert-unit(20px, "rem");
                }
                .secondary-promo-content-header {
                    font-size: convert-unit(38px, "rem");
                    line-height: convert-unit(40px, "rem");
                    margin: 0;
                    p {
                        width: 100%;
                        min-height: convert-unit(26px, "rem");
                        margin: 0;
                    }
                }
                .secondary-promo-content {
                    font-size: convert-unit(16px, "rem");
                    p {
                        max-height: 100%;
                        overflow: hidden;
                        margin: 0;
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
    .secondary-promo-banner-container {
        .secondary-promo-main-content {
            margin-left: 0px;
        }
    }
}
@media only screen and (min-width: 768px) {
    .ahp-secondary-banner {
        .secondary-promo-banner-container {
            min-height: convert-unit(420px, "rem");
            .secondary-promo-content-img {
                img {
                    width: 120%;
                }
            }
        }
    }

    .secondary-promo-banner-container {
        min-height: convert-unit(408px, "rem");
        padding: convert-unit(32px, "rem") 0;
        margin: 0;
        .secondary-promo-content-img {
            img {
                width: 120%;
            }
        }
        .secondary-promo-main-content {
            width: convert-unit(708px, "rem");
            .secondary-promo-col {
                max-width: 64%;
                .secondary-promo-content-header {
                    margin: 0;
                    p {
                        margin: 0;
                        font-size: convert-unit(30px, "rem");
                        max-height: 100%;
                    }
                }
                .secondary-promo-content {
                    p {
                        font-size: convert-unit(16px, "rem");
                        max-height: 100%;
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 992px) {
    .secondary-promo-banner-container {
        min-height: convert-unit(465px, "rem");
        padding: convert-unit(32px, "rem") 0;
        margin: 0;
        .secondary-promo-content-img {
            min-height: convert-unit(465px, "rem");
            img {
                width: 100%;
                min-height: 101%;
                margin-left: 0;
            }
        }
        .secondary-promo-main-content {
            width: convert-unit(964px, "rem");
            margin: 0;
            .secondary-promo-col {
                max-width: 55%;
                margin-right: convert-unit(100px, "rem");
                .secondary-promo-content-header {
                    margin: convert-unit(25px, "rem") 0 0 0;
                    p {
                        margin: 0;
                        font-size: convert-unit(32px, "rem");
                        line-height: convert-unit(40.4px, "rem");
                        height: auto;
                    }
                }
                .secondary-promo-content {
                    margin: convert-unit(15px, "rem") 0 0 0;
                    p {
                        margin: 0;
                        font-size: convert-unit(18px, "rem");
                        color: inherit;
                        line-height: convert-unit(25.2px, "rem");
                    }
                }
                a.secondary-promo-tag-link {
                    font-size: convert-unit(14px, "rem");
                    color: inherit;
                    margin-top: convert-unit(30px, "rem");
                    i {
                        font-size: convert-unit(18px, "rem");
                        line-height: convert-unit(19px, "rem");
                    }
                }
                a.secondary-promo-tag-link:hover {
                    text-decoration: none;
                    i {
                        text-decoration: none;
                    }
                }
            }
        }
    }
    .ahp-secondary-banner {
        .secondary-promo-banner-container {
            min-height: convert-unit(595px, "rem");
            .secondary-promo-content-img {
                img {
                    width: 120%;
                    min-height: 101%;
                    margin-left: -8%;
                }
            }
        }
    }
}
@media only screen and (min-width: 1200px) {
    .secondary-promo-banner-container {
        min-height: convert-unit(525px, "rem");
        padding: convert-unit(64px, "rem") 0px;
        .secondary-promo-main-content {
            width: convert-unit(1140px, "rem");
            .secondary-promo-col {
                max-width: 40%;
                .secondary-promo-content-header {
                    p {
                        font-size: convert-unit(42px, "rem");
                        line-height: convert-unit(50.4px, "rem");
                    }
                }
            }
        }
    }
    .ahp-secondary-banner {
        .secondary-promo-banner-container {
            min-height: convert-unit(595px, "rem");
            .secondary-promo-content-img {
                img {
                    width: 100%;
                    min-height: 101%;
                    margin-left: 0;
                }
            }
        }
    }
}
