@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    header {
        .header-bar {
            ul {
                li {
                    display: inline-block;
                    .search-filter-container {
                        margin-bottom: -7px;
                    }
                }
            }
        }
    }
    .promo-col2 {
        a.promo-tag-link {
            i {
                width: 23px !important;
                font-size: 19px !important;
            }
        }
    }
    .aside-title-text-image-container {
        float: none;
    }
    .aside-title-text-image-container,
    .title-text-image-container {
        a {
            height: 100%;
        }
    }
    a.left-arrow-icon {
        i {
            line-height: 16px !important;
            padding: 4px 0px 0px 1px;
            width: 23px;
            font-size: 19px !important;
        }
    }
    .image-header-content-anchor-link {
        a.left-arrow-icon {
            i {
                width: auto !important;
                margin-left: convert-unit(10px, "rem");
            }
        }
    }
    .classic-advance-promo-wrapper {
        align-items: stretch;
        h1 {
            margin-top: convert-unit(150px, "rem");
        }
    }
    .fhl-rates-promo-wrapper {
        align-items: stretch;
    }
    .fhl-rates-dynamic-promo-wrapper {
        min-height: convert-unit(300px, "rem");
    }
    .com-adv-ie-wrapper {
        a.left-arrow-icon {
            i {
                padding: 2px 3px 2px 1px !important;
            }
        }
    }
    .compare-advances-products-wrapper {
        .product-top-title {
            align-items: flex-start;
            .removeCompareList {
                margin-top: 3px;
            }
        }
    }
    .accordion-content-box {
        .card-body {
            .filter-list label {
                .checkmarkradio:after {
                    top: 2.5px !important;
                    left: 2px !important;
                }
            }
        }
    }
    .secondary-header {
        .dropdown-menu {
            .container {
                .row {
                    float: none;
                }
            }
        }
    }
    .fhlb-split-content-left-area {
        td {
            span {
                a {
                    display: none !important;
                }
            }
        }
    }
    .events-article-content-block {
        .events-right-section {
            margin-left: 70px !important;
        }
    }
    .fhlb-split-content-right-section {
        .fhlb-split-content-right-area {
            .fhl-button-content-container {
                i.material-icons {
                    padding: 2px;
                }
            }
        }
    }
    .fhlb-split-si-right-section {
        a.left-arrow-icon {
            .fhl-button-content-container {
                .right-section {
                    i {
                        padding: 2px 0px 2px 1px;
                    }
                }
            }
        }
    }
    .news-events-detail-content-right-section {
        a.left-arrow-icon {
            .fhl-button-content-container {
                .right-section {
                    i {
                        line-height: 15px;
                        padding: 2px 0 2px 1px;
                    }
                }
            }
        }
    }
    .news-article-wrapper h2,
    .events-article-wrapper h2,
    .partnership-content-article .partership-bottom-container .partnership-column1 .partnership-content-contentbody,
    .partnership-content-article .partership-bottom-container .partnership-column2 .partnership-content-contentbody,
    .partnership-content-article .partnership-content-title,
    .partnership-content-article .partership-bottom-container .partnership-column1 h4,
    .partnership-content-article .partership-bottom-container .partnership-column2 h4,
    .news-article-wrapper .news-article-content-block h4,
    .events-article-wrapper .events-article-content-block .events-right-section h3,
    .membership-content-article,
    .pl-carousel-slide .title-text-article-container .title-text-right-section .title-text-article-title,
    .pl-carousel-slide .title-text-article-container .title-text-right-section .title-text-article-content,
    .pl-carousel-slide .title-text-article-container .title-text-right-section p a.title-text-anchor {
        word-wrap: break-word !important;
    }
    .pl-carousel-slide .title-text-article-container.w100pr.d-flex {
        display: block !important;
    }
    .pl-carousel-slide .title-text-article-container .title-text-right-section p a.title-text-anchor i {
        width: auto !important;
    }
    .aside-title-text-image-container,
    .title-text-image-container,
    .fhl-medium-title-para-text-wrapper {
        p,
        h1,
        h2,
        h3 {
            word-wrap: break-word !important;
        }
    }
    .contacts-rhs-container {
        .contacts-rhs-select-content {
            .feature-list {
                .list-content img {
                    width: auto !important;
                    height: auto !important;
                }
            }
        }
    }
    .contacts-lhs-container {
        .contacts-slot img {
            height: auto !important;
        }
    }
    .imgcontent-layout-last-layer {
        .imgcontent-layout-index-link {
            .left-arrow-icon,
            .card-layout-anchor {
                i {
                    padding: 1px 0px 0px 1px !important;
                }
            }
        }
    }
    .fhl-promo-header-content-wrapper,
    .fhl-promo-header-content-wrapper div {
        display: block !important;
    }
    .investor-relations-left-section,
    .rates-left-section {
        .fhl-title-content-btn-wrapper,
        .fhl-title-content-btn-wrapper div {
            display: block !important;
        }
    }
    .img-header-content-container,
    .image-header-content-link-container {
        p.image-header-content-anchor-link {
            display: flex !important;
        }
    }
    .search-results-wrapper button[type="reset"] {
        line-height: 19px !important;
        padding-left: 1px;
    }
    .search-results-wrapper .search-wrapper input[type="search"]::-ms-clear,
    .search-filter-container input[type="search"]::-ms-clear {
        display: none;
    }
    .advance-media-login-wrapper {
        .fhl-title-content-btn-wrapper {
            div {
                a.left-arrow-icon {
                    i.material-icons {
                        width: auto !important;
                    }
                }
            }
        }
    }
    .rates-online-banking .fhl-title-content-btn-wrapper div {
        max-width: 100%;
        word-wrap: break-word;
    }
    .fhl-historical-page-wrapper .historical-rates-cntr .historical-rates-button-wrapper .calendar-picker-placeholder {
        margin-left: -8px !important;
    }
    @media only screen and (max-width: 767px) {
        .rates-online-banking .fhl-title-content-btn-wrapper div a {
            display: flex;
            max-width: 100%;
        }
        .fhl-rates-page-wrapper .dynamic-rates-cntr .rates-button-wrapper .rates_download_icon {
            background-size: 70%;
        }
        .fhl-rates-page-wrapper .dynamic-rates-cntr .rates-button-wrapper {
            display: block;
        }
        .rates-mobile-button-wrapper .col-6 {
            flex: auto;
        }
        .rates-right-section {
            justify-content: flex-start;
        }
        .search-results-wrapper button[type="reset"] i {
            left: convert-unit(1px, "rem");
            position: absolute;
            top: 1px;
        }
        .advance-media-login-wrapper,
        .compare-advance-mobile-wrapper {
            .fhl-title-content-btn-wrapper {
                div {
                    align-items: flex-start;
                }
            }
        }
        .contactus-left-section,
        .contactus-right-section {
            .fhl-title-content-btn-wrapper {
                div {
                    align-items: flex-start !important;
                }
            }
        }
        .advance-editable-templates-2 {
            .aside-title-text-image-container {
                margin-top: convert-unit(40px, "rem") !important;
            }
        }
        .classic-advance-promo-wrapper {
            h1 {
                margin-top: 0;
            }
        }
        .fhlb-split-content-left-area {
            .fhl-title-content-btn-wrapper {
                div {
                    flex-direction: row;
                }
            }
        }
    }
    @media only screen and (min-width: 992px) {
        table.compare-advance-table th, 
        table.compare-advance-table td {
            min-width: 150px;
            max-width: inherit;
        }
        .secondary-header {
            .navbar {
                ul.navbar-nav {
                    li.main-menu {
                        a.nav-link {
                            padding-bottom: convert-unit(18px, "rem");
                        }
                    }
                }
            }
            .header-login-dropdown-content {
                margin-top: convert-unit(10px, "rem") !important;
            }
        }
        .compare-advances-products-wrapper {
            .product-top-title {
                width: 150px;
            }
        }
        .advance-feature-left {
            max-width: 76% !important;
        }

        .advance-feature-right {
            max-width: 225px !important;
            padding: 0px !important;
        }
        .fhl-rates-promo-wrapper {
            padding-top: convert-unit(100px, "rem");
        }
        .fhl-rates-dynamic-promo-wrapper {
            margin-top: convert-unit(100px, "rem");
        }
        .general-email-IE-browser {
            .contacts-slot {
                margin-top: convert-unit(10px, "rem");
            }
        }
    }
    @media only screen and (max-width: 991px) {
        .fhl-rates-page-wrapper .dynamic-rates-cntr .rates-button-wrapper {
            display: block;
        }
        .rates-mobile-button-wrapper .col-6 {
            flex: auto;
        }
        .promo-content-container,
        .promo-content-without-title-container {
            .promo-main-content,
            .promo-main-without-content {
                .promo-col1,
                .promo-col2 {
                    flex: auto;
                }
                .promo-col2 {
                    a.promo-tag-link {
                        margin-top: 5px;
                    }
                }
            }
        }
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
        // .classic-advance-promo-wrapper {
        //     h1 {
        //         margin-top: convert-unit(80px, "rem");
        //     }
        // }
        .forms-media-wrapper {
            // #forms-results {
            //     .error-message-wrapper {
            //         margin: 0px 0px 0px 50px;
            //     }
            // }
            .forms-result-page {
                margin: 0px 0px 0px 40px;
            }
        }
        .fhlb-split-content-right-section {
            .fhlb-split-content-right-area {
                .aside-title-text-image-container {
                    h1 {
                        line-height: 30px;
                    }
                }
            }
        }
        .fhl-rates-promo-wrapper {
            padding-top: convert-unit(100px, "rem");
        }
        .fhl-rates-dynamic-promo-wrapper {
            margin-top: convert-unit(100px, "rem");
        }

        .rates-online-banking .fhl-title-content-btn-wrapper .d-flex {
            align-items: flex-start;
        }
        .rates-online-banking .fhl-title-content-btn-wrapper div p {
            margin-bottom: 0px;
        }
        .rates-online-banking .fhl-title-content-btn-wrapper .d-flex a {
            display: flex;
            max-width: 100%;
        }
    }
    @media only screen and (min-width: 992px) and (max-width: 1190px) {
        .classic-advance-promo-wrapper {
            h1 {
                margin-top: convert-unit(90px, "rem");
            }
        }
    }
    @media only screen and (min-width: 1200px) {
        .fhl-rates-page-wrapper {
            margin-left: convert-unit(-8px, "rem");
        }
        .compare-advances-products-wrapper {
            .product-top-title {
                width: 180px;
            }
        }
        .advance-feature-left {
            max-width: 80% !important;
        }
        .rates-online-banking .fhl-title-content-btn-wrapper div p {
            max-width: convert-unit(350px, "rem");
        }
    }
}

@supports (-ms-ime-align: auto) {
    .accordion-content-box {
        .card-body {
            .filter-list label {
                .checkmarkradio:after {
                    top: 2.5px !important;
                    left: 3px !important;
                }
            }
        }
    }
    .different-option-container {
        display: table;
    }
    a.left-arrow-icon {
        display: flex !important;
    }
    .com-adv-button-wrapper .fhl-button-content-container,
    .com-adv-button-wrapper a.left-arrow-icon {
        display: inline-block !important;
    }
    @media only screen and (min-width: 1200px) {
        .fhl-rates-page-wrapper {
            margin-left: convert-unit(-8px, "rem");
        }
    }
}
