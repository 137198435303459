// .fhlBank-loader {
//     border: 16px solid #f3f3f3;
//     border-radius: 50%;
//     border-top: 16px solid #3498db;
//     width: 120px;
//     height: 120px;
//     -webkit-animation: spin 2s linear infinite; /* Safari */
//     animation: spin 2s linear infinite;
// }

.overlay-modal {
    width: 100%;
    position: fixed;
    height: 100%;
    background-color: $default-prime-gray;
    top: 0;
    left: 0;
    z-index: 9;
    opacity: 0.2;
}
.fhlb-loader {
    margin: 25% auto;
    font-size: 15px;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    -webkit-animation: load5 1.1s infinite ease;
    animation: load5 1.1s infinite ease;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}
@-webkit-keyframes load5 {
    0%,
    100% {
        box-shadow: 0em -2.6em 0em 0em #00833c, 1.8em -1.8em 0 0em rgba(0, 131, 60, 0.2),
            2.5em 0em 0 0em rgba(0, 131, 60, 0.2), 1.75em 1.75em 0 0em rgba(0, 131, 60, 0.2),
            0em 2.5em 0 0em rgba(0, 131, 60, 0.2), -1.8em 1.8em 0 0em rgba(0, 131, 60, 0.2),
            -2.6em 0em 0 0em rgba(0, 131, 60, 0.5), -1.8em -1.8em 0 0em rgba(0, 131, 60, 0.7);
    }
    12.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(0, 131, 60, 0.7), 1.8em -1.8em 0 0em #00833c,
            2.5em 0em 0 0em rgba(0, 131, 60, 0.2), 1.75em 1.75em 0 0em rgba(0, 131, 60, 0.2),
            0em 2.5em 0 0em rgba(0, 131, 60, 0.2), -1.8em 1.8em 0 0em rgba(0, 131, 60, 0.2),
            -2.6em 0em 0 0em rgba(0, 131, 60, 0.2), -1.8em -1.8em 0 0em rgba(0, 131, 60, 0.5);
    }
    25% {
        box-shadow: 0em -2.6em 0em 0em rgba(0, 131, 60, 0.5), 1.8em -1.8em 0 0em rgba(0, 131, 60, 0.7),
            2.5em 0em 0 0em #00833c, 1.75em 1.75em 0 0em rgba(0, 131, 60, 0.2), 0em 2.5em 0 0em rgba(0, 131, 60, 0.2),
            -1.8em 1.8em 0 0em rgba(0, 131, 60, 0.2), -2.6em 0em 0 0em rgba(0, 131, 60, 0.2),
            -1.8em -1.8em 0 0em rgba(0, 131, 60, 0.2);
    }
    37.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(0, 131, 60, 0.2), 1.8em -1.8em 0 0em rgba(0, 131, 60, 0.5),
            2.5em 0em 0 0em rgba(0, 131, 60, 0.7), 1.75em 1.75em 0 0em #00833c, 0em 2.5em 0 0em rgba(0, 131, 60, 0.2),
            -1.8em 1.8em 0 0em rgba(0, 131, 60, 0.2), -2.6em 0em 0 0em rgba(0, 131, 60, 0.2),
            -1.8em -1.8em 0 0em rgba(0, 131, 60, 0.2);
    }
    50% {
        box-shadow: 0em -2.6em 0em 0em rgba(0, 131, 60, 0.2), 1.8em -1.8em 0 0em rgba(0, 131, 60, 0.2),
            2.5em 0em 0 0em rgba(0, 131, 60, 0.5), 1.75em 1.75em 0 0em rgba(0, 131, 60, 0.7), 0em 2.5em 0 0em #00833c,
            -1.8em 1.8em 0 0em rgba(0, 131, 60, 0.2), -2.6em 0em 0 0em rgba(0, 131, 60, 0.2),
            -1.8em -1.8em 0 0em rgba(0, 131, 60, 0.2);
    }
    62.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(0, 131, 60, 0.2), 1.8em -1.8em 0 0em rgba(0, 131, 60, 0.2),
            2.5em 0em 0 0em rgba(0, 131, 60, 0.2), 1.75em 1.75em 0 0em rgba(0, 131, 60, 0.5),
            0em 2.5em 0 0em rgba(0, 131, 60, 0.7), -1.8em 1.8em 0 0em #00833c, -2.6em 0em 0 0em rgba(0, 131, 60, 0.2),
            -1.8em -1.8em 0 0em rgba(0, 131, 60, 0.2);
    }
    75% {
        box-shadow: 0em -2.6em 0em 0em rgba(0, 131, 60, 0.2), 1.8em -1.8em 0 0em rgba(0, 131, 60, 0.2),
            2.5em 0em 0 0em rgba(0, 131, 60, 0.2), 1.75em 1.75em 0 0em rgba(0, 131, 60, 0.2),
            0em 2.5em 0 0em rgba(0, 131, 60, 0.5), -1.8em 1.8em 0 0em rgba(0, 131, 60, 0.7), -2.6em 0em 0 0em #00833c,
            -1.8em -1.8em 0 0em rgba(0, 131, 60, 0.2);
    }
    87.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(0, 131, 60, 0.2), 1.8em -1.8em 0 0em rgba(0, 131, 60, 0.2),
            2.5em 0em 0 0em rgba(0, 131, 60, 0.2), 1.75em 1.75em 0 0em rgba(0, 131, 60, 0.2),
            0em 2.5em 0 0em rgba(0, 131, 60, 0.2), -1.8em 1.8em 0 0em rgba(0, 131, 60, 0.5),
            -2.6em 0em 0 0em rgba(0, 131, 60, 0.7), -1.8em -1.8em 0 0em #00833c;
    }
}
@keyframes load5 {
    0%,
    100% {
        box-shadow: 0em -2.6em 0em 0em #00833c, 1.8em -1.8em 0 0em rgba(0, 131, 60, 0.2),
            2.5em 0em 0 0em rgba(0, 131, 60, 0.2), 1.75em 1.75em 0 0em rgba(0, 131, 60, 0.2),
            0em 2.5em 0 0em rgba(0, 131, 60, 0.2), -1.8em 1.8em 0 0em rgba(0, 131, 60, 0.2),
            -2.6em 0em 0 0em rgba(0, 131, 60, 0.5), -1.8em -1.8em 0 0em rgba(0, 131, 60, 0.7);
    }
    12.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(0, 131, 60, 0.7), 1.8em -1.8em 0 0em #00833c,
            2.5em 0em 0 0em rgba(0, 131, 60, 0.2), 1.75em 1.75em 0 0em rgba(0, 131, 60, 0.2),
            0em 2.5em 0 0em rgba(0, 131, 60, 0.2), -1.8em 1.8em 0 0em rgba(0, 131, 60, 0.2),
            -2.6em 0em 0 0em rgba(0, 131, 60, 0.2), -1.8em -1.8em 0 0em rgba(0, 131, 60, 0.5);
    }
    25% {
        box-shadow: 0em -2.6em 0em 0em rgba(0, 131, 60, 0.5), 1.8em -1.8em 0 0em rgba(0, 131, 60, 0.7),
            2.5em 0em 0 0em #00833c, 1.75em 1.75em 0 0em rgba(0, 131, 60, 0.2), 0em 2.5em 0 0em rgba(0, 131, 60, 0.2),
            -1.8em 1.8em 0 0em rgba(0, 131, 60, 0.2), -2.6em 0em 0 0em rgba(0, 131, 60, 0.2),
            -1.8em -1.8em 0 0em rgba(0, 131, 60, 0.2);
    }
    37.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(0, 131, 60, 0.2), 1.8em -1.8em 0 0em rgba(0, 131, 60, 0.5),
            2.5em 0em 0 0em rgba(0, 131, 60, 0.7), 1.75em 1.75em 0 0em #00833c, 0em 2.5em 0 0em rgba(0, 131, 60, 0.2),
            -1.8em 1.8em 0 0em rgba(0, 131, 60, 0.2), -2.6em 0em 0 0em rgba(0, 131, 60, 0.2),
            -1.8em -1.8em 0 0em rgba(0, 131, 60, 0.2);
    }
    50% {
        box-shadow: 0em -2.6em 0em 0em rgba(0, 131, 60, 0.2), 1.8em -1.8em 0 0em rgba(0, 131, 60, 0.2),
            2.5em 0em 0 0em rgba(0, 131, 60, 0.5), 1.75em 1.75em 0 0em rgba(0, 131, 60, 0.7), 0em 2.5em 0 0em #00833c,
            -1.8em 1.8em 0 0em rgba(0, 131, 60, 0.2), -2.6em 0em 0 0em rgba(0, 131, 60, 0.2),
            -1.8em -1.8em 0 0em rgba(0, 131, 60, 0.2);
    }
    62.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(0, 131, 60, 0.2), 1.8em -1.8em 0 0em rgba(0, 131, 60, 0.2),
            2.5em 0em 0 0em rgba(0, 131, 60, 0.2), 1.75em 1.75em 0 0em rgba(0, 131, 60, 0.5),
            0em 2.5em 0 0em rgba(0, 131, 60, 0.7), -1.8em 1.8em 0 0em #00833c, -2.6em 0em 0 0em rgba(0, 131, 60, 0.2),
            -1.8em -1.8em 0 0em rgba(0, 131, 60, 0.2);
    }
    75% {
        box-shadow: 0em -2.6em 0em 0em rgba(0, 131, 60, 0.2), 1.8em -1.8em 0 0em rgba(0, 131, 60, 0.2),
            2.5em 0em 0 0em rgba(0, 131, 60, 0.2), 1.75em 1.75em 0 0em rgba(0, 131, 60, 0.2),
            0em 2.5em 0 0em rgba(0, 131, 60, 0.5), -1.8em 1.8em 0 0em rgba(0, 131, 60, 0.7), -2.6em 0em 0 0em #00833c,
            -1.8em -1.8em 0 0em rgba(0, 131, 60, 0.2);
    }
    87.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(0, 131, 60, 0.2), 1.8em -1.8em 0 0em rgba(0, 131, 60, 0.2),
            2.5em 0em 0 0em rgba(0, 131, 60, 0.2), 1.75em 1.75em 0 0em rgba(0, 131, 60, 0.2),
            0em 2.5em 0 0em rgba(0, 131, 60, 0.2), -1.8em 1.8em 0 0em rgba(0, 131, 60, 0.5),
            -2.6em 0em 0 0em rgba(0, 131, 60, 0.7), -1.8em -1.8em 0 0em #00833c;
    }
}

/* Safari */
/*@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .fhlbank-loadText{
    position: fixed;
    top: 50%;
    left: 50%;
    
    transform: translate(-50%, -50%);
    transform: -webkit-translate(-50%, -50%);
    transform: -moz-translate(-50%, -50%);
    transform: -ms-translate(-50%, -50%);
  }

  .fhlbank-loadText h3{
    color: get("color.primary-green.100");
  }*/
