.fhlb-table-styles {
    .table-top-border-green {
        border-bottom: 1px solid get("color.primary-green.100");
    }
    .table-row-border-gray {
        border-bottom: 1px solid $borderLine;
    }
}

.table-bottom-space {
    margin-bottom: convert-unit(95px, "rem");
}
.table-design-2 {
    table {
        th,
        td {
            text-align: center !important;
        }
    }
}
.table-design-default {
    table {
        td {
            text-align: center;
        }
        thead {
            tr {
                th {
                    color: #002a3a;
                }
            }
        }
    }
}
.fhl-table-richtext-wrapper {
    table {
        width: 100% !important;
        th,
        td {
            border-bottom: 1px solid $borderLine;
            padding: convert-unit(10px, "rem") 0px;
        }
        td {
            letter-spacing: convert-unit(1.71px, "rem");
            color: get("color.gray.50");
            font-size: convert-unit(12px, "rem") !important;
            font-family: "OpenSans-Regular";
            line-height: convert-unit(16.8px, "rem");
        }
        th {
            font-family: "HarmoniaSansW05-Semibold";
            font-weight: 600;
            font-style: normal;
            font-size: convert-unit(14px, "rem");
            color: $footer-base;
            letter-spacing: convert-unit(2px, "rem");
            text-align: inherit;
            margin: 0px auto;
        }
    }
    .table-tagline {
        width: 100%;
    }
}
@media only screen and (max-width: 767px) {
    .fhlb-table-styles {
        width: 100% !important;
        overflow-x: auto;
        margin-left: 0px !important;
        .fhl-table-richtext-wrapper {
            table {
                width: 600px !important;
            }
        }
        .table-tagline {
            width: 600px !important;
        }
    }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .fhlb-table-styles {
        margin-left: convert-unit(8px, "rem") !important;
    }
    .table-design-2 {
        margin-left: 0px !important;
    }
}
