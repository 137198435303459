.advances-promo-banner-main {
    a.promo-tag-link {
        cursor: text;
        i {
            display: none;
        }
    }
}
.adv-havequestion-cntr .fhl-button-content-container,
.adv-havequestion-cntr a {
    width: 225px;
    float: left;
    margin-left: 8px;
}
.advance-results-wrapper {
    min-height: convert-unit(900px, "rem");
    overflow: visible;
    .comparebutton,
    .comparebutton:disabled {
        //font-size: convert-unit(16px, "rem");
        font-size: convert-unit(15px, "rem");
        text-align: center;
        border-radius: convert-unit(4px, "rem");
        line-height: convert-unit(18px, "rem");
        width: convert-unit(130px, "rem");
        height: convert-unit(30px, "rem");
    }
    .comparebutton:disabled {
        background-color: $btn-disabled !important;
        color: $white !important;
        cursor: not-allowed;
        font-size: convert-unit(15px, "rem");
    }
    /* Hide the browser's default checkbox */
    .advance-page-max-error {
        //float: left;
        .alert {
            margin-bottom: 0px !important;
            margin-left: -15px;
            .alert-labeled-row {
                span {
                    padding: 10px !important;
                }
                p {
                    padding: 0px 0px 0px 5px !important;
                }
            }
        }
    }
    .errormsg-wrapper {
        .alert {
            border-radius: 0;
        }
        .alert-labeled {
            padding: 0px;
        }
        .alert-info {
            border-color: get("color.danger.100");
        }
        .alert-labeled-row {
            display: table-row;
            padding: convert-unit(0px, "rem");
            .alert-labelled-cell {
                padding: convert-unit(20px, "rem");
                display: table-cell;
                vertical-align: middle;
            }

            .alert-label {
                vertical-align: middle;
                background-color: get("color.danger.100");
                width: auto;
                padding: convert-unit(10px, "rem"), convert-unit(15px, "rem");
                min-height: convert-unit(75px, "rem");
                font-size: 1.1em;
                i {
                    color: $white;
                    margin-top: convert-unit(3px, "rem");
                }
            }
            .alert-body {
                align-items: center;
                font-family: "Opensans-Regular";
                font-size: convert-unit(14px, "rem");
                color: get("color.danger.100");
                line-height: convert-unit(21px, "rem");
                padding: convert-unit(24px, "rem");
            }
        }
    }
    li {
        list-style-type: none;
    }
    .advance-result-card-box {
        h4,
        legend {
            font-family: "HarmoniaSansW05-Semibold";
            font-weight: 600;
            font-style: normal;
            font-size: convert-unit(21px, "rem");
            color: $dark-blue;
            line-height: convert-unit(25.2px, "rem");
            a {
                color: $dark-blue !important;
            }
        }
        .mobileView-advance-remove {
            display: none;
        }
        .add-to-compare-wrapper {
            width: 100%;
            overflow: visible;
            label {
                position: relative;
                cursor: pointer;
                width: convert-unit(150px, "rem");
                float: left;
                .addtocompare {
                    font-family: "Opensans-Regular";
                    //font-size: convert-unit(12px, "rem");
                    font-size: convert-unit(10px, "rem");
                    color: $default-prime-gray;
                    letter-spacing: convert-unit(1px, "rem");
                    line-height: convert-unit(16px, "rem");
                }
                .checkmark {
                    position: absolute;
                    height: convert-unit(13px, "rem");
                    width: convert-unit(13px, "rem");
                    z-index: -1;
                    border: convert-unit(0.5px, "rem") solid $dark-blue;
                    border-radius: convert-unit(2px, "rem");
                    left: 0;
                }
                input[type="checkbox"] {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                    z-index: 0;
                }
                /* input[type="checkbox"]:focus + .addtocompare {
                outline: auto;
                box-shadow: 0 0 0 0.1rem green !important;
            }

            input[type="checkbox"]:focus,
      .addtocompare:focus {
        outline: none;
      }
      input[type="checkbox"]:focus ~ .addtocompare
        {
          outline: auto;
        box-shadow: 0 0 0 0.1rem red !important;
    } */

                .addtocompare:active,
                .addtocompare:hover,
                .addtocompare:focus {
                    outline: none !important;
                    box-shadow: none !important;
                }
                input:focus ~ .addtocompare {
                    outline: auto;
                    box-shadow: auto;
                }
                /* When the checkbox is checked, add a blue background */
                input:checked ~ .checkmark {
                    background-color: $dark-blue;
                    border: convert-unit(1px, "rem") solid $dark-blue;
                    border-radius: convert-unit(2px, "rem");
                }
                /* Create the checkmark/indicator (hidden when not checked) */
                .checkmark:after {
                    content: "";
                    position: absolute;
                    display: none;
                    border-radius: convert-unit(2px, "rem");
                }

                /* Show the checkmark when checked */
                input:checked ~ .checkmark:after {
                    display: block;
                }

                /* Style the checkmark/indicator */
                .checkmark:after {
                    left: 2px;
                    top: -1px;
                    width: 7px;
                    height: convert-unit(10px, "rem");
                    border: convert-unit(1px, "rem") solid $white;
                    border-width: 0 convert-unit(2px, "rem") convert-unit(2px, "rem") 0;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .advance-media-wrapper {
        max-width: 100%;
        .row {
            .col-sm-3 {
                max-width: 100%;
                flex: 0 0 100%;
                .narrow-list-wrapper {
                    max-width: 100%;
                    padding: 0px convert-unit(15px, "rem");
                    margin: convert-unit(-10px, "rem") 0px convert-unit(40px, "rem");
                }
            }
            .col-sm-9 {
                flex: 0 0 100%;
                max-width: 100%;
                //padding: 0;
                .advance-results-wrapper {
                    padding: 0px convert-unit(15px, "rem");
                    .comparebutton {
                        margin-top: convert-unit(0px, "rem");
                    }
                    .col-sm-6 {
                        min-width: 72%;
                    }
                }
            }
        }
    }
    .adv-havequestion-cntr {
        a.left-arrow-icon,
        .fhl-button-content-container {
            margin-left: 0px;
            .left-section {
                width: 71%;
            }
            .right-section {
                i {
                    margin-left: 0;
                }
            }
        }
    }
}

@media only screen and (min-width: 768px) {
    .narrow-list-wrapper {
        margin-top: convert-unit(0px, "rem");
    }
    .advance-media-wrapper {
        .row {
            .col-sm-3 {
                max-width: 35%;
                flex: auto;
                .narrow-list-wrapper {
                    width: 225px;
                    margin: 0px;
                }
            }
            .col-sm-9 {
                max-width: 65%;
                flex: auto;
                .advance-results-wrapper {
                    .advance-result-card-box {
                        h4,
                        legend {
                            width: 100%;
                        }
                    }
                    .comparebutton {
                        margin-top: convert-unit(0px, "rem");
                    }
                    .col-sm-6 {
                        min-width: 50%;
                    }
                }
            }
        }
    }
    .advance-page-top-header {
        .fhl-small-titlebar-wrapper {
            margin-left: convert-unit(-35px, "rem");
            margin-bottom: convert-unit(35px, "rem");
        }
    }
    .adv-havequestion-cntr .fhl-button-content-container,
    .adv-havequestion-cntr a {
        margin-left: convert-unit(-17px, "rem");
    }
}

@media only screen and (min-width: 992px) {
    .advance_top_row .col-md-9 {
        display: block;
    }
    .adv-havequestion-cntr {
        a.left-arrow-icon {
            margin: 10px 0px -10px -5px;
        }
    }
    .advance-media-wrapper {
        .row {
            .col-sm-3 {
                max-width: 25%;
                .narrow-list-wrapper {
                    width: 220px;
                    margin: 0px;
                }
            }
            .col-sm-9 {
                max-width: 75%;
            }
        }
    }
    .advance-page-top-header {
        .fhl-small-titlebar-wrapper {
            margin-left: convert-unit(-20px, "rem");
        }
    }
}

@media only screen and (min-width: 1200px) {
    .adv-havequestion-cntr {
        a.left-arrow-icon {
            margin: 10px 0px -20px 0px;
        }
    }
    .advance-page-top-header {
        .fhl-small-titlebar-wrapper {
            padding-left: 30px;
        }
    }
}
