.si-article-content-container {
    position: relative;
    text-align: left;
    h1 {
        font-size: convert-unit(42px, "rem");
        line-height: convert-unit(50.4px, "rem");
        margin-bottom: convert-unit(30px, "rem");
        font-family: "HarmoniaSansW05-Regular";
        color: inherit;
    }
    .arthur-container {
        float: left;
        padding-left: convert-unit(15px, "rem");
        margin-top: convert-unit(5px, "rem");
    }
    .si-article-content-img {
        float: left;
        img {
            width: convert-unit(55px, "rem");
            height: convert-unit(55px, "rem");
            border-radius: 50%;
        }
    }
    .arthur-name,
    .arthur-name a {
        font-size: convert-unit(18px, "rem");
        line-height: convert-unit(21.6px, "rem");
        font-family: "HarmoniaSansW05-Regular";
        color: inherit;
    }
    .article-date {
        font-size: convert-unit(14px, "rem");
        line-height: convert-unit(21px, "rem");
        font-family: "Opensans-Regular";
        color: inherit;
    }
    .article-description {
        font-size: convert-unit(24px, "rem");
        color: inherit;
        line-height: convert-unit(33.6px, "rem");
        margin-top: 34px;
    }
}
@media only screen and (min-width: 320px) {
    .si-article-content-container {
        .arthur-container {
            width: 80%;
        }
    }
}
@media only screen and (min-width: 768px) {
    .si-article-content-container {
        .arthur-container {
            width: 85%;
        }
    }
}
@media only screen and (min-width: 992px) {
    .si-article-content-container {
        .arthur-container {
            width: 90%;
        }
    }
}
