.card-layout-container {
    background-color: get("color.white");
    border-radius: convert-unit(4px, "rem");
    margin: convert-unit(-120px, "rem") 0 convert-unit(50px, "rem") 0;
    -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    .card-layout-top-layer {
        border-radius: convert-unit(4px, "rem") convert-unit(4px, "rem") 0 0;
    }
    .card-layout-middle-layer {
        word-break: break-word;
        word-wrap: break-word;
        padding: convert-unit(20px, "rem");
        min-height: convert-unit(232px, "rem");
        h2.card-layout-index-header {
            font-size: convert-unit(28px, "rem");
            font-family: "HarmoniaSansW05-Semibold";
            font-weight: 600;
            font-style: normal;
            text-align: left;
            margin-top: convert-unit(-10px, "rem");
            line-height: convert-unit(33.6px, "rem");
            a,
            a:hover {
                color: inherit;
                text-decoration: none;
            }
        }
        .card-layout-index-content {
            p {
                text-align: left;
                font-size: convert-unit(16px, "rem");
                font-family: "Opensans-Regular";
                font-style: normal;
                font-weight: normal;
                line-height: convert-unit(22.4px, "rem");
            }
        }
        .card-layout-index-link {
            text-align: left;
            display: flex;
            a {
                display: table;
                vertical-align: middle;
                letter-spacing: convert-unit(1px, "rem");
                font-size: convert-unit(14px, "rem");
                letter-spacing: convert-unit(1px, "rem");
                line-height: convert-unit(16.8px, "rem");
                // align-items: center;
                text-transform: uppercase;
                color: inherit;
                i {
                    display: inline-table;
                    vertical-align: sub;
                }
                i:hover {
                    display: inline-table;
                    text-decoration: none;
                }
            }
        }
        a.card-layout-anchor:hover {
            text-decoration: none;
        }
    }
    .card-layout-bottom-layer-container {
        border-radius: 0 0 convert-unit(4px, "rem") convert-unit(4px, "rem");
        min-height: 226px;
        position: relative;
       // overflow: hidden;
        .card-layout-bottom-layer {
            border-radius: 450% / convert-unit(150px, "rem") convert-unit(150px, "rem") convert-unit(5px, "rem")
                convert-unit(5px, "rem");
           /*  max-width: 100%;
            min-height: convert-unit(250px, "rem");
            position:absolute;
            left: 0; */
        }
    }
}
@media only screen and (min-width: 320px) {
    .card-layout-container {
        margin-top: convert-unit(-45px, "rem");
        margin-bottom: convert-unit(75px, "rem");
        .card-layout-middle-layer {
            min-height: 100%;
            padding: convert-unit(19px, "rem");
            .card-layout-index-link {
                a {
                    font-size: convert-unit(13px, "rem");
                }
            }
        }
      
       
    }
    .card-layout-media-wrapper {
        .col-md-4 {
            max-width: 90%;
            margin: 0px auto;
        }
        .col-md-4:last-child {
            .card-layout-container {
                margin-bottom: 0 !important;
            }
        }
    }
}
@media only screen and (min-width: 768px) {
    .card-layout-media-wrapper {
        .col-md-4 {
            max-width: 33.33%;
        }
    }
    .card-layout-container {
        margin-top: convert-unit(-90px, "rem");
        margin-bottom: 0;
        .card-layout-middle-layer {
            .card-layout-index-link {
                a {
                    font-size: convert-unit(14px, "rem");
                }
            }
        }
     
    }
    
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .card-layout-container {
        .card-layout-middle-layer {
            min-height: convert-unit(357px, "rem");
            padding-bottom: 0px;
            margin-bottom: convert-unit(15px, "rem");
        }
        .card-layout-bottom-layer-container {
            min-height: convert-unit(171px, "rem");
           /*  .card-layout-bottom-layer {
                min-height: convert-unit(282px, "rem");
            } */
        }
    }
  
 
}
@media only screen and (min-width: 992px) {
    .card-layout-container {
        margin-top: convert-unit(-120px, "rem");
        margin-bottom: 0;
        .card-layout-middle-layer {
            min-height: convert-unit(325px, "rem");//229
        }
    }

}
@media only screen and (min-width: 1200px) {
    .card-layout-container {
        .card-layout-middle-layer {
            min-height: convert-unit(272px, "rem");//230
        }
    }
  
}
