.event-detail-content-left-section {
    word-break: break-word;
    word-wrap: break-word;
    .fhl-richtext-wrapper {
        font-family: "OpenSans-Regular";
        p {
            line-height: convert-unit(22.4px, "rem");
            letter-spacing: 0;
            a {
                display: inline !important;
                color: inherit;
            }
        }
        a {
            display: inline !important;
            color: inherit;
        }
    }
}
.eventdetails-header-wrapper,
.eventdetails-header-para {
    font-family: "HarmoniaSansW05-Regular";
    p {
        margin-bottom: 0;
    }
    .eventdetails-header-date-text {
        font-size: convert-unit(14px, "rem");
        color: inherit;
        letter-spacing: convert-unit(2.33px, "rem");
        line-height: convert-unit(18px, "rem");
        p {
            margin-bottom: 0;
        }
    }
    .eventdetails-header-img {
        height: 100%;
        img {
            margin: convert-unit(25px, "rem") 0 convert-unit(20px, "rem");
            width: 100%;
        }
    }
    h1 {
        font-size: convert-unit(42px, "rem");
        color: inherit;
        letter-spacing: 0;
        line-height: convert-unit(50.4px, "rem");
    }
    .eventdetails-lead-para {
        font-size: convert-unit(24px, "rem");
        color: inherit;
        letter-spacing: 0;
        line-height: convert-unit(33.6px, "rem");
        margin-top: convert-unit(42px, "rem");
        // margin-bottom: convert-unit(38px, "rem");
        margin-bottom: convert-unit(10px, "rem");
        p {
            color: inherit;
            a {
                color: inherit;
                text-decoration: underline;
            }
        }
        @include fontfamilyHarmoniaSans;
    }
    .events-details-register-btn,
    a.events-details-register-btn {
        background-color: inherit;
        border-radius: convert-unit(4px, "rem");
        font-size: convert-unit(16px, "rem");
        color: inherit;
        letter-spacing: 0;
        text-align: center;
        width: convert-unit(139px, "rem");
        // min-height: convert-unit(36px, "rem");
        line-height: convert-unit(20px, "rem");
        padding-top: convert-unit(10px, "rem");
        padding-bottom: convert-unit(10px, "rem");
    }
    .events-details-register-btn {
        a {
            color: inherit;
        }
    }
}
.eventdetails-header-para {
    h2 {
        font-size: convert-unit(24px, "rem");
        color: inherit;
        letter-spacing: 0;
        line-height: convert-unit(28.8px, "rem");
        margin-bottom: convert-unit(4px, "rem");
    }
    .eventdetails-header-para-content {
        font-size: convert-unit(18px, "rem");
        color: inherit;
        letter-spacing: 0;
        line-height: convert-unit(25.2px, "rem");
        margin: convert-unit(10px, "rem") 0 convert-unit(8px, "rem");
    }
}
.newspage-events-details-last-section {
    margin-bottom: convert-unit(80px, "rem");
}
.news-events-detail-content-right-section {
    word-break: break-word;
    word-wrap: break-word;
    .aside-image-header-content-link-container {
        img {
            min-height: convert-unit(120px, "rem");
            min-width: convert-unit(225px, "rem");
            width: 100%;
        }
        .mb-3 {
            font-size: convert-unit(14px, "rem");
            color: inherit;
            letter-spacing: 0;
            line-height: convert-unit(21px, "rem");
        }
        .aside-image-header-content-anchor-link {
            a {
                font-family: "HarmoniaSansW05-Regular";
            }
        }
    }
    .fhl-button-content-container {
        padding: convert-unit(4px, "rem") convert-unit(10px, "rem");
    }
    h1 {
        font-size: convert-unit(18px, "rem");
        color: inherit;
        letter-spacing: 0;
        line-height: convert-unit(21.6px, "rem");
        font-family: "HarmoniaSansW05-Regular";
        font-weight: normal;
        margin-bottom: convert-unit(15px, "rem");
    }
    .si-side-article-content-container {
        margin-top: convert-unit(40px, "rem");
        .si-card-layout-anchor-btn {
            margin-bottom: 0;
        }
        .side-article-title {
            margin-bottom: convert-unit(10px, "rem");
        }
    }
}

@media only screen and (max-width: 767px) {
    .jne-mob {
        .fhl-button-content-container {
            margin-top: convert-unit(0px, "rem") !important;
        }
    }
    .event-detail-content-left-section {
        .fhl-medium-titlebar-wrapper {
            h2 {
                text-align: left;
            }
        }
    }
    .news-events-detail-content-right-section {
        margin-bottom: 39px;
        a.left-arrow-icon {
            width: auto !important;
        }
        .fhl-button-content-container {
            width: convert-unit(230px, "rem");
            margin-top: convert-unit(80px, "rem");
        }
    }
    .eventdetails-header-wrapper,
    .eventdetails-header-para {
        h1 {
            font-size: convert-unit(32px, "rem");
            line-height: convert-unit(40px, "rem");
        }
    }
}
@media only screen and (min-width: 768px) {
    .news-events-detail-content-right-section {
        min-width: convert-unit(225px, "rem");
        .fhl-button-content-container {
            margin-top: 0;
        }
    }
}
@media only screen and (min-width: 992px) {
    .event-detail-content-left-section {
        .fhl-richtext-wrapper {
            width: 100%;
        }
        .eventdetails-header-date-text {
            margin-top: convert-unit(13px, "rem");
        }
    }

    .news-events-detail-content-right-section {
        margin-top: convert-unit(16px, "rem");
        .aside-image-header-content-link-container {
            img {
                min-width: convert-unit(225px, "rem");
            }
        }
    }
    .news-article-right-side-top-section {
        margin-top: convert-unit(100px, "rem");
    }
    .events-mapping {
        margin-top: convert-unit(-20px, "rem");
    }
}
