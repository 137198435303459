.historical-title {
  margin-top: convert-unit(25px, "rem");
  .fhl-richtext-wrapper {
    font-family: "OpenSans-Semibold";
    font-weight: 300;
    font-style: normal;
    font-size: convert-unit(16px, "rem");
    color: get("color.primary-green.100");
    line-height: convert-unit(21px, "rem");
  }
}
.fhl-historical-page-wrapper {
  width: 100%;
  p {
    font-family: "Opensans-Regular";
    color: $footer-base;
    line-height: convert-unit(21px, "rem");
    font-size: convert-unit(14px, "rem");
    margin-bottom: convert-unit(25px, "rem");
  }
  .historical-rates-cntr {
    .historical-rates-button-wrapper {
      // margin-bottom: convert-unit(20px, "rem");
      display: flex;
      .historical-picker {
        margin-right: convert-unit(20px, "rem");
        margin-left: convert-unit(-10px, "rem");
        max-width: convert-unit(165px, "rem");
      }
      .calendar-picker-placeholder {
        font-family: "HarmoniaSansW05-Regular";
        margin-right: convert-unit(20px, "rem");
        margin-left: convert-unit(-10px, "rem");
        max-width: convert-unit(165px, "rem");
        margin-top: -10px;
        margin-bottom: 5px;
        font-size: 12px;
        color: get("color.gray.50");
      }
      > button:focus {
        outline: 0;
        border: 0;
        box-shadow: 0 0 0 0 transparent !important;
      }

      .rates_download_icon {
        background-size: 74%;
      }
      .rates_download {
        background-size: 70%;
      }

      .rates_download_icon,
      .rates_download {
        background-image: url("../assets/images/icon_download.svg");
        background-repeat: no-repeat;
        margin-left: convert-unit(10px, "rem");
        min-width: convert-unit(23px, "rem");
        height: convert-unit(20px, "rem");
      }
      > button:disabled {
        background-color: $btn-disabled !important;
        color: $white !important;
        cursor: not-allowed;
      }
      .historical-rates-btn,
      .download-rates-btn {
        border-radius: convert-unit(4px, "rem");
        font-family: "HarmoniaSansW05-Regular";
        font-size: convert-unit(16px, "rem");
        color: #ffffff;
        letter-spacing: 0;
        text-align: center;
        padding: 5px 25px;
        height: convert-unit(36px, "rem");
        display: flex;
        justify-content: center;
        margin-right: convert-unit(20px, "rem");
      }
      .historical-rates-btn {
        background: $dark-blue;
        width: convert-unit(157px, "rem");
      }
      .download-rates-btn {
        background: get("color.primary-green.100");
        width: 100%;
      }
      .download-rates-btn:focus {
        outline: 0;
        border: 0;
        box-shadow: 0 0 0 0.1rem #2b9be0 !important;    
      }
      input.selection:focus {
        box-shadow: 0 0 0 0.1rem #2b9be0 !important;
      }
      .btnpicker.btnpickerenabled:focus {
        box-shadow: 0 0 0 0.1rem #2b9be0 !important;
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .fhl-historical-page-wrapper {
    margin-left: convert-unit(-6px, "rem");
    .historical-rates-cntr {
      .historical-rates-button-wrapper {
        flex-direction: column;
        > button {
          max-width: 270px;
          margin-bottom: convert-unit(25px, "rem");
          margin-left: convert-unit(-10px, "rem");
        }
        > button:last-child {
          margin-bottom: convert-unit(40px, "rem");
        }
        .historical-picker {
          margin-bottom: 20px;
        }
      }
    }
    .rates-button-error-wrapper {
      margin: 0px;
      .errormsg-wrapper {
        padding: 0;
        .col-lg-12 {
          padding: 0;
        }
      }
    }
  }
}
@media only screen and (min-width: 768px) {
  .fhlb-table-styles .col-md-12.table-top-border-green {
    margin-left: 2%;
  }
  .fhl-historical-page-wrapper {
    margin-left: convert-unit(-6px, "rem");
    .historical-rates-cntr {
      .historical-rates-button-wrapper {
        flex-direction: column;
        > button {
          max-width: 270px;
          margin-bottom: convert-unit(25px, "rem");
          margin-left: convert-unit(-10px, "rem");
        }
        > button:last-child {
          margin-bottom: convert-unit(40px, "rem");
        }
        .historical-picker {
          margin-bottom: 20px;
        }
        .rates_download_icon {
          background-size: 70%;
        }
      }
    }
    .rates-button-error-wrapper {
      margin: 30px 0px 30px -15px;
      width: 110%;
    }
  }
}
@media only screen and (min-width: 992px) {
  .historical-title {
    margin-left: convert-unit(6px, "rem");
  }
  .fhlb-table-styles .col-md-12.table-top-border-green {
    margin-left: 1%;
  }
  .fhl-historical-page-wrapper {
    margin-left: convert-unit(0px, "rem");
    .historical-rates-cntr {
      .historical-rates-button-wrapper {
        max-width: 100%;
        flex-direction: row;
        > button {
          margin-bottom: convert-unit(0px, "rem");
          margin-left: convert-unit(0px, "rem");
          max-width: 100%;
        }
        > button:last-child {
          margin-bottom: convert-unit(0px, "rem");
          margin-right: convert-unit(14px, "rem");
        }
        .rates_download_icon {
          background-size: 72%;
        }
      }
    }
    .rates-button-error-wrapper {
      margin: 0px 0px -60px -25px;
      width: 108%;
      .error-message-wrapper {
        max-width: 100%;
        .errormsg-wrapper .alert-labeled-row .alert-body {
          padding-left: 19px;
        }
      }
    }
  }
}
@media only screen and (min-width: 1200px) {
  .historical-title {
    margin-left: convert-unit(0px, "rem");
  }
  .fhl-historical-page-wrapper {
    margin-left: convert-unit(-8px, "rem");
    .rates-button-error-wrapper {
      width: convert-unit(807px, "rem");
    }
  }
}
