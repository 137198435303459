.events-article-wrapper {
    flex-direction: column;
    word-break: break-word;
    h2 {
        margin: convert-unit(25px, "rem") 0 0 0;
        color: inherit;
        font-size: convert-unit(32px, "rem");
        line-height: convert-unit(38.4px, "rem");
    }
    .events-article-content-block {
        flex-direction: row;
        align-content: space-between;
        padding-right: 8vw;
        .events-left-section {
            flex: 1;
            img {
                margin-top: convert-unit(30px, "rem");
                width: convert-unit(60px, "rem");
                height: convert-unit(60px, "rem");
            }
        }
        .events-right-section {
            flex: 5;
            h3 {
                color: inherit;
                font-size: convert-unit(28px, "rem");
                margin-top: convert-unit(30px, "rem");
                margin-bottom: convert-unit(15px, "rem");
                font-family: "HarmoniaSansW05-Semibold";
                font-weight: 600;
                font-style: normal;
                line-height: convert-unit(33.6px, "rem");
                a,
                a:hover {
                    text-decoration: none;
                    color: inherit !important;
                    word-break: break-word;
                }
            }
            p {
                color: inherit;
                a {
                    color: inherit !important;
                }
            }
            .events-content-section {
                @include fontfamilyOpenSans;
                font-size: convert-unit(14px, "rem");
                color: inherit;
                line-height: convert-unit(21px, "rem");
            }
            .events-register-btn {
                min-height: convert-unit(30px, "rem");
                width: convert-unit(140px, "rem");
                border-radius: convert-unit(4px, "rem");
                background-color: $dark-blue;
                color: inherit;
                margin: convert-unit(32px, "rem") 0;
                display: block;
                text-align: center;
                line-height: convert-unit(16.8px, "rem");
                font-size: convert-unit(15px, "rem");
                word-break: break-word;
                padding-top: 6px;
            }
            .events-register-btn:hover {
                text-decoration: none;
            }
            .primary-blue-link {
                letter-spacing: convert-unit(1px, "rem");
                color: inherit;
                font-size: convert-unit(14px, "rem");
                line-height: convert-unit(16.8px, "rem");
            }
        }
    }
}
@media only screen and (min-width: 320px) {
    .events-article-wrapper {
        border-right: 0;
        margin: 0px 0px convert-unit(30px, "rem");
        padding-bottom: convert-unit(35px, "rem");
        h2 {
            margin-top: 0;
        }
        .events-article-content-block {
            flex-direction: row;
            padding-right: 0;
            .events-left-section {
                text-align: center;
                flex: 0;
                img {
                    margin-top: convert-unit(16px, "rem");
                }
            }
            .events-right-section {
                margin: 0px convert-unit(10px, "rem") 0px convert-unit(20px, "rem");
                h3 {
                    margin-top: convert-unit(15px, "rem");
                    a,
                    a:hover {
                        text-decoration: none;
                    }
                }
                .events-register-btn {
                    margin: convert-unit(32px, "rem") 0 convert-unit(15px, "rem");
                }
            }
        }
    }
}
@media only screen and (min-width: 768px) {
    .events-article-wrapper {
        padding-bottom: 0px;
        margin: 0;
        .events-article-content-block {
            padding-right: 8%;
            .events-right-section {
                .events-register-btn {
                    margin: convert-unit(32px, "rem") 0 convert-unit(25px, "rem");
                }
            }
        }
    }
}
