@import "../helpers/mixins";

.pl-carousel-layout {
  margin: 0px auto convert-unit(90px, "rem");
  .pl-carousel-slide {
    margin-bottom: convert-unit(70px, "rem");
  }
}
.pl-carousel-slides {
  min-height: convert-unit(150px, "rem");
}
.pl-carousel-prev,
.pl-carousel-next {
  width: convert-unit(34px, "rem");
}
button.pl-carousel-control {
  border: 1px solid $border-dark-line;
  background-color: transparent !important;
  border-radius: 6px;
}
button.pl-carousel-control:hover,
button.pl-carousel-control:focus {
  background-color: get("color.primary-green.100") !important;
  outline: 0;
  i.fa-chevron-left,
  i.fa-chevron-right {
    border: solid white;
    border-width: 0 2px 2px 0;
  }
}
i.fa-chevron-left,
i.fa-chevron-right {
  border: solid get("color.primary-green.100");
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 6px;
  margin-top: 5px;
}
i.fa-chevron-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  margin-left: 1px;
}
i.fa-chevron-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  margin-left: -6px;
}
.pl-carousel-pagination {
  .active {
    text-decoration: none !important;
  }
  .pagination {
    li.page-item {
      padding: 6px;
      line-height: 30px;
      cursor: pointer;
      border-radius: 4px;
      a.page-link {
        border: 0;
        font-size: 18px;
        padding: 5px 9px;
        color: get("color.primary-green.100");
        text-decoration-color: transparent !important;
      }
      a.page-link:hover,
      a.page-link:focus {
        background-color: transparent;
        color: get("color.primary-green.100");
      }
    }
    li.page-item.active {
      cursor: not-allowed;
      color: white;
      background: get("color.primary-green.100");
      .page-link,
      .page-link:hover,
      .page-link:focus {
        color: #fff;
        background-color: transparent;
        text-decoration: none;
        text-decoration-color: transparent !important;
      }
    }
  }
}
.pl-carousel-slide {
  .title-text-article-container {
    .title-text-right-section {
      .title-text-article-title {
        font-size: convert-unit(24px, "rem");
        line-height: convert-unit(28.8px, "rem");
        word-break: break-word;
      }
      .title-text-article-content {
        font-size: convert-unit(16px, "rem");
        color: inherit;
        line-height: convert-unit(22.4px, "rem");
        @include fontfamilyOpenSans;
        p {
          color: inherit;
          word-break: break-word;
        }
      }
    }
  }
}
@media only screen and (max-width: 575px) {
  .pl-carousel-layout {
    max-width: 90% !important;
    .pl-carousel-slide {
      margin-bottom: convert-unit(30px, "rem");
    }
  }
}
@media only screen and (max-width: 767px) {
  .pl-carousel-layout {
    max-width: convert-unit(540px, "rem");
    .pl-carousel-slides {
      .row {
        width: 80%;
        margin: 0 10%;
      }
    }
  }
  nav.pl-carousel-pagination {
    position: relative;
  }
  .pl-carousel-pagination {
    left: 15% !important;
    ul.pagination {
      display: block;
      left: 0;
      width: 80%;
      li.page-item {
        float: left;
      }
    }
  }
  .carousel-main-header {
    .fhl-medium-titlebar-wrapper {
      h2 {
        font-size: convert-unit(32px, "rem");
        margin-bottom: convert-unit(-20px, "rem");
      }
    }
  }
}
@media only screen and (min-width: 768px) {
  .pl-carousel-layout {
    max-width: convert-unit(720px, "rem");
    .pl-carousel-slides {
      .pl-carousel-slide {
        margin-bottom: convert-unit(55px, "rem");
        .row {
          width: 90%;
          margin: 0 5%;
        }
        .scenario-image1 {
          margin-bottom: convert-unit(20px, "rem");
        }
      }
    }
  }
  .carousel-main-header {
    .fhl-medium-titlebar-wrapper h2 {
      font-size: convert-unit(38px, "rem");
      padding-left: convert-unit(65px, "rem");
    }
  }
}
@media only screen and (min-width: 992px) {
  .pl-carousel-layout {
    max-width: convert-unit(960px, "rem");
  }
  .carousel-main-header {
    .fhl-medium-titlebar-wrapper h2 {
      font-size: convert-unit(42px, "rem");
      padding-left: convert-unit(0px, "rem");
    }
  }
}
@media only screen and (min-width: 1200px) {
  .pl-carousel-layout {
    max-width: convert-unit(1136px, "rem");
  }
}
