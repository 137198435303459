.search-filter-container {
  .search-header-wrapper {
    .search-header-box {
      .completer-holder {
        position: relative;
        white-space: normal;
        .completer-item-text {
          word-break: break-word;
        }
      }
      .completer-dropdown-holder {
        position: absolute;
        top: convert-unit(32px, "rem");
      }
    }
  }
  .form-group {
    margin: convert-unit(0px, "rem");
  }
  input[type="text"],
  input[type="search"] {
    display: block;
    min-width: convert-unit(225px, "rem");
    height: convert-unit(25px, "rem");
    padding: convert-unit(0px, "rem") convert-unit(25px, "rem")
      convert-unit(0px, "rem") convert-unit(10px, "rem");
    color: $footer-base;
    margin-top: convert-unit(5px, "rem");
    font-size: convert-unit(14px, "rem");
    background-color: $searchboxbg;
    border: 0;
    border-radius: convert-unit(4px, "rem");
  }
  input[type="text"]::-webkit-input-placeholder,
  input[type="search"]::-webkit-input-placeholder {
    color: get("color.black");
    opacity: 0.5;
  }
  input[type="text"]::-moz-placeholder,
  input[type="search"]::-moz-placeholder {
    color: get("color.black");
    opacity: 0.5;
  }
  input[type="text"]:-ms-input-placeholder,
  input[type="search"]:-ms-input-placeholder {
    color: get("color.black");
    opacity: 0.5;
  }
  input[type="text"]:-moz-placeholder,
  input[type="search"]:-moz-placeholder {
    color: get("color.black");
    opacity: 0.5;
  }
  button[type="button"] {
    border: convert-unit(0px, "rem");
    background-color: transparent;
    position: absolute;
    top: convert-unit(6px, "rem");
    right: convert-unit(0px, "rem");
    color: $black;
    cursor: pointer;
    height: convert-unit(25px, "rem");
    width: convert-unit(25px, "rem");
    padding: convert-unit(0px, "rem");
    text-indent: -999999px;
    i {
      //opacity: 0.7;
      font-size: convert-unit(18px, "rem");
      padding-top: convert-unit(3px, "rem");
      color: $footer-base;
      text-indent: 0px;
      float: right;
      margin-right: 2px;
      margin-bottom: 4px;
    }
  }
  button[type="button"]:hover {
    outline: 0;
    border: convert-unit(0px, "rem");
  }
  button[type="button"]:focus,
  button[type="button"]:active {
  }
  input[type="text"].search-input-btn:focus {
       box-shadow: 0 0 0 0.1rem #2b9be0 !important;
       }

}
