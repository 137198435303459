.imgcontent-layout-container {
    .imgcontent-layout-top-layer-container {
        text-align: center;
        a {
            display: inline-block;
        }
        .imgcontent-layout-top-layer {
            background-color: $smokyWhite;
            margin-left: auto;
            margin-right: auto;
            width: 11.875rem;
            height: 11.875rem;
            border-radius: 50%;
            img {
                max-width: 100%;
                width: convert-unit(190px, "rem");
                height: convert-unit(190px, "rem");
                border-radius: 50%;
            }
        }
    }
    .imgcontent-layout-last-layer {
        text-align: left;
        .imgcontent-layout-index-name {
            font-size: convert-unit(24px, "rem");
            color: get("color.primary-green.100");
            text-align: center;
            padding: convert-unit(25px, "rem") convert-unit(10px, "rem");
            line-height: convert-unit(28.8px, "rem");
            font-family: "HarmoniaSansW05-Regular";
            a,
            a:hover {
                text-decoration: none;
            }
        }
        .imgcontent-layout-index-header {
            font-size: convert-unit(16px, "rem");
            font-family: "HarmoniaSansW05-Semibold";
            line-height: convert-unit(19.2px, "rem");
            padding-bottom: convert-unit(10px, "rem");
            font-weight: 600;
            font-style: normal;
            margin-bottom: convert-unit(8px, "rem");
        }
        .imgcontent-layout-index-content {
            font-size: convert-unit(14px, "rem");
            line-height: convert-unit(21px, "rem");
            font-family: "Opensans-Regular";
        }
        .imgcontent-layout-index-link {
            padding-top: convert-unit(10px, "rem");
            color: inherit;
            a {
                font-size: convert-unit(14px, "rem");
                letter-spacing: convert-unit(1px, "rem");
                line-height: convert-unit(16.8px, "rem");
                font-family: "HarmoniaSansW05-Regular";
                color: inherit;
                i {
                    height: convert-unit(21px, "rem");
                }
            }
        }
    }
}
.img-header-content-container {
    text-align: center;
    img {
        border-radius: 50%;
        margin-bottom: convert-unit(10px, "rem") !important;
    }
    h3,
    h2 {
        font-size: convert-unit(24px, "rem");
        text-align: center;
        line-height: convert-unit(28.8px, "rem");
        color: inherit;
        font-family: "HarmoniaSansW05-Semibold";
        font-weight: 600;
        font-style: normal;
        a {
            color: inherit;
        }
    }
}
.aside-image-header-content-link-container {
    overflow: visible;
    width: 100%;
    img {
        max-width: 100%;
        margin: 0px auto convert-unit(30px, "rem");
    }
    h3 {
        font-family: "HarmoniaSansW05-Regular";
        font-size: convert-unit(18px, "rem");
        color: inherit;
        line-height: convert-unit(21.6px, "rem");
    }
    p {
        font-size: convert-unit(14px, "rem");
        line-height: convert-unit(21px, "rem");
    }
    @include fontfamilyOpenSans;
    p.mb-3 {
        a {
            text-decoration: underline;
            color: inherit;
        }
    }
    p.aside-image-header-content-anchor-link {
        display: table;
        a {
            color: inherit;
            font-family: "HarmoniaSansW05-Regular";
        }
    }
    a.aside-image-header-content-anchor-link {
        display: table !important;
        vertical-align: middle;
        color: inherit;
        i {
            display: inline-table;
            vertical-align: sub;
        }
        i:hover {
            text-decoration: none !important;
            display: inline-block !important;
        }
    }
}
.img-header-content-container,
.image-header-content-link-container {
    overflow: visible;
    width: 100%;
    img {
        max-width: 100%;
        margin: 0px auto convert-unit(30px, "rem");
    }
    a {
        color: inherit;
    }
    p.mb-3 {
        a {
            color: inherit;
            text-decoration: underline;
        }
    }
    h5,
    h3,
    h2,
    p {
        margin-bottom: 20px;
    }
    p {
        font-family: "Opensans-Regular";
        font-size: convert-unit(16px, "rem");
        color: inherit;
        line-height: convert-unit(22.4px, "rem");
    }
    @include fontfamilyOpenSans;
    p.image-header-content-anchor-link {
        display: table;
        a {
            color: inherit;
            font-family: "HarmoniaSansW05-Regular";
        }
    }
    a.image-header-content-anchor-link {
        display: table !important;
        vertical-align: middle;
        color: inherit;
        i {
            display: inline-table;
            vertical-align: sub;
        }
        i:hover {
            text-decoration: none !important;
            display: inline-block !important;
        }
    }
}

.image-header-content-link-container {
    h3 {
        font-size: convert-unit(24px, "rem");
        text-align: center;
        line-height: convert-unit(28.8px, "rem");
        color: inherit;
        font-family: "HarmoniaSansW05-Regular";
        a {
            color: inherit;
        }
    }
}
@media only screen and (max-width: 767px) {
    .image-header-content-link-container,
    .aside-image-header-content-link-container {
        img {
            width: 100%;
        }
    }
    .become-member-mobile-wrapper {
        margin-top: 0;
        padding: 0px !important;
    }
    .imgcontent-layout-container {
        padding: convert-unit(20px, "rem") convert-unit(0px, "rem") convert-unit(30px, "rem");
    }
    .img-header-content-container {
        margin-bottom: convert-unit(40px, "rem");
    }
    .become-member-card-container .col-md-4:last-child .img-header-content-container {
        margin-bottom: convert-unit(0px, "rem");
    }
    .become-a-member-page-container {
        padding: convert-unit(0px, "rem") convert-unit(16px, "rem");
        background-color: #fff;
    }
    .become-member-img-content-placeholder-mobile .col-md-4:last-child {
        margin-bottom: 0;
    }
    .become-member-img-content-placeholder-mobile {
        margin-top: convert-unit(-50px, "rem");
        .col-md-4 {
            margin-bottom: convert-unit(40px, "rem");
        }
    }
    .displayImg-desktop-right {
        text-align: left;
    }
    .member-row-middle-mt {
        margin-top: convert-unit(40px, "rem");
    }
    .ahp-content-wrapper {
        .become-member-img-content-placeholder-mobile {
            padding-bottom: convert-unit(16px, "rem") !important;
        }
        .mt-4-mobile {
            margin-top: convert-unit(24px, "rem") !important;
            display: inline-block;
        }
    }
    .careers-img-content-top {
        margin-top: convert-unit(15px, "rem");
    }
}
@media only screen and (min-width: 768px) {
    .displayImg-desktop-right {
        text-align: right;
    }
    .imgcontent-layout-container {
        padding: convert-unit(30px, "rem") convert-unit(16px, "rem") convert-unit(30px, "rem");
    }
    .become-member-card-container {
        padding-left: convert-unit(10px, "rem");
    }
    .image-header-content-link-container,
    .aside-image-header-content-link-container {
        img {
            min-width: auto;
            min-height: auto;
        }
    }
    .member-row-middle-mt {
        margin-top: convert-unit(60px, "rem");
    }
    .ahp-content-wrapper {
        .mt-4-mobile {
            display: inline-block;
        }
    }
}
@media only screen and (min-width: 992px) {
    .imgcontent-layout-container {
        padding: convert-unit(30px, "rem") convert-unit(6px, "rem") convert-unit(30px, "rem");
    }
    .img-header-content-container {
        img {
            max-width: convert-unit(169px, "rem") !important;
            min-height: convert-unit(169px, "rem");
        }
    }
    .image-header-content-link-container {
        img {
            width: 100%;
            min-width: convert-unit(307px, "rem");
            min-height: convert-unit(204px, "rem");
        }
    }
    .member-row-middle-mt {
        margin-top: convert-unit(90px, "rem");
    }
}
@media only screen and (min-width: 1200px) {
    .imgcontent-layout-container {
        padding: convert-unit(50px, "rem") convert-unit(45px, "rem") convert-unit(30px, "rem");
    }
}
