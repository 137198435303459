@import "../helpers/mixins";

.title-text-image-container {
    overflow: auto;
    h2,
    p {
        padding-right: 15%;
        word-break: break-word;
    }
    h2 {
        font-size: convert-unit(42px, "rem");
        line-height: convert-unit(50.4px, "rem");
    }
    p {
        font-family: "Opensans-Regular";
        font-style: normal;
        font-weight: normal;
        font-size: convert-unit(14px, "rem");
        //color: $footer-base;
        line-height: convert-unit(21px, "rem");
        text-align: left;
    }
    a {
        width: convert-unit(470px, "rem");
    }
    img {
        // max-width: 470px; // Default FHL Image Placeholder for Width
        // max-height: 265px; // Default FHL Image Placeholder for Height
        max-width: 100%;
    }
}
.aside-title-text-image-container {
    overflow: auto;
    @include fontfamilyOpenSans;
    h3,
    p {
        word-break: break-word;
    }
    h3 {
        font-size: convert-unit(28px, "rem");
        font-family: "HarmoniaSansW05-Semibold";
        font-weight: 600;
        font-style: normal;
        color: inherit;
        line-height: convert-unit(33.6px, "rem");
    }
    p {
        font-size: convert-unit(14px, "rem");
        line-height: convert-unit(21px, "rem");
        text-align: left;
    }
    a {
        width: 100%;
        max-width: 225px;
    }
    img {
        // max-width: 470px; // Default FHL Image Placeholder for Width
        // max-height: 265px; // Default FHL Image Placeholder for Height
        max-width: 100%;
    }
}
.fhl-title-content-btn-wrapper {
    word-break: break-word;
    word-wrap: break-word;
    overflow: visible;
    flex-direction: column;
    text-align: left;
    h2,
    h3 {
        font-size: convert-unit(32px, "rem");
        color: inherit;
        line-height: convert-unit(38.4px, "rem");
    }
    div {
        flex-direction: row;
        overflow: visible;
        align-items: baseline;
        @include fontfamilyOpenSans;
        p {
            margin-right: convert-unit(5px, "rem");
            color: inherit;
            font-size: convert-unit(14px, "rem");
            line-height: convert-unit(21px, "rem");
        }
        a {
            background-color: get("color.white");
            border-width: convert-unit(1px, "rem");
            border-style: solid;
            border-color: inherit;
            box-shadow: convert-unit(2px, "rem") convert-unit(2px, "rem") convert-unit(4px, "rem") 0
                $shadow-smokywhite-color;
            border-radius: convert-unit(4px, "rem");
            flex-direction: row;
            align-content: space-between;
            width: auto;
            overflow: visible;
            padding: convert-unit(7px, "rem") convert-unit(15px, "rem");
            cursor: pointer;
            margin-left: convert-unit(20px, "rem");
            color: inherit;
            i {
                margin-bottom: convert-unit(-2px, "rem"); //extra supporting property
                float: right; //extra supporting property
            }
        }
        a:hover {
            text-decoration: none;
        }
    }
}
.fhl-promo-header-content-wrapper {
    word-break: break-word;
    word-wrap: break-word;
    overflow: visible;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-right: 5px;
    @include fontfamilyHarmoniaSans;
    h1 {
        color: inherit;
        margin-bottom: convert-unit(16px, "rem");
    }
    div {
        flex-direction: row;
        overflow: visible;
        align-items: baseline;
        p {
            font-size: convert-unit(18px, "rem");
            color: inherit;
            line-height: convert-unit(25.2px, "rem");
            a {
                display: table;
                color: inherit;
            }
        }
        a {
            display: table;
            color: inherit;
        }
    }
}
.fhl-mediumLevel-h1-content-wrapper {
    margin-bottom: 0 !important;
    flex-direction: column;
    text-align: left;
    p {
        margin-bottom: 0px;
    }
    h1 {
        font-family: "HarmoniaSansW05-Semibold";
        font-weight: 600;
        font-style: normal;
        font-size: convert-unit(28px, "rem");
        line-height: convert-unit(33.6px, "rem");
        margin-bottom: convert-unit(10px, "rem");
        color: inherit;
    }
    div {
        line-height: convert-unit(21.6px, "rem");
        font-size: convert-unit(18px, "rem");
        color: inherit;
        @include fontfamilyOpenSans;
    }
}
@media only screen and (max-width: 767px) {
    .aside-title-text-image-container {
        margin: convert-unit(10px, "rem") 0px convert-unit(20px, "rem") !important;
        float: left;
        a {
            width: 100%;
            max-width: 100%;
            img {
                width: 100%;
            }
        }
    }

    .title-text-image-container {
        margin: 0px convert-unit(0px, "rem") convert-unit(25px, "rem");
        a {
            width: 100%;
        }
        img {
            max-width: 100%;
            margin-bottom: 0px;
        }
        h2 {
            padding: 0;
            font-size: convert-unit(36px, "rem");
        }
        p {
            padding: 0;
        }
    }
    .fhl-promo-header-content-wrapper {
        margin: convert-unit(45px, "rem") auto 0 !important;
        width: 90%;
        h1 {
            font-size: convert-unit(34px, "rem");
        }
        p {
            margin-top: convert-unit(5px, "rem");
        }
    }
    .fhl-title-content-btn-wrapper {
        h2,
        h3 {
            font-size: convert-unit(24px, "rem");
            line-height: convert-unit(30px, "rem");
        }
        div {
            flex-direction: column;
        }
    }
}
@media only screen and (min-width: 768px) {
    .title-text-image-container {
        margin: convert-unit(0px, "rem");
        h2 {
            font-size: convert-unit(32px, "rem");
        }
        a {
            max-width: 100%;
        }
        img {
            max-width: 100%;
        }
    }
    .fhl-promo-header-content-wrapper {
        width: 85%;
        h1 {
            font-size: convert-unit(34px, "rem");
        }
    }
}
@media only screen and (min-width: 992px) {
    .title-text-image-container {
        margin: convert-unit(0px, "rem");
        img {
            max-width: 100%;
        }
        h2 {
            font-size: convert-unit(42px, "rem");
        }
        h2,
        p {
            padding-right: 15%;
            margin-bottom: convert-unit(20px, "rem");
        }
    }
    .fhl-promo-header-content-wrapper {
        width: 70%;
        h1 {
            font-size: convert-unit(42px, "rem");
        }
    }
}
