/*!
 * Bootstrap Reboot v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport {
  width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "HarmoniaSansW05-Regular", sans-serif;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 500; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

:root {
  --chart-color-home: #12b886;
  --chart-color-mortgage: #12b886;
  --chart-color-rent: #12b886;
  --chart-color-health-beauty: #d24887;
  --chart-color-beauty: #d24887;
  --chart-color-health-fitness: #d24887;
  --chart-color-food-drinks: #fd7e14;
  --chart-color-alcohol-bars: #fd7e14;
  --chart-color-fast-food: #fd7e14;
  --chart-color-groceries: #fd7e14;
  --chart-color-restaurants: #fd7e14;
  --chart-color-transportation: #94c052;
  --chart-color-car: #94c052;
  --chart-color-public-transport: #94c052;
  --chart-color-shopping: #d24887;
  --chart-color-clothing: #d24887;
  --chart-color-electronics: #d24887;
  --chart-color-hobbies-entertainment: #883d9a;
  --chart-color-bills-utilities: #74c0fc;
  --chart-color-internet: #74c0fc;
  --chart-color-mobile-phone: #74c0fc;
  --chart-color-utilities: #74c0fc;
  --chart-color-income: #74c0fc;
  --chart-color-bonus: #74c0fc;
  --chart-color-salary-wages: #74c0fc;
  --chart-color-other-income: #4259c5;
  --chart-color-interest-income: #4259c5;
  --chart-color-rental-income: #4259c5;
  --chart-color-transfers: #e8590c;
  --chart-color-uncategorised: #8c9194;
  --chart-color-suppliers: #ffd075;
  --chart-color-salaries: #e8590c;
  --chart-color-office-rent: #ba293a;
  --chart-color-loan-repayment: #fd7e14;
  --chart-color-miscellaneous: #d24887;
  --chart-color-intercompany-receivable: #12b886;
  --chart-color-term-deposit: #fd7e14;
  --chart-color-direct-debit-collections: #00569c;
  --chart-color-intracompany-receivable: #4259c5;
  --chart-color-interest-received: #883d9a;
  --chart-categories-colors: home #12b886 home, mortgage #12b886 home, rent #12b886 home, health-beauty #d24887 favorite, beauty #d24887 favorite, health-fitness #d24887 favorite, food-drinks #fd7e14 restaurant, alcohol-bars #fd7e14 restaurant, fast-food #fd7e14 restaurant, groceries #fd7e14 restaurant, restaurants #fd7e14 restaurant, transportation #94c052 directions_car, car #94c052 directions_car, public-transport #94c052 directions_car, shopping #d24887 shopping_cart, clothing #d24887 shopping_cart, electronics #d24887 shopping_cart, hobbies-entertainment #883d9a local_play, bills-utilities #74c0fc description, internet #74c0fc description, mobile-phone #74c0fc description, utilities #74c0fc description, income #74c0fc add_box, bonus #74c0fc add_box, salary-wages #74c0fc add_box, other-income #4259c5 toll, interest-income #4259c5 toll, rental-income #4259c5 toll, transfers #e8590c swap_horiz, uncategorised #8c9194 help_outline, suppliers #ffd075, salaries #e8590c, office-rent #ba293a, loan-repayment #fd7e14, miscellaneous #d24887, intercompany-receivable #12b886, term-deposit #fd7e14, direct-debit-collections #00569c, intracompany-receivable #4259c5, interest-received #883d9a; }

/* transform translate x = badge width / 2 - arrow right offset - arrow width / 2 - popover border width */
/*
  This partial is for text variants for conveying status contexts
  For typographic styles, please add to _typography.scss
*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/*********************************************
        FHL Bank ADA Compilance Colors
*********************************************/
/***************Green Pattern****************/
/***************Gray Pattern****************/
/***************Bright Green Pattern****************/
/***************Bright Blue Pattern****************/
/***************Danger/Error Pattern****************/
.fhl-medium-titlebar-wrapper h2,
.fhl-mediumLevel-h1-wrapper h1 {
  font-size: 2rem;
  font-weight: normal;
  font-style: normal;
  height: auto;
  margin-bottom: 0.9375rem;
  text-align: left;
  color: #002a3a;
  line-height: 2.4rem; }

.fhl-large-titlebar-wrapper h1 {
  font-size: 2.625rem;
  height: auto;
  text-align: left;
  margin: 0 0 0.9375rem 0;
  color: #00833c;
  line-height: 3.15rem; }

.fhl-small-titlebar-wrapper h2 {
  font-weight: 400;
  height: auto;
  margin: 0 0 0.9375rem 0;
  text-align: left;
  font-size: 1.5rem;
  color: #00833c;
  line-height: 1.8rem; }

.fhl-extrasmall-title-wrapper h3,
.fhl-h2-medium-title-wrapper h2 {
  font-weight: 400;
  height: auto;
  margin: 0 0 0.9375rem 0;
  text-align: left;
  font-size: 1.125rem;
  line-height: 1.8rem; }

.fhl-large-bold-titlebar-wrapper h3 {
  font-size: 2rem;
  font-weight: 400;
  height: auto;
  margin: 0px 0px 0.9375rem 0px;
  text-align: left; }

.fhl-extrasmall-titlebar-wrapper h3,
.fhl-smallLevel-h2-wrapper h2 {
  color: inherit;
  font-family: "OpenSans-Semibold";
  font-weight: 300;
  font-style: normal;
  font-size: 1rem;
  line-height: 1.3125rem; }

.fhl-smallLevel-h3-wrapper h3 {
  font-family: "OpenSans-Semibold";
  font-size: 1rem;
  color: inherit;
  letter-spacing: 0;
  line-height: 1.3125rem; }

.fhl-smallLevel-h4-wrapper h4 {
  font-family: "OpenSans-Semibold";
  font-size: 1rem;
  color: inherit;
  letter-spacing: 0;
  line-height: 1.3125rem; }

@media only screen and (max-width: 767px) {
  .fhl-large-titlebar-wrapper h1 {
    font-size: 2.25rem; } }

.fhl-medium-title-para-text-wrapper {
  flex-direction: column;
  width: 100%; }
  .fhl-medium-title-para-text-wrapper h3 {
    font-family: "HarmoniaSansW05-Semibold";
    font-style: normal;
    font-weight: 600;
    font-size: 1.3125rem;
    color: inherit;
    line-height: 1.575rem;
    word-break: break-word; }
  .fhl-medium-title-para-text-wrapper p {
    font-family: "Opensans-Regular";
    font-weight: normal;
    font-style: normal; }
    .fhl-medium-title-para-text-wrapper p em {
      font-family: "OpenSans-Italic";
      font-weight: normal;
      font-style: italic; }
      .fhl-medium-title-para-text-wrapper p em strong {
        font-family: "OpenSans-SemiboldItalic";
        font-weight: 300;
        font-style: italic; }
    .fhl-medium-title-para-text-wrapper p strong {
      font-family: "OpenSans-Semibold";
      font-weight: 300;
      font-style: normal; }
      .fhl-medium-title-para-text-wrapper p strong em {
        font-family: "OpenSans-SemiboldItalic";
        font-weight: 300;
        font-style: italic; }
    .fhl-medium-title-para-text-wrapper p a {
      color: inherit; }
  .fhl-medium-title-para-text-wrapper p {
    font-size: 0.875rem;
    color: inherit;
    line-height: 1.3125rem;
    margin-bottom: 0;
    word-break: break-word; }

a.left-arrow-icon {
  text-transform: uppercase;
  font-size: 0.875rem;
  letter-spacing: 0.0625rem;
  line-height: 1.05rem;
  text-decoration: none;
  display: inline-block;
  vertical-align: middle; }
  a.left-arrow-icon i {
    margin-left: 0.625rem !important;
    border: 1px solid;
    border-radius: 50%;
    font-size: 20px;
    line-height: 1.1875rem;
    width: 1.375rem;
    display: inline-block;
    vertical-align: sub; }
  a.left-arrow-icon i:hover {
    display: inline-block;
    text-decoration: none; }

a.left-arrow-icon:hover {
  outline: 0;
  text-decoration: none; }

@media only screen and (max-width: 992px) {
  .glb-header-webbasic-patch {
    margin-top: -4.75rem; } }

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .fhl-medium-titlebar-wrapper h2 {
    text-align: center; }
  .si-promo-mobile-wrapper .fhl-large-titlebar-wrapper {
    margin-top: 0rem; }
  .simple-empty-grey-banner {
    margin-top: 4.625rem; }
  .search-result-main-title-wrapper {
    margin-top: 5.625rem; }
  .fundingGoal-container,
  .fhl-title-subTitle-wrapper {
    width: 100%; }
  .classic-advance-promo-wrapper {
    max-width: 100% !important;
    min-height: 100% !important;
    margin: 1.875rem 0 !important; }
    .classic-advance-promo-wrapper h1 {
      font-size: 2.25rem !important;
      line-height: 2.525rem; }
    .classic-advance-promo-wrapper p {
      font-size: 1rem; }
  .advance-sidebar-mapping {
    margin-top: 0 !important;
    padding: 0 !important; }
  .classic-advance-feature-wrapper .advance-sub-header,
  .classic-advance-feature-edit-wrapper .advance-sub-header {
    margin-bottom: 0.9375rem; }
  .fhl-rates-promo-wrapper {
    min-height: 20rem !important;
    margin-bottom: 1.875rem !important; }
  .mob-top-30 {
    margin-top: 1.875rem; }
  .horizontal-sep {
    display: none; }
  .white-bg,
  .smokyWhite-bg {
    padding: 1.5rem 1rem !important; }
  .gray-bg {
    padding: 1rem !important; }
  .fhl-small-titlebar-wrapper {
    margin-left: 0px; }
  .compare-advance-mobile-wrapper .fhl-large-titlebar-wrapper {
    margin: 0px 15px 0px !important; }
  .compare-advance-mobile-wrapper .left-arrow-icon {
    margin: 0px 20px;
    width: 100%; }
  .compare-advance-mobile-wrapper .fhl-title-content-btn-wrapper {
    margin: 0px 15px !important; }
    .compare-advance-mobile-wrapper .fhl-title-content-btn-wrapper .left-arrow-icon {
      width: auto;
      margin-left: 0; }
  .compare-advance-mobile-wrapper:last-child {
    margin-bottom: 10px; }
  .advance-details-mobile-wrapper {
    margin-bottom: 50px; }
    .advance-details-mobile-wrapper .fhl-title-content-btn-wrapper div a {
      margin-left: 0px; }
    .advance-details-mobile-wrapper .aside-title-text-image-container {
      margin: 0; }
  .news-article-content-block div p {
    margin-bottom: 10px !important; }
  .content-widget-test-image-template {
    margin: 10px 0px; }
    .content-widget-test-image-template img {
      width: 100%; }
  .aboutus-card-layout {
    margin-bottom: -0.9375rem !important; }
  .aboutus-smallpromo-layout {
    margin-top: 6.25rem; }
  .aboutus-headerplaceholder-aligner {
    margin-bottom: 1.875rem; }
  .careers-headerplaceholder-aligner {
    margin-bottom: 0.9375rem; }
  .aboutus-pagebottom-spacer {
    margin-bottom: 3.125rem; }
  .footer-copyright span ul {
    padding: 0; } }

@media only screen and (min-width: 768px) {
  .fhl-large-titlebar-wrapper {
    margin-top: 5.625rem; }
  .si-promo-mobile-wrapper .fhl-large-titlebar-wrapper {
    margin-top: 0rem; }
  .simple-empty-grey-banner {
    margin-top: 3.375rem; }
  .search-result-main-title-wrapper {
    margin-top: 5.625rem; }
  .glb-header-patch {
    margin-top: 3.75rem; }
  .news-events-media-right-section .fhl-content-wrapper,
  .news-events-media-right-section a.left-arrow-icon {
    min-width: 14.0625rem !important; }
  .fhl-medium-titlebar-wrapper h2 {
    text-align: left; }
  .fundingGoal-container,
  .fhl-title-subTitle-wrapper {
    width: 100%; }
  .white-bg.extra-space-whiteBg {
    padding: 0 35px;
    margin-top: -50px; }
  .secondary-header {
    width: 768px; }
    .secondary-header .container .col-md-3 {
      display: none !important; }
    .secondary-header .container .col-md-2 {
      display: none !important; }
    .secondary-header .container .col-md-7 {
      max-width: 100% !important;
      flex: 1; }
  .news-article-wrapper h2 {
    margin: 0px 0px 0.9375rem; }
  .events-section-bdr {
    border-right: 1px solid #00a0df;
    border-bottom: 0; }
  .advance_top_row {
    padding: 1.25rem 0 0 0.9375rem; }
  .large-screen-container {
    padding-right: 0rem !important;
    padding-left: 0rem !important; }
  .page-right-filler-md .advance-feature-right {
    margin-left: -0.625rem; }
  .aboutus-headerplaceholder-aligner {
    margin-bottom: 4.0625rem; }
  .aboutus-pagebottom-spacer {
    margin-bottom: 0.3125rem; }
  .careers-pagebottom-spacer {
    margin-bottom: 2.1875rem; }
  .aboutus-smallpromo-layout {
    margin-top: 0.625rem; } }

@media only screen and (min-width: 992px) {
  .simple-empty-grey-banner, .search-result-main-title-wrapper, .glb-header-patch {
    margin-top: 0rem; }
  .fhl-large-titlebar-wrapper {
    margin-top: 0rem; }
  .bio-table {
    margin-left: -60px; }
  .secondary-header {
    top: 36px;
    height: auto !important; }
    .secondary-header .container .col-md-3,
    .secondary-header .container .col-md-2 {
      display: block !important; }
    .secondary-header .container .col-md-2 {
      padding-right: 0; }
    .secondary-header .container .col-md-7 {
      flex: 0 0 58.33333333% !important;
      max-width: 58.33333333% !important; }
  .aboutus-smallpromo-layout {
    margin-top: 0rem; }
  .desktop-page-bottom-spacing {
    margin-bottom: 6.25rem !important; }
  .aboutus-headerplaceholder-aligner {
    margin-bottom: 6rem; }
  .aboutus-pagebottom-spacer {
    margin-bottom: 3rem; }
  .large-screen-container {
    max-width: 61.875rem !important;
    padding-right: 0rem !important; }
  .compare-advance-mobile-wrapper:last-child {
    margin-bottom: 50px; }
  .white-bg.extra-space-whiteBg {
    max-width: 62rem; }
  .card-layout-media-wrapper .col-md-4 {
    margin: 0; }
  .fundingGoal-container .fhl-select {
    width: 100%; }
  .advance-feature-left {
    flex: 0 0 76% !important;
    max-width: 76% !important; }
  .advance-feature-right {
    flex: 0 0 25% !important;
    max-width: 225px !important;
    padding: 0 !important; }
  .fundingGoal-container,
  .fhl-title-subTitle-wrapper {
    width: 70% !important;
    margin: 0px auto;
    float: none; }
  .fhlb-split-content-left-section {
    flex: 0 0 65% !important;
    max-width: 65% !important;
    margin-right: 100px; }
  .fhlb-split-content-right-section {
    flex: 0 0 25% !important;
    max-width: 225px !important;
    padding: 0 !important; }
  .com-adv-button-wrapper {
    text-align: right;
    padding-right: 30px; }
  .com-adv-button-wrapper .fhl-button-content-container,
  .com-adv-button-wrapper a {
    width: 20.3125rem;
    display: inline-block;
    margin-right: -0.3125rem; }
  .com-adv-title-content-wrapper .fhl-title-content-btn-wrapper div a {
    margin-left: 48px; }
  .compare-advance-mobile-wrapper .fhl-title-content-btn-wrapper {
    margin-left: 215px; }
  .advance-wrapper {
    margin-right: 5.625rem !important; }
  html {
    overflow-y: auto !important; }
  .si-promo-mobile-wrapper {
    margin-top: -15.625rem !important;
    padding-bottom: 5px !important; } }

@media only screen and (min-width: 1200px) {
  .bio-table {
    margin-left: -80px; }
  .large-screen-container {
    max-width: 75rem !important;
    padding-left: 1.875rem !important;
    padding-right: 1.25rem !important; }
  .header-login-btn {
    margin-right: -2.5rem !important; }
  .card-layout-media-wrapper .col-md-4 {
    max-width: 32% !important; }
  .fundingGoal-container,
  .fhl-title-subTitle-wrapper {
    float: left; }
    .fundingGoal-container .fhl-select,
    .fhl-title-subTitle-wrapper .fhl-select {
      width: 95%; }
  .advance-editable-templates-1 {
    padding-right: 6.25rem !important; }
  .fhl-content-wrapper {
    max-width: 14.0625rem; }
  .advance-feature-left {
    flex: 0 0 80% !important;
    max-width: 80% !important; }
  .fhlb-split-content-left-section {
    flex: 0 0 70% !important;
    max-width: 70% !important; }
  .fhlb-split-content-right-section {
    flex: 0 0 25% !important;
    max-width: 225px !important; }
  .com-adv-button-wrapper {
    text-align: right;
    padding-right: 30px !important; }
  .com-adv-title-content-wrapper .fhl-title-content-btn-wrapper div a {
    margin-left: 48px; }
  .advance-wrapper {
    margin-right: 6.25rem !important; } }

@media only screen and (max-width: 360px) {
  .card-layout-container {
    width: 17.1875rem !important; }
  .com-adv-button-wrapper .fhl-button-content-container {
    width: 14.625rem !important; } }

@media only screen and (max-width: 767px) {
  .glb-header-patch {
    margin-top: 3.75rem; }
  .fhl-large-titlebar-wrapper {
    margin-top: 5.625rem; }
  .error-message-wrapper {
    margin-left: 0.9375rem; }
  .events-section-bdr {
    border-right: 0; }
  .events-article-wrapper {
    border-bottom: 1px solid #00a0df; }
  .products-promo-media-wrapper {
    max-width: 20.625rem !important; }
  .card-layout-container {
    width: 20rem;
    margin-left: auto !important;
    margin-right: auto !important; }
  .com-adv-title-content-wrapper .fhl-title-content-btn-wrapper h2 {
    line-height: 1.875rem; } }

@media only screen and (max-height: 768px) {
  .secondary-header ul.navbar-nav li.mob-nav-link:last-child {
    margin-bottom: 2.5rem; } }

@media only screen and (max-width: 991px) {
  .mpf-fhl-select {
    height: 2.5rem; }
  .collapse.show {
    height: 100%;
    background-color: #fff;
    overflow-y: auto;
    overflow-x: hidden; }
  body .fhl-content-box {
    padding-top: 72px !important; }
  ul.navbar-nav li.nav-column a .nav-dropbox span {
    color: #002a3a !important; }
  header .header-bar {
    display: none; }
  .secondary-header {
    position: fixed;
    z-index: 3;
    left: 0;
    top: 0;
    box-shadow: 0 4px 3px -2px rgba(0, 0, 0, 0.18) !important; }
    .secondary-header ul.navbar-nav {
      max-height: 85vh;
      padding-bottom: 2.8125rem !important; }
      .secondary-header ul.navbar-nav li.main-menu .dropdown-nav .dropdown-menu .nav li.nav-column:last-child {
        border-bottom: 0px;
        margin-bottom: -0.625rem; }
  .advance_top_row .col-md-9 {
    display: none; }
  .advance-feature-title,
  .advance-feature-content {
    flex: auto !important; }
  .advance-features-content-wrapper .row {
    margin-bottom: 10px; }
  .advance-feature-title {
    max-width: 100% !important; }
  .advance-feature-content {
    max-width: 100% !important; }
  .si-promo-mobile-wrapper {
    margin-top: 0px !important;
    padding: 0px !important; } }

body {
  background-color: #fff;
  font-size: 1rem !important;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility; }
  body .fhl-content-box {
    min-height: calc(100vh - 530px); }
  body a:hover {
    text-decoration: underline !important; }

html {
  overflow-x: hidden;
  font-size: 100%; }

p {
  color: inherit;
  font-size: inherit; }

.white-bg,
.gray-bg,
.smokyWhite-bg {
  padding: 3.125rem;
  position: relative; }

.white-bg {
  background-color: #fff; }

.gray-bg {
  background-color: #f3f3f3; }

.simple-empty-grey-banner {
  background-color: #f3f3f3;
  padding: 0px 40px;
  min-height: 525px;
  display: block; }

.w100pr {
  width: 100%; }

.sticky {
  position: fixed;
  top: 0;
  z-index: 2;
  width: 100%; }

.classic-advance-feature-edit-wrapper,
.advance-media-login-wrapper {
  padding: 0 !important; }

.template-mpf-bdr,
.template-classic-advance-bdr,
.template-grey-verticle-line-bdr,
.template-employee-bio-bdr {
  border-bottom: 0.0625rem solid #939395;
  margin: 1.875rem 0rem 1.5rem; }

.template-mpf-bdr {
  margin: 3.125rem 0rem 3.4375rem; }

.redirectLink {
  display: flex; }

.secondary-header {
  border-bottom: 1px solid #e2e2e2;
  min-height: 4.5rem;
  background-color: #fff; }
  .secondary-header .fhl-logo {
    width: auto;
    margin-top: 0.9375rem;
    text-align: left; }
    .secondary-header .fhl-logo img {
      width: 14.0625rem;
      height: 2.25rem;
      max-width: 100%; }

.header-login-btn {
  background-color: #00833c !important;
  max-width: 6.5625rem;
  width: 100%;
  height: 1.75rem;
  line-height: 1rem !important;
  color: #fff !important;
  font-size: 0.875rem !important;
  margin: 1.3125rem -1.5625rem 0 0;
  border-radius: 0.25rem !important; }

.header-login-btn:focus {
  outline: 0;
  border: 0; }

.header-login-btn::after {
  display: none !important; }

ul.header-login-dropdown-content {
  padding: 1.25rem 1.25rem 0rem 1.25rem;
  margin: 0.625rem 0 0 0.9375rem;
  border-radius: 0.25rem;
  border: 0.0625rem solid #00833c; }
  ul.header-login-dropdown-content li {
    margin-bottom: 1.4375rem; }
    ul.header-login-dropdown-content li a {
      border-radius: 0.25rem;
      font-size: 1rem;
      color: #fff;
      width: 12rem; }

.content-widget-test-image-template img {
  max-width: 100%; }

.ax-hidden {
  position: absolute;
  height: 1px;
  width: 1px;
  clip: rect(1px, 1px, 1px, 1px);
  border: 0;
  overflow: hidden; }

.seperator {
  border-left: 1px solid #747476; }

.divider {
  border-bottom: 1px solid #747476; }

.min-H100 {
  min-height: 100px; }

.text-center {
  text-align: center !important; }

.text-right {
  text-align: right !important; }

.text-left {
  text-align: left !important; }

.float-right {
  float: right !important; }

.float-left {
  float: left !important; }

.hidden {
  display: none; }

.bor-bot-0 {
  border-bottom: 0rem !important; }

.redirectLink:not(*:root) {
  width: fit-content; }

_::-moz-progress-bar,
body:last-child .redirectLink {
  width: fit-content; }

em {
  font-style: italic !important; }

ul {
  list-style-type: disc;
  padding-left: 0.9375rem; }

ol {
  list-style-type: decimal;
  padding-left: 0.9375rem; }

table,
th,
td {
  border: 1px solid inherit; }

table {
  border-collapse: collapse; }

.text-link {
  text-decoration: underline; }

/*************************************************
 FHL Template Stylesheets
 ************************************************/
header .header-bar {
  height: 2.1875rem;
  max-height: 2.1875rem;
  background-color: #00833c; }
  header .header-bar ul {
    margin: 0;
    white-space: nowrap; }
    header .header-bar ul li:after {
      content: "|";
      margin-left: 0.9375rem; }
    header .header-bar ul li:last-child:after {
      content: "";
      margin: 0; }
    header .header-bar ul li:nth-last-child(2):after {
      content: "";
      margin: 0; }
    header .header-bar ul li {
      display: inline;
      margin-left: 0.9375rem;
      font-size: 0.875rem;
      color: #fff; }
      header .header-bar ul li a {
        color: #fafafa; }
      header .header-bar ul li span {
        display: inline-block;
        position: relative; }
        header .header-bar ul li span span {
          display: inline; }

@media only screen and (min-width: 992px) {
  header .header-bar ul {
    margin-right: -1.3125rem; } }

@media only screen and (min-width: 1200px) {
  header .header-bar ul {
    margin-right: -0.9375rem; } }

footer {
  color: white; }
  footer a {
    color: #fff; }
  footer a:hover {
    color: #fff;
    text-decoration: none; }
  footer .footer-primary {
    background-color: #415364;
    padding: 1.875rem 0; }
    footer .footer-primary .footer-secondary .fhl-logo-footer > img {
      margin: -11.25rem 0 0 0;
      max-width: 5.375rem;
      max-height: 5.375rem; }
    footer .footer-primary .footer-secondary p {
      color: #fff;
      line-height: 1.225rem;
      font-family: "Opensans-Regular";
      color: #ffffff;
      font-size: 0.875rem; }
    footer .footer-primary .footer-secondary .footer-logo-desc {
      margin-top: -1.875rem;
      font-family: "OpenSans-Bold";
      font-weight: 500;
      font-style: normal;
      line-height: 1.225rem; }
    footer .footer-primary .footer-secondary ul {
      padding-left: 0; }
    footer .footer-primary .footer-secondary li {
      border-top: 1px solid #6e7c7c;
      padding: 0.625rem;
      list-style: none; }
      footer .footer-primary .footer-secondary li a {
        font-size: 0.875rem;
        color: #fff;
        letter-spacing: 0.09375rem;
        text-transform: uppercase; }
        footer .footer-primary .footer-secondary li a img {
          width: 2.5rem;
          margin-right: 0.625rem; }
    footer .footer-primary .footer-secondary li:last-child {
      border-bottom: 1px solid #6e7c7c; }
    footer .footer-primary .footer-secondary .mob-top-30 {
      margin-top: 0rem; }
    footer .footer-primary .footer-secondary .display-bottom a {
      text-decoration: none !important; }
  footer .footer-base {
    background-color: #002a3a;
    padding: 1.5625rem 0; }
    footer .footer-base .footer-copyright {
      font-family: "Opensans-Regular";
      color: #fff;
      line-height: 1.225rem;
      font-size: 0.875rem; }
      footer .footer-base .footer-copyright span {
        display: inline-block;
        line-height: 30px; }
        footer .footer-base .footer-copyright span ul {
          margin: 0;
          width: auto; }
          footer .footer-base .footer-copyright span ul li {
            display: inline-block;
            margin-right: 0.3125rem; }
      footer .footer-base .footer-copyright span:first-child {
        margin-right: 1.875rem; }

@media only screen and (min-width: 320px) and (max-width: 767px) {
  footer .footer-primary {
    padding-right: 16px;
    padding-left: 16px; }
    footer .footer-primary .container .footer-secondary .fhl-logo-footer img {
      margin-top: -11.25rem; }
    footer .footer-primary .container .footer-secondary .mob-top-30 {
      margin-top: 1.875rem; }
    footer .footer-primary .container .footer-secondary li:last-child {
      border-bottom: 0px solid #6e7c7c; }
  footer .footer-base .container {
    margin: 0px 0.9375rem; } }

@media only screen and (min-width: 768px) {
  footer .footer-primary .container .footer-secondary .fhl-logo-footer > img {
    margin-top: -11.25rem; }
  footer .footer-primary .container .footer-secondary .mob-top-30 {
    margin-top: 0rem; }
  footer .footer-primary .container .footer-secondary li:last-child {
    border-bottom: 1px solid #6e7c7c; }
  footer .footer-primary .container .footer-secondary li .bor-bot-0 {
    border-bottom: 0px solid #6e7c7c; }
  footer .footer-primary .container .footer-secondary .display-bottom {
    position: absolute;
    bottom: 0px; } }

@media only screen and (min-width: 992px) {
  footer .footer-primary .container .footer-secondary .fhl-logo-footer {
    max-width: 20%; }
    footer .footer-primary .container .footer-secondary .fhl-logo-footer img {
      margin-top: -10.625rem; }
  footer .footer-primary .container .footer-secondary .mob-top-30 {
    margin-top: 0rem; }
  footer .footer-primary .container .footer-secondary li:last-child {
    border-bottom: 1px solid #6e7c7c; }
  footer .footer-primary .container .footer-secondary li .bor-bot-0 {
    border-bottom: 0px solid #6e7c7c; }
  footer .footer-primary .container .footer-secondary .display-bottom {
    position: absolute;
    bottom: 0px; } }

/* FHL Navigation General Styles Starts Here  */
ul.navbar-nav li.main-menu:first-child {
  display: none; }

.mobile-login-header-panel {
  display: none; }
  .mobile-login-header-panel .mob-header-login-btn {
    color: #00833c;
    cursor: pointer; }

.mob-arrow-link {
  display: none;
  cursor: pointer; }

.navbar-nav .nav-link i {
  float: right;
  margin-top: -0.3125rem; }

/* adds some margin below the link sets  */
.navbar .dropdown-menu div[class*="col"] {
  margin-bottom: 1rem; }

.navbar .dropdown-menu {
  border: none;
  background-color: #fff !important; }

.mob-nav-section {
  display: none;
  justify-content: space-between; }

.navbar .main-menu {
  width: auto;
  padding: 0; }

.nav-link {
  display: inline-block;
  color: #002a3a !important;
  font-size: 1rem; }
  .nav-link i {
    display: inline-block;
    padding-right: 0.625rem;
    line-height: 2.1875rem; }

.navbar-dark .navbar-nav .show > .nav-link {
  color: #005f85 !important; }

.navbar-expand-lg .navbar-nav .dropdown-toggle::after {
  display: none; }

li.main-menu:hover .dropdown-nav a > span,
li.main-menu:hover a > span {
  color: #00833c !important; }

.mob-nav-link {
  display: none; }

/*** Skip to mainmenu**/
.skip {
  position: absolute;
  top: -1000px;
  left: -1000px;
  height: 1px;
  width: 1px;
  text-align: left;
  overflow: hidden; }

a.skip:active,
a.skip:focus,
a.skip:hover {
  left: 0;
  top: 0;
  width: auto;
  height: auto;
  overflow: visible;
  color: #fff !important;
  padding-top: 0.5rem;
  font-size: 0.875rem; }

/*** Skip to mainmenu**/
/****** FHL Navigation General Styles Ends Here  ********/
/* breakpoint and up - mega dropdown styles */
@media only screen and (min-width: 320px) {
  .secondary-header .container .row {
    padding: 0px; }
    .secondary-header .container .row ul.nav {
      margin: -0.625rem 0px 0px -0.625rem; }
  .secondary-header .dropdown-menu .container .row {
    float: left; }
  .secondary-header .mob-logo {
    margin-top: 0.3125rem;
    margin-right: 0 !important; }
    .secondary-header .mob-logo img {
      width: 80%; }
  .secondary-header .mob-arrow-link {
    display: flex;
    margin-top: -35px;
    float: right; }
  .secondary-header .mob-nav-link {
    display: block;
    width: 100%;
    border-bottom: 0.0625rem solid #f3f3f3;
    text-align: left; }
  .secondary-header .mobile-login-header-panel {
    display: flex; }
    .secondary-header .mobile-login-header-panel button {
      border: 0;
      background-color: transparent;
      padding: 0 0.625rem 0 0; }
    .secondary-header .mobile-login-header-panel button::after {
      display: none; }
    .secondary-header .mobile-login-header-panel ul.header-login-dropdown-content {
      margin: 0 0 0 0.9375rem;
      right: 0.3125rem;
      left: auto;
      box-shadow: 0px 0.0625rem 0.0625rem 0px #cccccc;
      position: absolute;
      top: 70px;
      right: 20px;
      border: 0.0625rem solid #00833c; }
  .secondary-header .navbar {
    width: 100%;
    margin-top: 0.3125rem;
    padding: 0.625rem 1.375rem; }
    .secondary-header .navbar .mob-nav-section {
      display: flex; }
  .secondary-header .dropdown-nav .dropdown-menu {
    margin-top: -0.9375rem !important; }
  .secondary-header ul.navbar-nav {
    padding: 0 0.9375rem;
    margin-top: 1.25rem; }
    .secondary-header ul.navbar-nav [class*="col-"] {
      width: 100%; }
    .secondary-header ul.navbar-nav li {
      text-align: left;
      padding: 0.3125rem 0;
      border-bottom: 0.0625rem solid #cccccc;
      line-height: 2.1875rem; }
      .secondary-header ul.navbar-nav li a.dropbtn::after {
        display: none; }
    .secondary-header ul.navbar-nav li.main-menu {
      padding-bottom: 0px;
      padding-top: 0; }
    .secondary-header ul.navbar-nav li.mob-nav-link:last-child {
      border-bottom: 0px; }
    .secondary-header ul.navbar-nav li.nav-column {
      display: flex;
      width: 100%;
      border-bottom: 0.0625rem solid #ccc;
      padding: 0px 0px 0px 0.625rem;
      line-height: 2.1875rem; }
      .secondary-header ul.navbar-nav li.nav-column a {
        color: #002a3a; }
        .secondary-header ul.navbar-nav li.nav-column a .nav-dropbox {
          font-size: 1rem; }
          .secondary-header ul.navbar-nav li.nav-column a .nav-dropbox img {
            display: none; }
        .secondary-header ul.navbar-nav li.nav-column a p {
          display: none; } }

@media only screen and (min-width: 576px) {
  .secondary-header .navbar {
    padding: 0.625rem 0rem; } }

@media only screen and (min-width: 768px) {
  .mob-arrow-link {
    display: block;
    margin-top: -1.875rem;
    float: right; }
  .mob-logo {
    margin-top: 0.3125rem;
    margin-right: 0 !important; }
  .mobile-login-header-panel {
    display: flex; }
    .mobile-login-header-panel button {
      border: 0;
      background-color: transparent; }
    .mobile-login-header-panel button::after {
      display: none; }
    .mobile-login-header-panel ul.header-login-dropdown-content {
      margin: 0.3125rem 0 0 0.9375rem;
      right: 0.3125rem;
      left: auto;
      box-shadow: 0px 0.125rem 0.25rem 0px #454545;
      top: 3.75rem;
      min-width: auto; }
  .secondary-header .navbar {
    padding: 0.625rem 0.5rem;
    margin-top: 0.3125rem; }
    .secondary-header .navbar .mob-nav-section {
      display: flex; }
  ul.navbar-nav {
    padding: 0 0.9375rem;
    margin-top: 1.25rem; }
    ul.navbar-nav [class*="col-"] {
      width: 100%; }
    ul.navbar-nav li {
      text-align: left;
      padding: 0.3125rem 0;
      border-bottom: 0.0625rem solid #cccccc; }
      ul.navbar-nav li a.dropbtn::after {
        display: none; }
    ul.navbar-nav li.main-menu {
      padding-bottom: 0px;
      padding-top: 0; }
    ul.navbar-nav li.nav-column {
      display: flex;
      width: 100%;
      border-bottom: 1px solid #ccc;
      padding-left: 25px; }
      ul.navbar-nav li.nav-column a .nav-dropbox {
        font-size: 14px; }
        ul.navbar-nav li.nav-column a .nav-dropbox img {
          display: none; }
      ul.navbar-nav li.nav-column a p {
        display: none; }
    ul.navbar-nav .dropdown-nav .dropdown-menu {
      padding-top: 0;
      margin-top: 0; }
      ul.navbar-nav .dropdown-nav .dropdown-menu .container {
        margin: 0 0 0 0;
        width: 100%;
        max-width: 100%; } }

@media only screen and (min-width: 992px) {
  .secondary-header .mob-arrow-link {
    display: none; }
  .secondary-header .navbar {
    padding: 0px !important;
    margin: 0 0 0 0;
    /*li.fhl-nav-link:nth-of-type(5) {
                &::after 
                {
                content: "";                
                }
            }*/ }
    .secondary-header .navbar .mob-nav-section {
      display: none; }
    .secondary-header .navbar .dropdown {
      position: static; }
    .secondary-header .navbar li.nav-item .dropdown-nav {
      display: inline-block; }
      .secondary-header .navbar li.nav-item .dropdown-nav .dropdown-menu {
        width: 100%;
        left: -16rem;
        min-width: 62.875rem;
        top: 5.1875rem;
        box-shadow: 0px 0px 1px #ccc;
        border-top: 0.0625rem solid #d8d8d8;
        background-color: #fff;
        border-radius: 0px; }
        .secondary-header .navbar li.nav-item .dropdown-nav .dropdown-menu .container {
          padding: 3.125rem 1.25rem 0.625rem 2.5rem !important;
          margin: 0px; }
          .secondary-header .navbar li.nav-item .dropdown-nav .dropdown-menu .container .nav li.nav-item {
            padding: 0 0.5rem 0 0;
            margin: 2px 0 0 0;
            list-style-type: none;
            width: 33.33%;
            border-bottom: 0; }
            .secondary-header .navbar li.nav-item .dropdown-nav .dropdown-menu .container .nav li.nav-item a.nav-link {
              width: 100%;
              padding-top: 0px !important;
              padding-bottom: 20px !important; }
              .secondary-header .navbar li.nav-item .dropdown-nav .dropdown-menu .container .nav li.nav-item a.nav-link .nav-dropbox img {
                display: block; }
    .secondary-header .navbar li.mob-nav-link {
      display: none; }
    .secondary-header .navbar li.fhl-nav-link::after {
      content: "|";
      display: inline-block;
      margin: 0px 0px 0 0;
      color: #76bc21 !important; }
    .secondary-header .navbar li.fhl-nav-link:nth-last-child(6):after {
      display: none;
      content: ""; }
    .secondary-header .navbar li.fhl-nav-link:last-child {
      display: none; }
    .secondary-header .navbar ul.navbar-nav {
      margin-top: 3px;
      margin-bottom: 0;
      margin-left: 0;
      margin-right: 0;
      padding: 0 !important; }
      .secondary-header .navbar ul.navbar-nav li.main-menu {
        padding-bottom: 0px;
        padding-top: 0;
        border-bottom: 0px;
        width: auto; }
        .secondary-header .navbar ul.navbar-nav li.main-menu a.nav-link {
          padding-top: 0.875rem;
          padding-bottom: 1rem;
          display: inline-block; }
          .secondary-header .navbar ul.navbar-nav li.main-menu a.nav-link i {
            display: none; }
  .secondary-header .dropdown .dropdown-nav > a {
    border-top: 0.0625rem solid #fff;
    border-left: 0.0625rem solid #fff;
    border-right: 0.0625rem solid #fff; }
  .secondary-header .dropdown:hover .dropdown-nav > a,
  .secondary-header .dropdown-focus .dropdown-nav > a {
    z-index: 1001;
    position: relative;
    background-color: #fff;
    border-top: 0.0625rem solid #cccccc;
    border-top-left-radius: 0.625rem;
    border-top-right-radius: 0.625rem;
    border-left: 0.0625rem solid #cccccc;
    border-right: 0.0625rem solid #cccccc; }
  /* shows the dropdown menu on hover */
  /*   .navbar .dropdown .dropdown-menu:hover,  
  .navbar .dropdown:hover .dropdown-menu,  */
  .navbar .dropdown-focus .dropdown-menu,
  .navbar .dropdown .dropdown-menu-focus {
    display: block !important; }
  .show {
    display: block !important; }
  .hidden {
    display: none !important; }
  .nav-dropbox {
    border-bottom: 0.0625rem solid #d8d8d8;
    padding-bottom: 0.625rem;
    margin-bottom: 0.625rem;
    display: flex;
    align-items: center; }
    .nav-dropbox img {
      margin-right: 0.5rem;
      width: 3.125rem;
      height: 3.125rem; }
    .nav-dropbox .nav-dropdown-title {
      font-size: 1.125rem;
      font-weight: normal;
      font-style: normal;
      line-height: 1.35rem; }
  li.nav-column a.nav-link {
    color: inherit !important; }
    li.nav-column a.nav-link p {
      font-size: 0.875rem;
      color: #002a3a;
      line-height: 1.3125rem;
      font-family: "Opensans-Regular";
      font-weight: normal;
      font-style: normal;
      display: block !important; }
  .mob-logo {
    display: none !important; }
  .secondary-header .container {
    padding-top: 3px !important; } }

@media only screen and (min-width: 1200px) {
  .secondary-header {
    min-height: 4.5rem; }
    .secondary-header .navbar .mob-nav-section {
      display: none; }
    .secondary-header .navbar li.nav-item .dropdown-nav .dropdown-menu {
      width: 100%;
      left: -17.9375rem;
      min-width: 72.5rem;
      top: 5.1875rem;
      box-shadow: 0px 0px 1px #ccc;
      border-top: 0.0625rem solid #d8d8d8;
      background-color: #fff;
      border-radius: 0px; }
    .secondary-header .navbar ul.navbar-nav {
      margin-top: 0.375rem; }
      .secondary-header .navbar ul.navbar-nav li.main-menu {
        padding-bottom: 0px;
        padding-top: 0; }
        .secondary-header .navbar ul.navbar-nav li.main-menu a.nav-link {
          padding-bottom: 1.0625rem;
          padding-top: 0.625rem; } }

/********************************************************
 Overwritten the Base Bootstrap Origin Class for FHL Bank
*********************************************************/
/* .btn-green / .btn-info / .btn-success  */
a.btn:focus {
  box-shadow: 0 0 0 0.1rem #2b9be0 !important; }

.btn-green {
  background-color: #68a51d !important;
  color: #fff; }

.btn-green:hover {
  color: #fff !important;
  background-color: #68a51d !important;
  border-color: transparent !important; }

.btn-green:focus {
  background-color: #68a51d !important; }

.btn-darkgreen {
  background-color: #00833c !important;
  color: #fff; }

.btn-darkgreen:hover {
  color: #fff !important;
  background-color: #00833c !important;
  border-color: transparent !important; }

.btn-darkgreen:focus {
  background-color: #00833c !important; }

.btn-blue {
  background-color: #009ddb !important;
  color: #fff; }

.btn-blue:hover {
  color: #fff !important;
  background-color: #009ddb !important;
  border-color: transparent !important; }

.btn-blue:focus {
  background-color: #009ddb !important; }

.btn-info {
  background-color: #009ddb !important;
  color: #fff; }

.btn-info:hover {
  color: #fff;
  background-color: #009ddb !important;
  border-color: transparent; }

.btn-info:focus {
  background-color: #009ddb !important; }

.btn-success {
  background-color: #00833c !important;
  color: #fff !important; }

.btn-success:hover {
  color: #fff !important;
  background-color: #00833c !important;
  border-color: transparent; }

.btn-success:focus {
  box-shadow: 0 0 0 0.1rem #2b9be0 !important;
  background-color: #00833c !important; }

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  border-color: transparent !important; }

/*
button[type="button"]:focus {
  box-shadow: none !important;
}
*/
/*
.fhl-hamburger-button {
    height: convert-unit(40px, "rem");
    width: convert-unit(40px, "rem");
    padding: convert-unit(5px, "rem") convert-unit(9px, "rem") !important;
    border: 0 !important;
}
.fhl-hamburger-button:focus {
    outline: 0;
}
.hambuger-icon {
    width: 26px;
    height: 22px;
    position: relative;
    margin: 0px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    margin-top: convert-unit(5px, "rem");
    span {
        background: get("color.primary-green.100");
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.25s ease-in-out;
        -moz-transition: 0.25s ease-in-out;
        -o-transition: 0.25s ease-in-out;
        transition: 0.25s ease-in-out;
    }
}
.hambuger-icon span:nth-child(1) {
    top: 0px;
}

.hambuger-icon span:nth-child(2),
.hambuger-icon span:nth-child(3) {
    top: 8px;
}

.hambuger-icon span:nth-child(4) {
    top: 16px;
}

.hambuger-icon.open span:nth-child(1) {
    top: 11px;
    width: 0%;
    left: 50%;
}

.hambuger-icon.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.hambuger-icon.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.hambuger-icon.open span:nth-child(4) {
    top: 11px;
    width: 0%;
    left: 50%;
}
*/
.hamburger-menu-button {
  width: 30px;
  height: 30px;
  padding: 6px;
  display: block;
  position: relative;
  z-index: 100;
  background: #fff;
  box-sizing: content-box;
  border-radius: 50%;
  text-indent: 100%;
  color: transparent;
  white-space: nowrap;
  cursor: pointer;
  overflow: hidden;
  border: 1px solid #fff;
  outline: 0; }

.hamburger-menu-button-open {
  top: 50%;
  margin-top: -1px;
  left: 50%;
  margin-left: -12px; }

.hamburger-menu-button-open,
.hamburger-menu-button-open::before,
.hamburger-menu-button-open::after {
  position: absolute;
  width: 24px;
  height: 2px;
  background: #00833c;
  border-radius: 4px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.hamburger-menu-button-open::before,
.hamburger-menu-button-open::after {
  left: 0;
  content: ""; }

.hamburger-menu-button-open::before {
  top: 6px; }

.hamburger-menu-button-open::after {
  bottom: 6px; }

.hamburger-menu-button-close {
  background: transparent;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg); }

.hamburger-menu-button-close::before {
  -webkit-transform: translateY(-6px) rotate(45deg);
  transform: translateY(-6px) rotate(45deg); }

.hamburger-menu-button-close::after {
  -webkit-transform: translateY(6px) rotate(-45deg);
  transform: translateY(6px) rotate(-45deg); }

#ham-navigation[open]:not(:focus-within) {
  transition: background-color 0.1s ease; }

/*************************************************
 FHL Application Reusable Widgets
 ************************************************/
.search-filter-container .search-header-wrapper .search-header-box .completer-holder {
  position: relative;
  white-space: normal; }
  .search-filter-container .search-header-wrapper .search-header-box .completer-holder .completer-item-text {
    word-break: break-word; }

.search-filter-container .search-header-wrapper .search-header-box .completer-dropdown-holder {
  position: absolute;
  top: 2rem; }

.search-filter-container .form-group {
  margin: 0rem; }

.search-filter-container input[type="text"],
.search-filter-container input[type="search"] {
  display: block;
  min-width: 14.0625rem;
  height: 1.5625rem;
  padding: 0rem 1.5625rem 0rem 0.625rem;
  color: #002a3a;
  margin-top: 0.3125rem;
  font-size: 0.875rem;
  background-color: #a4cbb5;
  border: 0;
  border-radius: 0.25rem; }

.search-filter-container input[type="text"]::-webkit-input-placeholder,
.search-filter-container input[type="search"]::-webkit-input-placeholder {
  color: #000;
  opacity: 0.5; }

.search-filter-container input[type="text"]::-moz-placeholder,
.search-filter-container input[type="search"]::-moz-placeholder {
  color: #000;
  opacity: 0.5; }

.search-filter-container input[type="text"]:-ms-input-placeholder,
.search-filter-container input[type="search"]:-ms-input-placeholder {
  color: #000;
  opacity: 0.5; }

.search-filter-container input[type="text"]:-moz-placeholder,
.search-filter-container input[type="search"]:-moz-placeholder {
  color: #000;
  opacity: 0.5; }

.search-filter-container button[type="button"] {
  border: 0rem;
  background-color: transparent;
  position: absolute;
  top: 0.375rem;
  right: 0rem;
  color: #000;
  cursor: pointer;
  height: 1.5625rem;
  width: 1.5625rem;
  padding: 0rem;
  text-indent: -999999px; }
  .search-filter-container button[type="button"] i {
    font-size: 1.125rem;
    padding-top: 0.1875rem;
    color: #002a3a;
    text-indent: 0px;
    float: right;
    margin-right: 2px;
    margin-bottom: 4px; }

.search-filter-container button[type="button"]:hover {
  outline: 0;
  border: 0rem; }

.search-filter-container input[type="text"].search-input-btn:focus {
  box-shadow: 0 0 0 0.1rem #2b9be0 !important; }

.fhl-select {
  position: absolute;
  width: 70%; }
  .fhl-select label {
    position: absolute;
    z-index: -5;
    color: #f3f3f3; }
  .fhl-select select {
    border: solid 0.0625rem #98989a;
    border-radius: 0.25rem;
    font-size: 0.875rem;
    color: #58585a;
    height: 2.625rem;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    line-height: 1.4rem !important; }
    .fhl-select select:focus {
      color: #495057;
      background-color: #fff;
      border-color: #212223;
      outline: 0;
      box-shadow: 0 0 0 0.1rem #58585a; }
  .fhl-select .select-side {
    width: 2rem;
    position: absolute;
    top: 0px;
    background-color: #fff;
    height: 100%;
    right: 0px;
    border-radius: 0px 0.25rem 0.25rem 0px;
    border-right: solid 0.0625rem #98989a;
    border-top: solid 0.0625rem #98989a;
    border-bottom: solid 0.0625rem #98989a;
    pointer-events: none;
    line-height: 3.125rem;
    text-align: center; }
    .fhl-select .select-side:before {
      border-left: solid 0.0625rem #c8c8cc;
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      height: 100%;
      width: 0.0625rem;
      /* or 100px */ }
    .fhl-select .select-side i {
      left: 30%;
      top: 0.75rem;
      color: #004987; }
      .fhl-select .select-side i.blue {
        color: #004987; }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .fhl-select {
    width: 100%; } }

.title-text-image-container {
  overflow: auto; }
  .title-text-image-container h2,
  .title-text-image-container p {
    padding-right: 15%;
    word-break: break-word; }
  .title-text-image-container h2 {
    font-size: 2.625rem;
    line-height: 3.15rem; }
  .title-text-image-container p {
    font-family: "Opensans-Regular";
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.3125rem;
    text-align: left; }
  .title-text-image-container a {
    width: 29.375rem; }
  .title-text-image-container img {
    max-width: 100%; }

.aside-title-text-image-container {
  overflow: auto; }
  .aside-title-text-image-container p {
    font-family: "Opensans-Regular";
    font-weight: normal;
    font-style: normal; }
    .aside-title-text-image-container p em {
      font-family: "OpenSans-Italic";
      font-weight: normal;
      font-style: italic; }
      .aside-title-text-image-container p em strong {
        font-family: "OpenSans-SemiboldItalic";
        font-weight: 300;
        font-style: italic; }
    .aside-title-text-image-container p strong {
      font-family: "OpenSans-Semibold";
      font-weight: 300;
      font-style: normal; }
      .aside-title-text-image-container p strong em {
        font-family: "OpenSans-SemiboldItalic";
        font-weight: 300;
        font-style: italic; }
    .aside-title-text-image-container p a {
      color: inherit; }
  .aside-title-text-image-container h3,
  .aside-title-text-image-container p {
    word-break: break-word; }
  .aside-title-text-image-container h3 {
    font-size: 1.75rem;
    font-family: "HarmoniaSansW05-Semibold";
    font-weight: 600;
    font-style: normal;
    color: inherit;
    line-height: 2.1rem; }
  .aside-title-text-image-container p {
    font-size: 0.875rem;
    line-height: 1.3125rem;
    text-align: left; }
  .aside-title-text-image-container a {
    width: 100%;
    max-width: 225px; }
  .aside-title-text-image-container img {
    max-width: 100%; }

.fhl-title-content-btn-wrapper {
  word-break: break-word;
  word-wrap: break-word;
  overflow: visible;
  flex-direction: column;
  text-align: left; }
  .fhl-title-content-btn-wrapper h2,
  .fhl-title-content-btn-wrapper h3 {
    font-size: 2rem;
    color: inherit;
    line-height: 2.4rem; }
  .fhl-title-content-btn-wrapper div {
    flex-direction: row;
    overflow: visible;
    align-items: baseline; }
    .fhl-title-content-btn-wrapper div p {
      font-family: "Opensans-Regular";
      font-weight: normal;
      font-style: normal; }
      .fhl-title-content-btn-wrapper div p em {
        font-family: "OpenSans-Italic";
        font-weight: normal;
        font-style: italic; }
        .fhl-title-content-btn-wrapper div p em strong {
          font-family: "OpenSans-SemiboldItalic";
          font-weight: 300;
          font-style: italic; }
      .fhl-title-content-btn-wrapper div p strong {
        font-family: "OpenSans-Semibold";
        font-weight: 300;
        font-style: normal; }
        .fhl-title-content-btn-wrapper div p strong em {
          font-family: "OpenSans-SemiboldItalic";
          font-weight: 300;
          font-style: italic; }
      .fhl-title-content-btn-wrapper div p a {
        color: inherit; }
    .fhl-title-content-btn-wrapper div p {
      margin-right: 0.3125rem;
      color: inherit;
      font-size: 0.875rem;
      line-height: 1.3125rem; }
    .fhl-title-content-btn-wrapper div a {
      background-color: #fff;
      border-width: 0.0625rem;
      border-style: solid;
      border-color: inherit;
      box-shadow: 0.125rem 0.125rem 0.25rem 0 #e5e5e5;
      border-radius: 0.25rem;
      flex-direction: row;
      align-content: space-between;
      width: auto;
      overflow: visible;
      padding: 0.4375rem 0.9375rem;
      cursor: pointer;
      margin-left: 1.25rem;
      color: inherit; }
      .fhl-title-content-btn-wrapper div a i {
        margin-bottom: -0.125rem;
        float: right; }
    .fhl-title-content-btn-wrapper div a:hover {
      text-decoration: none; }

.fhl-promo-header-content-wrapper {
  word-break: break-word;
  word-wrap: break-word;
  overflow: visible;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-right: 5px; }
  .fhl-promo-header-content-wrapper p {
    font-family: "HarmoniaSansW05-Regular";
    font-weight: normal;
    font-style: normal; }
    .fhl-promo-header-content-wrapper p em {
      font-family: "HarmoniaSansW05-Italic";
      font-weight: normal;
      font-style: italic; }
      .fhl-promo-header-content-wrapper p em strong {
        font-family: "HarmoniaSansW05-SemiboldItalic";
        font-weight: 300;
        font-style: italic; }
    .fhl-promo-header-content-wrapper p strong {
      font-family: "HarmoniaSansW05-Semibold";
      font-weight: 600;
      font-style: normal; }
      .fhl-promo-header-content-wrapper p strong em {
        font-family: "HarmoniaSans-SemiboldIt";
        font-weight: 600;
        font-style: italic; }
    .fhl-promo-header-content-wrapper p a {
      color: inherit; }
  .fhl-promo-header-content-wrapper h1 {
    color: inherit;
    margin-bottom: 1rem; }
  .fhl-promo-header-content-wrapper div {
    flex-direction: row;
    overflow: visible;
    align-items: baseline; }
    .fhl-promo-header-content-wrapper div p {
      font-size: 1.125rem;
      color: inherit;
      line-height: 1.575rem; }
      .fhl-promo-header-content-wrapper div p a {
        display: table;
        color: inherit; }
    .fhl-promo-header-content-wrapper div a {
      display: table;
      color: inherit; }

.fhl-mediumLevel-h1-content-wrapper {
  margin-bottom: 0 !important;
  flex-direction: column;
  text-align: left; }
  .fhl-mediumLevel-h1-content-wrapper p {
    margin-bottom: 0px; }
  .fhl-mediumLevel-h1-content-wrapper h1 {
    font-family: "HarmoniaSansW05-Semibold";
    font-weight: 600;
    font-style: normal;
    font-size: 1.75rem;
    line-height: 2.1rem;
    margin-bottom: 0.625rem;
    color: inherit; }
  .fhl-mediumLevel-h1-content-wrapper div {
    line-height: 1.35rem;
    font-size: 1.125rem;
    color: inherit; }
    .fhl-mediumLevel-h1-content-wrapper div p {
      font-family: "Opensans-Regular";
      font-weight: normal;
      font-style: normal; }
      .fhl-mediumLevel-h1-content-wrapper div p em {
        font-family: "OpenSans-Italic";
        font-weight: normal;
        font-style: italic; }
        .fhl-mediumLevel-h1-content-wrapper div p em strong {
          font-family: "OpenSans-SemiboldItalic";
          font-weight: 300;
          font-style: italic; }
      .fhl-mediumLevel-h1-content-wrapper div p strong {
        font-family: "OpenSans-Semibold";
        font-weight: 300;
        font-style: normal; }
        .fhl-mediumLevel-h1-content-wrapper div p strong em {
          font-family: "OpenSans-SemiboldItalic";
          font-weight: 300;
          font-style: italic; }
      .fhl-mediumLevel-h1-content-wrapper div p a {
        color: inherit; }

@media only screen and (max-width: 767px) {
  .aside-title-text-image-container {
    margin: 0.625rem 0px 1.25rem !important;
    float: left; }
    .aside-title-text-image-container a {
      width: 100%;
      max-width: 100%; }
      .aside-title-text-image-container a img {
        width: 100%; }
  .title-text-image-container {
    margin: 0px 0rem 1.5625rem; }
    .title-text-image-container a {
      width: 100%; }
    .title-text-image-container img {
      max-width: 100%;
      margin-bottom: 0px; }
    .title-text-image-container h2 {
      padding: 0;
      font-size: 2.25rem; }
    .title-text-image-container p {
      padding: 0; }
  .fhl-promo-header-content-wrapper {
    margin: 2.8125rem auto 0 !important;
    width: 90%; }
    .fhl-promo-header-content-wrapper h1 {
      font-size: 2.125rem; }
    .fhl-promo-header-content-wrapper p {
      margin-top: 0.3125rem; }
  .fhl-title-content-btn-wrapper h2,
  .fhl-title-content-btn-wrapper h3 {
    font-size: 1.5rem;
    line-height: 1.875rem; }
  .fhl-title-content-btn-wrapper div {
    flex-direction: column; } }

@media only screen and (min-width: 768px) {
  .title-text-image-container {
    margin: 0rem; }
    .title-text-image-container h2 {
      font-size: 2rem; }
    .title-text-image-container a {
      max-width: 100%; }
    .title-text-image-container img {
      max-width: 100%; }
  .fhl-promo-header-content-wrapper {
    width: 85%; }
    .fhl-promo-header-content-wrapper h1 {
      font-size: 2.125rem; } }

@media only screen and (min-width: 992px) {
  .title-text-image-container {
    margin: 0rem; }
    .title-text-image-container img {
      max-width: 100%; }
    .title-text-image-container h2 {
      font-size: 2.625rem; }
    .title-text-image-container h2,
    .title-text-image-container p {
      padding-right: 15%;
      margin-bottom: 1.25rem; }
  .fhl-promo-header-content-wrapper {
    width: 70%; }
    .fhl-promo-header-content-wrapper h1 {
      font-size: 2.625rem; } }

.fhl-title-subTitle-wrapper h2 {
  font-size: 2rem;
  color: inherit;
  line-height: 2.4rem; }

.fhl-title-subTitle-wrapper h3 {
  font-size: 1.5rem;
  color: inherit;
  line-height: 1.8rem; }

.fhl-mediumTitle-subTitle-wrapper h2 {
  font-size: 2rem;
  color: inherit;
  line-height: 2.4rem; }

.fhl-mediumTitle-subTitle-wrapper div {
  font-size: 1.5rem;
  color: inherit;
  line-height: 1.8rem; }

.fundingGoal-container button {
  background-color: #004987;
  border-radius: 0.25rem;
  font-size: 1rem;
  color: #fff;
  text-align: center;
  width: 8.6875rem;
  height: 2.1875rem !important;
  float: left;
  margin-top: 3.75rem;
  cursor: pointer; }

.fundingGoal-container button:focus {
  outline: 0;
  border: 0;
  box-shadow: 0 0 0 0.1rem #2b9be0 !important; }

.fundingGoal-container button:disabled {
  background-color: #767676 !important;
  color: #fff !important;
  cursor: not-allowed; }

.fundingGoal-dropdown-container button {
  background-color: #004987;
  border-radius: 0.25rem;
  font-size: 1rem;
  color: #fff;
  text-align: center;
  width: 8.6875rem;
  height: 2.1875rem !important;
  float: left;
  cursor: pointer;
  line-height: 1.05rem;
  word-break: break-word;
  display: block;
  margin-top: 3.75rem; }

.fundingGoal-dropdown-container button:focus {
  box-shadow: 0 0 0 0.1rem #2b9be0 !important; }

.fundingGoal-dropdown-container button:disabled {
  background-color: #767676 !important;
  color: #fff !important;
  cursor: not-allowed; }

.fundingGoal-dropdown-container button:hover {
  text-decoration: none; }

.form-control-funding:focus {
  color: #495057;
  background-color: #fff;
  border-color: #212223;
  outline: 0;
  box-shadow: 0 0 0 0.1rem #58585a; }

.form-control-funding {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }

.mpf-horizontal-select {
  margin: 0px !important;
  width: 96% !important; }
  .mpf-horizontal-select .fhl-select {
    width: 97% !important; }
    .mpf-horizontal-select .fhl-select .select-side i {
      color: #00833c; }
  .mpf-horizontal-select button {
    background-color: #00833c;
    margin: 0px 0px 0px 0.9375rem !important;
    height: 2.4375rem !important; }

.mpf-manage-membership .fhl-title-subTitle-wrapper {
  width: 100% !important; }

.mpf-manage-membership .fhl-mediumTitle-subTitle-wrapper {
  width: 100% !important; }

.mpf-horizontal-select-container {
  height: 100%;
  align-self: flex-end; }
  .mpf-horizontal-select-container > div {
    float: left;
    width: 100% !important; }
  .mpf-horizontal-select-container button:focus {
    box-shadow: 0 0 0 0.1rem #2b9be0 !important; }

.different-option-container .fhl-select {
  width: 90%; }
  .different-option-container .fhl-select .select-side {
    top: 0px;
    bottom: 0;
    height: 2.375rem; }
    .different-option-container .fhl-select .select-side i {
      color: #00833c; }

.different-option-container button[type="submit"] {
  background-color: #00833c;
  border-radius: 0.25rem;
  font-size: 0.9375rem;
  color: #fff;
  text-align: center;
  width: 7.5rem;
  height: 2.1875rem !important;
  float: left;
  margin-top: 3.75rem;
  cursor: pointer; }

.different-option-container button:focus {
  outline: 0;
  border: 0;
  box-shadow: 0 0 0 0.1rem #2b9be0 !important; }

.different-option-container button:disabled {
  background-color: #767676 !important;
  color: #fff !important;
  cursor: not-allowed; }

@media only screen and (max-width: 374px) {
  .fundingGoal-container .fhl-select {
    width: 100%; }
    .fundingGoal-container .fhl-select select {
      padding-left: 0.1875rem; } }

@media only screen and (min-width: 375px) {
  .fundingGoal-container {
    margin: 0px 0.625rem; }
    .fundingGoal-container h2 {
      width: 90%; }
    .fundingGoal-container .fhl-select {
      width: 90%; } }

@media only screen and (min-width: 375px) and (max-width: 767px) {
  .products-promo-media-wrapper {
    padding-left: 5px !important; } }

@media only screen and (max-width: 767px) {
  .mpf-horizontal-select {
    width: 100% !important; }
    .mpf-horizontal-select .fhl-select {
      width: 100% !important; }
    .mpf-horizontal-select button {
      margin: 1.25rem 0px 0px 0px !important; }
  .template-mpf-bdr {
    margin: 1.5625rem 0rem 1.875rem; } }

@media only screen and (min-width: 768px) {
  .fundingGoal-container .fhl-select {
    width: 100%; }
  .mpf-manage-membership {
    max-width: 610px !important; }
    .mpf-manage-membership .fhl-title-subTitle-wrapper h2 {
      margin-bottom: 10px !important; }
    .mpf-manage-membership .fhl-mediumTitle-subTitle-wrapper h3 {
      margin-bottom: 10px !important; }
    .mpf-manage-membership .mpf-horizontal-select .fhl-select {
      width: 70% !important; }
    .mpf-manage-membership .mpf-horizontal-select button {
      margin: 1.25rem 0px 0px 0px !important; } }

@media only screen and (min-width: 992px) {
  .mpf-manage-membership {
    max-width: 960px !important; }
    .mpf-manage-membership .fhl-title-subTitle-wrapper h2 {
      margin-bottom: 1.5rem !important; }
    .mpf-manage-membership .fhl-title-subTitle-wrapper h3 {
      margin-bottom: 0.5rem !important; }
    .mpf-manage-membership .fhl-mediumTitle-subTitle-wrapper h2 {
      margin-bottom: 1.5rem !important; }
    .mpf-manage-membership .fhl-mediumTitle-subTitle-wrapper div {
      margin-bottom: 0.5rem !important; }
    .mpf-manage-membership .mpf-horizontal-select .fhl-select {
      width: 90% !important; }
    .mpf-manage-membership .mpf-horizontal-select button {
      margin: 0px 0px 0px -0.625rem !important; } }

@media only screen and (min-width: 1200px) {
  .mpf-manage-membership {
    max-width: 1140px !important; }
    .mpf-manage-membership .mpf-horizontal-select .fhl-select {
      width: 100% !important; }
    .mpf-manage-membership .mpf-horizontal-select button {
      margin: 0px 0px 0px 1rem !important; } }

.fhl-button-content-container {
  background-color: #fff;
  border-width: 0.0625rem;
  border-style: solid;
  border-color: inherit;
  box-shadow: 0.125rem 0.125rem 0.25rem 0 #e5e5e5;
  border-radius: 0.25rem;
  width: 85%;
  height: auto;
  overflow: visible;
  padding: 0.625rem;
  float: right;
  cursor: pointer; }
  .fhl-button-content-container .left-section {
    width: 80%; }
    .fhl-button-content-container .left-section p {
      font-family: "OpenSans-Regular";
      font-size: 1rem;
      color: inherit;
      margin: 0;
      line-height: 1.5625rem;
      text-transform: none;
      letter-spacing: 0; }
  .fhl-button-content-container .right-section {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center; }
    .fhl-button-content-container .right-section a {
      margin-right: -1.25rem; }
  .fhl-button-content-container .left-section,
  .fhl-button-content-container .right-section {
    display: table-cell;
    vertical-align: middle; }

a.left-arrow-icon:hover .fhl-button-content-container .left-section p {
  text-decoration: underline; }

@media only screen and (max-width: 767px) {
  .compare-advance-mobile-wrapper .fhl-large-titlebar-wrapper h1 {
    font-size: 2rem; }
  .fhl-button-content-container {
    margin-top: 0px;
    overflow-x: hidden;
    width: 100%; }
    .fhl-button-content-container .left-section {
      width: 85%; }
    .fhl-button-content-container .right-section {
      width: 15%; }
  .com-adv-button-wrapper .fhl-button-content-container {
    width: 19.375rem;
    float: left; }
  .com-adv-button-wrapper a.left-arrow-icon {
    margin-top: -2.5rem;
    float: left; }
  .compare-advances-products-wrapper {
    margin: 0px 0.9375rem; } }

@media only screen and (min-width: 768px) {
  .fhl-button-content-container {
    width: 100%; }
  .com-adv-button-wrapper a.left-arrow-icon i {
    text-align: left;
    float: left;
    margin-left: 20px; } }

/*************************************************
 FHL Application Ice Widgets Templates
 ************************************************/
.promo-content-container {
  position: relative;
  min-height: 525px;
  padding: 60px 0;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .promo-content-container .promo-content-img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
    min-height: 32.8125rem;
    overflow: hidden; }
    .promo-content-container .promo-content-img img {
      width: 100%; }
  .promo-content-container .promo-main-content {
    text-align: left;
    display: flex;
    width: 71.25rem; }
    .promo-content-container .promo-main-content .promo-col1,
    .promo-content-container .promo-main-content .promo-col2 {
      display: flex;
      flex-direction: column;
      text-align: left;
      max-width: 24%;
      flex: 2; }
      .promo-content-container .promo-main-content .promo-col1 .promo-tag-cntr,
      .promo-content-container .promo-main-content .promo-col2 .promo-tag-cntr {
        position: relative;
        width: 100%;
        font-size: 1.125rem;
        margin: 7.1875rem 0 0 0;
        font-family: "HarmoniaSansW05-Italic";
        font-style: italic;
        font-weight: normal;
        line-height: 1.3125rem;
        color: inherit; }
      .promo-content-container .promo-main-content .promo-col1 p,
      .promo-content-container .promo-main-content .promo-col2 p {
        margin: 0;
        color: inherit; }
        .promo-content-container .promo-main-content .promo-col1 p a,
        .promo-content-container .promo-main-content .promo-col2 p a {
          color: inherit; }
          .promo-content-container .promo-main-content .promo-col1 p a span,
          .promo-content-container .promo-main-content .promo-col2 p a span {
            color: inherit; }
      .promo-content-container .promo-main-content .promo-col1 .promo-tag-name,
      .promo-content-container .promo-main-content .promo-col2 .promo-tag-name {
        position: relative;
        margin-top: 0.625rem;
        font-family: "Opensans-Regular";
        font-size: 1rem;
        color: inherit;
        line-height: 1.4rem; }
      .promo-content-container .promo-main-content .promo-col1 a,
      .promo-content-container .promo-main-content .promo-col2 a {
        position: relative; }
      .promo-content-container .promo-main-content .promo-col1 a.promo-tag-link,
      .promo-content-container .promo-main-content .promo-col2 a.promo-tag-link {
        position: relative;
        font-size: 0.875rem;
        margin-top: 0.625rem;
        letter-spacing: 0.0625rem;
        line-height: 1.05rem;
        display: table !important;
        vertical-align: middle; }
        .promo-content-container .promo-main-content .promo-col1 a.promo-tag-link i,
        .promo-content-container .promo-main-content .promo-col2 a.promo-tag-link i {
          font-size: 1.125rem;
          width: 21px;
          display: inline-table;
          vertical-align: sub; }
        .promo-content-container .promo-main-content .promo-col1 a.promo-tag-link i:hover,
        .promo-content-container .promo-main-content .promo-col2 a.promo-tag-link i:hover {
          text-decoration: none !important;
          display: inline-block !important; }
    .promo-content-container .promo-main-content .promo-col1 {
      max-width: 37%;
      margin-right: 5rem;
      flex: 1; }
      .promo-content-container .promo-main-content .promo-col1 .promo-content-header {
        position: relative;
        margin: 1.5625rem 0 0 0;
        width: 100%;
        font-size: 2.625rem;
        line-height: 3.15rem; }
      .promo-content-container .promo-main-content .promo-col1 .promo-content {
        position: relative;
        width: 100%;
        margin: 0.9375rem 0 0 0;
        font-size: 1.125rem;
        color: inherit;
        line-height: 1.575rem; }
        .promo-content-container .promo-main-content .promo-col1 .promo-content p {
          font-family: "HarmoniaSansW05-Regular";
          font-weight: normal;
          font-style: normal; }
          .promo-content-container .promo-main-content .promo-col1 .promo-content p em {
            font-family: "HarmoniaSansW05-Italic";
            font-weight: normal;
            font-style: italic; }
            .promo-content-container .promo-main-content .promo-col1 .promo-content p em strong {
              font-family: "HarmoniaSansW05-SemiboldItalic";
              font-weight: 300;
              font-style: italic; }
          .promo-content-container .promo-main-content .promo-col1 .promo-content p strong {
            font-family: "HarmoniaSansW05-Semibold";
            font-weight: 600;
            font-style: normal; }
            .promo-content-container .promo-main-content .promo-col1 .promo-content p strong em {
              font-family: "HarmoniaSans-SemiboldIt";
              font-weight: 600;
              font-style: italic; }
          .promo-content-container .promo-main-content .promo-col1 .promo-content p a {
            color: inherit; }
        .promo-content-container .promo-main-content .promo-col1 .promo-content p a {
          text-decoration: underline; }

@media only screen and (max-width: 767px) {
  .extra-space-whiteBg {
    padding: 0px 20px; }
  .ahp-main-promo-banner .promo-content-container .promo-main-content .promo-col1 .promo-content-header {
    font-size: 2rem;
    line-height: 2.25rem; }
  .promo-content-container {
    padding: 1.875rem 0px 0px 0px;
    min-height: 100%;
    background-color: #f3f3f3;
    overflow: hidden;
    flex-direction: column-reverse;
    margin-top: 4.625rem; }
    .promo-content-container .promo-content-img {
      top: auto;
      width: 100%;
      margin-left: 0;
      min-height: 100%;
      bottom: 0;
      position: relative; }
      .promo-content-container .promo-content-img img {
        width: 100%;
        margin-left: 0;
        left: 0;
        max-width: 100%;
        margin-top: 0.3125rem; }
    .promo-content-container .promo-main-content {
      flex-direction: column;
      width: 100%; }
      .promo-content-container .promo-main-content .promo-col1 {
        max-width: 100%;
        margin: 0px 5%;
        width: 90%;
        min-height: auto; }
        .promo-content-container .promo-main-content .promo-col1 .promo-content-header {
          margin: 0px;
          font-size: 1.75rem;
          line-height: 1.875rem; }
          .promo-content-container .promo-main-content .promo-col1 .promo-content-header p {
            display: block;
            font-size: 1.75rem;
            max-height: auto;
            position: relative;
            line-height: 1.875rem; }
          .promo-content-container .promo-main-content .promo-col1 .promo-content-header p > span {
            display: block;
            font-size: 2rem;
            max-height: auto;
            position: relative;
            margin-top: 1.25rem;
            line-height: 2.1875rem; }
        .promo-content-container .promo-main-content .promo-col1 .promo-content {
          font-size: 1rem;
          max-width: auto; }
          .promo-content-container .promo-main-content .promo-col1 .promo-content p {
            height: auto;
            font-size: 1rem; }
      .promo-content-container .promo-main-content .promo-col2 {
        max-width: 90%;
        margin: 0px 5%; }
        .promo-content-container .promo-main-content .promo-col2 .promo-tag-cntr {
          font-size: 1rem;
          margin: 0.9375rem 0 0;
          max-height: auto; }
          .promo-content-container .promo-main-content .promo-col2 .promo-tag-cntr a.promo-tag-link {
            font-size: 0.8125rem;
            width: 9.375rem;
            line-height: 1.3125rem;
            margin-top: 0px; }
            .promo-content-container .promo-main-content .promo-col2 .promo-tag-cntr a.promo-tag-link i {
              height: 1.3125rem; }
        .promo-content-container .promo-main-content .promo-col2 .promo-tag-name p {
          display: inline;
          margin: 0px 0.1875rem 0px 0px; } }

@media only screen and (min-width: 768px) {
  .promo-content-container {
    background-color: #f3f3f3;
    min-height: 24.375rem;
    padding: 1.875rem 0px;
    flex-direction: column;
    margin-top: 4.5rem; }
    .promo-content-container .promo-content-img {
      width: 127%;
      min-height: 100%;
      top: 0;
      position: absolute; }
      .promo-content-container .promo-content-img img {
        margin: 0;
        height: auto;
        width: 100%;
        max-width: 100%;
        position: absolute;
        left: -15%; }
    .promo-content-container .promo-main-content {
      width: 44.25rem;
      flex-direction: column; }
      .promo-content-container .promo-main-content .promo-col1 {
        max-width: 55%;
        margin: 0rem;
        width: 100%; }
        .promo-content-container .promo-main-content .promo-col1 .promo-content-header {
          margin: 0 0 0 0;
          font-size: 2rem;
          line-height: 2.25rem; }
        .promo-content-container .promo-main-content .promo-col1 .promo-content {
          margin-bottom: 1.875rem; }
          .promo-content-container .promo-main-content .promo-col1 .promo-content p {
            height: 100%;
            font-size: 1rem; }
      .promo-content-container .promo-main-content .promo-col2 {
        max-width: 60%;
        margin: 0px;
        padding: 0px; }
        .promo-content-container .promo-main-content .promo-col2 p {
          margin: 0px 2px 0px 0px;
          display: inline-block;
          font-size: 1rem; }
        .promo-content-container .promo-main-content .promo-col2 .promo-tag-cntr {
          margin: 0;
          overflow: visible; }
  .ahp-main-promo-banner .promo-content-container {
    margin-top: 4.625rem; }
    .ahp-main-promo-banner .promo-content-container .promo-main-content .promo-col1 .promo-content-header {
      font-size: 2rem; } }

@media only screen and (min-width: 992px) {
  .promo-content-container {
    min-height: 32.8125rem;
    padding: 3.75rem 0px;
    background-color: transparent;
    margin-top: 0px; }
    .promo-content-container .promo-content-img {
      min-height: 32.8125rem;
      width: 125%;
      top: 0; }
      .promo-content-container .promo-content-img img {
        margin: 0;
        left: -11%; }
    .promo-content-container .promo-main-content {
      width: 60.25rem;
      flex-direction: row; }
      .promo-content-container .promo-main-content .promo-col1 {
        margin: 0 1.25rem 0 0;
        max-width: 42%; }
        .promo-content-container .promo-main-content .promo-col1 .promo-content-header,
        .promo-content-container .promo-main-content .promo-col1 .promo-content-header p {
          margin: 1.5625rem 0 0 0;
          font-size: 2.625rem;
          line-height: 3.15rem; }
        .promo-content-container .promo-main-content .promo-col1 .promo-content,
        .promo-content-container .promo-main-content .promo-col1 .promo-content p {
          max-height: 6.25rem;
          margin: 0.9375rem 0 0 0;
          font-size: 1.125rem;
          line-height: 1.575rem; }
      .promo-content-container .promo-main-content .promo-col2 {
        max-width: 25%;
        margin: 0; }
        .promo-content-container .promo-main-content .promo-col2 p {
          display: block; }
        .promo-content-container .promo-main-content .promo-col2 .promo-tag-cntr {
          margin: 7.1875rem 0 0 0; }
        .promo-content-container .promo-main-content .promo-col2 .promo-tag-cntr,
        .promo-content-container .promo-main-content .promo-col2 .promo-tag-cntr p {
          font-size: 1.125rem;
          line-height: 1.3125rem;
          margin-bottom: 0; }
        .promo-content-container .promo-main-content .promo-col2 a {
          position: relative; }
        .promo-content-container .promo-main-content .promo-col2 a.promo-tag-link {
          font-size: 0.875rem;
          margin-top: 0.625rem;
          letter-spacing: 0.0625rem;
          line-height: 1.05rem; }
          .promo-content-container .promo-main-content .promo-col2 a.promo-tag-link i {
            font-size: 1.125rem;
            line-height: 1.1875rem;
            width: 21px; }
  .ahp-content-wrapper .promo-content-container {
    margin-top: 0rem; }
    .ahp-content-wrapper .promo-content-container .promo-main-content .promo-col1 .promo-content p {
      margin-top: 0; }
  .ahp-main-promo-banner .promo-content-container .promo-main-content .promo-col1 .promo-content-header {
    font-size: 2.625rem; } }

@media only screen and (min-width: 1200px) {
  .promo-content-container .promo-content-img {
    width: 100%;
    top: 0; }
    .promo-content-container .promo-content-img img {
      left: 0;
      margin: 0;
      width: 100%; }
  .promo-content-container .promo-main-content {
    width: 48rem; }
    .promo-content-container .promo-main-content .promo-col1 {
      max-width: 55%;
      margin: 0 6.25rem 0 0; }
    .promo-content-container .promo-main-content .promo-col2 {
      max-width: 35%; } }

.secondary-promo-banner-container {
  position: relative;
  min-height: 32.8125rem;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .secondary-promo-banner-container .secondary-promo-content-img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
    min-height: 32.8125rem;
    overflow: hidden; }
    .secondary-promo-banner-container .secondary-promo-content-img img {
      width: 103%; }
  .secondary-promo-banner-container .secondary-promo-main-content {
    text-align: left;
    display: flex;
    width: 71.25rem; }
    .secondary-promo-banner-container .secondary-promo-main-content .secondary-promo-col {
      max-width: 40%;
      margin-right: 6.25rem;
      flex: 1; }
      .secondary-promo-banner-container .secondary-promo-main-content .secondary-promo-col .secondary-promo-content-header {
        position: relative;
        margin: 1.5625rem 0 0 0;
        color: inherit;
        width: 100%;
        font-size: 2.625rem;
        line-height: 3.15rem; }
      .secondary-promo-banner-container .secondary-promo-main-content .secondary-promo-col .secondary-promo-content {
        position: relative;
        width: 100%;
        margin: 0.9375rem 0 0 0;
        font-size: 1.125rem;
        color: inherit;
        line-height: 1.575rem; }
        .secondary-promo-banner-container .secondary-promo-main-content .secondary-promo-col .secondary-promo-content p {
          font-family: "HarmoniaSansW05-Regular";
          font-weight: normal;
          font-style: normal; }
          .secondary-promo-banner-container .secondary-promo-main-content .secondary-promo-col .secondary-promo-content p em {
            font-family: "HarmoniaSansW05-Italic";
            font-weight: normal;
            font-style: italic; }
            .secondary-promo-banner-container .secondary-promo-main-content .secondary-promo-col .secondary-promo-content p em strong {
              font-family: "HarmoniaSansW05-SemiboldItalic";
              font-weight: 300;
              font-style: italic; }
          .secondary-promo-banner-container .secondary-promo-main-content .secondary-promo-col .secondary-promo-content p strong {
            font-family: "HarmoniaSansW05-Semibold";
            font-weight: 600;
            font-style: normal; }
            .secondary-promo-banner-container .secondary-promo-main-content .secondary-promo-col .secondary-promo-content p strong em {
              font-family: "HarmoniaSans-SemiboldIt";
              font-weight: 600;
              font-style: italic; }
          .secondary-promo-banner-container .secondary-promo-main-content .secondary-promo-col .secondary-promo-content p a {
            color: inherit; }
        .secondary-promo-banner-container .secondary-promo-main-content .secondary-promo-col .secondary-promo-content p a {
          text-decoration: underline; }
      .secondary-promo-banner-container .secondary-promo-main-content .secondary-promo-col a.secondary-promo-tag-link {
        position: relative;
        font-size: 0.875rem;
        margin-top: 0.625rem;
        letter-spacing: 0.0625rem;
        line-height: 1.05rem;
        display: table !important;
        vertical-align: middle;
        color: inherit; }
        .secondary-promo-banner-container .secondary-promo-main-content .secondary-promo-col a.secondary-promo-tag-link i {
          font-size: 1.125rem;
          line-height: 1.1875rem;
          width: 21px;
          display: inline-table;
          vertical-align: sub; }
        .secondary-promo-banner-container .secondary-promo-main-content .secondary-promo-col a.secondary-promo-tag-link i:hover {
          text-decoration: none !important;
          display: inline-block !important; }

@media only screen and (max-width: 767px) {
  .secondary-promo-banner-container {
    margin: 0rem;
    padding: 1.5625rem 0 0;
    min-height: 21.875rem;
    flex-direction: column-reverse; }
    .secondary-promo-banner-container .secondary-promo-content-img {
      min-height: 100%;
      width: 100%;
      position: relative; }
      .secondary-promo-banner-container .secondary-promo-content-img img {
        width: 100%;
        min-height: 101%;
        margin-left: 0%;
        margin-top: 1.875rem;
        margin-bottom: -0.125rem; }
    .secondary-promo-banner-container .secondary-promo-main-content {
      width: 90%;
      margin: 0 0 0 0.9375rem; }
      .secondary-promo-banner-container .secondary-promo-main-content .secondary-promo-col {
        max-width: 100%;
        margin-right: 0; }
        .secondary-promo-banner-container .secondary-promo-main-content .secondary-promo-col a.secondary-promo-tag-link {
          margin-top: 1.25rem; }
        .secondary-promo-banner-container .secondary-promo-main-content .secondary-promo-col .secondary-promo-content-header {
          font-size: 2.375rem;
          line-height: 2.5rem;
          margin: 0; }
          .secondary-promo-banner-container .secondary-promo-main-content .secondary-promo-col .secondary-promo-content-header p {
            width: 100%;
            min-height: 1.625rem;
            margin: 0; }
        .secondary-promo-banner-container .secondary-promo-main-content .secondary-promo-col .secondary-promo-content {
          font-size: 1rem; }
          .secondary-promo-banner-container .secondary-promo-main-content .secondary-promo-col .secondary-promo-content p {
            max-height: 100%;
            overflow: hidden;
            margin: 0; } }

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .secondary-promo-banner-container .secondary-promo-main-content {
    margin-left: 0px; } }

@media only screen and (min-width: 768px) {
  .ahp-secondary-banner .secondary-promo-banner-container {
    min-height: 26.25rem; }
    .ahp-secondary-banner .secondary-promo-banner-container .secondary-promo-content-img img {
      width: 120%; }
  .secondary-promo-banner-container {
    min-height: 25.5rem;
    padding: 2rem 0;
    margin: 0; }
    .secondary-promo-banner-container .secondary-promo-content-img img {
      width: 120%; }
    .secondary-promo-banner-container .secondary-promo-main-content {
      width: 44.25rem; }
      .secondary-promo-banner-container .secondary-promo-main-content .secondary-promo-col {
        max-width: 64%; }
        .secondary-promo-banner-container .secondary-promo-main-content .secondary-promo-col .secondary-promo-content-header {
          margin: 0; }
          .secondary-promo-banner-container .secondary-promo-main-content .secondary-promo-col .secondary-promo-content-header p {
            margin: 0;
            font-size: 1.875rem;
            max-height: 100%; }
        .secondary-promo-banner-container .secondary-promo-main-content .secondary-promo-col .secondary-promo-content p {
          font-size: 1rem;
          max-height: 100%; } }

@media only screen and (min-width: 992px) {
  .secondary-promo-banner-container {
    min-height: 29.0625rem;
    padding: 2rem 0;
    margin: 0; }
    .secondary-promo-banner-container .secondary-promo-content-img {
      min-height: 29.0625rem; }
      .secondary-promo-banner-container .secondary-promo-content-img img {
        width: 100%;
        min-height: 101%;
        margin-left: 0; }
    .secondary-promo-banner-container .secondary-promo-main-content {
      width: 60.25rem;
      margin: 0; }
      .secondary-promo-banner-container .secondary-promo-main-content .secondary-promo-col {
        max-width: 55%;
        margin-right: 6.25rem; }
        .secondary-promo-banner-container .secondary-promo-main-content .secondary-promo-col .secondary-promo-content-header {
          margin: 1.5625rem 0 0 0; }
          .secondary-promo-banner-container .secondary-promo-main-content .secondary-promo-col .secondary-promo-content-header p {
            margin: 0;
            font-size: 2rem;
            line-height: 2.525rem;
            height: auto; }
        .secondary-promo-banner-container .secondary-promo-main-content .secondary-promo-col .secondary-promo-content {
          margin: 0.9375rem 0 0 0; }
          .secondary-promo-banner-container .secondary-promo-main-content .secondary-promo-col .secondary-promo-content p {
            margin: 0;
            font-size: 1.125rem;
            color: inherit;
            line-height: 1.575rem; }
        .secondary-promo-banner-container .secondary-promo-main-content .secondary-promo-col a.secondary-promo-tag-link {
          font-size: 0.875rem;
          color: inherit;
          margin-top: 1.875rem; }
          .secondary-promo-banner-container .secondary-promo-main-content .secondary-promo-col a.secondary-promo-tag-link i {
            font-size: 1.125rem;
            line-height: 1.1875rem; }
        .secondary-promo-banner-container .secondary-promo-main-content .secondary-promo-col a.secondary-promo-tag-link:hover {
          text-decoration: none; }
          .secondary-promo-banner-container .secondary-promo-main-content .secondary-promo-col a.secondary-promo-tag-link:hover i {
            text-decoration: none; }
  .ahp-secondary-banner .secondary-promo-banner-container {
    min-height: 37.1875rem; }
    .ahp-secondary-banner .secondary-promo-banner-container .secondary-promo-content-img img {
      width: 120%;
      min-height: 101%;
      margin-left: -8%; } }

@media only screen and (min-width: 1200px) {
  .secondary-promo-banner-container {
    min-height: 32.8125rem;
    padding: 4rem 0px; }
    .secondary-promo-banner-container .secondary-promo-main-content {
      width: 71.25rem; }
      .secondary-promo-banner-container .secondary-promo-main-content .secondary-promo-col {
        max-width: 40%; }
        .secondary-promo-banner-container .secondary-promo-main-content .secondary-promo-col .secondary-promo-content-header p {
          font-size: 2.625rem;
          line-height: 3.15rem; }
  .ahp-secondary-banner .secondary-promo-banner-container {
    min-height: 37.1875rem; }
    .ahp-secondary-banner .secondary-promo-banner-container .secondary-promo-content-img img {
      width: 100%;
      min-height: 101%;
      margin-left: 0; } }

.card-layout-container {
  background-color: #fff;
  border-radius: 0.25rem;
  margin: -7.5rem 0 3.125rem 0;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2); }
  .card-layout-container .card-layout-top-layer {
    border-radius: 0.25rem 0.25rem 0 0; }
  .card-layout-container .card-layout-middle-layer {
    word-break: break-word;
    word-wrap: break-word;
    padding: 1.25rem;
    min-height: 14.5rem; }
    .card-layout-container .card-layout-middle-layer h2.card-layout-index-header {
      font-size: 1.75rem;
      font-family: "HarmoniaSansW05-Semibold";
      font-weight: 600;
      font-style: normal;
      text-align: left;
      margin-top: -0.625rem;
      line-height: 2.1rem; }
      .card-layout-container .card-layout-middle-layer h2.card-layout-index-header a,
      .card-layout-container .card-layout-middle-layer h2.card-layout-index-header a:hover {
        color: inherit;
        text-decoration: none; }
    .card-layout-container .card-layout-middle-layer .card-layout-index-content p {
      text-align: left;
      font-size: 1rem;
      font-family: "Opensans-Regular";
      font-style: normal;
      font-weight: normal;
      line-height: 1.4rem; }
    .card-layout-container .card-layout-middle-layer .card-layout-index-link {
      text-align: left;
      display: flex; }
      .card-layout-container .card-layout-middle-layer .card-layout-index-link a {
        display: table;
        vertical-align: middle;
        letter-spacing: 0.0625rem;
        font-size: 0.875rem;
        letter-spacing: 0.0625rem;
        line-height: 1.05rem;
        text-transform: uppercase;
        color: inherit; }
        .card-layout-container .card-layout-middle-layer .card-layout-index-link a i {
          display: inline-table;
          vertical-align: sub; }
        .card-layout-container .card-layout-middle-layer .card-layout-index-link a i:hover {
          display: inline-table;
          text-decoration: none; }
    .card-layout-container .card-layout-middle-layer a.card-layout-anchor:hover {
      text-decoration: none; }
  .card-layout-container .card-layout-bottom-layer-container {
    border-radius: 0 0 0.25rem 0.25rem;
    min-height: 226px;
    position: relative; }
    .card-layout-container .card-layout-bottom-layer-container .card-layout-bottom-layer {
      border-radius: 450%/9.375rem 9.375rem 0.3125rem 0.3125rem;
      /*  max-width: 100%;
            min-height: convert-unit(250px, "rem");
            position:absolute;
            left: 0; */ }

@media only screen and (min-width: 320px) {
  .card-layout-container {
    margin-top: -2.8125rem;
    margin-bottom: 4.6875rem; }
    .card-layout-container .card-layout-middle-layer {
      min-height: 100%;
      padding: 1.1875rem; }
      .card-layout-container .card-layout-middle-layer .card-layout-index-link a {
        font-size: 0.8125rem; }
  .card-layout-media-wrapper .col-md-4 {
    max-width: 90%;
    margin: 0px auto; }
  .card-layout-media-wrapper .col-md-4:last-child .card-layout-container {
    margin-bottom: 0 !important; } }

@media only screen and (min-width: 768px) {
  .card-layout-media-wrapper .col-md-4 {
    max-width: 33.33%; }
  .card-layout-container {
    margin-top: -5.625rem;
    margin-bottom: 0; }
    .card-layout-container .card-layout-middle-layer .card-layout-index-link a {
      font-size: 0.875rem; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .card-layout-container .card-layout-middle-layer {
    min-height: 22.3125rem;
    padding-bottom: 0px;
    margin-bottom: 0.9375rem; }
  .card-layout-container .card-layout-bottom-layer-container {
    min-height: 10.6875rem;
    /*  .card-layout-bottom-layer {
                min-height: convert-unit(282px, "rem");
            } */ } }

@media only screen and (min-width: 992px) {
  .card-layout-container {
    margin-top: -7.5rem;
    margin-bottom: 0; }
    .card-layout-container .card-layout-middle-layer {
      min-height: 20.3125rem; } }

@media only screen and (min-width: 1200px) {
  .card-layout-container .card-layout-middle-layer {
    min-height: 17rem; } }

.membership-content-article {
  flex-direction: column;
  word-break: break-word; }
  .membership-content-article .membership-content-title {
    margin: 0px;
    color: inherit;
    font-size: 2rem;
    line-height: 2.4rem; }
  .membership-content-article .membership-content-contentbody {
    padding-right: 35px;
    margin-top: 2.5rem;
    font-size: 1.125rem;
    font-weight: 500;
    font-style: normal;
    line-height: 1.575rem;
    color: inherit; }
    .membership-content-article .membership-content-contentbody p {
      font-family: "HarmoniaSansW05-Regular";
      font-weight: normal;
      font-style: normal; }
      .membership-content-article .membership-content-contentbody p em {
        font-family: "HarmoniaSansW05-Italic";
        font-weight: normal;
        font-style: italic; }
        .membership-content-article .membership-content-contentbody p em strong {
          font-family: "HarmoniaSansW05-SemiboldItalic";
          font-weight: 300;
          font-style: italic; }
      .membership-content-article .membership-content-contentbody p strong {
        font-family: "HarmoniaSansW05-Semibold";
        font-weight: 600;
        font-style: normal; }
        .membership-content-article .membership-content-contentbody p strong em {
          font-family: "HarmoniaSans-SemiboldIt";
          font-weight: 600;
          font-style: italic; }
      .membership-content-article .membership-content-contentbody p a {
        color: inherit; }
    .membership-content-article .membership-content-contentbody p a {
      text-decoration: underline; }

@media only screen and (max-width: 767px) {
  .membership-content-article {
    margin: 0px; }
    .membership-content-article .membership-content-title {
      margin-top: 0;
      font-size: 1.75rem; }
    .membership-content-article .membership-content-contentbody {
      padding-right: 0;
      margin-top: 0.9375rem;
      margin-bottom: 0.9375rem; }
  .ahp-content-wrapper .membership-content-article .membership-content-title {
    margin-top: 1.5625rem;
    margin-bottom: 0.9375rem;
    font-size: 2rem; } }

@media only screen and (min-width: 768px) {
  .membership-content-article {
    margin: 0px; }
    .membership-content-article .membership-content-contentbody {
      padding-right: 8%;
      margin-top: 2.5rem; }
  .ahp-content-wrapper .membership-content-article .membership-content-contentbody {
    margin-top: 2rem; } }

@media only screen and (min-width: 992px) {
  .membership-content-article .membership-content-title {
    font-size: 2rem;
    line-height: 2.4rem; } }

.partnership-content-article {
  height: auto;
  flex-direction: column;
  word-break: break-word; }
  .partnership-content-article .partnership-content-title {
    margin: 8px 0px 0px 0px;
    color: #00833c;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.8rem; }
  .partnership-content-article .partership-bottom-container {
    flex-direction: row;
    align-content: space-between;
    overflow: visible; }
    .partnership-content-article .partership-bottom-container .partnership-column1,
    .partnership-content-article .partership-bottom-container .partnership-column2 {
      overflow: visible;
      flex-direction: row;
      max-width: 45%;
      margin-top: 2.5rem;
      margin-right: 1.875rem; }
      .partnership-content-article .partership-bottom-container .partnership-column1 h4,
      .partnership-content-article .partership-bottom-container .partnership-column2 h4 {
        font-size: 1.3125rem;
        font-family: "HarmoniaSansW05-Semibold";
        font-weight: 600;
        font-style: normal;
        color: inherit;
        line-height: 1.575rem;
        margin: 0 !important;
        word-break: break-word; }
      .partnership-content-article .partership-bottom-container .partnership-column1 .partnership-content-contentbody,
      .partnership-content-article .partership-bottom-container .partnership-column2 .partnership-content-contentbody {
        margin-top: 0.625rem;
        margin-bottom: 1.5625rem;
        font-family: "Opensans-Regular";
        font-weight: normal;
        font-style: normal;
        font-size: 0.875rem;
        color: inherit;
        line-height: 1.3125rem;
        word-break: break-word; }
        .partnership-content-article .partership-bottom-container .partnership-column1 .partnership-content-contentbody p,
        .partnership-content-article .partership-bottom-container .partnership-column2 .partnership-content-contentbody p {
          font-family: "Opensans-Regular";
          font-weight: normal;
          font-style: normal; }
          .partnership-content-article .partership-bottom-container .partnership-column1 .partnership-content-contentbody p em,
          .partnership-content-article .partership-bottom-container .partnership-column2 .partnership-content-contentbody p em {
            font-family: "OpenSans-Italic";
            font-weight: normal;
            font-style: italic; }
            .partnership-content-article .partership-bottom-container .partnership-column1 .partnership-content-contentbody p em strong,
            .partnership-content-article .partership-bottom-container .partnership-column2 .partnership-content-contentbody p em strong {
              font-family: "OpenSans-SemiboldItalic";
              font-weight: 300;
              font-style: italic; }
          .partnership-content-article .partership-bottom-container .partnership-column1 .partnership-content-contentbody p strong,
          .partnership-content-article .partership-bottom-container .partnership-column2 .partnership-content-contentbody p strong {
            font-family: "OpenSans-Semibold";
            font-weight: 300;
            font-style: normal; }
            .partnership-content-article .partership-bottom-container .partnership-column1 .partnership-content-contentbody p strong em,
            .partnership-content-article .partership-bottom-container .partnership-column2 .partnership-content-contentbody p strong em {
              font-family: "OpenSans-SemiboldItalic";
              font-weight: 300;
              font-style: italic; }
          .partnership-content-article .partership-bottom-container .partnership-column1 .partnership-content-contentbody p a,
          .partnership-content-article .partership-bottom-container .partnership-column2 .partnership-content-contentbody p a {
            color: inherit; }
        .partnership-content-article .partership-bottom-container .partnership-column1 .partnership-content-contentbody p a,
        .partnership-content-article .partership-bottom-container .partnership-column2 .partnership-content-contentbody p a {
          text-decoration: underline; }
      .partnership-content-article .partership-bottom-container .partnership-column1 .partnership-content-link,
      .partnership-content-article .partership-bottom-container .partnership-column2 .partnership-content-link {
        color: inherit;
        word-break: break-word; }
      .partnership-content-article .partership-bottom-container .partnership-column1 p,
      .partnership-content-article .partership-bottom-container .partnership-column2 p {
        color: inherit; }
        .partnership-content-article .partership-bottom-container .partnership-column1 p a,
        .partnership-content-article .partership-bottom-container .partnership-column2 p a {
          color: inherit; }
  .partnership-content-article p a.partnership-anchor {
    color: inherit;
    text-decoration: none; }
  .partnership-content-article a.partnership-anchor {
    color: inherit;
    text-decoration: none; }

@media only screen and (min-width: 320px) {
  .partnership-content-article {
    margin: 0px; }
    .partnership-content-article .partnership-content-title {
      margin: 0px;
      font-size: 1.75rem; }
    .partnership-content-article .partership-bottom-container {
      flex-direction: column;
      padding-right: 0.625rem; }
      .partnership-content-article .partership-bottom-container .partnership-column1,
      .partnership-content-article .partership-bottom-container .partnership-column2 {
        margin-top: 1.5625rem;
        max-width: 100%;
        margin-right: 0; }
  .partnershiparrow {
    height: 1.5625rem;
    width: 1.5625rem; } }

@media only screen and (min-width: 768px) {
  .partnership-content-article {
    margin-bottom: 0px; }
    .partnership-content-article .partnership-content-title {
      line-height: 2.4rem; }
    .partnership-content-article .partership-bottom-container {
      flex-direction: row; }
      .partnership-content-article .partership-bottom-container .partnership-column1,
      .partnership-content-article .partership-bottom-container .partnership-column2 {
        max-width: 45%;
        margin-right: 1.875rem;
        margin-top: 2.5rem; }
  .ahp-content-wrapper .partnership-content-article .partership-bottom-container .partnership-column1 {
    margin-top: 2rem; }
    .ahp-content-wrapper .partnership-content-article .partership-bottom-container .partnership-column1 .partnership-content-contentbody {
      margin-bottom: 1.125rem; }
  .ahp-content-wrapper .partnership-content-article .partership-bottom-container .partnership-column2 {
    margin-top: 2rem; }
    .ahp-content-wrapper .partnership-content-article .partership-bottom-container .partnership-column2 .partnership-content-contentbody {
      margin-bottom: 1.125rem; } }

@media only screen and (min-width: 992px) {
  .partnership-content-article .partnership-content-title {
    font-size: 1.5rem;
    line-height: 1.8rem; }
  .ahp-workwithus-cntr {
    margin-top: 0.3125rem; } }

.inspire-article-container,
.title-text-article-container {
  flex-direction: row;
  overflow: visible;
  font-family: "HarmoniaSansW05-Regular";
  font-weight: normal;
  font-style: normal; }
  .inspire-article-container .inspire-right-section,
  .inspire-article-container .title-text-right-section,
  .title-text-article-container .inspire-right-section,
  .title-text-article-container .title-text-right-section {
    flex-direction: column; }
    .inspire-article-container .inspire-right-section .inspire-article-title,
    .inspire-article-container .inspire-right-section .title-text-article-title,
    .inspire-article-container .title-text-right-section .inspire-article-title,
    .inspire-article-container .title-text-right-section .title-text-article-title,
    .title-text-article-container .inspire-right-section .inspire-article-title,
    .title-text-article-container .inspire-right-section .title-text-article-title,
    .title-text-article-container .title-text-right-section .inspire-article-title,
    .title-text-article-container .title-text-right-section .title-text-article-title {
      color: inherit;
      font-size: 2rem;
      line-height: 2.4rem;
      text-align: left;
      margin: 0; }
    .inspire-article-container .inspire-right-section .inspire-article-content,
    .inspire-article-container .inspire-right-section .title-text-article-content,
    .inspire-article-container .title-text-right-section .inspire-article-content,
    .inspire-article-container .title-text-right-section .title-text-article-content,
    .title-text-article-container .inspire-right-section .inspire-article-content,
    .title-text-article-container .inspire-right-section .title-text-article-content,
    .title-text-article-container .title-text-right-section .inspire-article-content,
    .title-text-article-container .title-text-right-section .title-text-article-content {
      font-size: 1.125rem;
      margin: 1.5625rem 0 1.5625rem 0;
      color: inherit;
      line-height: 1.575rem; }
    .inspire-article-container .inspire-right-section .inspire-article-content p,
    .inspire-article-container .title-text-right-section .inspire-article-content p,
    .title-text-article-container .inspire-right-section .inspire-article-content p,
    .title-text-article-container .title-text-right-section .inspire-article-content p {
      font-family: "HarmoniaSansW05-Regular";
      font-weight: normal;
      font-style: normal; }
      .inspire-article-container .inspire-right-section .inspire-article-content p em,
      .inspire-article-container .title-text-right-section .inspire-article-content p em,
      .title-text-article-container .inspire-right-section .inspire-article-content p em,
      .title-text-article-container .title-text-right-section .inspire-article-content p em {
        font-family: "HarmoniaSansW05-Italic";
        font-weight: normal;
        font-style: italic; }
        .inspire-article-container .inspire-right-section .inspire-article-content p em strong,
        .inspire-article-container .title-text-right-section .inspire-article-content p em strong,
        .title-text-article-container .inspire-right-section .inspire-article-content p em strong,
        .title-text-article-container .title-text-right-section .inspire-article-content p em strong {
          font-family: "HarmoniaSansW05-SemiboldItalic";
          font-weight: 300;
          font-style: italic; }
      .inspire-article-container .inspire-right-section .inspire-article-content p strong,
      .inspire-article-container .title-text-right-section .inspire-article-content p strong,
      .title-text-article-container .inspire-right-section .inspire-article-content p strong,
      .title-text-article-container .title-text-right-section .inspire-article-content p strong {
        font-family: "HarmoniaSansW05-Semibold";
        font-weight: 600;
        font-style: normal; }
        .inspire-article-container .inspire-right-section .inspire-article-content p strong em,
        .inspire-article-container .title-text-right-section .inspire-article-content p strong em,
        .title-text-article-container .inspire-right-section .inspire-article-content p strong em,
        .title-text-article-container .title-text-right-section .inspire-article-content p strong em {
          font-family: "HarmoniaSans-SemiboldIt";
          font-weight: 600;
          font-style: italic; }
      .inspire-article-container .inspire-right-section .inspire-article-content p a,
      .inspire-article-container .title-text-right-section .inspire-article-content p a,
      .title-text-article-container .inspire-right-section .inspire-article-content p a,
      .title-text-article-container .title-text-right-section .inspire-article-content p a {
        color: inherit; }
    .inspire-article-container .inspire-right-section .inspire-article-content p a,
    .inspire-article-container .title-text-right-section .inspire-article-content p a,
    .title-text-article-container .inspire-right-section .inspire-article-content p a,
    .title-text-article-container .title-text-right-section .inspire-article-content p a {
      text-decoration: underline; }
    .inspire-article-container .inspire-right-section p a.inspire-anchor,
    .inspire-article-container .inspire-right-section p a.title-text-anchor,
    .inspire-article-container .title-text-right-section p a.inspire-anchor,
    .inspire-article-container .title-text-right-section p a.title-text-anchor,
    .title-text-article-container .inspire-right-section p a.inspire-anchor,
    .title-text-article-container .inspire-right-section p a.title-text-anchor,
    .title-text-article-container .title-text-right-section p a.inspire-anchor,
    .title-text-article-container .title-text-right-section p a.title-text-anchor {
      word-break: break-word;
      display: table !important;
      vertical-align: middle;
      color: inherit; }
      .inspire-article-container .inspire-right-section p a.inspire-anchor i,
      .inspire-article-container .inspire-right-section p a.title-text-anchor i,
      .inspire-article-container .title-text-right-section p a.inspire-anchor i,
      .inspire-article-container .title-text-right-section p a.title-text-anchor i,
      .title-text-article-container .inspire-right-section p a.inspire-anchor i,
      .title-text-article-container .inspire-right-section p a.title-text-anchor i,
      .title-text-article-container .title-text-right-section p a.inspire-anchor i,
      .title-text-article-container .title-text-right-section p a.title-text-anchor i {
        font-size: 1.125rem;
        line-height: 1.1875rem;
        width: 21px;
        display: inline-table;
        vertical-align: sub; }
      .inspire-article-container .inspire-right-section p a.inspire-anchor i:hover,
      .inspire-article-container .inspire-right-section p a.title-text-anchor i:hover,
      .inspire-article-container .title-text-right-section p a.inspire-anchor i:hover,
      .inspire-article-container .title-text-right-section p a.title-text-anchor i:hover,
      .title-text-article-container .inspire-right-section p a.inspire-anchor i:hover,
      .title-text-article-container .inspire-right-section p a.title-text-anchor i:hover,
      .title-text-article-container .title-text-right-section p a.inspire-anchor i:hover,
      .title-text-article-container .title-text-right-section p a.title-text-anchor i:hover {
        text-decoration: none !important;
        display: inline-block !important; }
    .inspire-article-container .inspire-right-section a.inspire-anchor,
    .inspire-article-container .inspire-right-section a.title-text-anchor,
    .inspire-article-container .title-text-right-section a.inspire-anchor,
    .inspire-article-container .title-text-right-section a.title-text-anchor,
    .title-text-article-container .inspire-right-section a.inspire-anchor,
    .title-text-article-container .inspire-right-section a.title-text-anchor,
    .title-text-article-container .title-text-right-section a.inspire-anchor,
    .title-text-article-container .title-text-right-section a.title-text-anchor {
      text-decoration: none;
      display: table !important;
      vertical-align: middle;
      color: inherit; }
      .inspire-article-container .inspire-right-section a.inspire-anchor i,
      .inspire-article-container .inspire-right-section a.title-text-anchor i,
      .inspire-article-container .title-text-right-section a.inspire-anchor i,
      .inspire-article-container .title-text-right-section a.title-text-anchor i,
      .title-text-article-container .inspire-right-section a.inspire-anchor i,
      .title-text-article-container .inspire-right-section a.title-text-anchor i,
      .title-text-article-container .title-text-right-section a.inspire-anchor i,
      .title-text-article-container .title-text-right-section a.title-text-anchor i {
        font-size: 1.125rem;
        line-height: 1.1875rem;
        width: 21px;
        display: inline-table;
        vertical-align: sub; }
      .inspire-article-container .inspire-right-section a.inspire-anchor i:hover,
      .inspire-article-container .inspire-right-section a.title-text-anchor i:hover,
      .inspire-article-container .title-text-right-section a.inspire-anchor i:hover,
      .inspire-article-container .title-text-right-section a.title-text-anchor i:hover,
      .title-text-article-container .inspire-right-section a.inspire-anchor i:hover,
      .title-text-article-container .inspire-right-section a.title-text-anchor i:hover,
      .title-text-article-container .title-text-right-section a.inspire-anchor i:hover,
      .title-text-article-container .title-text-right-section a.title-text-anchor i:hover {
        text-decoration: none !important;
        display: inline-block !important; }

.title-text-article-container .title-text-right-section .title-text-article-content p {
  font-family: "Opensans-Regular";
  font-weight: normal;
  font-style: normal; }
  .title-text-article-container .title-text-right-section .title-text-article-content p em {
    font-family: "OpenSans-Italic";
    font-weight: normal;
    font-style: italic; }
    .title-text-article-container .title-text-right-section .title-text-article-content p em strong {
      font-family: "OpenSans-SemiboldItalic";
      font-weight: 300;
      font-style: italic; }
  .title-text-article-container .title-text-right-section .title-text-article-content p strong {
    font-family: "OpenSans-Semibold";
    font-weight: 300;
    font-style: normal; }
    .title-text-article-container .title-text-right-section .title-text-article-content p strong em {
      font-family: "OpenSans-SemiboldItalic";
      font-weight: 300;
      font-style: italic; }
  .title-text-article-container .title-text-right-section .title-text-article-content p a {
    color: inherit; }

@media only screen and (max-width: 767px) {
  .inspire-article-container {
    flex-direction: column;
    margin: 1.25rem 0px 0px;
    width: 100%; }
    .inspire-article-container .inspire-right-section .inspire-article-title {
      margin-top: -0.625rem; }
    .inspire-article-container .inspire-right-section .inspire-article-content {
      font-size: 1rem; }
  .title-text-article-container {
    flex-direction: column;
    margin: 1.25rem 0px 0px;
    width: 100%; }
    .title-text-article-container .title-text-right-section .title-text-article-title {
      margin-top: -0.625rem; }
    .title-text-article-container .title-text-right-section .title-text-article-content {
      font-size: 1rem; }
  .ahp-content-wrapper .inspire-article-container .inspire-right-section .inspire-article-title {
    margin-top: 0.625rem; }
  .ahp-content-wrapper .member-row-middle-mt {
    margin-top: 0;
    padding-top: 0 !important; }
  .ahp-content-wrapper .displayImg-desktop-right {
    margin-bottom: 2rem; } }

@media only screen and (min-width: 768px) {
  .inspire-article-container,
  .title-text-article-container {
    margin: 0rem; }
  .ahp-content-wrapper .inspire-article-container .inspire-right-section .inspire-article-title {
    margin-top: 0; } }

@media only screen and (min-width: 992px) {
  .inspire-article-container .inspire-right-section .inspire-article-content {
    font-size: 1.125rem; }
  .title-text-article-container .title-text-right-section .title-text-article-content {
    font-size: 1.125rem; } }

.events-article-wrapper {
  flex-direction: column;
  word-break: break-word; }
  .events-article-wrapper h2 {
    margin: 1.5625rem 0 0 0;
    color: inherit;
    font-size: 2rem;
    line-height: 2.4rem; }
  .events-article-wrapper .events-article-content-block {
    flex-direction: row;
    align-content: space-between;
    padding-right: 8vw; }
    .events-article-wrapper .events-article-content-block .events-left-section {
      flex: 1; }
      .events-article-wrapper .events-article-content-block .events-left-section img {
        margin-top: 1.875rem;
        width: 3.75rem;
        height: 3.75rem; }
    .events-article-wrapper .events-article-content-block .events-right-section {
      flex: 5; }
      .events-article-wrapper .events-article-content-block .events-right-section h3 {
        color: inherit;
        font-size: 1.75rem;
        margin-top: 1.875rem;
        margin-bottom: 0.9375rem;
        font-family: "HarmoniaSansW05-Semibold";
        font-weight: 600;
        font-style: normal;
        line-height: 2.1rem; }
        .events-article-wrapper .events-article-content-block .events-right-section h3 a,
        .events-article-wrapper .events-article-content-block .events-right-section h3 a:hover {
          text-decoration: none;
          color: inherit !important;
          word-break: break-word; }
      .events-article-wrapper .events-article-content-block .events-right-section p {
        color: inherit; }
        .events-article-wrapper .events-article-content-block .events-right-section p a {
          color: inherit !important; }
      .events-article-wrapper .events-article-content-block .events-right-section .events-content-section {
        font-size: 0.875rem;
        color: inherit;
        line-height: 1.3125rem; }
        .events-article-wrapper .events-article-content-block .events-right-section .events-content-section p {
          font-family: "Opensans-Regular";
          font-weight: normal;
          font-style: normal; }
          .events-article-wrapper .events-article-content-block .events-right-section .events-content-section p em {
            font-family: "OpenSans-Italic";
            font-weight: normal;
            font-style: italic; }
            .events-article-wrapper .events-article-content-block .events-right-section .events-content-section p em strong {
              font-family: "OpenSans-SemiboldItalic";
              font-weight: 300;
              font-style: italic; }
          .events-article-wrapper .events-article-content-block .events-right-section .events-content-section p strong {
            font-family: "OpenSans-Semibold";
            font-weight: 300;
            font-style: normal; }
            .events-article-wrapper .events-article-content-block .events-right-section .events-content-section p strong em {
              font-family: "OpenSans-SemiboldItalic";
              font-weight: 300;
              font-style: italic; }
          .events-article-wrapper .events-article-content-block .events-right-section .events-content-section p a {
            color: inherit; }
      .events-article-wrapper .events-article-content-block .events-right-section .events-register-btn {
        min-height: 1.875rem;
        width: 8.75rem;
        border-radius: 0.25rem;
        background-color: #004987;
        color: inherit;
        margin: 2rem 0;
        display: block;
        text-align: center;
        line-height: 1.05rem;
        font-size: 0.9375rem;
        word-break: break-word;
        padding-top: 6px; }
      .events-article-wrapper .events-article-content-block .events-right-section .events-register-btn:hover {
        text-decoration: none; }
      .events-article-wrapper .events-article-content-block .events-right-section .primary-blue-link {
        letter-spacing: 0.0625rem;
        color: inherit;
        font-size: 0.875rem;
        line-height: 1.05rem; }

@media only screen and (min-width: 320px) {
  .events-article-wrapper {
    border-right: 0;
    margin: 0px 0px 1.875rem;
    padding-bottom: 2.1875rem; }
    .events-article-wrapper h2 {
      margin-top: 0; }
    .events-article-wrapper .events-article-content-block {
      flex-direction: row;
      padding-right: 0; }
      .events-article-wrapper .events-article-content-block .events-left-section {
        text-align: center;
        flex: 0; }
        .events-article-wrapper .events-article-content-block .events-left-section img {
          margin-top: 1rem; }
      .events-article-wrapper .events-article-content-block .events-right-section {
        margin: 0px 0.625rem 0px 1.25rem; }
        .events-article-wrapper .events-article-content-block .events-right-section h3 {
          margin-top: 0.9375rem; }
          .events-article-wrapper .events-article-content-block .events-right-section h3 a,
          .events-article-wrapper .events-article-content-block .events-right-section h3 a:hover {
            text-decoration: none; }
        .events-article-wrapper .events-article-content-block .events-right-section .events-register-btn {
          margin: 2rem 0 0.9375rem; } }

@media only screen and (min-width: 768px) {
  .events-article-wrapper {
    padding-bottom: 0px;
    margin: 0; }
    .events-article-wrapper .events-article-content-block {
      padding-right: 8%; }
      .events-article-wrapper .events-article-content-block .events-right-section .events-register-btn {
        margin: 2rem 0 1.5625rem; } }

.news-article-wrapper {
  flex-direction: column;
  overflow: visible; }
  .news-article-wrapper h2,
  .news-article-wrapper .newsContent-article-date,
  .news-article-wrapper .news-article-content-block h4,
  .news-article-wrapper .news-article-content-block h3,
  .news-article-wrapper .news-article-content-block .news-content {
    word-break: break-word; }
  .news-article-wrapper h2 {
    color: inherit;
    font-size: 2rem;
    line-height: 2.4rem; }
  .news-article-wrapper .newsContent-article-date {
    font-size: 0.875rem;
    color: inherit;
    margin-bottom: 0.625rem;
    letter-spacing: 0.145625rem; }
  .news-article-wrapper .news-article-content-block h4, .news-article-wrapper .news-article-content-block h3 {
    font-size: 1.3125rem;
    font-family: "HarmoniaSansW05-Semibold";
    font-weight: 600;
    font-style: normal;
    color: inherit;
    line-height: 1.575rem; }
    .news-article-wrapper .news-article-content-block h4 a,
    .news-article-wrapper .news-article-content-block h4 a:hover, .news-article-wrapper .news-article-content-block h3 a,
    .news-article-wrapper .news-article-content-block h3 a:hover {
      text-decoration: none;
      color: inherit; }
  .news-article-wrapper .news-article-content-block div p {
    font-family: "Opensans-Regular";
    font-weight: normal;
    font-style: normal; }
    .news-article-wrapper .news-article-content-block div p em {
      font-family: "OpenSans-Italic";
      font-weight: normal;
      font-style: italic; }
      .news-article-wrapper .news-article-content-block div p em strong {
        font-family: "OpenSans-SemiboldItalic";
        font-weight: 300;
        font-style: italic; }
    .news-article-wrapper .news-article-content-block div p strong {
      font-family: "OpenSans-Semibold";
      font-weight: 300;
      font-style: normal; }
      .news-article-wrapper .news-article-content-block div p strong em {
        font-family: "OpenSans-SemiboldItalic";
        font-weight: 300;
        font-style: italic; }
    .news-article-wrapper .news-article-content-block div p a {
      color: inherit; }
  .news-article-wrapper .news-article-content-block div p {
    font-size: 0.875rem;
    color: inherit;
    line-height: 1.3125rem; }
  .news-article-wrapper .news-article-content-block > p {
    font-family: "Opensans-Regular";
    margin-top: 0.3125rem;
    font-size: 0.875rem;
    color: inherit;
    line-height: 1.3125rem;
    margin-bottom: 0px; }
    .news-article-wrapper .news-article-content-block > p a.newsContent-anchor {
      font-size: 0.875rem;
      color: inherit;
      letter-spacing: 0.0625rem;
      line-height: 1.05rem; }
  .news-article-wrapper .news-article-content-block p {
    color: inherit; }
    .news-article-wrapper .news-article-content-block p a {
      color: inherit; }

@media only screen and (min-width: 320px) {
  .news-article-wrapper {
    margin: 0px;
    width: 100%; } }

@media only screen and (min-width: 768px) {
  .news-article-wrapper {
    margin-left: 10%;
    width: 90%; } }

.circular-container .col-* {
  overflow: visible; }

.product-circular-anchor a {
  margin-top: 240px; }

.products-circular-item {
  height: 14.375rem;
  margin: 3.125rem 0 1.875rem 0;
  display: block; }
  .products-circular-item > a {
    float: left;
    height: 14.375rem;
    width: 8.75rem;
    overflow: visible; }
  .products-circular-item a:hover .products-circular-bottom-block {
    text-decoration: underline; }
  .products-circular-item .product-promo-image-adjust-bottom {
    height: 14.6875rem; }
  .products-circular-item .products-circular-image-container {
    z-index: 1;
    position: absolute;
    padding-left: 3px; }
    .products-circular-item .products-circular-image-container img {
      border-radius: 50%;
      width: 142px !important;
      margin-top: -15px;
      border: 5px solid white;
      background-color: white;
      margin-left: -5px; }
  .products-circular-item .products-circular-bottom-container {
    height: 180px;
    width: 138px;
    z-index: 0;
    position: absolute;
    margin-top: 50px;
    border-radius: 5px;
    padding-top: 40px; }
    .products-circular-item .products-circular-bottom-container .products-circular-bottom-block {
      text-align: center;
      font-size: 1rem;
      font-family: "HarmoniaSansW05-Semibold";
      font-weight: 600;
      font-style: normal;
      margin-top: 25px; }

@media only screen and (max-width: 374px) {
  .products-promo-media-wrapper .col-md-2 {
    max-width: 45%; } }

@media only screen and (min-width: 375px) {
  .products-promo-media-wrapper .col-md-2 {
    max-width: 50%; } }

@media only screen and (min-width: 320px) {
  .products-promo-media-wrapper .fhl-medium-titlebar-wrapper h2 {
    padding: 0;
    text-align: center;
    font-size: 1.875rem; }
  .products-promo-media-wrapper .col-md-2 .products-circular-item {
    margin: 1.25rem 0.625rem 1.25rem 0.3125rem; } }

@media only screen and (min-width: 768px) {
  .products-promo-media-wrapper {
    margin-bottom: 30px; }
    .products-promo-media-wrapper .fhl-medium-titlebar-wrapper h2 {
      padding: 0;
      text-align: left;
      font-size: 1.875rem; }
    .products-promo-media-wrapper .row .col-md-2 {
      max-width: 33%;
      flex: auto; }
      .products-promo-media-wrapper .row .col-md-2 .products-circular-item {
        margin: 1.5625rem 0 0 -3px;
        text-align: center; }
        .products-promo-media-wrapper .row .col-md-2 .products-circular-item a {
          float: none;
          height: auto;
          width: 8.75rem;
          overflow: visible;
          display: inline-block;
          cursor: pointer; } }

@media only screen and (min-width: 992px) {
  .products-promo-media-wrapper .row .col-md-2 {
    max-width: 16.66666667%;
    flex: 0 0 16.66666667%; }
  .products-promo-media-wrapper .fhl-medium-titlebar-wrapper h2 {
    padding: 0;
    text-align: left;
    font-size: 1.875rem; }
  .white-bg.extra-space-whiteBg {
    margin-top: -5.625rem; } }

@media only screen and (min-width: 1200px) {
  .white-bg.extra-space-whiteBg {
    margin-top: -6.25rem;
    padding: 30px 60px 0px;
    max-width: 1280px !important; }
  .products-promo-media-wrapper .fhl-medium-titlebar-wrapper h2 {
    padding: 0;
    text-align: left;
    font-size: 1.875rem; } }

.overlay-modal {
  width: 100%;
  position: fixed;
  height: 100%;
  background-color: #454545;
  top: 0;
  left: 0;
  z-index: 9;
  opacity: 0.2; }

.fhlb-loader {
  margin: 25% auto;
  font-size: 15px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load5 1.1s infinite ease;
  animation: load5 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0); }

@-webkit-keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #00833c, 1.8em -1.8em 0 0em rgba(0, 131, 60, 0.2), 2.5em 0em 0 0em rgba(0, 131, 60, 0.2), 1.75em 1.75em 0 0em rgba(0, 131, 60, 0.2), 0em 2.5em 0 0em rgba(0, 131, 60, 0.2), -1.8em 1.8em 0 0em rgba(0, 131, 60, 0.2), -2.6em 0em 0 0em rgba(0, 131, 60, 0.5), -1.8em -1.8em 0 0em rgba(0, 131, 60, 0.7); }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(0, 131, 60, 0.7), 1.8em -1.8em 0 0em #00833c, 2.5em 0em 0 0em rgba(0, 131, 60, 0.2), 1.75em 1.75em 0 0em rgba(0, 131, 60, 0.2), 0em 2.5em 0 0em rgba(0, 131, 60, 0.2), -1.8em 1.8em 0 0em rgba(0, 131, 60, 0.2), -2.6em 0em 0 0em rgba(0, 131, 60, 0.2), -1.8em -1.8em 0 0em rgba(0, 131, 60, 0.5); }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(0, 131, 60, 0.5), 1.8em -1.8em 0 0em rgba(0, 131, 60, 0.7), 2.5em 0em 0 0em #00833c, 1.75em 1.75em 0 0em rgba(0, 131, 60, 0.2), 0em 2.5em 0 0em rgba(0, 131, 60, 0.2), -1.8em 1.8em 0 0em rgba(0, 131, 60, 0.2), -2.6em 0em 0 0em rgba(0, 131, 60, 0.2), -1.8em -1.8em 0 0em rgba(0, 131, 60, 0.2); }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(0, 131, 60, 0.2), 1.8em -1.8em 0 0em rgba(0, 131, 60, 0.5), 2.5em 0em 0 0em rgba(0, 131, 60, 0.7), 1.75em 1.75em 0 0em #00833c, 0em 2.5em 0 0em rgba(0, 131, 60, 0.2), -1.8em 1.8em 0 0em rgba(0, 131, 60, 0.2), -2.6em 0em 0 0em rgba(0, 131, 60, 0.2), -1.8em -1.8em 0 0em rgba(0, 131, 60, 0.2); }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(0, 131, 60, 0.2), 1.8em -1.8em 0 0em rgba(0, 131, 60, 0.2), 2.5em 0em 0 0em rgba(0, 131, 60, 0.5), 1.75em 1.75em 0 0em rgba(0, 131, 60, 0.7), 0em 2.5em 0 0em #00833c, -1.8em 1.8em 0 0em rgba(0, 131, 60, 0.2), -2.6em 0em 0 0em rgba(0, 131, 60, 0.2), -1.8em -1.8em 0 0em rgba(0, 131, 60, 0.2); }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(0, 131, 60, 0.2), 1.8em -1.8em 0 0em rgba(0, 131, 60, 0.2), 2.5em 0em 0 0em rgba(0, 131, 60, 0.2), 1.75em 1.75em 0 0em rgba(0, 131, 60, 0.5), 0em 2.5em 0 0em rgba(0, 131, 60, 0.7), -1.8em 1.8em 0 0em #00833c, -2.6em 0em 0 0em rgba(0, 131, 60, 0.2), -1.8em -1.8em 0 0em rgba(0, 131, 60, 0.2); }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(0, 131, 60, 0.2), 1.8em -1.8em 0 0em rgba(0, 131, 60, 0.2), 2.5em 0em 0 0em rgba(0, 131, 60, 0.2), 1.75em 1.75em 0 0em rgba(0, 131, 60, 0.2), 0em 2.5em 0 0em rgba(0, 131, 60, 0.5), -1.8em 1.8em 0 0em rgba(0, 131, 60, 0.7), -2.6em 0em 0 0em #00833c, -1.8em -1.8em 0 0em rgba(0, 131, 60, 0.2); }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(0, 131, 60, 0.2), 1.8em -1.8em 0 0em rgba(0, 131, 60, 0.2), 2.5em 0em 0 0em rgba(0, 131, 60, 0.2), 1.75em 1.75em 0 0em rgba(0, 131, 60, 0.2), 0em 2.5em 0 0em rgba(0, 131, 60, 0.2), -1.8em 1.8em 0 0em rgba(0, 131, 60, 0.5), -2.6em 0em 0 0em rgba(0, 131, 60, 0.7), -1.8em -1.8em 0 0em #00833c; } }

@keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #00833c, 1.8em -1.8em 0 0em rgba(0, 131, 60, 0.2), 2.5em 0em 0 0em rgba(0, 131, 60, 0.2), 1.75em 1.75em 0 0em rgba(0, 131, 60, 0.2), 0em 2.5em 0 0em rgba(0, 131, 60, 0.2), -1.8em 1.8em 0 0em rgba(0, 131, 60, 0.2), -2.6em 0em 0 0em rgba(0, 131, 60, 0.5), -1.8em -1.8em 0 0em rgba(0, 131, 60, 0.7); }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(0, 131, 60, 0.7), 1.8em -1.8em 0 0em #00833c, 2.5em 0em 0 0em rgba(0, 131, 60, 0.2), 1.75em 1.75em 0 0em rgba(0, 131, 60, 0.2), 0em 2.5em 0 0em rgba(0, 131, 60, 0.2), -1.8em 1.8em 0 0em rgba(0, 131, 60, 0.2), -2.6em 0em 0 0em rgba(0, 131, 60, 0.2), -1.8em -1.8em 0 0em rgba(0, 131, 60, 0.5); }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(0, 131, 60, 0.5), 1.8em -1.8em 0 0em rgba(0, 131, 60, 0.7), 2.5em 0em 0 0em #00833c, 1.75em 1.75em 0 0em rgba(0, 131, 60, 0.2), 0em 2.5em 0 0em rgba(0, 131, 60, 0.2), -1.8em 1.8em 0 0em rgba(0, 131, 60, 0.2), -2.6em 0em 0 0em rgba(0, 131, 60, 0.2), -1.8em -1.8em 0 0em rgba(0, 131, 60, 0.2); }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(0, 131, 60, 0.2), 1.8em -1.8em 0 0em rgba(0, 131, 60, 0.5), 2.5em 0em 0 0em rgba(0, 131, 60, 0.7), 1.75em 1.75em 0 0em #00833c, 0em 2.5em 0 0em rgba(0, 131, 60, 0.2), -1.8em 1.8em 0 0em rgba(0, 131, 60, 0.2), -2.6em 0em 0 0em rgba(0, 131, 60, 0.2), -1.8em -1.8em 0 0em rgba(0, 131, 60, 0.2); }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(0, 131, 60, 0.2), 1.8em -1.8em 0 0em rgba(0, 131, 60, 0.2), 2.5em 0em 0 0em rgba(0, 131, 60, 0.5), 1.75em 1.75em 0 0em rgba(0, 131, 60, 0.7), 0em 2.5em 0 0em #00833c, -1.8em 1.8em 0 0em rgba(0, 131, 60, 0.2), -2.6em 0em 0 0em rgba(0, 131, 60, 0.2), -1.8em -1.8em 0 0em rgba(0, 131, 60, 0.2); }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(0, 131, 60, 0.2), 1.8em -1.8em 0 0em rgba(0, 131, 60, 0.2), 2.5em 0em 0 0em rgba(0, 131, 60, 0.2), 1.75em 1.75em 0 0em rgba(0, 131, 60, 0.5), 0em 2.5em 0 0em rgba(0, 131, 60, 0.7), -1.8em 1.8em 0 0em #00833c, -2.6em 0em 0 0em rgba(0, 131, 60, 0.2), -1.8em -1.8em 0 0em rgba(0, 131, 60, 0.2); }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(0, 131, 60, 0.2), 1.8em -1.8em 0 0em rgba(0, 131, 60, 0.2), 2.5em 0em 0 0em rgba(0, 131, 60, 0.2), 1.75em 1.75em 0 0em rgba(0, 131, 60, 0.2), 0em 2.5em 0 0em rgba(0, 131, 60, 0.5), -1.8em 1.8em 0 0em rgba(0, 131, 60, 0.7), -2.6em 0em 0 0em #00833c, -1.8em -1.8em 0 0em rgba(0, 131, 60, 0.2); }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(0, 131, 60, 0.2), 1.8em -1.8em 0 0em rgba(0, 131, 60, 0.2), 2.5em 0em 0 0em rgba(0, 131, 60, 0.2), 1.75em 1.75em 0 0em rgba(0, 131, 60, 0.2), 0em 2.5em 0 0em rgba(0, 131, 60, 0.2), -1.8em 1.8em 0 0em rgba(0, 131, 60, 0.5), -2.6em 0em 0 0em rgba(0, 131, 60, 0.7), -1.8em -1.8em 0 0em #00833c; } }

/* Safari */
/*@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .fhlbank-loadText{
    position: fixed;
    top: 50%;
    left: 50%;
    
    transform: translate(-50%, -50%);
    transform: -webkit-translate(-50%, -50%);
    transform: -moz-translate(-50%, -50%);
    transform: -ms-translate(-50%, -50%);
  }

  .fhlbank-loadText h3{
    color: get("color.primary-green.100");
  }*/
.fhl-content-wrapper {
  border-bottom: 0.0625rem solid #939395;
  margin-bottom: 1.25rem;
  padding-bottom: 0.625rem;
  margin-top: 0.625rem; }
  .fhl-content-wrapper h3 {
    font-size: 1.5rem;
    line-height: 1.8rem;
    font-weight: normal;
    font-style: normal;
    color: inherit; }
  .fhl-content-wrapper p {
    font-family: "Opensans-Regular";
    font-weight: normal;
    font-style: normal;
    font-size: 0.875rem;
    color: inherit;
    line-height: 1.3125rem; }
    .fhl-content-wrapper p a {
      text-decoration: underline;
      font-family: "OpenSans-Semibold";
      font-weight: 300;
      font-style: normal;
      color: inherit; }
    .fhl-content-wrapper p a:visited,
    .fhl-content-wrapper p a:focus,
    .fhl-content-wrapper p a:hover,
    .fhl-content-wrapper p a:active {
      color: inherit; }

.fhl-lists-wrapper,
.fhl-richtext-wrapper,
.fhl-article-content-richtext-wrapper {
  font-size: 1rem;
  line-height: 1.4rem; }
  .fhl-lists-wrapper a,
  .fhl-richtext-wrapper a,
  .fhl-article-content-richtext-wrapper a {
    text-decoration: underline;
    font-weight: 300;
    font-style: normal;
    color: inherit; }
  .fhl-lists-wrapper a:visited,
  .fhl-lists-wrapper a:focus,
  .fhl-lists-wrapper a:hover,
  .fhl-lists-wrapper a:active,
  .fhl-richtext-wrapper a:visited,
  .fhl-richtext-wrapper a:focus,
  .fhl-richtext-wrapper a:hover,
  .fhl-richtext-wrapper a:active,
  .fhl-article-content-richtext-wrapper a:visited,
  .fhl-article-content-richtext-wrapper a:focus,
  .fhl-article-content-richtext-wrapper a:hover,
  .fhl-article-content-richtext-wrapper a:active {
    color: inherit; }

.fhl-lists-wrapper {
  font-family: "OpenSans-Semibold"; }
  .fhl-lists-wrapper p {
    margin: 0; }
  .fhl-lists-wrapper a {
    margin-bottom: 0.3125rem;
    display: table; }

.bio-table .fhl-richtext-wrapper td:first-child {
  min-width: auto;
  width: 20%;
  font-weight: 300;
  font-style: normal;
  font-family: "OpenSans-Semibold"; }

.bio-table .fhl-richtext-wrapper td:last-child {
  width: 80%; }

.fhl-richtext-wrapper {
  word-break: break-word;
  word-wrap: break-word;
  font-family: "OpenSans-Semibold"; }
  .fhl-richtext-wrapper a {
    margin-bottom: 0rem;
    display: block !important; }
  .fhl-richtext-wrapper td:first-child {
    font-weight: bold;
    min-width: 8.75rem; }

.fhl-article-content-richtext-wrapper {
  font-family: "OpenSans-Regular";
  padding-top: 1.25rem;
  padding-bottom: 1.25rem; }
  .fhl-article-content-richtext-wrapper a {
    margin-bottom: 0.625rem;
    display: block !important; }

.fhl-article-richtext-wrapper {
  font-size: 2.25rem;
  line-height: 2.7rem;
  padding-bottom: 1.875rem;
  padding-top: 1.875rem;
  margin-bottom: 0px; }
  .fhl-article-richtext-wrapper blockquote {
    margin-bottom: 0px;
    display: block; }
  .fhl-article-richtext-wrapper p {
    border-left: 6px solid #76bc21;
    padding-left: 1.25rem;
    font-family: "HarmoniaSansW05-Italic";
    font-weight: normal;
    font-style: italic;
    color: inherit; }

.fhl-si-video-richtext-wrapper {
  font-family: "HarmoniaSansW05-Regular";
  font-size: 1rem;
  line-height: 1.4rem;
  border-width: 1px;
  border-style: solid;
  border-color: #939395;
  padding: 1.25rem 2.1875rem;
  margin-bottom: 6rem;
  overflow: auto;
  height: 20.3125rem; }

.error-message-wrapper {
  max-width: 650px; }
  .error-message-wrapper .error-message-space {
    margin: 25% 0 25% -15px; }
  .error-message-wrapper .advance-page-max-error .alert {
    margin-bottom: 0px !important;
    margin-left: -15px; }
    .error-message-wrapper .advance-page-max-error .alert .alert-labeled-row span {
      padding: 10px !important; }
    .error-message-wrapper .advance-page-max-error .alert .alert-labeled-row p {
      padding: 0px 0px 0px 5px !important; }
  .error-message-wrapper .errormsg-wrapper .alert {
    border-radius: 0; }
  .error-message-wrapper .errormsg-wrapper .alert-labeled {
    padding: 0px; }
  .error-message-wrapper .errormsg-wrapper .alert-info {
    border-color: #9f1c33; }
  .error-message-wrapper .errormsg-wrapper .alert-labeled-row {
    display: table-row;
    padding: 0rem; }
    .error-message-wrapper .errormsg-wrapper .alert-labeled-row .alert-labelled-cell {
      padding: 1.25rem;
      display: table-cell;
      vertical-align: middle; }
    .error-message-wrapper .errormsg-wrapper .alert-labeled-row .alert-label {
      vertical-align: middle;
      background-color: #9f1c33;
      width: auto;
      padding: 0.625rem, 0.9375rem;
      min-height: 4.6875rem;
      font-size: 1.1em; }
      .error-message-wrapper .errormsg-wrapper .alert-labeled-row .alert-label i {
        color: #fff;
        margin-top: 0.1875rem; }
    .error-message-wrapper .errormsg-wrapper .alert-labeled-row .alert-body {
      align-items: center;
      font-family: "Opensans-Regular";
      font-size: 0.875rem;
      color: #9f1c33;
      line-height: 1.3125rem;
      padding: 1.5rem; }

.imgcontent-layout-container .imgcontent-layout-top-layer-container {
  text-align: center; }
  .imgcontent-layout-container .imgcontent-layout-top-layer-container a {
    display: inline-block; }
  .imgcontent-layout-container .imgcontent-layout-top-layer-container .imgcontent-layout-top-layer {
    background-color: #fcfcfc;
    margin-left: auto;
    margin-right: auto;
    width: 11.875rem;
    height: 11.875rem;
    border-radius: 50%; }
    .imgcontent-layout-container .imgcontent-layout-top-layer-container .imgcontent-layout-top-layer img {
      max-width: 100%;
      width: 11.875rem;
      height: 11.875rem;
      border-radius: 50%; }

.imgcontent-layout-container .imgcontent-layout-last-layer {
  text-align: left; }
  .imgcontent-layout-container .imgcontent-layout-last-layer .imgcontent-layout-index-name {
    font-size: 1.5rem;
    color: #00833c;
    text-align: center;
    padding: 1.5625rem 0.625rem;
    line-height: 1.8rem;
    font-family: "HarmoniaSansW05-Regular"; }
    .imgcontent-layout-container .imgcontent-layout-last-layer .imgcontent-layout-index-name a,
    .imgcontent-layout-container .imgcontent-layout-last-layer .imgcontent-layout-index-name a:hover {
      text-decoration: none; }
  .imgcontent-layout-container .imgcontent-layout-last-layer .imgcontent-layout-index-header {
    font-size: 1rem;
    font-family: "HarmoniaSansW05-Semibold";
    line-height: 1.2rem;
    padding-bottom: 0.625rem;
    font-weight: 600;
    font-style: normal;
    margin-bottom: 0.5rem; }
  .imgcontent-layout-container .imgcontent-layout-last-layer .imgcontent-layout-index-content {
    font-size: 0.875rem;
    line-height: 1.3125rem;
    font-family: "Opensans-Regular"; }
  .imgcontent-layout-container .imgcontent-layout-last-layer .imgcontent-layout-index-link {
    padding-top: 0.625rem;
    color: inherit; }
    .imgcontent-layout-container .imgcontent-layout-last-layer .imgcontent-layout-index-link a {
      font-size: 0.875rem;
      letter-spacing: 0.0625rem;
      line-height: 1.05rem;
      font-family: "HarmoniaSansW05-Regular";
      color: inherit; }
      .imgcontent-layout-container .imgcontent-layout-last-layer .imgcontent-layout-index-link a i {
        height: 1.3125rem; }

.img-header-content-container {
  text-align: center; }
  .img-header-content-container img {
    border-radius: 50%;
    margin-bottom: 0.625rem !important; }
  .img-header-content-container h3,
  .img-header-content-container h2 {
    font-size: 1.5rem;
    text-align: center;
    line-height: 1.8rem;
    color: inherit;
    font-family: "HarmoniaSansW05-Semibold";
    font-weight: 600;
    font-style: normal; }
    .img-header-content-container h3 a,
    .img-header-content-container h2 a {
      color: inherit; }

.aside-image-header-content-link-container {
  overflow: visible;
  width: 100%; }
  .aside-image-header-content-link-container img {
    max-width: 100%;
    margin: 0px auto 1.875rem; }
  .aside-image-header-content-link-container h3 {
    font-family: "HarmoniaSansW05-Regular";
    font-size: 1.125rem;
    color: inherit;
    line-height: 1.35rem; }
  .aside-image-header-content-link-container p {
    font-size: 0.875rem;
    line-height: 1.3125rem; }
  .aside-image-header-content-link-container p {
    font-family: "Opensans-Regular";
    font-weight: normal;
    font-style: normal; }
    .aside-image-header-content-link-container p em {
      font-family: "OpenSans-Italic";
      font-weight: normal;
      font-style: italic; }
      .aside-image-header-content-link-container p em strong {
        font-family: "OpenSans-SemiboldItalic";
        font-weight: 300;
        font-style: italic; }
    .aside-image-header-content-link-container p strong {
      font-family: "OpenSans-Semibold";
      font-weight: 300;
      font-style: normal; }
      .aside-image-header-content-link-container p strong em {
        font-family: "OpenSans-SemiboldItalic";
        font-weight: 300;
        font-style: italic; }
    .aside-image-header-content-link-container p a {
      color: inherit; }
  .aside-image-header-content-link-container p.mb-3 a {
    text-decoration: underline;
    color: inherit; }
  .aside-image-header-content-link-container p.aside-image-header-content-anchor-link {
    display: table; }
    .aside-image-header-content-link-container p.aside-image-header-content-anchor-link a {
      color: inherit;
      font-family: "HarmoniaSansW05-Regular"; }
  .aside-image-header-content-link-container a.aside-image-header-content-anchor-link {
    display: table !important;
    vertical-align: middle;
    color: inherit; }
    .aside-image-header-content-link-container a.aside-image-header-content-anchor-link i {
      display: inline-table;
      vertical-align: sub; }
    .aside-image-header-content-link-container a.aside-image-header-content-anchor-link i:hover {
      text-decoration: none !important;
      display: inline-block !important; }

.img-header-content-container,
.image-header-content-link-container {
  overflow: visible;
  width: 100%; }
  .img-header-content-container img,
  .image-header-content-link-container img {
    max-width: 100%;
    margin: 0px auto 1.875rem; }
  .img-header-content-container a,
  .image-header-content-link-container a {
    color: inherit; }
  .img-header-content-container p.mb-3 a,
  .image-header-content-link-container p.mb-3 a {
    color: inherit;
    text-decoration: underline; }
  .img-header-content-container h5,
  .img-header-content-container h3,
  .img-header-content-container h2,
  .img-header-content-container p,
  .image-header-content-link-container h5,
  .image-header-content-link-container h3,
  .image-header-content-link-container h2,
  .image-header-content-link-container p {
    margin-bottom: 20px; }
  .img-header-content-container p,
  .image-header-content-link-container p {
    font-family: "Opensans-Regular";
    font-size: 1rem;
    color: inherit;
    line-height: 1.4rem; }
  .img-header-content-container p,
  .image-header-content-link-container p {
    font-family: "Opensans-Regular";
    font-weight: normal;
    font-style: normal; }
    .img-header-content-container p em,
    .image-header-content-link-container p em {
      font-family: "OpenSans-Italic";
      font-weight: normal;
      font-style: italic; }
      .img-header-content-container p em strong,
      .image-header-content-link-container p em strong {
        font-family: "OpenSans-SemiboldItalic";
        font-weight: 300;
        font-style: italic; }
    .img-header-content-container p strong,
    .image-header-content-link-container p strong {
      font-family: "OpenSans-Semibold";
      font-weight: 300;
      font-style: normal; }
      .img-header-content-container p strong em,
      .image-header-content-link-container p strong em {
        font-family: "OpenSans-SemiboldItalic";
        font-weight: 300;
        font-style: italic; }
    .img-header-content-container p a,
    .image-header-content-link-container p a {
      color: inherit; }
  .img-header-content-container p.image-header-content-anchor-link,
  .image-header-content-link-container p.image-header-content-anchor-link {
    display: table; }
    .img-header-content-container p.image-header-content-anchor-link a,
    .image-header-content-link-container p.image-header-content-anchor-link a {
      color: inherit;
      font-family: "HarmoniaSansW05-Regular"; }
  .img-header-content-container a.image-header-content-anchor-link,
  .image-header-content-link-container a.image-header-content-anchor-link {
    display: table !important;
    vertical-align: middle;
    color: inherit; }
    .img-header-content-container a.image-header-content-anchor-link i,
    .image-header-content-link-container a.image-header-content-anchor-link i {
      display: inline-table;
      vertical-align: sub; }
    .img-header-content-container a.image-header-content-anchor-link i:hover,
    .image-header-content-link-container a.image-header-content-anchor-link i:hover {
      text-decoration: none !important;
      display: inline-block !important; }

.image-header-content-link-container h3 {
  font-size: 1.5rem;
  text-align: center;
  line-height: 1.8rem;
  color: inherit;
  font-family: "HarmoniaSansW05-Regular"; }
  .image-header-content-link-container h3 a {
    color: inherit; }

@media only screen and (max-width: 767px) {
  .image-header-content-link-container img,
  .aside-image-header-content-link-container img {
    width: 100%; }
  .become-member-mobile-wrapper {
    margin-top: 0;
    padding: 0px !important; }
  .imgcontent-layout-container {
    padding: 1.25rem 0rem 1.875rem; }
  .img-header-content-container {
    margin-bottom: 2.5rem; }
  .become-member-card-container .col-md-4:last-child .img-header-content-container {
    margin-bottom: 0rem; }
  .become-a-member-page-container {
    padding: 0rem 1rem;
    background-color: #fff; }
  .become-member-img-content-placeholder-mobile .col-md-4:last-child {
    margin-bottom: 0; }
  .become-member-img-content-placeholder-mobile {
    margin-top: -3.125rem; }
    .become-member-img-content-placeholder-mobile .col-md-4 {
      margin-bottom: 2.5rem; }
  .displayImg-desktop-right {
    text-align: left; }
  .member-row-middle-mt {
    margin-top: 2.5rem; }
  .ahp-content-wrapper .become-member-img-content-placeholder-mobile {
    padding-bottom: 1rem !important; }
  .ahp-content-wrapper .mt-4-mobile {
    margin-top: 1.5rem !important;
    display: inline-block; }
  .careers-img-content-top {
    margin-top: 0.9375rem; } }

@media only screen and (min-width: 768px) {
  .displayImg-desktop-right {
    text-align: right; }
  .imgcontent-layout-container {
    padding: 1.875rem 1rem 1.875rem; }
  .become-member-card-container {
    padding-left: 0.625rem; }
  .image-header-content-link-container img,
  .aside-image-header-content-link-container img {
    min-width: auto;
    min-height: auto; }
  .member-row-middle-mt {
    margin-top: 3.75rem; }
  .ahp-content-wrapper .mt-4-mobile {
    display: inline-block; } }

@media only screen and (min-width: 992px) {
  .imgcontent-layout-container {
    padding: 1.875rem 0.375rem 1.875rem; }
  .img-header-content-container img {
    max-width: 10.5625rem !important;
    min-height: 10.5625rem; }
  .image-header-content-link-container img {
    width: 100%;
    min-width: 19.1875rem;
    min-height: 12.75rem; }
  .member-row-middle-mt {
    margin-top: 5.625rem; } }

@media only screen and (min-width: 1200px) {
  .imgcontent-layout-container {
    padding: 3.125rem 2.8125rem 1.875rem; } }

.si-promo-content-container {
  position: relative;
  min-height: 325px;
  padding: 60px 0;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .si-promo-content-container .si-promo-content-img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
    min-height: 20.3125rem;
    overflow: hidden; }
    .si-promo-content-container .si-promo-content-img img {
      width: 100%; }

@media only screen and (max-width: 767px) {
  .si-promo-content-container {
    padding: 0.625rem;
    min-height: 4.0625rem;
    background-color: #f3f3f3;
    overflow: hidden; }
    .si-promo-content-container .si-promo-content-img {
      top: auto;
      width: 100%;
      margin-left: 0rem;
      min-height: 100%;
      bottom: 0; }
      .si-promo-content-container .si-promo-content-img img {
        width: 100%;
        margin-left: 0rem;
        left: 0;
        max-width: 100%; } }

@media only screen and (min-width: 768px) {
  .si-promo-content-container {
    background-color: #f3f3f3;
    padding: 1.875rem 0px;
    min-height: 5.625rem; }
    .si-promo-content-container .si-promo-content-img {
      width: 130%;
      min-height: 100%;
      top: 0; }
      .si-promo-content-container .si-promo-content-img img {
        margin: 0;
        height: auto;
        width: 100%;
        max-width: 100%;
        position: absolute;
        left: -15%; } }

@media only screen and (min-width: 992px) {
  .si-promo-content-container {
    min-height: 20.3125rem;
    padding: 3.75rem 0px;
    background-color: transparent; }
    .si-promo-content-container .si-promo-content-img {
      min-height: 20.3125rem;
      width: 125%;
      top: 0; }
      .si-promo-content-container .si-promo-content-img img {
        margin: 0;
        left: -12%; } }

@media only screen and (min-width: 1200px) {
  .si-promo-content-container .si-promo-content-img {
    width: 110%;
    top: 0; }
    .si-promo-content-container .si-promo-content-img img {
      left: 0;
      margin: 0;
      width: 100%; } }

.si-article-content-container {
  position: relative;
  text-align: left; }
  .si-article-content-container h1 {
    font-size: 2.625rem;
    line-height: 3.15rem;
    margin-bottom: 1.875rem;
    font-family: "HarmoniaSansW05-Regular";
    color: inherit; }
  .si-article-content-container .arthur-container {
    float: left;
    padding-left: 0.9375rem;
    margin-top: 0.3125rem; }
  .si-article-content-container .si-article-content-img {
    float: left; }
    .si-article-content-container .si-article-content-img img {
      width: 3.4375rem;
      height: 3.4375rem;
      border-radius: 50%; }
  .si-article-content-container .arthur-name,
  .si-article-content-container .arthur-name a {
    font-size: 1.125rem;
    line-height: 1.35rem;
    font-family: "HarmoniaSansW05-Regular";
    color: inherit; }
  .si-article-content-container .article-date {
    font-size: 0.875rem;
    line-height: 1.3125rem;
    font-family: "Opensans-Regular";
    color: inherit; }
  .si-article-content-container .article-description {
    font-size: 1.5rem;
    color: inherit;
    line-height: 2.1rem;
    margin-top: 34px; }

@media only screen and (min-width: 320px) {
  .si-article-content-container .arthur-container {
    width: 80%; } }

@media only screen and (min-width: 768px) {
  .si-article-content-container .arthur-container {
    width: 85%; } }

@media only screen and (min-width: 992px) {
  .si-article-content-container .arthur-container {
    width: 90%; } }

.image-caption-template {
  text-align: left; }
  .image-caption-template .image-content img {
    width: 100%; }
  .image-caption-template .image-caption {
    font-size: 0.75rem;
    letter-spacing: 0;
    line-height: 1.4rem;
    margin-top: 0.9375rem; }

.mpf-direct-title .fhl-medium-titlebar-wrapper h2 {
  font-size: 1.75rem;
  line-height: 2.1rem;
  text-align: left;
  font-family: "HarmoniaSansW05-Semibold";
  font-weight: 600;
  font-style: normal; }

.fhl-simple-bullet-list-text .fhl-richtext-wrapper {
  font-family: "OpenSans-Regular";
  font-size: 1rem;
  margin-left: 1.25rem;
  line-height: 1.4rem;
  color: inherit; }
  .fhl-simple-bullet-list-text .fhl-richtext-wrapper em {
    font-family: "OpenSans-Italic";
    font-weight: normal;
    font-style: italic; }
    .fhl-simple-bullet-list-text .fhl-richtext-wrapper em strong {
      font-family: "OpenSans-SemiboldItalic";
      font-weight: 300;
      font-style: italic; }
  .fhl-simple-bullet-list-text .fhl-richtext-wrapper strong {
    font-family: "OpenSans-Semibold";
    font-weight: 300;
    font-style: normal; }
    .fhl-simple-bullet-list-text .fhl-richtext-wrapper strong em {
      font-family: "OpenSans-SemiboldItalic";
      font-weight: 300;
      font-style: italic; }

.tagline-area-bottom-container {
  margin: 6.875rem 0px 2.5rem; }

@media only screen and (max-width: 767px) {
  .mpf-direct-title .fhl-medium-titlebar-wrapper h2 {
    margin-bottom: 3.75rem; }
  .fhl-simple-bullet-list-text .fhl-richtext-wrapper {
    margin-left: 0.3125rem; }
  .tagline-area-bottom-container {
    margin: 1.875rem 0px; } }

.si-side-article-content-container {
  position: relative;
  text-align: left;
  margin-top: 58px; }
  .si-side-article-content-container h1 {
    font-size: 2.625rem;
    line-height: 3.15rem;
    margin-bottom: 1.875rem; }
  .si-side-article-content-container h3 {
    font-size: 1.5rem;
    line-height: 1.8rem;
    margin: 0.625rem 0px; }
  .si-side-article-content-container .side-article-type {
    font-size: 1rem;
    line-height: 1.2rem;
    letter-spacing: 0.125rem;
    color: #58585a; }
  .si-side-article-content-container .side-article-title {
    font-size: 1.5rem;
    line-height: 1.8rem;
    margin-top: 0.625rem;
    margin-bottom: 0.9375rem; }
  .si-side-article-content-container .side-article-description {
    font-size: 1rem;
    line-height: 1.4rem;
    margin-top: 0rem;
    font-family: "Opensans-Regular"; }
  .si-side-article-content-container .si-card-layout-anchor-btn {
    min-height: 1.875rem;
    min-width: 8.75rem;
    border-radius: 0.25rem;
    font-size: 0.9375rem;
    line-height: 1.05rem;
    letter-spacing: 0rem;
    margin: 1.25rem 0 2rem;
    display: inline-block;
    text-align: center;
    font-family: "HarmoniaSansW05-Regular";
    padding: 0.375rem 0.75rem; }
  .si-side-article-content-container .si-card-layout-anchor-btn:hover {
    text-decoration: none; }
  .si-side-article-content-container a {
    color: inherit; }

.promo-content-without-title-container {
  position: relative;
  min-height: 32.8125rem;
  padding: 3.75rem 0;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .promo-content-without-title-container .promo-content-without-img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
    min-height: 32.8125rem;
    overflow: hidden; }
    .promo-content-without-title-container .promo-content-without-img img {
      width: 100%;
      margin-left: 0%; }
  .promo-content-without-title-container .promo-main-without-content {
    text-align: left;
    display: flex;
    width: 71.25rem; }
    .promo-content-without-title-container .promo-main-without-content .promo-col1 {
      display: flex;
      flex-direction: column;
      text-align: left;
      max-width: 55%;
      flex: 2; }
      .promo-content-without-title-container .promo-main-without-content .promo-col1 .promo-tag-cntr {
        position: relative;
        width: 100%;
        font-size: 1.125rem;
        margin: 3.125rem 0 0 0;
        font-family: "HarmoniaSansW05-Italic";
        font-style: italic;
        font-weight: normal;
        line-height: 1.3125rem; }
      .promo-content-without-title-container .promo-main-without-content .promo-col1 p {
        margin: 0 !important; }
      .promo-content-without-title-container .promo-main-without-content .promo-col1 .promo-tag-name {
        position: relative;
        margin-top: 1.5625rem;
        font-family: "Opensans-Regular";
        font-size: 1rem;
        line-height: 1.4rem; }
    .promo-content-without-title-container .promo-main-without-content .promo-col1 {
      max-width: 37%;
      margin-right: 5rem;
      flex: 1; }

@media only screen and (max-width: 767px) {
  .promo-content-without-title-container {
    padding: 0.625rem 0.625rem 0px;
    min-height: 100%;
    background-color: #f3f3f3;
    overflow: hidden;
    flex-direction: column-reverse;
    margin-top: 4.625rem; }
    .promo-content-without-title-container .promo-content-without-img {
      width: 100%;
      min-height: 100%;
      position: relative; }
    .promo-content-without-title-container .promo-main-without-content {
      flex-direction: column;
      width: 100%; }
      .promo-content-without-title-container .promo-main-without-content .promo-col1 {
        max-width: 100% !important;
        margin: 0px 5%;
        width: 90%;
        min-height: 100%; }
        .promo-content-without-title-container .promo-main-without-content .promo-col1 .promo-tag-cntr {
          font-size: 1.125rem !important;
          margin: 3.75rem 0 0 !important;
          overflow: hidden; }
        .promo-content-without-title-container .promo-main-without-content .promo-col1 .promo-tag-name {
          margin-top: 1.5625rem;
          width: 90%; } }

@media only screen and (min-width: 768px) {
  .promo-content-without-title-container {
    background-color: transparent;
    min-height: 28.125rem;
    margin-top: 3.125rem; }
    .promo-content-without-title-container .promo-content-without-img {
      left: -8.75rem;
      min-height: 28.125rem; }
      .promo-content-without-title-container .promo-content-without-img img {
        width: 150%;
        margin-left: -30%; }
    .promo-content-without-title-container .promo-main-without-content {
      width: 44.25rem; }
      .promo-content-without-title-container .promo-main-without-content .promo-col1 {
        max-width: 58%; }
        .promo-content-without-title-container .promo-main-without-content .promo-col1 .promo-tag-cntr {
          margin-top: 0;
          font-size: 1rem; }
          .promo-content-without-title-container .promo-main-without-content .promo-col1 .promo-tag-cntr p {
            color: inherit; } }

@media only screen and (min-width: 992px) {
  .emp-bio-ipad {
    margin-left: 0.9375rem; }
  .promo-content-without-title-container {
    margin-top: 0rem; }
    .promo-content-without-title-container .promo-main-without-content {
      width: 60.25rem; }
      .promo-content-without-title-container .promo-main-without-content .promo-col1 {
        max-width: 45%; }
        .promo-content-without-title-container .promo-main-without-content .promo-col1 .promo-tag-cntr {
          margin-top: 3.125rem;
          font-size: 1.125rem; }
          .promo-content-without-title-container .promo-main-without-content .promo-col1 .promo-tag-cntr p {
            color: inherit; }
    .promo-content-without-title-container .promo-content-without-img {
      min-height: 32.8125rem; }
      .promo-content-without-title-container .promo-content-without-img img {
        width: 122%;
        margin-left: -10%; } }

@media only screen and (min-width: 1200px) {
  .emp-bio-ipad {
    margin-left: 0rem; }
  .promo-content-without-title-container .promo-main-without-content .promo-col1 {
    max-width: 55%; }
  .promo-content-without-title-container .promo-content-without-img img {
    width: 100%; }
  .promo-content-without-title-container .promo-content-without-img img {
    margin-left: 0%; } }

.event-detail-content-left-section {
  word-break: break-word;
  word-wrap: break-word; }
  .event-detail-content-left-section .fhl-richtext-wrapper {
    font-family: "OpenSans-Regular"; }
    .event-detail-content-left-section .fhl-richtext-wrapper p {
      line-height: 1.4rem;
      letter-spacing: 0; }
      .event-detail-content-left-section .fhl-richtext-wrapper p a {
        display: inline !important;
        color: inherit; }
    .event-detail-content-left-section .fhl-richtext-wrapper a {
      display: inline !important;
      color: inherit; }

.eventdetails-header-wrapper,
.eventdetails-header-para {
  font-family: "HarmoniaSansW05-Regular"; }
  .eventdetails-header-wrapper p,
  .eventdetails-header-para p {
    margin-bottom: 0; }
  .eventdetails-header-wrapper .eventdetails-header-date-text,
  .eventdetails-header-para .eventdetails-header-date-text {
    font-size: 0.875rem;
    color: inherit;
    letter-spacing: 0.145625rem;
    line-height: 1.125rem; }
    .eventdetails-header-wrapper .eventdetails-header-date-text p,
    .eventdetails-header-para .eventdetails-header-date-text p {
      margin-bottom: 0; }
  .eventdetails-header-wrapper .eventdetails-header-img,
  .eventdetails-header-para .eventdetails-header-img {
    height: 100%; }
    .eventdetails-header-wrapper .eventdetails-header-img img,
    .eventdetails-header-para .eventdetails-header-img img {
      margin: 1.5625rem 0 1.25rem;
      width: 100%; }
  .eventdetails-header-wrapper h1,
  .eventdetails-header-para h1 {
    font-size: 2.625rem;
    color: inherit;
    letter-spacing: 0;
    line-height: 3.15rem; }
  .eventdetails-header-wrapper .eventdetails-lead-para,
  .eventdetails-header-para .eventdetails-lead-para {
    font-size: 1.5rem;
    color: inherit;
    letter-spacing: 0;
    line-height: 2.1rem;
    margin-top: 2.625rem;
    margin-bottom: 0.625rem; }
    .eventdetails-header-wrapper .eventdetails-lead-para p,
    .eventdetails-header-para .eventdetails-lead-para p {
      color: inherit; }
      .eventdetails-header-wrapper .eventdetails-lead-para p a,
      .eventdetails-header-para .eventdetails-lead-para p a {
        color: inherit;
        text-decoration: underline; }
    .eventdetails-header-wrapper .eventdetails-lead-para p,
    .eventdetails-header-para .eventdetails-lead-para p {
      font-family: "HarmoniaSansW05-Regular";
      font-weight: normal;
      font-style: normal; }
      .eventdetails-header-wrapper .eventdetails-lead-para p em,
      .eventdetails-header-para .eventdetails-lead-para p em {
        font-family: "HarmoniaSansW05-Italic";
        font-weight: normal;
        font-style: italic; }
        .eventdetails-header-wrapper .eventdetails-lead-para p em strong,
        .eventdetails-header-para .eventdetails-lead-para p em strong {
          font-family: "HarmoniaSansW05-SemiboldItalic";
          font-weight: 300;
          font-style: italic; }
      .eventdetails-header-wrapper .eventdetails-lead-para p strong,
      .eventdetails-header-para .eventdetails-lead-para p strong {
        font-family: "HarmoniaSansW05-Semibold";
        font-weight: 600;
        font-style: normal; }
        .eventdetails-header-wrapper .eventdetails-lead-para p strong em,
        .eventdetails-header-para .eventdetails-lead-para p strong em {
          font-family: "HarmoniaSans-SemiboldIt";
          font-weight: 600;
          font-style: italic; }
      .eventdetails-header-wrapper .eventdetails-lead-para p a,
      .eventdetails-header-para .eventdetails-lead-para p a {
        color: inherit; }
  .eventdetails-header-wrapper .events-details-register-btn,
  .eventdetails-header-wrapper a.events-details-register-btn,
  .eventdetails-header-para .events-details-register-btn,
  .eventdetails-header-para a.events-details-register-btn {
    background-color: inherit;
    border-radius: 0.25rem;
    font-size: 1rem;
    color: inherit;
    letter-spacing: 0;
    text-align: center;
    width: 8.6875rem;
    line-height: 1.25rem;
    padding-top: 0.625rem;
    padding-bottom: 0.625rem; }
  .eventdetails-header-wrapper .events-details-register-btn a,
  .eventdetails-header-para .events-details-register-btn a {
    color: inherit; }

.eventdetails-header-para h2 {
  font-size: 1.5rem;
  color: inherit;
  letter-spacing: 0;
  line-height: 1.8rem;
  margin-bottom: 0.25rem; }

.eventdetails-header-para .eventdetails-header-para-content {
  font-size: 1.125rem;
  color: inherit;
  letter-spacing: 0;
  line-height: 1.575rem;
  margin: 0.625rem 0 0.5rem; }

.newspage-events-details-last-section {
  margin-bottom: 5rem; }

.news-events-detail-content-right-section {
  word-break: break-word;
  word-wrap: break-word; }
  .news-events-detail-content-right-section .aside-image-header-content-link-container img {
    min-height: 7.5rem;
    min-width: 14.0625rem;
    width: 100%; }
  .news-events-detail-content-right-section .aside-image-header-content-link-container .mb-3 {
    font-size: 0.875rem;
    color: inherit;
    letter-spacing: 0;
    line-height: 1.3125rem; }
  .news-events-detail-content-right-section .aside-image-header-content-link-container .aside-image-header-content-anchor-link a {
    font-family: "HarmoniaSansW05-Regular"; }
  .news-events-detail-content-right-section .fhl-button-content-container {
    padding: 0.25rem 0.625rem; }
  .news-events-detail-content-right-section h1 {
    font-size: 1.125rem;
    color: inherit;
    letter-spacing: 0;
    line-height: 1.35rem;
    font-family: "HarmoniaSansW05-Regular";
    font-weight: normal;
    margin-bottom: 0.9375rem; }
  .news-events-detail-content-right-section .si-side-article-content-container {
    margin-top: 2.5rem; }
    .news-events-detail-content-right-section .si-side-article-content-container .si-card-layout-anchor-btn {
      margin-bottom: 0; }
    .news-events-detail-content-right-section .si-side-article-content-container .side-article-title {
      margin-bottom: 0.625rem; }

@media only screen and (max-width: 767px) {
  .jne-mob .fhl-button-content-container {
    margin-top: 0rem !important; }
  .event-detail-content-left-section .fhl-medium-titlebar-wrapper h2 {
    text-align: left; }
  .news-events-detail-content-right-section {
    margin-bottom: 39px; }
    .news-events-detail-content-right-section a.left-arrow-icon {
      width: auto !important; }
    .news-events-detail-content-right-section .fhl-button-content-container {
      width: 14.375rem;
      margin-top: 5rem; }
  .eventdetails-header-wrapper h1,
  .eventdetails-header-para h1 {
    font-size: 2rem;
    line-height: 2.5rem; } }

@media only screen and (min-width: 768px) {
  .news-events-detail-content-right-section {
    min-width: 14.0625rem; }
    .news-events-detail-content-right-section .fhl-button-content-container {
      margin-top: 0; } }

@media only screen and (min-width: 992px) {
  .event-detail-content-left-section .fhl-richtext-wrapper {
    width: 100%; }
  .event-detail-content-left-section .eventdetails-header-date-text {
    margin-top: 0.8125rem; }
  .news-events-detail-content-right-section {
    margin-top: 1rem; }
    .news-events-detail-content-right-section .aside-image-header-content-link-container img {
      min-width: 14.0625rem; }
  .news-article-right-side-top-section {
    margin-top: 6.25rem; }
  .events-mapping {
    margin-top: -1.25rem; } }

.fhlb-table-styles .table-top-border-green {
  border-bottom: 1px solid #00833c; }

.fhlb-table-styles .table-row-border-gray {
  border-bottom: 1px solid #d8d8d8; }

.table-bottom-space {
  margin-bottom: 5.9375rem; }

.table-design-2 table th,
.table-design-2 table td {
  text-align: center !important; }

.table-design-default table td {
  text-align: center; }

.table-design-default table thead tr th {
  color: #002a3a; }

.fhl-table-richtext-wrapper table {
  width: 100% !important; }
  .fhl-table-richtext-wrapper table th,
  .fhl-table-richtext-wrapper table td {
    border-bottom: 1px solid #d8d8d8;
    padding: 0.625rem 0px; }
  .fhl-table-richtext-wrapper table td {
    letter-spacing: 0.106875rem;
    color: #58585a;
    font-size: 0.75rem !important;
    font-family: "OpenSans-Regular";
    line-height: 1.05rem; }
  .fhl-table-richtext-wrapper table th {
    font-family: "HarmoniaSansW05-Semibold";
    font-weight: 600;
    font-style: normal;
    font-size: 0.875rem;
    color: #002a3a;
    letter-spacing: 0.125rem;
    text-align: inherit;
    margin: 0px auto; }

.fhl-table-richtext-wrapper .table-tagline {
  width: 100%; }

@media only screen and (max-width: 767px) {
  .fhlb-table-styles {
    width: 100% !important;
    overflow-x: auto;
    margin-left: 0px !important; }
    .fhlb-table-styles .fhl-table-richtext-wrapper table {
      width: 600px !important; }
    .fhlb-table-styles .table-tagline {
      width: 600px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .fhlb-table-styles {
    margin-left: 0.5rem !important; }
  .table-design-2 {
    margin-left: 0px !important; } }

.fhlb-calendar-wrapper {
  min-width: 10.3125rem; }
  .fhlb-calendar-wrapper .mydp {
    border: 0px !important; }
    .fhlb-calendar-wrapper .mydp .selection {
      border: 1px solid #58585a; }
    .fhlb-calendar-wrapper .mydp .selector:focus {
      border: 0px; }
    .fhlb-calendar-wrapper .mydp .monthlabel,
    .fhlb-calendar-wrapper .mydp .weekdaytitle,
    .fhlb-calendar-wrapper .mydp .headertodaybtn,
    .fhlb-calendar-wrapper .mydp .yearlabel,
    .fhlb-calendar-wrapper .mydp .datevalue,
    .fhlb-calendar-wrapper .mydp .yearvalue,
    .fhlb-calendar-wrapper .mydp .selection {
      font-family: "HarmoniaSansW05-Regular" !important;
      font-weight: normal;
      font-style: normal; }
    .fhlb-calendar-wrapper .mydp .yearcell .yearvalue,
    .fhlb-calendar-wrapper .mydp .monthcell .monthvalue,
    .fhlb-calendar-wrapper .mydp .daycell .datevalue span {
      font-family: "OpenSans-Semibold" !important;
      font-weight: 300;
      font-style: normal;
      font-size: 11px;
      color: #58585a;
      letter-spacing: 0; }
    .fhlb-calendar-wrapper .mydp .invaliddate {
      background-color: transparent; }
    .fhlb-calendar-wrapper .mydp .header {
      height: 40px;
      background-color: #00833c;
      border-radius: 6px 6px 0 0; }
    .fhlb-calendar-wrapper .mydp .caltable {
      height: calc(100% - 40px);
      border-radius: 0 0 6px 6px; }
    .fhlb-calendar-wrapper .mydp .headerbtn:focus,
    .fhlb-calendar-wrapper .mydp .monthlabel:focus,
    .fhlb-calendar-wrapper .mydp .yearchangebtn:focus,
    .fhlb-calendar-wrapper .mydp .yearlabel:focus {
      outline: 1px;
      color: #19ea9f !important; }
    .fhlb-calendar-wrapper .mydp button.headerbtn.mydpicon.icon-mydpleft.headerbtnenabled:focus,
    .fhlb-calendar-wrapper .mydp button.headerbtn.mydpicon.icon-mydpright.headerbtnenabled:focus,
    .fhlb-calendar-wrapper .mydp button.yearchangebtn.mydpicon.icon-mydpup.yearchangebtnenabled:focus,
    .fhlb-calendar-wrapper .mydp button.yearchangebtn.mydpicon.icon-mydpdown.yearchangebtnenabled:focus {
      border-right: 1px solid;
      border-bottom: 1px solid; }
    .fhlb-calendar-wrapper .mydp .selectorarrow:after {
      border-bottom-color: #00833c; }
    .fhlb-calendar-wrapper .mydp .caltable,
    .fhlb-calendar-wrapper .mydp .daycell,
    .fhlb-calendar-wrapper .mydp .monthcell,
    .fhlb-calendar-wrapper .mydp .monthtable,
    .fhlb-calendar-wrapper .mydp .weekdaytitle,
    .fhlb-calendar-wrapper .mydp .yearcell,
    .fhlb-calendar-wrapper .mydp .yeartable {
      color: #002a3a; }
    .fhlb-calendar-wrapper .mydp .headerbtncell,
    .fhlb-calendar-wrapper .mydp .yearchangebtncell {
      background: #00833c; }
    .fhlb-calendar-wrapper .mydp .yearchangebtncell {
      padding: 0px !important;
      height: 30px; }
    .fhlb-calendar-wrapper .mydp .headerbtn,
    .fhlb-calendar-wrapper .mydp .headerlabelbtn,
    .fhlb-calendar-wrapper .mydp .yearchangebtn {
      background: #00833c; }
    .fhlb-calendar-wrapper .mydp .btnpicker {
      background: transparent; }
    .fhlb-calendar-wrapper .mydp .daycell .datevalue {
      padding-top: 10px;
      padding-bottom: 14px; }
    .fhlb-calendar-wrapper .mydp .daycell,
    .fhlb-calendar-wrapper .mydp .monthcell,
    .fhlb-calendar-wrapper .mydp .weekdaytitle,
    .fhlb-calendar-wrapper .mydp .yearcell {
      padding: 0px; }
    .fhlb-calendar-wrapper .mydp .monthcell {
      vertical-align: middle;
      overflow: visible; }
    .fhlb-calendar-wrapper .mydp th.weekdaytitle {
      padding: 7px 0px !important;
      font-family: "OpenSans-Semibold" !important;
      font-weight: 300;
      font-style: normal;
      font-size: 14px;
      color: #00833c;
      letter-spacing: 0;
      line-height: 21px; }
    .fhlb-calendar-wrapper .mydp .yearvalue {
      padding: 6px 0px 4px; }
    .fhlb-calendar-wrapper .mydp .yearcell.disabled .yearvalue,
    .fhlb-calendar-wrapper .mydp .yearcell .yearvalue {
      padding: 6px 0px 4px;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center; }
    .fhlb-calendar-wrapper .mydp .yearcell:hover .yearvalue {
      color: white; }
    .fhlb-calendar-wrapper .mydp .yearcell.disabled:hover .yearvalue {
      color: white; }
    .fhlb-calendar-wrapper .mydp td.yearcell.tablesingleyear.disabled:hover .yearvalue,
    .fhlb-calendar-wrapper .mydp td.monthcell.tablesinglemonth.disabled:hover .monthvalue {
      color: #58585a !important; }
    .fhlb-calendar-wrapper .mydp .monthcell:focus,
    .fhlb-calendar-wrapper .mydp .yearcell:focus {
      outline: #00833c solid 0px !important;
      background-color: #00833c; }
    .fhlb-calendar-wrapper .mydp .headermonthtxt .monthlabel,
    .fhlb-calendar-wrapper .mydp .headeryeartxt .yearlabel {
      font-size: 18px;
      color: #ffffff;
      letter-spacing: 0;
      line-height: 21.6px; }
    .fhlb-calendar-wrapper .mydp .selectedday .datevalue {
      border-radius: 50%;
      width: 100% !important;
      height: 100% !important;
      color: #fff;
      display: inline-block;
      background-color: #00833c;
      font-family: "OpenSans-Semibold" !important;
      font-weight: 300;
      font-style: normal;
      font-size: 11px; }
      .fhlb-calendar-wrapper .mydp .selectedday .datevalue span {
        color: #ffffff;
        float: left;
        margin-top: 3px;
        width: 100%;
        text-align: center;
        margin-bottom: -2px; }
    .fhlb-calendar-wrapper .mydp .selectedmonth .monthvalue,
    .fhlb-calendar-wrapper .mydp .selectedyear .yearvalue {
      background-color: #00833c;
      width: 100%;
      height: 100%;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "OpenSans-Semibold" !important;
      font-weight: 300;
      font-style: normal;
      font-size: 11px; }
    .fhlb-calendar-wrapper .mydp .icon-mydpdown,
    .fhlb-calendar-wrapper .mydp .icon-mydpleft,
    .fhlb-calendar-wrapper .mydp .icon-mydpright,
    .fhlb-calendar-wrapper .mydp .icon-mydpup,
    .fhlb-calendar-wrapper .mydp .headeryeartxt .yearlabel,
    .fhlb-calendar-wrapper .mydp .headermonthtxt .monthlabel {
      color: white; }
    .fhlb-calendar-wrapper .mydp .currmonth,
    .fhlb-calendar-wrapper .mydp .weekdaytitle {
      background-color: white;
      border-radius: 50%; }
    .fhlb-calendar-wrapper .mydp .tablesingleday:hover,
    .fhlb-calendar-wrapper .mydp .tablesinglemonth:hover,
    .fhlb-calendar-wrapper .mydp .tablesingleyear:hover,
    .fhlb-calendar-wrapper .mydp .tablesingleday:focus,
    .fhlb-calendar-wrapper .mydp .tablesinglemonth:focus,
    .fhlb-calendar-wrapper .mydp .tablesingleyear:focus {
      background-color: #00833c; }
      .fhlb-calendar-wrapper .mydp .tablesingleday:hover div,
      .fhlb-calendar-wrapper .mydp .tablesinglemonth:hover div,
      .fhlb-calendar-wrapper .mydp .tablesingleyear:hover div,
      .fhlb-calendar-wrapper .mydp .tablesingleday:focus div,
      .fhlb-calendar-wrapper .mydp .tablesinglemonth:focus div,
      .fhlb-calendar-wrapper .mydp .tablesingleyear:focus div {
        color: white; }
    .fhlb-calendar-wrapper .mydp .daycell:focus,
    .fhlb-calendar-wrapper .mydp .daycell:focus div {
      outline: 0;
      background-color: #00833c;
      border-radius: 50%;
      color: white; }
      .fhlb-calendar-wrapper .mydp .daycell:focus span,
      .fhlb-calendar-wrapper .mydp .daycell:focus div span {
        color: white; }
    .fhlb-calendar-wrapper .mydp .tablesingleday:hover span {
      color: #ffffff; }
    .fhlb-calendar-wrapper .mydp .selector {
      min-width: 17.8125rem !important;
      box-shadow: 0 2px 23px 0px #c4c3c3;
      border: 0px;
      z-index: 1; }
    .fhlb-calendar-wrapper .mydp .icon-mydpcalendar:before,
    .fhlb-calendar-wrapper .mydp .icon-mydptoday:before,
    .fhlb-calendar-wrapper .mydp .icon-mydpdown:before,
    .fhlb-calendar-wrapper .mydp .icon-mydpup:before {
      display: none; }
    .fhlb-calendar-wrapper .mydp .icon-mydpcalendar {
      width: 20px;
      height: 24px;
      background-image: url("../assets/images/calendar_Icon.svg");
      background-repeat: no-repeat;
      background-size: 100%;
      background-color: transparent;
      display: inline-block; }
    .fhlb-calendar-wrapper .mydp .monthlabel:hover,
    .fhlb-calendar-wrapper .mydp .yearlabel:hover {
      color: #fff; }
    .fhlb-calendar-wrapper .mydp .headerbtnenabled:hover,
    .fhlb-calendar-wrapper .mydp .yearchangebtnenabled:hover {
      color: #68a51d; }
    .fhlb-calendar-wrapper .mydp .btnpicker {
      margin-right: 0.4375rem; }
    .fhlb-calendar-wrapper .mydp .btnpickerenabled:hover {
      background-color: transparent; }
    .fhlb-calendar-wrapper .mydp .btnpicker:focus {
      background-color: transparent; }
      .fhlb-calendar-wrapper .mydp .btnpicker:focus span {
        box-shadow: 1px 1px 4px #454545; }
    .fhlb-calendar-wrapper .mydp .btnclear {
      display: none; }
    .fhlb-calendar-wrapper .mydp .headerbtn,
    .fhlb-calendar-wrapper .mydp .yearchangebtn {
      border: solid white;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 2px;
      width: 12px;
      height: 12px; }
    .fhlb-calendar-wrapper .mydp .icon-mydpup {
      transform: rotate(225deg);
      -webkit-transform: rotate(225deg);
      margin-top: 10px; }
    .fhlb-calendar-wrapper .mydp .icon-mydpdown {
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      margin-top: 5px; }
    .fhlb-calendar-wrapper .mydp .icon-mydpleft {
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
      margin-left: 10px; }
    .fhlb-calendar-wrapper .mydp .icon-mydpright {
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      margin-right: 10px; }
    .fhlb-calendar-wrapper .mydp .icon-mydpleft:before,
    .fhlb-calendar-wrapper .mydp .icon-mydpright:before {
      display: none; }
    .fhlb-calendar-wrapper .mydp .datevalue.prevmonth {
      opacity: 0.5; }
    .fhlb-calendar-wrapper .mydp .datevalue.nextmonth {
      opacity: 0.5; }
    .fhlb-calendar-wrapper .mydp .disabled {
      opacity: 0.5;
      font-family: "OpenSans-Semibold" !important;
      font-weight: 300;
      font-style: normal;
      font-size: 11px;
      color: #58585a !important;
      letter-spacing: 0;
      background-color: transparent !important; }
    .fhlb-calendar-wrapper .mydp span.dimday {
      opacity: 1 !important; }

/*************************************************
 FHL Application Angular Widgets Templates
 ************************************************/
.narrow-list-wrapper {
  max-width: 225px; }
  .narrow-list-wrapper .result-count {
    font-family: "Opensans-Regular";
    font-size: 0.75rem;
    color: #58585A;
    letter-spacing: 0;
    line-height: 0.875rem;
    padding-bottom: 1.125rem; }
  .narrow-list-wrapper input[type="checkbox"]:checked {
    background-color: #00833c; }
  .narrow-list-wrapper h5,
  .narrow-list-wrapper h3 {
    font-size: 1.125rem !important;
    color: #002a3a;
    line-height: 1.575rem; }
    .narrow-list-wrapper h5 button.clearall,
    .narrow-list-wrapper h3 button.clearall {
      font-family: "Opensans-Regular";
      font-size: 0.8125rem;
      color: #58585a;
      letter-spacing: 0.0625rem;
      float: right;
      cursor: pointer;
      background: none;
      border: 0;
      padding: 0;
      outline: inherit;
      margin-top: 0.125rem; }
    .narrow-list-wrapper h5 button.clearall:focus,
    .narrow-list-wrapper h3 button.clearall:focus {
      outline: auto;
      box-shadow: 0 0 0 0.1rem #2b9be0 !important; }
  .narrow-list-wrapper hr {
    border-bottom: 0.0625rem solid #00833c;
    margin: 0; }
  .narrow-list-wrapper .accordion .card-header-button .card-title-content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    font-family: "HarmoniaSansW05-Regular";
    color: #58585a;
    letter-spacing: 0.125rem;
    font-size: 0.6875rem; }
  .narrow-list-wrapper .accordion .card-header-button button {
    background: none;
    border: 0;
    padding: 0; }
  .narrow-list-wrapper .accordion .card-header-button button:focus,
  .narrow-list-wrapper .accordion .card-header-button .card-title-content:focus {
    outline: none; }
  .narrow-list-wrapper .accordion .card-header-button button:focus > .card-title-content {
    box-shadow: 0 0 0 0.1rem #2b9be0 !important; }
  .narrow-list-wrapper .accordion .card-header {
    width: 100%;
    display: flex;
    height: 35px;
    text-align: left;
    justify-content: space-between;
    padding: 0 10px;
    line-height: 35px;
    color: #454545;
    background-color: transparent;
    border: 0px; }
    .narrow-list-wrapper .accordion .card-header a,
    .narrow-list-wrapper .accordion .card-header button,
    .narrow-list-wrapper .accordion .card-header .card-title-content {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0;
      font-family: "HarmoniaSansW05-Regular";
      color: #58585a;
      letter-spacing: 0.125rem;
      font-size: 0.6875rem; }
    .narrow-list-wrapper .accordion .card-header button {
      background: none;
      border: 0;
      padding: 0; }
    .narrow-list-wrapper .accordion .card-header button:focus,
    .narrow-list-wrapper .accordion .card-header .card-title-content:focus {
      outline: none; }
    .narrow-list-wrapper .accordion .card-header button:focus > .card-title-content {
      box-shadow: 0 0 0 0.1rem #2b9be0 !important; }
    .narrow-list-wrapper .accordion .card-header a:hover {
      text-decoration: none !important; }
  .narrow-list-wrapper .accordion-title-bar button.categorytopic {
    font-size: 0.6875rem;
    color: #58585a;
    letter-spacing: 0.125rem;
    text-align: left;
    line-height: 1.2rem;
    display: block;
    width: 100%;
    padding: 0.625rem;
    text-transform: uppercase; }
    .narrow-list-wrapper .accordion-title-bar button.categorytopic i {
      float: right; }
  .narrow-list-wrapper .accordion-title-bar button:focus {
    outline: none;
    border: 0; }
  .narrow-list-wrapper .accordion-title-bar i.add {
    font-size: 1rem; }
  .narrow-list-wrapper .accordion-content-box .card-body {
    padding: 0.625rem; }
    .narrow-list-wrapper .accordion-content-box .card-body .filter-list {
      display: flex; }
      .narrow-list-wrapper .accordion-content-box .card-body .filter-list label {
        display: flex;
        position: relative;
        margin-bottom: 0.625rem;
        cursor: pointer;
        /* When the checkbox is checked, add a green background */
        /* When the radio is clicked, add a green background */
        /* Create the checkmark/indicator (hidden when not checked) */
        /* Create the checkmarkradio/indicator (hidden when not clicked) */
        /* Show the checkmark when checked */
        /* Show the checkmark when checked */
        /* Style the checkmark/indicator */
        /* Style the checkmarkradio/indicator */ }
        .narrow-list-wrapper .accordion-content-box .card-body .filter-list label input {
          opacity: 0;
          cursor: pointer;
          margin-right: 0.625rem; }
        .narrow-list-wrapper .accordion-content-box .card-body .filter-list label span.filter-item:active,
        .narrow-list-wrapper .accordion-content-box .card-body .filter-list label span.filter-item:hover,
        .narrow-list-wrapper .accordion-content-box .card-body .filter-list label span.filter-item:focus,
        .narrow-list-wrapper .accordion-content-box .card-body .filter-list label p.filter-item:active,
        .narrow-list-wrapper .accordion-content-box .card-body .filter-list label p.filter-item:hover,
        .narrow-list-wrapper .accordion-content-box .card-body .filter-list label p.filter-item:focus {
          outline: none !important;
          box-shadow: none !important; }
        .narrow-list-wrapper .accordion-content-box .card-body .filter-list label input:focus ~ span.filter-item,
        .narrow-list-wrapper .accordion-content-box .card-body .filter-list label input:focus ~ p.filter-item {
          outline: auto;
          box-shadow: auto; }
        .narrow-list-wrapper .accordion-content-box .card-body .filter-list label .filter-item {
          display: inline;
          font-family: "Opensans-Regular";
          font-size: 0.75rem;
          color: #58585a;
          line-height: 0.875rem; }
        .narrow-list-wrapper .accordion-content-box .card-body .filter-list label .checkmark {
          position: absolute;
          height: 0.8125rem;
          width: 0.8125rem;
          z-index: 1;
          border: 0.03125rem solid #00833c;
          margin-right: 10px;
          left: 0; }
        .narrow-list-wrapper .accordion-content-box .card-body .filter-list label .checkmarkradio {
          z-index: 1;
          border: 0.03125rem solid #00833c;
          position: absolute;
          height: 0.9375rem;
          width: 0.9375rem;
          border-radius: 0.5rem;
          left: 0; }
        .narrow-list-wrapper .accordion-content-box .card-body .filter-list label input:checked ~ .checkmark {
          background-color: #00833c; }
        .narrow-list-wrapper .accordion-content-box .card-body .filter-list label input:checked ~ .checkmarkradio {
          background-color: #00833c; }
        .narrow-list-wrapper .accordion-content-box .card-body .filter-list label .checkmark:after,
        .narrow-list-wrapper .accordion-content-box .card-body .filter-list label .checkmarkradio:after {
          content: "";
          position: absolute;
          display: none; }
        .narrow-list-wrapper .accordion-content-box .card-body .filter-list label input:checked ~ .checkmark:after,
        .narrow-list-wrapper .accordion-content-box .card-body .filter-list label input:checked ~ .checkmarkradio:after {
          display: block; }
        .narrow-list-wrapper .accordion-content-box .card-body .filter-list label input:checked ~ .checkmarkradio:after {
          display: block; }
        .narrow-list-wrapper .accordion-content-box .card-body .filter-list label .checkmark:after {
          left: 2px;
          top: -1px;
          width: 0.4375rem;
          height: 0.625rem;
          border: 0.0625rem solid #fff;
          border-width: 0 0.125rem 0.125rem 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg); }
        .narrow-list-wrapper .accordion-content-box .card-body .filter-list label .checkmarkradio:after {
          left: 3px;
          top: 2px;
          width: 0.5625rem;
          height: 0.5625rem;
          border-radius: 50%;
          background-color: #fff; }

@media only screen and (max-width: 1366px) {
  .narrow-list-wrapper .accordion-content-box .card-body .filter-list label .checkmarkradio:after {
    left: 2px; } }

.advances-promo-banner-main a.promo-tag-link {
  cursor: text; }
  .advances-promo-banner-main a.promo-tag-link i {
    display: none; }

.adv-havequestion-cntr .fhl-button-content-container,
.adv-havequestion-cntr a {
  width: 225px;
  float: left;
  margin-left: 8px; }

.advance-results-wrapper {
  min-height: 56.25rem;
  overflow: visible;
  /* Hide the browser's default checkbox */ }
  .advance-results-wrapper .comparebutton,
  .advance-results-wrapper .comparebutton:disabled {
    font-size: 0.9375rem;
    text-align: center;
    border-radius: 0.25rem;
    line-height: 1.125rem;
    width: 8.125rem;
    height: 1.875rem; }
  .advance-results-wrapper .comparebutton:disabled {
    background-color: #767676 !important;
    color: #fff !important;
    cursor: not-allowed;
    font-size: 0.9375rem; }
  .advance-results-wrapper .advance-page-max-error .alert {
    margin-bottom: 0px !important;
    margin-left: -15px; }
    .advance-results-wrapper .advance-page-max-error .alert .alert-labeled-row span {
      padding: 10px !important; }
    .advance-results-wrapper .advance-page-max-error .alert .alert-labeled-row p {
      padding: 0px 0px 0px 5px !important; }
  .advance-results-wrapper .errormsg-wrapper .alert {
    border-radius: 0; }
  .advance-results-wrapper .errormsg-wrapper .alert-labeled {
    padding: 0px; }
  .advance-results-wrapper .errormsg-wrapper .alert-info {
    border-color: #9f1c33; }
  .advance-results-wrapper .errormsg-wrapper .alert-labeled-row {
    display: table-row;
    padding: 0rem; }
    .advance-results-wrapper .errormsg-wrapper .alert-labeled-row .alert-labelled-cell {
      padding: 1.25rem;
      display: table-cell;
      vertical-align: middle; }
    .advance-results-wrapper .errormsg-wrapper .alert-labeled-row .alert-label {
      vertical-align: middle;
      background-color: #9f1c33;
      width: auto;
      padding: 0.625rem, 0.9375rem;
      min-height: 4.6875rem;
      font-size: 1.1em; }
      .advance-results-wrapper .errormsg-wrapper .alert-labeled-row .alert-label i {
        color: #fff;
        margin-top: 0.1875rem; }
    .advance-results-wrapper .errormsg-wrapper .alert-labeled-row .alert-body {
      align-items: center;
      font-family: "Opensans-Regular";
      font-size: 0.875rem;
      color: #9f1c33;
      line-height: 1.3125rem;
      padding: 1.5rem; }
  .advance-results-wrapper li {
    list-style-type: none; }
  .advance-results-wrapper .advance-result-card-box h4,
  .advance-results-wrapper .advance-result-card-box legend {
    font-family: "HarmoniaSansW05-Semibold";
    font-weight: 600;
    font-style: normal;
    font-size: 1.3125rem;
    color: #004987;
    line-height: 1.575rem; }
    .advance-results-wrapper .advance-result-card-box h4 a,
    .advance-results-wrapper .advance-result-card-box legend a {
      color: #004987 !important; }
  .advance-results-wrapper .advance-result-card-box .mobileView-advance-remove {
    display: none; }
  .advance-results-wrapper .advance-result-card-box .add-to-compare-wrapper {
    width: 100%;
    overflow: visible; }
    .advance-results-wrapper .advance-result-card-box .add-to-compare-wrapper label {
      position: relative;
      cursor: pointer;
      width: 9.375rem;
      float: left;
      /* input[type="checkbox"]:focus + .addtocompare {
                outline: auto;
                box-shadow: 0 0 0 0.1rem green !important;
            }

            input[type="checkbox"]:focus,
      .addtocompare:focus {
        outline: none;
      }
      input[type="checkbox"]:focus ~ .addtocompare
        {
          outline: auto;
        box-shadow: 0 0 0 0.1rem red !important;
    } */
      /* When the checkbox is checked, add a blue background */
      /* Create the checkmark/indicator (hidden when not checked) */
      /* Show the checkmark when checked */
      /* Style the checkmark/indicator */ }
      .advance-results-wrapper .advance-result-card-box .add-to-compare-wrapper label .addtocompare {
        font-family: "Opensans-Regular";
        font-size: 0.625rem;
        color: #454545;
        letter-spacing: 0.0625rem;
        line-height: 1rem; }
      .advance-results-wrapper .advance-result-card-box .add-to-compare-wrapper label .checkmark {
        position: absolute;
        height: 0.8125rem;
        width: 0.8125rem;
        z-index: -1;
        border: 0.03125rem solid #004987;
        border-radius: 0.125rem;
        left: 0; }
      .advance-results-wrapper .advance-result-card-box .add-to-compare-wrapper label input[type="checkbox"] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        z-index: 0; }
      .advance-results-wrapper .advance-result-card-box .add-to-compare-wrapper label .addtocompare:active,
      .advance-results-wrapper .advance-result-card-box .add-to-compare-wrapper label .addtocompare:hover,
      .advance-results-wrapper .advance-result-card-box .add-to-compare-wrapper label .addtocompare:focus {
        outline: none !important;
        box-shadow: none !important; }
      .advance-results-wrapper .advance-result-card-box .add-to-compare-wrapper label input:focus ~ .addtocompare {
        outline: auto;
        box-shadow: auto; }
      .advance-results-wrapper .advance-result-card-box .add-to-compare-wrapper label input:checked ~ .checkmark {
        background-color: #004987;
        border: 0.0625rem solid #004987;
        border-radius: 0.125rem; }
      .advance-results-wrapper .advance-result-card-box .add-to-compare-wrapper label .checkmark:after {
        content: "";
        position: absolute;
        display: none;
        border-radius: 0.125rem; }
      .advance-results-wrapper .advance-result-card-box .add-to-compare-wrapper label input:checked ~ .checkmark:after {
        display: block; }
      .advance-results-wrapper .advance-result-card-box .add-to-compare-wrapper label .checkmark:after {
        left: 2px;
        top: -1px;
        width: 7px;
        height: 0.625rem;
        border: 0.0625rem solid #fff;
        border-width: 0 0.125rem 0.125rem 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg); }

@media only screen and (max-width: 767px) {
  .advance-media-wrapper {
    max-width: 100%; }
    .advance-media-wrapper .row .col-sm-3 {
      max-width: 100%;
      flex: 0 0 100%; }
      .advance-media-wrapper .row .col-sm-3 .narrow-list-wrapper {
        max-width: 100%;
        padding: 0px 0.9375rem;
        margin: -0.625rem 0px 2.5rem; }
    .advance-media-wrapper .row .col-sm-9 {
      flex: 0 0 100%;
      max-width: 100%; }
      .advance-media-wrapper .row .col-sm-9 .advance-results-wrapper {
        padding: 0px 0.9375rem; }
        .advance-media-wrapper .row .col-sm-9 .advance-results-wrapper .comparebutton {
          margin-top: 0rem; }
        .advance-media-wrapper .row .col-sm-9 .advance-results-wrapper .col-sm-6 {
          min-width: 72%; }
  .adv-havequestion-cntr a.left-arrow-icon,
  .adv-havequestion-cntr .fhl-button-content-container {
    margin-left: 0px; }
    .adv-havequestion-cntr a.left-arrow-icon .left-section,
    .adv-havequestion-cntr .fhl-button-content-container .left-section {
      width: 71%; }
    .adv-havequestion-cntr a.left-arrow-icon .right-section i,
    .adv-havequestion-cntr .fhl-button-content-container .right-section i {
      margin-left: 0; } }

@media only screen and (min-width: 768px) {
  .narrow-list-wrapper {
    margin-top: 0rem; }
  .advance-media-wrapper .row .col-sm-3 {
    max-width: 35%;
    flex: auto; }
    .advance-media-wrapper .row .col-sm-3 .narrow-list-wrapper {
      width: 225px;
      margin: 0px; }
  .advance-media-wrapper .row .col-sm-9 {
    max-width: 65%;
    flex: auto; }
    .advance-media-wrapper .row .col-sm-9 .advance-results-wrapper .advance-result-card-box h4,
    .advance-media-wrapper .row .col-sm-9 .advance-results-wrapper .advance-result-card-box legend {
      width: 100%; }
    .advance-media-wrapper .row .col-sm-9 .advance-results-wrapper .comparebutton {
      margin-top: 0rem; }
    .advance-media-wrapper .row .col-sm-9 .advance-results-wrapper .col-sm-6 {
      min-width: 50%; }
  .advance-page-top-header .fhl-small-titlebar-wrapper {
    margin-left: -2.1875rem;
    margin-bottom: 2.1875rem; }
  .adv-havequestion-cntr .fhl-button-content-container,
  .adv-havequestion-cntr a {
    margin-left: -1.0625rem; } }

@media only screen and (min-width: 992px) {
  .advance_top_row .col-md-9 {
    display: block; }
  .adv-havequestion-cntr a.left-arrow-icon {
    margin: 10px 0px -10px -5px; }
  .advance-media-wrapper .row .col-sm-3 {
    max-width: 25%; }
    .advance-media-wrapper .row .col-sm-3 .narrow-list-wrapper {
      width: 220px;
      margin: 0px; }
  .advance-media-wrapper .row .col-sm-9 {
    max-width: 75%; }
  .advance-page-top-header .fhl-small-titlebar-wrapper {
    margin-left: -1.25rem; } }

@media only screen and (min-width: 1200px) {
  .adv-havequestion-cntr a.left-arrow-icon {
    margin: 10px 0px -20px 0px; }
  .advance-page-top-header .fhl-small-titlebar-wrapper {
    padding-left: 30px; } }

table.compare-advance-table {
  width: 100%; }
  table.compare-advance-table th {
    padding: 0.625rem 0.9375rem;
    border-bottom: 1px solid #e5e5e5;
    vertical-align: top; }
  table.compare-advance-table td {
    border-bottom: 1px solid #e5e5e5;
    padding: 0 0.9375rem; }
  table.compare-advance-table tr:last-child td {
    border: 0px; }

.compare-advances-products-wrapper {
  text-align: left; }
  .compare-advances-products-wrapper .greyhorizontalline {
    border-bottom: 1px solid #e5e5e5;
    margin: 0.625rem 0;
    border-top: 0px;
    width: 100%; }
  .compare-advances-products-wrapper .product-top-title {
    font-family: "HarmoniaSansW05-Semibold";
    font-weight: 600;
    font-style: normal;
    font-size: 1.125rem;
    color: #00833c;
    line-height: 1.35rem;
    justify-content: flex-start;
    align-items: self-start;
    max-width: 190px;
    flex-direction: row-reverse; }
    .compare-advances-products-wrapper .product-top-title .removeCompareList {
      color: transparent;
      cursor: pointer;
      background: none;
      border: none;
      padding: 0;
      outline: inherit;
      margin-right: 0rem;
      margin-left: 0.3125rem;
      position: relative;
      width: 30px;
      height: 22px;
      font-size: 2px; }
      .compare-advances-products-wrapper .product-top-title .removeCompareList i {
        color: #747476;
        font-size: 1.375rem !important;
        position: absolute;
        left: 0px;
        top: 0px; }
    .compare-advances-products-wrapper .product-top-title .removeCompareList:focus {
      box-shadow: 0 0 0 0.1rem #2b9be0 !important; }
  .compare-advances-products-wrapper .product-left-title {
    padding-left: 0px !important;
    padding-right: 0px !important;
    vertical-align: inherit; }
    .compare-advances-products-wrapper .product-left-title h3 {
      font-family: "OpenSans-Semibold";
      font-weight: 300;
      font-style: normal;
      font-size: 1rem;
      color: #00833c;
      line-height: 1.3125rem;
      padding: 0.9375rem 0.9375rem 0.9375rem 0px;
      min-width: 200px; }
  .compare-advances-products-wrapper ul.product-list-desc {
    margin: 0;
    padding: 0;
    min-width: 6.875rem; }
    .compare-advances-products-wrapper ul.product-list-desc li {
      font-family: "Opensans-Regular";
      font-size: 0.75rem;
      color: #002a3a;
      list-style-type: disc; }
  .compare-advances-products-wrapper .product-desc {
    font-family: "Opensans-Regular";
    font-size: 0.75rem;
    color: #002a3a; }
  .compare-advances-products-wrapper .product-learnMore-btn {
    width: 8rem;
    border-radius: 0.25rem !important;
    font-weight: normal;
    font-style: normal;
    font-family: "HarmoniaSansW05-Regular";
    font-size: 1rem; }

@media only screen and (min-width: 320px) {
  table.compare-advance-table th,
  table.compare-advance-table td {
    min-width: 250px;
    max-width: 250px; }
  .compare-advances-products-wrapper .product-learnMore-btn {
    margin-bottom: 0px !important; } }

@media only screen and (min-width: 768px) {
  .compare-advances-products-wrapper {
    width: 100%; }
    .compare-advances-products-wrapper .product-desc {
      margin-right: 0.0625rem; } }

@media only screen and (min-width: 992px) {
  table.compare-advance-table th,
  table.compare-advance-table td {
    min-width: 150px;
    max-width: 150px; } }

.classic-advance-promo-wrapper {
  flex-direction: column;
  overflow: visible;
  align-items: flex-start;
  min-height: 32.8125rem;
  justify-content: center; }
  .classic-advance-promo-wrapper h1 {
    font-size: 2.625rem;
    color: #00833c;
    line-height: 3.15rem; }
  .classic-advance-promo-wrapper p {
    font-size: 1.125rem;
    color: #002a3a;
    line-height: 1.575rem; }
  .classic-advance-promo-wrapper h1,
  .classic-advance-promo-wrapper p {
    margin-bottom: 0.9375rem; }

.classic-advance-feature-wrapper {
  overflow: visible;
  height: auto;
  margin: 3.125rem 0 1.875rem; }
  .classic-advance-feature-wrapper h2 {
    color: #00833c;
    font-family: "HarmoniaSansW05-Semibold";
    font-weight: 600;
    font-style: normal;
    font-size: 1.75rem;
    line-height: 2.1rem; }
  .classic-advance-feature-wrapper .advance-sub-header {
    color: #00833c;
    font-family: "OpenSans-Semibold";
    font-weight: 300;
    font-style: normal;
    font-size: 1rem;
    line-height: 1.3125rem; }
  .classic-advance-feature-wrapper hr {
    border-bottom: 1px solid #939395;
    margin: 1.875rem 0 1.5625rem;
    border-top: 0; }
  .classic-advance-feature-wrapper .advance-features-content-wrapper ul {
    padding-left: 0;
    list-style-type: none; }
  .classic-advance-feature-wrapper ul.funding-goals {
    margin-bottom: 0.9375rem;
    padding: 0; }
    .classic-advance-feature-wrapper ul.funding-goals li {
      list-style-type: disc;
      font-family: "Opensans-Regular";
      font-size: 1rem;
      color: #002a3a;
      line-height: 1.4rem;
      text-align: left;
      margin-left: 1.25rem; }
  .classic-advance-feature-wrapper img {
    width: 5rem;
    height: 5rem;
    margin: 0px 0.9375rem 0.9375rem 0px; }

.advance-feature-title {
  font-weight: 300;
  font-style: normal;
  font-size: 1rem;
  font-family: "OpenSans-Semibold";
  color: #002a3a; }

.advance-feature-content {
  font-family: "Opensans-Regular";
  font-size: 1rem;
  color: #002a3a; }

.advance-editable-templates-2 {
  margin-top: 3.125rem; }

.advance-editable-templates-2,
.advance-sidebar-mapping {
  overflow: visible; }
  .advance-editable-templates-2 .aside-title-text-image-container,
  .advance-sidebar-mapping .aside-title-text-image-container {
    margin-bottom: -10px; }

.advance-editable-templates-2 .different-option-container .fhl-select label {
  float: left; }

@media only screen and (max-width: 767px) {
  .advance-editable-templates-2 {
    margin-top: -3.125rem; }
  .advance-media-login-wrapper .fhl-title-content-btn-wrapper div a {
    margin-left: 0px; }
  .advance-feature-left,
  .advance-feature-right {
    flex: 0 0 100% !important;
    max-width: 100% !important; } }

@media only screen and (min-width: 768px) {
  .classic-advance-promo-wrapper {
    max-width: 80%; }
  .advance-editable-templates-2 {
    margin-top: 3.125rem; }
  .classic-advance-feature-wrapper,
  .classic-advance-feature-edit-wrapper {
    overflow: visible; }
    .classic-advance-feature-wrapper .advance-sub-header,
    .classic-advance-feature-wrapper .advance-features-content-wrapper,
    .classic-advance-feature-edit-wrapper .advance-sub-header,
    .classic-advance-feature-edit-wrapper .advance-features-content-wrapper {
      flex: auto; }
    .classic-advance-feature-wrapper .advance-sub-header,
    .classic-advance-feature-edit-wrapper .advance-sub-header {
      max-width: 35% !important; }
    .classic-advance-feature-wrapper .advance-features-content-wrapper,
    .classic-advance-feature-edit-wrapper .advance-features-content-wrapper {
      max-width: 65% !important; }
      .classic-advance-feature-wrapper .advance-features-content-wrapper .row,
      .classic-advance-feature-edit-wrapper .advance-features-content-wrapper .row {
        margin-bottom: 10px; }
  .fhl-title-content-btn-wrapper div a {
    height: 2.1875rem; }
    .fhl-title-content-btn-wrapper div a i {
      margin-top: 0; }
  .advance-media-login-wrapper .fhl-title-content-btn-wrapper div a {
    padding-top: 7px;
    height: auto; }
  .advance-media-login-wrapper .fhl-title-content-btn-wrapper div a.left-arrow-icon {
    display: flex !important;
    align-items: center; }
    .advance-media-login-wrapper .fhl-title-content-btn-wrapper div a.left-arrow-icon i {
      margin-top: -0.625rem; } }

@media only screen and (min-width: 992px) {
  .classic-advance-promo-wrapper {
    max-width: 65%; }
  .advance-editable-templates-2 {
    margin-top: 3.125rem; }
  .advance-media-login-wrapper .fhl-title-content-btn-wrapper div p {
    margin-right: 1.5625rem; }
  .advance-media-login-wrapper .fhl-title-content-btn-wrapper div a {
    min-height: 2.1875rem; }
    .advance-media-login-wrapper .fhl-title-content-btn-wrapper div a i {
      margin-top: 0rem; }
  .advance-media-login-wrapper .fhl-title-content-btn-wrapper div a.left-arrow-icon {
    display: inline-block !important; }
    .advance-media-login-wrapper .fhl-title-content-btn-wrapper div a.left-arrow-icon i {
      margin-top: 0rem; }
  .fhl-title-content-btn-wrapper div a {
    height: auto; }
    .fhl-title-content-btn-wrapper div a i {
      margin-top: 0rem; }
  .classic-advance-feature-wrapper,
  .classic-advance-feature-edit-wrapper {
    overflow: visible; }
    .classic-advance-feature-wrapper .advance-sub-header,
    .classic-advance-feature-wrapper .advance-features-content-wrapper,
    .classic-advance-feature-edit-wrapper .advance-sub-header,
    .classic-advance-feature-edit-wrapper .advance-features-content-wrapper {
      flex: auto; }
    .classic-advance-feature-wrapper .advance-sub-header,
    .classic-advance-feature-edit-wrapper .advance-sub-header {
      max-width: 25% !important; }
    .classic-advance-feature-wrapper .advance-features-content-wrapper,
    .classic-advance-feature-edit-wrapper .advance-features-content-wrapper {
      max-width: 72% !important; }
      .classic-advance-feature-wrapper .advance-features-content-wrapper .row,
      .classic-advance-feature-edit-wrapper .advance-features-content-wrapper .row {
        margin-bottom: 0px; } }

@media only screen and (min-width: 1200px) {
  .advance-media-login-wrapper .fhl-title-content-btn-wrapper div {
    /*  a {
                height: convert-unit(35px, "rem");
            } */ }
    .advance-media-login-wrapper .fhl-title-content-btn-wrapper div p {
      margin-right: 2.8125rem; } }

.fhl-rates-promo-wrapper {
  flex-direction: column;
  overflow: visible;
  align-items: flex-start;
  max-width: 100%;
  min-height: 32.8125rem;
  justify-content: center; }
  .fhl-rates-promo-wrapper h6, .fhl-rates-promo-wrapper .member-header {
    font-size: 1rem;
    color: #58585a;
    letter-spacing: 0.125rem;
    line-height: 1.2rem;
    margin-bottom: 0px; }
  .fhl-rates-promo-wrapper h2 {
    font-size: 2rem;
    color: #00833c;
    line-height: 2.4rem;
    border-bottom: 1px solid #00833c;
    width: 100%;
    margin-bottom: 1.5625rem; }
  .fhl-rates-promo-wrapper p {
    font-family: "Opensans-Regular";
    color: #002a3a;
    line-height: 1.3125rem;
    font-size: 0.875rem;
    margin-bottom: 1.5625rem; }
  .fhl-rates-promo-wrapper img {
    width: 13.75rem;
    height: 9.375rem; }

.fhl-rates-dynamic-promo-wrapper {
  flex-direction: column;
  overflow: visible;
  align-items: flex-start;
  max-width: 100%;
  min-height: 32.8125rem;
  justify-content: center;
  width: 100%; }
  .fhl-rates-dynamic-promo-wrapper h2 {
    font-size: 2rem;
    color: #00833c;
    line-height: 2.4rem;
    border-bottom: 1px solid #00833c;
    width: 100%;
    margin-bottom: 1.5625rem; }
  .fhl-rates-dynamic-promo-wrapper .rate-header {
    margin-bottom: 0.3125rem; }
  .fhl-rates-dynamic-promo-wrapper .fhl-select .select-side {
    background-color: #f3f3f3;
    border-right: solid 0.0625rem #c7c7c7;
    border-top: solid 0.0625rem #c7c7c7;
    border-bottom: solid 0.0625rem #c7c7c7;
    text-align: center; }
    .fhl-rates-dynamic-promo-wrapper .fhl-select .select-side i {
      color: #00833c; }
  .fhl-rates-dynamic-promo-wrapper .fhl-select select {
    border: 1px solid #c7c7c7 !important;
    border-radius: 0.25rem;
    font-size: 1rem;
    color: #002a3a !important;
    text-align: left;
    background-color: #f3f3f3;
    padding: 0px 5px !important; }
  .fhl-rates-dynamic-promo-wrapper .fhl-select .current-rates {
    text-transform: uppercase; }
  .fhl-rates-dynamic-promo-wrapper .dynamic-rates-cntr p {
    font-family: "Opensans-Regular";
    font-size: 0.875rem;
    color: #002a3a;
    line-height: 1.3125rem; }
  .fhl-rates-dynamic-promo-wrapper .dynamic-rates-cntr h6, .fhl-rates-dynamic-promo-wrapper .dynamic-rates-cntr .reg-header {
    font-family: "HarmoniaSansW05-Semibold";
    font-weight: 600;
    font-style: normal;
    font-size: 0.875rem;
    color: #002a3a;
    letter-spacing: 0.125rem;
    text-align: left;
    margin: 0.9375rem 0px; }
  .fhl-rates-dynamic-promo-wrapper .dynamic-rates-cntr p.sub-header {
    margin-bottom: 0.1875rem;
    font-family: "HarmoniaSansW05-Semibold";
    font-size: 0.875rem;
    color: #002a3a;
    letter-spacing: 0.125rem; }
  .fhl-rates-dynamic-promo-wrapper .dynamic-rates-cntr .rates-display-section {
    width: 100%;
    margin-bottom: 1.875rem;
    font-size: 5.625rem;
    color: #00833c;
    text-align: center;
    line-height: 3.75rem; }
  .fhl-rates-dynamic-promo-wrapper .dynamic-rates-cntr .dynamic-dropdown-height {
    min-height: 4.75rem; }
  .fhl-rates-dynamic-promo-wrapper .dynamic-rates-cntr .fhl-select {
    width: 80%; }
  .fhl-rates-dynamic-promo-wrapper .dynamic-rates-cntr table {
    border: 0px;
    width: 100%; }
    .fhl-rates-dynamic-promo-wrapper .dynamic-rates-cntr table th {
      font-family: "HarmoniaSansW05-Semibold";
      font-weight: 600;
      font-style: normal;
      font-size: 0.875rem;
      color: #002a3a;
      letter-spacing: 0.125rem;
      text-align: center;
      height: 2.1875rem;
      border-bottom: 1px solid #00833c; }
    .fhl-rates-dynamic-promo-wrapper .dynamic-rates-cntr table td {
      font-size: 0.875rem;
      color: #002a3a;
      letter-spacing: 0.125rem;
      text-align: center;
      height: 2.1875rem;
      line-height: 2.5rem; }

@media only screen and (max-width: 767px) {
  .fhl-rates-dynamic-promo-wrapper {
    min-height: 18.75rem;
    margin-bottom: 1.875rem; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dynamic-rate-block {
    max-width: 100% !important;
    flex: auto !important;
    min-height: 50px !important;
    margin-left: 15px; }
    .dynamic-rate-block .fhl-select {
      margin-left: -15px; }
  .classic-advance-feature-wrapper .advance-sub-header {
    max-width: 30%; }
  .classic-advance-feature-wrapper .advance-features-content-wrapper {
    max-width: 70%; } }

@media only screen and (min-width: 992px) {
  .fhl-rates-dynamic-promo-wrapper .fhl-select .current-rates {
    padding-left: 3px;
    font-size: 13px; }
  .fhl-rates-dynamic-promo-wrapper .dynamic-rates-cntr p {
    font-size: 13px; } }

.fhlb-split-content-left-section .fhlb-split-content-left-area .fhl-title-content-btn-wrapper h2 {
  font-family: "HarmoniaSansW05-Semibold";
  font-size: 1.75rem;
  color: inherit;
  letter-spacing: 0;
  line-height: 2.1rem; }

.fhlb-split-content-left-section .fhlb-split-content-left-area .fhl-title-content-btn-wrapper div p {
  font-family: "HarmoniaSansW05-Regular";
  font-weight: normal;
  font-style: normal; }
  .fhlb-split-content-left-section .fhlb-split-content-left-area .fhl-title-content-btn-wrapper div p em {
    font-family: "HarmoniaSansW05-Italic";
    font-weight: normal;
    font-style: italic; }
    .fhlb-split-content-left-section .fhlb-split-content-left-area .fhl-title-content-btn-wrapper div p em strong {
      font-family: "HarmoniaSansW05-SemiboldItalic";
      font-weight: 300;
      font-style: italic; }
  .fhlb-split-content-left-section .fhlb-split-content-left-area .fhl-title-content-btn-wrapper div p strong {
    font-family: "HarmoniaSansW05-Semibold";
    font-weight: 600;
    font-style: normal; }
    .fhlb-split-content-left-section .fhlb-split-content-left-area .fhl-title-content-btn-wrapper div p strong em {
      font-family: "HarmoniaSans-SemiboldIt";
      font-weight: 600;
      font-style: italic; }
  .fhlb-split-content-left-section .fhlb-split-content-left-area .fhl-title-content-btn-wrapper div p a {
    color: inherit; }

.fhlb-split-content-left-section .fhlb-split-content-left-area .fhl-title-content-btn-wrapper div p {
  font-size: 1.125rem;
  color: inherit;
  letter-spacing: 0;
  line-height: 1.35rem;
  margin-bottom: -12px; }

.fhlb-split-content-left-section .fhlb-split-content-left-area .fhl-richtext-wrapper,
.fhlb-split-content-left-section .fhlb-split-content-left-area .fhl-lists-wrapper {
  font-family: "OpenSans-Regular";
  font-size: 1rem;
  color: inherit;
  line-height: 1.4rem;
  text-align: left; }
  .fhlb-split-content-left-section .fhlb-split-content-left-area .fhl-richtext-wrapper p,
  .fhlb-split-content-left-section .fhlb-split-content-left-area .fhl-lists-wrapper p {
    margin-bottom: 0px !important; }
    .fhlb-split-content-left-section .fhlb-split-content-left-area .fhl-richtext-wrapper p a,
    .fhlb-split-content-left-section .fhlb-split-content-left-area .fhl-lists-wrapper p a {
      color: inherit; }
    .fhlb-split-content-left-section .fhlb-split-content-left-area .fhl-richtext-wrapper p span em,
    .fhlb-split-content-left-section .fhlb-split-content-left-area .fhl-lists-wrapper p span em {
      font-family: "OpenSans-Italic";
      font-weight: normal;
      font-style: italic;
      color: inherit; }
    .fhlb-split-content-left-section .fhlb-split-content-left-area .fhl-richtext-wrapper p em,
    .fhlb-split-content-left-section .fhlb-split-content-left-area .fhl-lists-wrapper p em {
      font-family: "OpenSans-Italic";
      font-weight: normal;
      font-style: italic;
      color: inherit; }
    .fhlb-split-content-left-section .fhlb-split-content-left-area .fhl-richtext-wrapper p strong,
    .fhlb-split-content-left-section .fhlb-split-content-left-area .fhl-richtext-wrapper p b,
    .fhlb-split-content-left-section .fhlb-split-content-left-area .fhl-lists-wrapper p strong,
    .fhlb-split-content-left-section .fhlb-split-content-left-area .fhl-lists-wrapper p b {
      font-family: "OpenSans-Semibold";
      font-weight: 300;
      font-style: normal;
      color: inherit; }
      .fhlb-split-content-left-section .fhlb-split-content-left-area .fhl-richtext-wrapper p strong em,
      .fhlb-split-content-left-section .fhlb-split-content-left-area .fhl-richtext-wrapper p b em,
      .fhlb-split-content-left-section .fhlb-split-content-left-area .fhl-lists-wrapper p strong em,
      .fhlb-split-content-left-section .fhlb-split-content-left-area .fhl-lists-wrapper p b em {
        font-family: "OpenSans-SemiboldItalic";
        font-weight: 300;
        font-style: italic;
        color: inherit; }

.fhlb-split-content-right-section a.left-arrow-icon {
  width: 100%; }

.fhlb-split-content-right-section .fhlb-split-content-right-area .fhl-button-content-container {
  width: 100% !important;
  padding: 0.3125rem 0.625rem;
  max-width: 14.0625rem;
  float: left; }

.fhlb-split-content-right-section .fhlb-split-content-right-area .fundingGoal-container,
.fhlb-split-content-right-section .fhlb-split-content-right-area .fhl-title-subTitle-wrapper {
  width: 100% !important;
  float: none; }
  .fhlb-split-content-right-section .fhlb-split-content-right-area .fundingGoal-container h3,
  .fhlb-split-content-right-section .fhlb-split-content-right-area .fhl-title-subTitle-wrapper h3 {
    font-size: 1.3125rem;
    font-family: "HarmoniaSansW05-Semibold";
    font-weight: 600;
    font-style: normal;
    line-height: 1.575rem;
    margin-top: 1.25rem; }
  .fhlb-split-content-right-section .fhlb-split-content-right-area .fundingGoal-container h5,
  .fhlb-split-content-right-section .fhlb-split-content-right-area .fhl-title-subTitle-wrapper h5 {
    font-family: "Opensans-Regular";
    font-size: 0.875rem;
    color: inherit;
    line-height: 1.3125rem; }

@media only screen and (min-width: 320px) {
  .employee-bio-left-section .fhl-extrasmall-titlebar-wrapper h3 {
    margin-bottom: 0.8125rem; } }

@media only screen and (min-width: 768px) {
  .fhlb-split-content-right-section .fhl-button-content-container .left-section {
    width: 100%; }
    .fhlb-split-content-right-section .fhl-button-content-container .left-section p {
      font-size: 0.875rem; } }

@media only screen and (max-width: 991px) {
  .fhlb-split-content-left-section .fhlb-split-content-left-area .fhl-richtext-wrapper table {
    table-layout: fixed;
    width: 100% !important;
    border: 1px solid #fff; }
  .fhlb-split-content-left-section .fhlb-split-content-left-area .fhl-richtext-wrapper tr {
    display: table;
    width: 100%;
    margin-bottom: 0.625rem; }
  .fhlb-split-content-left-section .fhlb-split-content-left-area .fhl-richtext-wrapper td {
    display: table-row;
    line-height: 1.6875rem;
    word-break: break-all; } }

@media only screen and (min-width: 992px) {
  .fhlb-split-content-right-section .fhl-button-content-container .left-section {
    width: 100%; }
    .fhlb-split-content-right-section .fhl-button-content-container .left-section p {
      font-size: 1rem; }
  .fhlb-split-si-left-section {
    flex: 0 0 65% !important;
    max-width: 65% !important;
    margin-right: 60px; }
    .fhlb-split-si-left-section .fhl-extrasmall-title-wrapper h3 {
      line-height: 1.35rem; }
    .fhlb-split-si-left-section .fhl-h2-medium-title-wrapper h2 {
      line-height: 1.35rem; }
  .fhlb-split-si-right-section {
    flex: 0 0 25% !important;
    max-width: 244px !important; } }

@media only screen and (min-width: 1200px) {
  .fhlb-split-si-left-section {
    flex: 0 0 70% !important;
    max-width: 70% !important; } }

.strategies-media-wrapper {
  padding: 3.125rem 0 0 !important; }

.strategies-results-wrapper {
  overflow: visible;
  /* Hide the browser's default checkbox */ }
  .strategies-results-wrapper .strategies-result-page {
    min-height: 47.8125rem; }
  .strategies-results-wrapper li {
    list-style-type: none; }
  .strategies-results-wrapper .strategies-result-card-box {
    margin: 0.3125rem 0 2.5rem; }
    .strategies-results-wrapper .strategies-result-card-box .contentType {
      color: #767676;
      font-size: 0.75rem;
      letter-spacing: 0.125rem;
      line-height: 1rem;
      text-transform: uppercase; }
    .strategies-results-wrapper .strategies-result-card-box img {
      width: 3.8125rem;
      height: 3.8125rem; }
    .strategies-results-wrapper .strategies-result-card-box h3 {
      font-size: 1.5rem;
      color: #00833c;
      line-height: 1.8rem;
      letter-spacing: 0rem;
      margin-bottom: 0.625rem; }
      .strategies-results-wrapper .strategies-result-card-box h3 a {
        color: #004987 !important; }
    .strategies-results-wrapper .strategies-result-card-box .strategies-date {
      font-size: 0.875rem;
      color: #58585a;
      line-height: 1.125rem;
      letter-spacing: 0.145625rem;
      text-transform: uppercase; }
    .strategies-results-wrapper .strategies-result-card-box a.si-result-title,
    .strategies-results-wrapper .strategies-result-card-box a {
      font-size: 0.875rem;
      color: #00833c;
      line-height: 1.4375rem;
      letter-spacing: 0.0625rem;
      display: table; }
      .strategies-results-wrapper .strategies-result-card-box a.si-result-title span,
      .strategies-results-wrapper .strategies-result-card-box a span {
        display: inline-block;
        float: right; }
    .strategies-results-wrapper .strategies-result-card-box a.learn-more-link {
      padding-top: 1.25rem;
      line-height: 1.05rem; }
      .strategies-results-wrapper .strategies-result-card-box a.learn-more-link span {
        margin-top: -3px; }

.si-video-main-wrapper,
.si-infographic-main-wrapper {
  display: block;
  overflow: visible;
  min-height: 9.375rem;
  background-color: #fff; }
  .si-video-main-wrapper .fhlb-split-content-left-section .fhl-extrasmall-title-wrapper h3,
  .si-infographic-main-wrapper .fhlb-split-content-left-section .fhl-extrasmall-title-wrapper h3 {
    padding-left: 2.1875rem; }
  .si-video-main-wrapper .fhlb-split-content-left-section .fhl-h2-medium-title-wrapper h2,
  .si-infographic-main-wrapper .fhlb-split-content-left-section .fhl-h2-medium-title-wrapper h2 {
    padding-left: 2.1875rem; }

@media only screen and (max-width: 767px) {
  .fhl-si-video-richtext-wrapper,
  .si-side-article-content-container {
    margin-bottom: 2.5rem; }
  .mob-mb-0 .si-side-article-content-container {
    margin-bottom: 0px !important; }
  .si-side-article-content-container {
    margin-top: 2.1875rem; }
    .si-side-article-content-container .side-article-title {
      margin-bottom: 0px; }
    .si-side-article-content-container .si-card-layout-anchor-btn {
      margin: 1.25rem 0 2.5rem; }
    .si-side-article-content-container .side-article-description {
      margin-top: 0.9375rem;
      margin-bottom: 0.625rem; }
  .si-video-wrapper .fhlb-split-content-right-section .fhl-button-content-container,
  .si-video-wrapper .fhlb-split-content-right-section .left-arrow-icon,
  .si-infographic-main-wrapper .fhlb-split-content-right-section .fhl-button-content-container,
  .si-infographic-main-wrapper .fhlb-split-content-right-section .left-arrow-icon {
    width: 16.25rem; }
  .strategies-more-results-wrapper .strategies-results-wrapper .col-md-4 {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 3.25rem; }
  .strategies-more-results-wrapper .col-md-3 {
    max-width: 100%;
    flex: 0 0 100%;
    margin-bottom: 0.625rem;
    padding: 0; }
  .strategies-more-results-wrapper .col-md-9 {
    max-width: 90%;
    flex: 0 0 90%;
    padding: 0; }
  .strategies-media-wrapper {
    padding: 1.875rem 1rem !important; }
    .strategies-media-wrapper .narrow-list-wrapper {
      max-width: 100%;
      margin-bottom: 1.875rem; }
    .strategies-media-wrapper .strategies-results-wrapper .strategies-result-page .col-md-6 {
      padding: 0;
      margin-bottom: 3.25rem; }
    .strategies-media-wrapper .strategies-results-wrapper .strategies-result-page .col-md-12 {
      padding: 0; }
    .strategies-media-wrapper .strategies-results-wrapper .strategies-result-page .strategies-result-card-box .col-md-3 {
      margin-bottom: 0.625rem; }
  .strategies-result-card-box {
    margin-bottom: 0px !important; }
  .si-article-main-wrapper .fhlb-split-content-right-area,
  .si-article-main-wrapper .strategies-more-title-wrapper,
  .si-article-main-wrapper .strategies-more-results-wrapper {
    padding-left: 0.9375rem !important; }
    .si-article-main-wrapper .fhlb-split-content-right-area .error-message-wrapper .error-message-space,
    .si-article-main-wrapper .strategies-more-title-wrapper .error-message-wrapper .error-message-space,
    .si-article-main-wrapper .strategies-more-results-wrapper .error-message-wrapper .error-message-space {
      margin: 3% 0px -5%; }
  .si-article-main-wrapper .fhlb-split-content-right-section .fhlb-split-content-right-area a,
  .si-article-main-wrapper .fhlb-split-content-right-section .fhlb-split-content-right-area .fhl-button-content-container {
    max-width: 16.25rem; }
  .min-screen-992px-pl-pr-0 .white-bg.extra-space-whiteBg {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media only screen and (max-width: 1024px) {
  .min-screen-992px-pl-pr-0 .white-bg.extra-space-whiteBg {
    padding-top: 20px !important;
    padding-bottom: 15px !important; } }

@media only screen and (min-width: 768px) {
  .si-article-main-wrapper .fhlb-split-content-right-area,
  .si-video-wrapper .fhlb-split-content-right-area,
  .si-infographic-main-wrapper .fhlb-split-content-right-area {
    margin-top: 0.9375rem; }
  .strategies-media-wrapper .row .col-md-3 {
    max-width: 35%; }
    .strategies-media-wrapper .row .col-md-3 .narrow-list-wrapper {
      width: 225px; }
  .strategies-media-wrapper .row .col-md-9 {
    max-width: 65%; }
    .strategies-media-wrapper .row .col-md-9 .strategies-result-page .strategies-result-card-box .col-md-3 {
      margin-bottom: 0.625rem; }
    .strategies-media-wrapper .row .col-md-9 .strategies-result-page .strategies-result-card-box .col-md-3,
    .strategies-media-wrapper .row .col-md-9 .strategies-result-page .strategies-result-card-box .col-md-9 {
      max-width: 100%;
      flex: 0 0 100%; }
  .strategies-more-results-wrapper .col-md-3 {
    max-width: 100%;
    flex: 0 0 100%;
    margin-bottom: 0.625rem;
    padding: 0; }
  .strategies-more-results-wrapper .col-md-9 {
    max-width: 90%;
    flex: 0 0 90%;
    padding: 0; }
  .strategies-more-results-wrapper .error-message-wrapper .error-message-space {
    margin: 3% 0px -5%; } }

@media only screen and (min-width: 992px) {
  .strategies-media-wrapper .row .col-md-3,
  .strategies-more-results-wrapper .row .col-md-3 {
    max-width: 30%;
    padding: 0 0 0 15px;
    flex: 0 0 30%; }
  .strategies-media-wrapper .row .col-md-9,
  .strategies-more-results-wrapper .row .col-md-9 {
    max-width: 70%;
    padding: 0px 15px 0px 0px;
    flex: 0 0 70%; }
    .strategies-media-wrapper .row .col-md-9 .strategies-result-page .strategies-result-card-box .col-md-3,
    .strategies-more-results-wrapper .row .col-md-9 .strategies-result-page .strategies-result-card-box .col-md-3 {
      max-width: 30%;
      flex: 0 0 30%; }
    .strategies-media-wrapper .row .col-md-9 .strategies-result-page .strategies-result-card-box .col-md-9,
    .strategies-more-results-wrapper .row .col-md-9 .strategies-result-page .strategies-result-card-box .col-md-9 {
      max-width: 70%;
      flex: 0 0 70%; }
  .si-article-main-wrapper .strategies-more-title-wrapper {
    padding: 0px 0.9375rem !important; }
  .si-article-main-wrapper .fhlb-split-content-right-area,
  .si-video-wrapper .fhlb-split-content-right-area,
  .si-infographic-main-wrapper .fhlb-split-content-right-area {
    margin-top: 0rem; }
  .si-video-editable-templates .strategies-more-title-wrapper,
  .si-video-editable-templates .strategies-more-results-wrapper,
  .si-video-main-wrapper .strategies-more-title-wrapper,
  .si-video-main-wrapper .strategies-more-results-wrapper,
  .si-infographic-main-wrapper .strategies-more-title-wrapper,
  .si-infographic-main-wrapper .strategies-more-results-wrapper {
    padding: 0px 2.1875rem !important; }
  .si-video-editable-templates .strategies-more-results-wrapper .row .col-md-3,
  .si-video-main-wrapper .strategies-more-results-wrapper .row .col-md-3,
  .si-infographic-main-wrapper .strategies-more-results-wrapper .row .col-md-3 {
    max-width: 25%;
    padding: 0 0 0 0px;
    flex: 0 0 25%; }
  .si-video-editable-templates .strategies-more-results-wrapper .row .col-md-9,
  .si-video-main-wrapper .strategies-more-results-wrapper .row .col-md-9,
  .si-infographic-main-wrapper .strategies-more-results-wrapper .row .col-md-9 {
    max-width: 75%;
    padding: 0px 15px 0px 0px;
    flex: 0 0 75%; }
  .si-article-main-wrapper {
    max-width: 61.875rem !important; }
  .si-article-main-wrapper .fhlb-split-content-left-section {
    margin-top: -14.375rem;
    margin-right: 85px; }
  .si-article-main-wrapper .fhlb-split-content-right-section {
    flex: 0 0 30% !important;
    max-width: 250px !important; }
    .si-article-main-wrapper .fhlb-split-content-right-section .fhlb-split-content-right-area .fhl-button-content-container {
      max-width: 100%; }
  .min-screen-992px-pl-pr-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media only screen and (min-width: 1200px) {
  .white-bg.si-article-main-wrapper {
    max-width: 73.75rem !important;
    padding: 0px 1.875rem !important; }
  .strategies-media-wrapper .row .col-md-9 .strategies-result-page .strategies-result-card-box .col-md-3,
  .strategies-more-results-wrapper .row .col-md-9 .strategies-result-page .strategies-result-card-box .col-md-3 {
    max-width: 25%;
    flex: 0 0 25%; }
  .strategies-media-wrapper .row .col-md-9 .strategies-result-page .strategies-result-card-box .col-md-9,
  .strategies-more-results-wrapper .row .col-md-9 .strategies-result-page .strategies-result-card-box .col-md-9 {
    max-width: 75%;
    flex: 0 0 75%; } }

.si-video-wrapper {
  background-color: #fff; }
  .si-video-wrapper .video-embed-container {
    height: 0;
    padding-top: 0px;
    padding-bottom: 56.25%;
    margin-bottom: 60px;
    position: relative;
    overflow: hidden; }
  .si-video-wrapper .video-embed-container.widescreen {
    padding-bottom: 57.25%; }
  .si-video-wrapper .video-embed-container.vimeo {
    padding-top: 0; }
  .si-video-wrapper .video-embed-container iframe {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute; }

/*
@media only screen and (max-width: 575px) {
    .si-video-wrapper {
        object {
            height: convert-unit(195px, "rem") !important;
        }
        
        iframe{
            height: convert-unit(195px, "rem") !important;
        }
    }
}
@media only screen and (max-width: 767px) {
    .si-video-wrapper {
        object {
            height: convert-unit(270px, "rem");
        }
        iframe{
            height: convert-unit(270px, "rem");
        }
    }
}
@media only screen and (min-width: 768px) {
    .si-video-wrapper {
        object {
            height: convert-unit(320px, "rem");
        }
        iframe {
            height: convert-unit(320px, "rem");
        }
    }
}
@media only screen and (min-width: 992px) {
    .si-video-wrapper {
        object {
            height: convert-unit(605px, "rem");
        }
        iframe {
            height: convert-unit(605px, "rem");
        }
    }
}
*/
.news-events-results-wrapper {
  padding: 0 0 0 !important; }

.news-events-media-left-section {
  word-break: break-word;
  word-wrap: break-word; }

.news-events-results-wrapper {
  overflow: visible;
  /* Hide the browser's default checkbox */ }
  .news-events-results-wrapper .news-events-result-page {
    min-height: 47.8125rem; }
    .news-events-results-wrapper .news-events-result-page .pagenation-content {
      margin-top: 1.25rem; }
    .news-events-results-wrapper .news-events-result-page ul li {
      list-style-type: none; }
  .news-events-results-wrapper hr {
    border-bottom: 1px solid #939395;
    margin: 1.875rem 0 1.5625rem;
    border-top: 0; }
  .news-events-results-wrapper .news-events-result-card-box {
    margin: 0.3125rem 0 2.5rem; }
    .news-events-results-wrapper .news-events-result-card-box .content-description {
      font-family: "OpenSans-Regular";
      color: #002a3a;
      font-size: 1rem;
      letter-spacing: 0rem;
      line-height: 1.4rem; }
    .news-events-results-wrapper .news-events-result-card-box img {
      width: 100%; }
    .news-events-results-wrapper .news-events-result-card-box h2 {
      font-size: 2rem;
      color: #00833c;
      line-height: 2.4rem;
      letter-spacing: 0rem;
      margin-bottom: 1.25rem;
      font-family: "HarmoniaSansW05-Regular"; }
      .news-events-results-wrapper .news-events-result-card-box h2 a {
        color: #004987 !important; }
    .news-events-results-wrapper .news-events-result-card-box .events-date {
      border-radius: 0.25rem;
      border: 1px solid #98989a;
      color: #007cad;
      width: 3.75rem;
      height: 3.75rem;
      text-transform: uppercase; }
      .news-events-results-wrapper .news-events-result-card-box .events-date .events-formatted-month {
        font-size: 0.75rem;
        color: #415364;
        letter-spacing: 0;
        text-align: center;
        line-height: 1.125rem;
        padding-bottom: 0.625rem; }
      .news-events-results-wrapper .news-events-result-card-box .events-date .events-formatted-date {
        font-size: 1.75rem;
        color: #007cad;
        letter-spacing: 0;
        text-align: center;
        line-height: 1.125rem; }
    .news-events-results-wrapper .news-events-result-card-box .news-date {
      font-size: 0.875rem;
      color: #58585a;
      line-height: 1.125rem;
      letter-spacing: 0.145625rem;
      text-transform: uppercase; }
    .news-events-results-wrapper .news-events-result-card-box a.news-events-result-title,
    .news-events-results-wrapper .news-events-result-card-box a {
      font-size: 2rem;
      color: #00833c;
      line-height: 2.4rem;
      letter-spacing: 0rem;
      display: table; }
      .news-events-results-wrapper .news-events-result-card-box a.news-events-result-title span,
      .news-events-results-wrapper .news-events-result-card-box a span {
        display: inline-block;
        float: right; }
    .news-events-results-wrapper .news-events-result-card-box a.learn-more-link {
      font-size: 0.875rem;
      color: #00833c;
      letter-spacing: 0.0625rem;
      padding-top: 1.25rem;
      line-height: 1.05rem; }
      .news-events-results-wrapper .news-events-result-card-box a.learn-more-link span {
        margin-top: -3px; }
  .news-events-results-wrapper .news-events-main-section-img {
    margin: 1.25rem 0px 1.875rem; }

@media only screen and (max-width: 767px) {
  .news-events-result-card-box {
    margin-bottom: 0px !important; }
    .news-events-result-card-box .events-date {
      margin-bottom: 0.9375rem; }
  .news-events-main-header {
    padding-left: 0.9375rem !important; }
  .news-events-media-wrapper .row .col-md-3 .narrow-list-wrapper,
  .news-events-media-wrapper .row .col-md-9 .narrow-list-wrapper {
    max-width: 100%;
    margin-bottom: 0.9375rem; }
  .news-events-results-wrapper .news-events-result-page {
    min-height: 100%; }
    .news-events-results-wrapper .news-events-result-page .pagenation-content {
      margin-top: 0.9375rem !important; }
  .news-events-static-page-wrapper .news-events-media-left-section,
  .news-events-static-page-wrapper .news-events-media-right-section {
    padding: 0; }
  .news-events-static-page-wrapper .news-events-media-right-section {
    margin-bottom: 25px; } }

.news-events-media-right-section a.left-arrow-icon {
  width: 100%; }

@media only screen and (min-width: 768px) {
  .news-events-main-header {
    padding-left: 0rem !important; }
  .events-result-content-section {
    display: flex;
    flex-direction: row; }
    .events-result-content-section .events-date {
      min-width: 3.75rem;
      min-height: 3.75rem;
      margin-right: 1.875rem; }
  .news-events-media-wrapper .news-events-media-left-section {
    margin-right: 0px; }
  .news-events-media-wrapper .row .col-md-3 {
    max-width: 33%; }
    .news-events-media-wrapper .row .col-md-3 .narrow-list-wrapper {
      width: 225px; }
  .news-events-media-wrapper .row .col-md-9 {
    max-width: 67%; }
    .news-events-media-wrapper .row .col-md-9 .news-events-result-page .pagenation-content {
      margin-top: 0.9375rem !important; }
    .news-events-media-wrapper .row .col-md-9 .news-events-result-page .news-events-result-card-box .col-md-3 {
      margin-bottom: 0.625rem; }
    .news-events-media-wrapper .row .col-md-9 .news-events-result-page .news-events-result-card-box .col-md-3,
    .news-events-media-wrapper .row .col-md-9 .news-events-result-page .news-events-result-card-box .col-md-9 {
      max-width: 100%;
      flex: 0 0 100%; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .news-events-media-wrapper .news-events-result-page .pagenation-content {
    margin-left: -0.9375rem !important; }
  .news-events-results-display-wrapper,
  .events-result-content-section {
    padding-left: 0 !important; }
  .news-events-results-wrapper hr {
    margin-left: -0.9375rem !important; }
  .events-mapping {
    margin-top: -80px; }
  .news-events-static-page-wrapper .news-events-media-left-section {
    padding-left: 0; } }

@media only screen and (min-width: 992px) {
  .events-page-main-content-section {
    padding: 0 !important; }
  .news-events-media-wrapper .news-events-media-left-section {
    margin-right: 100px; }
  .news-events-media-wrapper .row .col-md-3 {
    max-width: 225px;
    padding: 0 0 0 15px;
    flex: 0 0 30%; }
  .news-events-media-wrapper .row .col-md-9 {
    max-width: 65%;
    padding: 0px 15px 0px 0px;
    flex: 0 0 65%; }
    .news-events-media-wrapper .row .col-md-9 .news-events-result-page .news-events-result-card-box .col-md-3 {
      max-width: 30%;
      flex: 0 0 30%; }
    .news-events-media-wrapper .row .col-md-9 .news-events-result-page .news-events-result-card-box .col-md-9 {
      max-width: 70%;
      flex: 0 0 70%; } }

@media only screen and (min-width: 1200px) {
  .news-events-media-wrapper .row .col-md-9 {
    max-width: 70%;
    flex: 0 0 70%; }
    .news-events-media-wrapper .row .col-md-9 .news-events-result-page .news-events-result-card-box .col-md-3 {
      max-width: 25%;
      flex: 0 0 25%; }
    .news-events-media-wrapper .row .col-md-9 .news-events-result-page .news-events-result-card-box .col-md-9 {
      max-width: 75%;
      flex: 0 0 75%; } }

.pl-carousel-layout {
  margin: 0px auto 5.625rem; }
  .pl-carousel-layout .pl-carousel-slide {
    margin-bottom: 4.375rem; }

.pl-carousel-slides {
  min-height: 9.375rem; }

.pl-carousel-prev,
.pl-carousel-next {
  width: 2.125rem; }

button.pl-carousel-control {
  border: 1px solid #939395;
  background-color: transparent !important;
  border-radius: 6px; }

button.pl-carousel-control:hover,
button.pl-carousel-control:focus {
  background-color: #00833c !important;
  outline: 0; }
  button.pl-carousel-control:hover i.fa-chevron-left,
  button.pl-carousel-control:hover i.fa-chevron-right,
  button.pl-carousel-control:focus i.fa-chevron-left,
  button.pl-carousel-control:focus i.fa-chevron-right {
    border: solid white;
    border-width: 0 2px 2px 0; }

i.fa-chevron-left,
i.fa-chevron-right {
  border: solid #00833c;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 6px;
  margin-top: 5px; }

i.fa-chevron-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  margin-left: 1px; }

i.fa-chevron-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  margin-left: -6px; }

.pl-carousel-pagination .active {
  text-decoration: none !important; }

.pl-carousel-pagination .pagination li.page-item {
  padding: 6px;
  line-height: 30px;
  cursor: pointer;
  border-radius: 4px; }
  .pl-carousel-pagination .pagination li.page-item a.page-link {
    border: 0;
    font-size: 18px;
    padding: 5px 9px;
    color: #00833c;
    text-decoration-color: transparent !important; }
  .pl-carousel-pagination .pagination li.page-item a.page-link:hover,
  .pl-carousel-pagination .pagination li.page-item a.page-link:focus {
    background-color: transparent;
    color: #00833c; }

.pl-carousel-pagination .pagination li.page-item.active {
  cursor: not-allowed;
  color: white;
  background: #00833c; }
  .pl-carousel-pagination .pagination li.page-item.active .page-link,
  .pl-carousel-pagination .pagination li.page-item.active .page-link:hover,
  .pl-carousel-pagination .pagination li.page-item.active .page-link:focus {
    color: #fff;
    background-color: transparent;
    text-decoration: none;
    text-decoration-color: transparent !important; }

.pl-carousel-slide .title-text-article-container .title-text-right-section .title-text-article-title {
  font-size: 1.5rem;
  line-height: 1.8rem;
  word-break: break-word; }

.pl-carousel-slide .title-text-article-container .title-text-right-section .title-text-article-content {
  font-size: 1rem;
  color: inherit;
  line-height: 1.4rem; }
  .pl-carousel-slide .title-text-article-container .title-text-right-section .title-text-article-content p {
    font-family: "Opensans-Regular";
    font-weight: normal;
    font-style: normal; }
    .pl-carousel-slide .title-text-article-container .title-text-right-section .title-text-article-content p em {
      font-family: "OpenSans-Italic";
      font-weight: normal;
      font-style: italic; }
      .pl-carousel-slide .title-text-article-container .title-text-right-section .title-text-article-content p em strong {
        font-family: "OpenSans-SemiboldItalic";
        font-weight: 300;
        font-style: italic; }
    .pl-carousel-slide .title-text-article-container .title-text-right-section .title-text-article-content p strong {
      font-family: "OpenSans-Semibold";
      font-weight: 300;
      font-style: normal; }
      .pl-carousel-slide .title-text-article-container .title-text-right-section .title-text-article-content p strong em {
        font-family: "OpenSans-SemiboldItalic";
        font-weight: 300;
        font-style: italic; }
    .pl-carousel-slide .title-text-article-container .title-text-right-section .title-text-article-content p a {
      color: inherit; }
  .pl-carousel-slide .title-text-article-container .title-text-right-section .title-text-article-content p {
    color: inherit;
    word-break: break-word; }

@media only screen and (max-width: 575px) {
  .pl-carousel-layout {
    max-width: 90% !important; }
    .pl-carousel-layout .pl-carousel-slide {
      margin-bottom: 1.875rem; } }

@media only screen and (max-width: 767px) {
  .pl-carousel-layout {
    max-width: 33.75rem; }
    .pl-carousel-layout .pl-carousel-slides .row {
      width: 80%;
      margin: 0 10%; }
  nav.pl-carousel-pagination {
    position: relative; }
  .pl-carousel-pagination {
    left: 15% !important; }
    .pl-carousel-pagination ul.pagination {
      display: block;
      left: 0;
      width: 80%; }
      .pl-carousel-pagination ul.pagination li.page-item {
        float: left; }
  .carousel-main-header .fhl-medium-titlebar-wrapper h2 {
    font-size: 2rem;
    margin-bottom: -1.25rem; } }

@media only screen and (min-width: 768px) {
  .pl-carousel-layout {
    max-width: 45rem; }
    .pl-carousel-layout .pl-carousel-slides .pl-carousel-slide {
      margin-bottom: 3.4375rem; }
      .pl-carousel-layout .pl-carousel-slides .pl-carousel-slide .row {
        width: 90%;
        margin: 0 5%; }
      .pl-carousel-layout .pl-carousel-slides .pl-carousel-slide .scenario-image1 {
        margin-bottom: 1.25rem; }
  .carousel-main-header .fhl-medium-titlebar-wrapper h2 {
    font-size: 2.375rem;
    padding-left: 4.0625rem; } }

@media only screen and (min-width: 992px) {
  .pl-carousel-layout {
    max-width: 60rem; }
  .carousel-main-header .fhl-medium-titlebar-wrapper h2 {
    font-size: 2.625rem;
    padding-left: 0rem; } }

@media only screen and (min-width: 1200px) {
  .pl-carousel-layout {
    max-width: 71rem; } }

.pagenation-content {
  display: flex;
  list-style-type: none;
  margin: 0px 0px 3.125rem;
  padding: 0; }
  .pagenation-content li {
    border-radius: 0.25rem;
    font-size: 1.125rem;
    line-height: 1.875rem;
    padding: 0.25rem 0.9375rem 0px;
    margin: 0px 0.1875rem;
    color: #00833c;
    text-align: center; }
    .pagenation-content li button {
      cursor: pointer;
      background-color: transparent;
      border: 0px; }
  .pagenation-content li:first-child {
    margin-left: 0px; }
  .pagenation-content li:last-child {
    margin-right: 0px; }
  .pagenation-content button {
    background-color: transparent;
    border: 0;
    color: inherit; }
  .pagenation-content .pagenationArrowDisabled {
    background-color: #767676;
    padding: 0.375rem 0.625rem 0px;
    line-height: 1.875rem;
    font-size: 1.125rem;
    color: #fff; }
    .pagenation-content .pagenationArrowDisabled button {
      pointer-events: none;
      cursor: not-allowed; }
  .pagenation-content .pagenationArrowEnabled {
    border: 1px solid #c7c7c7;
    padding: 0.375rem 0.625rem 0px;
    line-height: 1.875rem; }
    .pagenation-content .pagenationArrowEnabled button {
      cursor: pointer; }
  .pagenation-content .selectedPage {
    color: white;
    background: #00833c; }
    .pagenation-content .selectedPage button {
      cursor: not-allowed;
      pointer-events: none; }
  .pagenation-content .unSelectedPage button {
    cursor: pointer; }

@media only screen and (max-width: 767px) {
  .desktopPagination {
    display: none; }
  .mobilePagination {
    display: block; }
  .iPadPagination {
    display: none; }
  .pagenation-content {
    margin: 0px !important; } }

@media only screen and (min-width: 768px) {
  .desktopPagination {
    display: none; }
  .mobilePagination {
    display: none; }
  .iPadPagination {
    display: block; } }

@media only screen and (min-width: 992px) {
  .desktopPagination {
    display: block; }
  .mobilePagination {
    display: none; }
  .iPadPagination {
    display: none; }
  .pagenation-content li {
    padding: 8px 12px 0px;
    line-height: 24px; }
  .pagenation-content .pagenationArrowDisabled {
    padding: 0.375rem 0.4375rem 0px; }
  .pagenation-content .pagenationArrowEnabled {
    padding: 6px 0.375rem 0px;
    line-height: 24px; } }

@media only screen and (min-width: 1200px) {
  .desktopPagination {
    display: block; }
  .mobilePagination {
    display: none; }
  .iPadPagination {
    display: none; }
  .pagenation-content li {
    padding: 6px 15px 0px;
    line-height: 30px; }
  .pagenation-content .pagenationArrowEnabled {
    padding: 6px 0.375rem 0px;
    line-height: 30px; }
  .pagenation-content .pagenationArrowDisabled {
    padding: 0.375rem 0.625rem 0px; } }

.contacts-page-header-wrapper {
  display: flex; }
  .contacts-page-header-wrapper .fhl-header-leadpara-wrapper {
    margin-top: 1.875rem; }
    .contacts-page-header-wrapper .fhl-header-leadpara-wrapper h1 {
      font-size: 2.625rem;
      line-height: 3.15rem;
      color: inherit; }
      .contacts-page-header-wrapper .fhl-header-leadpara-wrapper h1 p {
        font-family: "HarmoniaSansW05-Regular";
        font-weight: normal;
        font-style: normal; }
        .contacts-page-header-wrapper .fhl-header-leadpara-wrapper h1 p em {
          font-family: "HarmoniaSansW05-Italic";
          font-weight: normal;
          font-style: italic; }
          .contacts-page-header-wrapper .fhl-header-leadpara-wrapper h1 p em strong {
            font-family: "HarmoniaSansW05-SemiboldItalic";
            font-weight: 300;
            font-style: italic; }
        .contacts-page-header-wrapper .fhl-header-leadpara-wrapper h1 p strong {
          font-family: "HarmoniaSansW05-Semibold";
          font-weight: 600;
          font-style: normal; }
          .contacts-page-header-wrapper .fhl-header-leadpara-wrapper h1 p strong em {
            font-family: "HarmoniaSans-SemiboldIt";
            font-weight: 600;
            font-style: italic; }
        .contacts-page-header-wrapper .fhl-header-leadpara-wrapper h1 p a {
          color: inherit; }
    .contacts-page-header-wrapper .fhl-header-leadpara-wrapper p {
      color: inherit;
      line-height: 2.1rem;
      font-size: 1.5rem; }
      .contacts-page-header-wrapper .fhl-header-leadpara-wrapper p p {
        font-family: "HarmoniaSansW05-Regular";
        font-weight: normal;
        font-style: normal; }
        .contacts-page-header-wrapper .fhl-header-leadpara-wrapper p p em {
          font-family: "HarmoniaSansW05-Italic";
          font-weight: normal;
          font-style: italic; }
          .contacts-page-header-wrapper .fhl-header-leadpara-wrapper p p em strong {
            font-family: "HarmoniaSansW05-SemiboldItalic";
            font-weight: 300;
            font-style: italic; }
        .contacts-page-header-wrapper .fhl-header-leadpara-wrapper p p strong {
          font-family: "HarmoniaSansW05-Semibold";
          font-weight: 600;
          font-style: normal; }
          .contacts-page-header-wrapper .fhl-header-leadpara-wrapper p p strong em {
            font-family: "HarmoniaSans-SemiboldIt";
            font-weight: 600;
            font-style: italic; }
        .contacts-page-header-wrapper .fhl-header-leadpara-wrapper p p a {
          color: inherit; }

.contactus-left-section .col-lg-4.col-md-6:last-child .contacts-slot:last-child {
  margin-top: 0.625rem; }
  .contactus-left-section .col-lg-4.col-md-6:last-child .contacts-slot:last-child img {
    height: 1.25rem;
    width: 2.4375rem; }

.contacts-lhs-container {
  margin-bottom: 1.6875rem; }
  .contacts-lhs-container h3 {
    font-family: "HarmoniaSansW05-Semibold";
    font-weight: 600;
    font-style: normal;
    font-size: 1.3125rem;
    color: inherit;
    line-height: 1.575rem; }
  .contacts-lhs-container .contacts-slot {
    height: auto;
    word-break: break-all;
    align-items: center; }
    .contacts-lhs-container .contacts-slot .contactsLHS-link {
      margin-left: 1.4375rem;
      align-self: center;
      font-size: 1.125rem;
      line-height: 1.575rem;
      color: inherit; }
      .contacts-lhs-container .contacts-slot .contactsLHS-link p {
        margin-bottom: 0;
        color: inherit; }
        .contacts-lhs-container .contacts-slot .contactsLHS-link p a {
          color: inherit; }
    .contacts-lhs-container .contacts-slot img {
      max-width: 2.3125rem;
      height: 2.3125rem; }

.contacts-rhs-container {
  border: 0.0625rem solid #979797;
  padding: 0.9375rem;
  border-radius: 0.25rem;
  margin: 1.25rem 0px; }
  .contacts-rhs-container h2 {
    font-size: 2rem;
    line-height: 2.4rem; }
  .contacts-rhs-container .contacts-image {
    width: 100%;
    margin-bottom: 1.25rem;
    margin-top: 0.25rem; }
  .contacts-rhs-container .contacts-rhs-content {
    font-family: "OpenSans-Regular";
    font-size: 0.875rem;
    color: inherit;
    line-height: 1.3125rem; }
  .contacts-rhs-container .contact-select-title p {
    margin-bottom: 0; }
  .contacts-rhs-container .contact-select-phoneno {
    font-family: "HarmoniaSansW05-Semibold";
    font-weight: 600;
    font-style: normal;
    font-size: 1.5rem;
    line-height: 1.8rem;
    word-break: break-all;
    margin-bottom: 0.9375rem; }
  .contacts-rhs-container h5, .contacts-rhs-container .contact-select-title {
    font-size: 1.125rem;
    line-height: 1.35rem;
    margin-bottom: 0.5rem; }
  .contacts-rhs-container .contacts-rhs-select-content .feature-list {
    font-family: "OpenSans-Regular";
    font-style: normal;
    margin: 0.5rem 0px; }
    .contacts-rhs-container .contacts-rhs-select-content .feature-list .list-content {
      display: flex;
      align-items: center; }
      .contacts-rhs-container .contacts-rhs-select-content .feature-list .list-content img {
        width: 1.5rem;
        height: 1.5rem; }
      .contacts-rhs-container .contacts-rhs-select-content .feature-list .list-content div {
        font-size: 0.75rem;
        color: inherit;
        margin-left: 0.6875rem; }
        .contacts-rhs-container .contacts-rhs-select-content .feature-list .list-content div p {
          margin-bottom: 0; }
        .contacts-rhs-container .contacts-rhs-select-content .feature-list .list-content div a {
          font-family: "OpenSans-Semibold";
          font-weight: 300;
          font-style: normal;
          text-decoration: underline;
          color: #002a3a; }

.contactus-left-section .fhl-richtext-wrapper {
  font-family: "OpenSans-Regular";
  font-style: normal; }
  .contactus-left-section .fhl-richtext-wrapper div p {
    line-height: 1.4rem;
    margin-bottom: 1.25rem; }

.contactus-left-section .fhl-h3-content-wrapper {
  margin-bottom: 0 !important;
  flex-direction: column; }
  .contactus-left-section .fhl-h3-content-wrapper h3 {
    font-family: "HarmoniaSansW05-Semibold";
    font-weight: 600;
    font-style: normal;
    font-size: 1.3125rem;
    line-height: 1.575rem;
    margin-bottom: 0px; }
  .contactus-left-section .fhl-h3-content-wrapper div {
    line-height: 1.3125rem;
    font-size: 0.875rem;
    color: inherit; }
    .contactus-left-section .fhl-h3-content-wrapper div p {
      font-family: "Opensans-Regular";
      font-weight: normal;
      font-style: normal; }
      .contactus-left-section .fhl-h3-content-wrapper div p em {
        font-family: "OpenSans-Italic";
        font-weight: normal;
        font-style: italic; }
        .contactus-left-section .fhl-h3-content-wrapper div p em strong {
          font-family: "OpenSans-SemiboldItalic";
          font-weight: 300;
          font-style: italic; }
      .contactus-left-section .fhl-h3-content-wrapper div p strong {
        font-family: "OpenSans-Semibold";
        font-weight: 300;
        font-style: normal; }
        .contactus-left-section .fhl-h3-content-wrapper div p strong em {
          font-family: "OpenSans-SemiboldItalic";
          font-weight: 300;
          font-style: italic; }
      .contactus-left-section .fhl-h3-content-wrapper div p a {
        color: inherit; }

.contactus-left-section .fhl-small-titlebar-wrapper h2 {
  font-size: 1.5rem;
  line-height: 1.8rem; }

.contactus-left-section .fhl-medium-titlebar-wrapper h2 {
  font-family: "HarmoniaSansW05-Semibold";
  font-weight: 600;
  font-style: normal;
  font-size: 1.75rem;
  line-height: 2.1rem; }

.contactus-left-section .fhl-extrasmall-titlebar-wrapper h3 {
  font-family: "OpenSans-Semibold";
  font-weight: 300;
  font-style: normal;
  line-height: 1.3125rem; }

.contactus-left-section-secondary-header .fhl-small-titlebar-wrapper h2 {
  font-size: 1.75rem;
  line-height: 2.1rem;
  font-family: "HarmoniaSansW05-Semibold";
  font-weight: 600;
  font-style: normal; }

.contactus-right-section .fhl-title-content-btn-wrapper {
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 0.5rem; }
  .contactus-right-section .fhl-title-content-btn-wrapper h2 {
    font-size: 1.5rem;
    line-height: 1.8rem; }
  .contactus-right-section .fhl-title-content-btn-wrapper div {
    line-height: 1.3125rem;
    word-break: break-all; }
    .contactus-right-section .fhl-title-content-btn-wrapper div p {
      font-family: "Opensans-Regular";
      font-weight: normal;
      font-style: normal; }
      .contactus-right-section .fhl-title-content-btn-wrapper div p em {
        font-family: "OpenSans-Italic";
        font-weight: normal;
        font-style: italic; }
        .contactus-right-section .fhl-title-content-btn-wrapper div p em strong {
          font-family: "OpenSans-SemiboldItalic";
          font-weight: 300;
          font-style: italic; }
      .contactus-right-section .fhl-title-content-btn-wrapper div p strong {
        font-family: "OpenSans-Semibold";
        font-weight: 300;
        font-style: normal; }
        .contactus-right-section .fhl-title-content-btn-wrapper div p strong em {
          font-family: "OpenSans-SemiboldItalic";
          font-weight: 300;
          font-style: italic; }
      .contactus-right-section .fhl-title-content-btn-wrapper div p a {
        color: inherit; }
  .contactus-right-section .fhl-title-content-btn-wrapper p > a,
  .contactus-right-section .fhl-title-content-btn-wrapper a {
    padding: 0px;
    line-height: 1.3125rem;
    display: table;
    border: 0px;
    margin: 0px;
    box-shadow: none;
    border-radius: 0;
    color: inherit; }

@media only screen and (max-width: 767px) {
  .contacts-rhs-container {
    width: 80%;
    margin: auto; }
  .contactus-left-section .fhl-extrasmall-titlebar-wrapper h3 {
    margin-bottom: 2.125rem;
    margin-top: 1rem; }
  .contactus-left-section .fhl-medium-titlebar-wrapper h2 {
    text-align: left !important; } }

@media only screen and (min-width: 768px) {
  .contacts-lhs-container h3 {
    font-size: 1.25rem; }
  .contactus-right-section-top-margin {
    margin-top: 4.375rem; } }

@media only screen and (min-width: 992px) {
  .contacts-page-header-wrapper .fhl-title-subTitle-wrapper {
    width: 100% !important; }
  .contacts-lhs-container h3 {
    font-size: 1.3125rem; }
  .contactus-right-section-top-margin {
    margin-right: -0.4375rem;
    margin-top: 6.875rem; } }

@media screen and (min-width: 0px) and (max-width: 767px) {
  .show-on-mobile {
    display: block; }
  /* show it on small screens */
  .hide-on-mobile {
    display: none; } }

@media screen and (min-width: 768px) and (max-width: 3024px) {
  .show-on-mobile {
    display: none; }
  /* hide it elsewhere */
  .hide-on-mobile {
    display: block; } }

.completer-dropdown-holder {
  text-align: left !important;
  word-break: break-word;
  word-wrap: break-word; }
  .completer-dropdown-holder .completer-dropdown .completer-row-wrapper,
  .completer-dropdown-holder .completer-dropdown .completer-row,
  .completer-dropdown-holder .completer-dropdown .completer-selected-row,
  .completer-dropdown-holder .completer-dropdown .completer-list-item {
    word-break: break-word; }

.search-media-wrapper {
  height: 0px; }

.search-results-main-wrapper .search-media-wrapper {
  height: auto; }

.search-results-wrapper ul li {
  list-style-type: none; }

.search-results-wrapper .search-header a {
  font-size: 1.5rem;
  color: #00833c;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  line-height: 1.8rem; }

.search-results-wrapper .search-description {
  font-family: "Opensans-Regular";
  font-size: 1rem;
  color: #002a3a;
  padding-bottom: 1.875rem;
  padding-top: 0.625rem;
  line-height: 1.4rem; }

.search-results-wrapper .search-breadcrumb a {
  font-size: 0.875rem;
  color: #00833c;
  letter-spacing: 0.0625rem;
  line-height: 1.05rem;
  text-decoration: none; }

.search-results-wrapper hr {
  border-bottom: 1px solid #939395;
  margin: 1.875rem 0 1.5rem;
  border-top: 0; }

.search-results-wrapper .search-result-container {
  margin-top: -0.625rem; }
  .search-results-wrapper .search-result-container .pagenation-content {
    margin-top: 3.125rem; }

.search-results-wrapper .search-wrapper input[type="search"] {
  width: 100%;
  height: 2.1875rem;
  padding: 0rem 2.5rem 0rem 0.625rem;
  margin-top: 0.0625rem;
  font-size: 0.875rem;
  border: 1px solid #98989a;
  border-radius: 0.25rem;
  color: #58585a;
  line-height: 1.4rem; }

.search-results-wrapper .search-wrapper .search-box .completer-holder {
  position: relative; }

.search-results-wrapper .search-wrapper .search-box .completer-dropdown-holder {
  position: absolute;
  top: 2.625rem; }

.search-results-wrapper button[type="submit"] {
  width: 100%;
  background: #00833c;
  border-radius: 0.25rem;
  font-size: 1rem;
  color: #fff;
  text-align: center; }

.search-results-wrapper button[type="reset"] {
  border: 0.0625rem solid #939395;
  background-color: transparent;
  position: absolute;
  top: 0.625rem;
  right: 0.625rem;
  color: #939395;
  cursor: pointer;
  height: 1.125rem;
  width: 1.125rem;
  padding: 0rem;
  border-radius: 50%;
  line-height: 1.25rem; }
  .search-results-wrapper button[type="reset"] i {
    font-size: 0.875rem;
    color: #454545; }

.search-results-wrapper button[type="reset"]:hover,
.search-results-wrapper button[type="reset"]:focus,
.search-results-wrapper button[type="reset"]:active {
  outline: 0;
  border: 1px solid #00833c; }

.search-results-wrapper .search-btn-wrapper {
  padding-right: 0;
  padding-left: 2rem; }

.search-results-wrapper .search-box:not(:valid) ~ .close-icon {
  display: none; }

.search-results-wrapper em {
  font-weight: bold; }

@media only screen and (max-width: 767px) {
  .search-result-main-title-wrapper .fhl-medium-titlebar-wrapper h2 {
    text-align: left;
    font-size: 1.875rem; }
  .search-results-wrapper .search-wrapper input[type="search"] {
    margin-bottom: 1.5625rem; }
  .search-results-wrapper .search-btn-wrapper {
    padding-left: 0px; }
    .search-results-wrapper .search-btn-wrapper button {
      width: 9.375rem; }
  .search-results-wrapper .search-result-container .pagenation-content {
    margin: 3.125rem 0px !important; } }

.investor-need-help-container {
  word-break: break-word;
  word-wrap: break-word;
  background-color: #ffffff;
  border: 0.0625rem solid #979797;
  padding: 1.25rem 1rem;
  border-radius: 0.25rem;
  margin: 1.25rem 0px 1.25rem auto;
  max-width: 14.0625rem; }
  .investor-need-help-container h2 {
    font-size: 2rem;
    line-height: 2.4rem;
    color: inherit; }
  .investor-need-help-container .investor-need-help-image {
    width: 100%; }
  .investor-need-help-container .investor-need-help-content {
    font-family: "OpenSans-Regular";
    font-size: 0.875rem;
    color: inherit;
    line-height: 1.3125rem; }
  .investor-need-help-container .investor-need-help-btn,
  .investor-need-help-container a.investor-need-help-btn {
    width: 100%;
    margin: auto;
    background-color: inherit;
    border-radius: 0.25rem;
    font-size: 0.9375rem;
    color: inherit;
    letter-spacing: 0;
    line-height: 1.25rem;
    padding-top: 0.625rem;
    padding-bottom: 0.625rem; }
    .investor-need-help-container .investor-need-help-btn a,
    .investor-need-help-container a.investor-need-help-btn a {
      color: inherit; }

.investor-relations-left-section {
  word-break: break-word;
  word-wrap: break-word;
  display: block;
  margin: auto 0px;
  min-height: 32.8125rem !important; }

.investor-banner-section {
  min-height: 32.8125rem;
  display: flex;
  align-items: center;
  justify-content: flex-end; }

.investor-relations-content-wrapper .investor-relations-content-title-text .fhl-richtext-wrapper {
  font-family: "OpenSans-Regular";
  font-size: 0.875rem;
  color: inherit;
  line-height: 1.3125rem; }
  .investor-relations-content-wrapper .investor-relations-content-title-text .fhl-richtext-wrapper div p em {
    font-style: italic;
    font-family: "OpenSans-Italic";
    font-weight: normal; }
  .investor-relations-content-wrapper .investor-relations-content-title-text .fhl-richtext-wrapper div p strong {
    font-weight: 300;
    font-style: normal;
    font-family: "OpenSans-Semibold"; }
  .investor-relations-content-wrapper .investor-relations-content-title-text .fhl-richtext-wrapper div p strong {
    font-weight: 300;
    font-style: normal;
    font-family: "OpenSans-Semibold"; }
    .investor-relations-content-wrapper .investor-relations-content-title-text .fhl-richtext-wrapper div p strong em {
      font-style: italic;
      font-family: "OpenSans-Italic";
      font-weight: normal; }
  .investor-relations-content-wrapper .investor-relations-content-title-text .fhl-richtext-wrapper div p em {
    font-style: italic;
    font-family: "OpenSans-Italic";
    font-weight: normal; }
    .investor-relations-content-wrapper .investor-relations-content-title-text .fhl-richtext-wrapper div p em strong {
      font-weight: 300;
      font-style: normal;
      font-family: "OpenSans-Semibold"; }
  .investor-relations-content-wrapper .investor-relations-content-title-text .fhl-richtext-wrapper div p a {
    display: inline !important; }

.investor-relations-content-wrapper .investor-top-table-section .col-md-4,
.investor-relations-content-wrapper .investor-top-table-section .col-md-2 {
  flex: 0 0 20%;
  max-width: 20%; }

.investor-relations-content-wrapper .investor-top-table-section .investor-tabular-border-green {
  border-bottom: 1px solid #00833c; }

.investor-relations-content-wrapper .investor-top-table-section .investor-tabular-border {
  border-bottom: 1px solid #d8d8d8; }

.investor-relations-content-wrapper .investor-top-table-section .col-md-4,
.investor-relations-content-wrapper .investor-top-table-section .col-md-2 {
  padding: 0.625rem; }

.investor-relations-content-wrapper .fhl-extrasmall-column-title-wrapper h2,
.investor-relations-content-wrapper .fhl-extrasmall-column-responsive-title h2 {
  font-family: "HarmoniaSansW05-Semibold";
  font-weight: 600;
  font-style: normal;
  font-size: 0.875rem;
  color: inherit;
  letter-spacing: 0.125rem;
  text-align: center;
  margin: 0px auto; }

.investor-relations-content-wrapper .simple-smallText-wrapper {
  color: inherit;
  font-size: 0.75rem !important; }
  .investor-relations-content-wrapper .simple-smallText-wrapper p {
    color: inherit;
    font-size: 0.75rem !important; }
    .investor-relations-content-wrapper .simple-smallText-wrapper p a {
      color: inherit; }

.investor-relations-content-wrapper .editable-template-border {
  border-bottom: 1px solid #d8d8d8;
  margin: 1.875rem 0rem 1.5rem; }

.investor-relations-content-wrapper .simple-smallText-wrapper p,
.investor-relations-content-wrapper .simple-smallText-wrapper p.content-anchor-link,
.investor-relations-content-wrapper .simple-smallText-wrapper p.content-anchor-link a,
.investor-relations-content-wrapper .link-content-container p,
.investor-relations-content-wrapper .link-content-container p.content-anchor-link,
.investor-relations-content-wrapper .link-content-container p.content-anchor-link a {
  font-size: 0.75rem !important;
  letter-spacing: 0.106875rem;
  text-align: center;
  font-family: "OpenSans-Regular";
  font-size: 0.875rem;
  color: inherit;
  margin-bottom: 0; }

.investor-relations-content-wrapper .simple-smallText-wrapper p.pdf-content-anchor-link,
.investor-relations-content-wrapper .simple-smallText-wrapper p.pdf-content-anchor-link a,
.investor-relations-content-wrapper .link-content-container p.pdf-content-anchor-link,
.investor-relations-content-wrapper .link-content-container p.pdf-content-anchor-link a {
  letter-spacing: 0rem;
  text-align: left;
  font-family: "OpenSans-Semibold";
  font-size: 1rem;
  line-height: 1.4rem;
  color: inherit;
  margin-bottom: 0.3125rem;
  text-decoration: underline; }

.investor-relations-content-wrapper .investor-relations-editable-templates .advance-features-content-wrapper .fhl-richtext-wrapper {
  font-family: "OpenSans-Regular"; }
  .investor-relations-content-wrapper .investor-relations-editable-templates .advance-features-content-wrapper .fhl-richtext-wrapper div p {
    margin: 0; }

.simple-smallText-wrapper,
.simple-mediumText-wrapper {
  color: inherit; }
  .simple-smallText-wrapper p,
  .simple-mediumText-wrapper p {
    color: inherit;
    letter-spacing: 0px;
    text-align: left;
    font-family: "OpenSans-Regular";
    margin-bottom: 0; }
    .simple-smallText-wrapper p a,
    .simple-mediumText-wrapper p a {
      color: inherit;
      text-decoration: underline; }
    .simple-smallText-wrapper p span em,
    .simple-mediumText-wrapper p span em {
      font-family: "OpenSans-Italic";
      font-weight: normal;
      font-style: italic;
      color: inherit; }
    .simple-smallText-wrapper p em,
    .simple-mediumText-wrapper p em {
      font-family: "OpenSans-Italic";
      font-weight: normal;
      font-style: italic;
      color: inherit; }
      .simple-smallText-wrapper p em strong,
      .simple-mediumText-wrapper p em strong {
        font-family: "OpenSans-SemiboldItalic";
        font-weight: 300;
        font-style: italic;
        color: inherit; }
    .simple-smallText-wrapper p strong,
    .simple-smallText-wrapper p b,
    .simple-mediumText-wrapper p strong,
    .simple-mediumText-wrapper p b {
      font-family: "OpenSans-Semibold";
      font-weight: 300;
      font-style: normal;
      color: inherit; }
      .simple-smallText-wrapper p strong em,
      .simple-smallText-wrapper p b em,
      .simple-mediumText-wrapper p strong em,
      .simple-mediumText-wrapper p b em {
        font-family: "OpenSans-SemiboldItalic";
        font-weight: 300;
        font-style: italic;
        color: inherit; }

.simple-smallText-wrapper {
  font-size: 0.75rem !important; }
  .simple-smallText-wrapper p {
    font-size: 0.75rem !important; }

.simple-mediumText-wrapper {
  font-size: 0.875rem !important;
  line-height: 1.3125rem !important; }
  .simple-mediumText-wrapper p {
    font-size: 0.875rem !important;
    line-height: 1.3125rem !important; }

@media only screen and (max-width: 767px) {
  .investor-need-help-container {
    margin: 3rem auto 2rem !important;
    max-width: 75%; }
  .investor-relations-left-section {
    min-height: auto !important; }
  .investor-relation-mobile-topmargin {
    margin-top: -2.5rem; }
  .investor-top-table-section {
    overflow-x: auto;
    margin-bottom: 3.75rem !important; }
    .investor-top-table-section .row {
      min-width: 37.5rem; }
  .investor-relations-right-section {
    display: flex;
    justify-content: center; }
  .investor-relations-editable-templates .fhl-extrasmall-titlebar-wrapper {
    margin-bottom: 1.75rem; } }

@media only screen and (min-width: 320px) and (max-width: 375px) {
  .investor-need-help-container {
    max-width: 15.625rem !important; } }

@media only screen and (min-width: 768px) {
  .investor-relations-editable-templates .advance-sub-header {
    flex: 0 0 35%;
    max-width: 35%; }
  .investor-relations-editable-templates .advance-features-content-wrapper {
    flex: 0 0 65%;
    max-width: 65%; }
  .investor-top-table-section {
    margin-bottom: 5rem; }
  .fhl-extrasmall-column-responsive-title h2 {
    width: 80%; } }

@media only screen and (min-width: 992px) {
  .investor-relations-editable-templates .advance-sub-header {
    flex: 0 0 25%;
    max-width: 25%; }
  .investor-relations-editable-templates .advance-features-content-wrapper {
    flex: 0 0 75%;
    max-width: 75%; }
  .investor-top-table-section {
    margin-bottom: 5.625rem; }
  .fhl-extrasmall-column-responsive-title h2 {
    width: 100%; } }

.rates-left-section {
  word-break: break-word;
  word-wrap: break-word;
  display: block;
  margin: auto 0px;
  min-height: 32.8125rem !important; }

.rates-banner-section {
  min-height: 32.8125rem;
  display: flex;
  align-items: center;
  justify-content: flex-end; }

.rates-promo-right-wrapper {
  word-break: break-word;
  word-wrap: break-word;
  justify-content: center;
  max-width: 14.0625rem; }
  .rates-promo-right-wrapper .smalltext {
    font-size: 1rem;
    color: inherit;
    letter-spacing: 0.125rem;
    line-height: 1.2rem;
    margin-bottom: 0px; }
  .rates-promo-right-wrapper h2 {
    font-size: 2rem;
    color: inherit;
    line-height: 2.4rem;
    border-bottom: 1px solid inherit;
    width: 100%;
    margin-bottom: 1.5625rem; }
  .rates-promo-right-wrapper .rates-promo-right-wrapper-lead-para {
    font-family: "Opensans-Regular";
    color: inherit;
    line-height: 1.3125rem;
    font-size: 0.875rem;
    margin-bottom: 1.5625rem; }
    .rates-promo-right-wrapper .rates-promo-right-wrapper-lead-para p {
      font-family: "Opensans-Regular";
      font-weight: normal;
      font-style: normal; }
      .rates-promo-right-wrapper .rates-promo-right-wrapper-lead-para p em {
        font-family: "OpenSans-Italic";
        font-weight: normal;
        font-style: italic; }
        .rates-promo-right-wrapper .rates-promo-right-wrapper-lead-para p em strong {
          font-family: "OpenSans-SemiboldItalic";
          font-weight: 300;
          font-style: italic; }
      .rates-promo-right-wrapper .rates-promo-right-wrapper-lead-para p strong {
        font-family: "OpenSans-Semibold";
        font-weight: 300;
        font-style: normal; }
        .rates-promo-right-wrapper .rates-promo-right-wrapper-lead-para p strong em {
          font-family: "OpenSans-SemiboldItalic";
          font-weight: 300;
          font-style: italic; }
      .rates-promo-right-wrapper .rates-promo-right-wrapper-lead-para p a {
        color: inherit; }
  .rates-promo-right-wrapper img {
    width: 13.75rem;
    height: 9.375rem; }

.fhlb-split-content-left-area .rates-tab {
  margin-left: -0.3125rem;
  margin-top: 1.25rem; }

@media only screen and (max-width: 767px) {
  .rates-left-section {
    min-height: auto !important; }
  .rates-banner-section {
    margin-top: 4.625rem; }
  .rates-right-section {
    display: flex;
    justify-content: left;
    margin: 3rem 0 3rem 5%; }
  .rates-alert-wrapper {
    margin: 0px -0.625rem; } }

@media only screen and (min-width: 768px) {
  .rates-alert-wrapper {
    margin-left: 0.3125rem;
    width: 100%; }
  .rates-banner-section {
    margin-top: 3.125rem; } }

@media only screen and (min-width: 992px) {
  .rates-banner-section {
    margin-top: 0rem; }
  .rates-alert-wrapper {
    margin-left: 1.25rem;
    width: 105%; }
    .rates-alert-wrapper .error-message-wrapper .errormsg-wrapper .alert-labeled-row .alert-body {
      padding-left: 19px;
      padding-right: 10px; } }

@media only screen and (min-width: 1200px) {
  .rates-alert-wrapper {
    margin-left: 0.625rem;
    width: 98%;
    margin-right: -10px; }
    .rates-alert-wrapper .error-message-wrapper {
      max-width: 100%; }
      .rates-alert-wrapper .error-message-wrapper .errormsg-wrapper .alert-labeled-row .alert-body {
        padding: 1.5rem; } }

.rates-tab .nav-tabs {
  border-bottom: 0px; }
  .rates-tab .nav-tabs .nav-link {
    color: #00833c !important; }

.rates-tab .nav-item {
  display: block;
  padding: 0.5rem 0.5rem; }

.rates-tab .nav-link {
  display: block;
  padding: 0px; }

.rates-tab .nav-tabs .nav-link {
  border: 0px; }

.rates-tab .nav-tabs .nav-link.active,
.rates-tab .nav-tabs .nav-item.show .nav-link {
  border-bottom: 0.125rem solid #00833c;
  padding-bottom: 0.125rem; }

.rates-tab .tab-content {
  padding-top: 1.5625rem; }

.fhl-rates-page-wrapper {
  width: 100%; }
  .fhl-rates-page-wrapper p {
    font-family: "Opensans-Regular";
    color: #002a3a;
    line-height: 1.3125rem;
    font-size: 0.875rem;
    margin-bottom: 1.5625rem; }
  .fhl-rates-page-wrapper .dynamic-rates-cntr p {
    font-family: "Opensans-Regular";
    font-size: 0.875rem;
    color: #002a3a;
    line-height: 1.3125rem;
    letter-spacing: 0rem; }
  .fhl-rates-page-wrapper .dynamic-rates-cntr .rates-block-header {
    font-family: "HarmoniaSansW05-Regular";
    font-size: 1.125rem;
    color: #002a3a;
    letter-spacing: 0rem;
    line-height: 1.575rem; }
  .fhl-rates-page-wrapper .dynamic-rates-cntr .rates-page table {
    border: 0px;
    width: 100%;
    table-layout: fixed; }
  .fhl-rates-page-wrapper .dynamic-rates-cntr .rates-page caption {
    caption-side: top; }
  .fhl-rates-page-wrapper .dynamic-rates-cntr .rates-page .col-header-th {
    font-family: "HarmoniaSansW05-Semibold";
    border-bottom: 1px solid #d8d8d8;
    font-size: 0.875rem;
    color: #002a3a;
    letter-spacing: 0.125rem;
    text-align: center;
    border-top: 1px solid #00833c;
    height: 2.1875rem;
    padding-top: 10px;
    width: auto; }
  .fhl-rates-page-wrapper .dynamic-rates-cntr .rates-page .col-value-td {
    border-bottom: 1px solid #d8d8d8;
    font-family: "Opensans-Regular";
    font-size: 0.75rem;
    color: #58585a;
    letter-spacing: 0.106875rem;
    text-align: center;
    line-height: 2.5rem;
    width: auto; }
  .fhl-rates-page-wrapper .dynamic-rates-cntr .rates-button-wrapper {
    margin-top: 3.125rem;
    display: flex; }
    .fhl-rates-page-wrapper .dynamic-rates-cntr .rates-button-wrapper button:focus {
      box-shadow: 0 0 0 0.1rem #2b9be0 !important; }
    .fhl-rates-page-wrapper .dynamic-rates-cntr .rates-button-wrapper .rates_download_icon {
      background-size: 74%; }
    .fhl-rates-page-wrapper .dynamic-rates-cntr .rates-button-wrapper .rates_download {
      background-size: 70%; }
    .fhl-rates-page-wrapper .dynamic-rates-cntr .rates-button-wrapper .rates_download_icon,
    .fhl-rates-page-wrapper .dynamic-rates-cntr .rates-button-wrapper .rates_download {
      background-image: url("../assets/images/icon_download.svg");
      background-repeat: no-repeat;
      margin-left: 0.625rem;
      min-width: 1.4375rem;
      height: 1.25rem; }
  .fhl-rates-page-wrapper .dynamic-rates-cntr button:disabled {
    background-color: #767676 !important;
    color: #fff !important;
    cursor: not-allowed; }
  .fhl-rates-page-wrapper .dynamic-rates-cntr .historical-rates-btn,
  .fhl-rates-page-wrapper .dynamic-rates-cntr .download-rates-btn {
    border-radius: 0.25rem;
    font-family: "HarmoniaSansW05-Regular";
    font-size: 1rem;
    color: #ffffff;
    letter-spacing: 0;
    text-align: center;
    padding: 5px 25px;
    height: 2.1875rem;
    display: flex;
    justify-content: center;
    margin-right: 1.25rem; }
  .fhl-rates-page-wrapper .dynamic-rates-cntr .historical-rates-btn {
    background: #004987;
    width: 9.8125rem; }
  .fhl-rates-page-wrapper .dynamic-rates-cntr .download-rates-btn {
    background: #00833c;
    width: 100%; }
  .fhl-rates-page-wrapper .dynamic-rates-cntr .download-rates-btn:focus {
    outline: 0;
    border: 0;
    box-shadow: 0 0 0 0.1rem #2b9be0 !important; }

.dynamic-rate-block {
  display: flex; }
  .dynamic-rate-block .dynamic-rate-block button {
    margin-right: 1.25rem; }

@media only screen and (max-width: 767px) {
  .rates-mobile-section {
    padding: 0 !important; }
  .fhl-rates-page-wrapper {
    margin-left: -0.5625rem; }
    .fhl-rates-page-wrapper .dynamic-rates-cntr .rates-button-wrapper .col-9 {
      max-width: 100%;
      flex: 0 0 100%; }
    .fhl-rates-page-wrapper .dynamic-rates-cntr .rates-page {
      overflow-x: auto; }
      .fhl-rates-page-wrapper .dynamic-rates-cntr .rates-page table {
        min-width: 31.25rem; }
  .fhl-rates-page-wrapper .dynamic-rates-cntr .rates-button-wrapper {
    flex-direction: column; }
  .rates-online-banking .fhl-title-content-btn-wrapper {
    max-width: 100%; }
    .rates-online-banking .fhl-title-content-btn-wrapper h2 {
      font-size: 2rem;
      line-height: 2.4rem;
      margin-bottom: 0rem; }
    .rates-online-banking .fhl-title-content-btn-wrapper div p {
      margin-right: 0 !important;
      margin-bottom: 1.25rem !important; }
  .rates-mobile-button-wrapper .col-6 {
    max-width: 270px;
    margin-bottom: 25px; }
  .rates-mobile-button-wrapper {
    flex-direction: column; }
  .rates-button-error-wrapper {
    margin: 30px 0px -135px 0px; }
    .rates-button-error-wrapper .errormsg-wrapper {
      padding: 0; }
      .rates-button-error-wrapper .errormsg-wrapper .col-lg-12 {
        padding: 0; } }

@media only screen and (min-width: 768px) {
  .rates-mobile-button-wrapper .col-6 {
    max-width: 270px;
    margin-bottom: 25px; }
  .rates-mobile-button-wrapper {
    flex-direction: column; }
  .rates-button-error-wrapper {
    margin: 30px 0px -140px -15px;
    width: 110%; }
  .fhl-rates-page-wrapper {
    margin-left: -0.5625rem; }
  .advance-media-login-wrapper.rates-online-banking .fhl-title-content-btn-wrapper div a.left-arrow-icon {
    display: inline-block !important; }
  .rates-online-banking .fhl-title-content-btn-wrapper div a.left-arrow-icon i {
    margin-top: 0rem; }
  .rates-online-banking .fhl-title-content-btn-wrapper {
    max-width: 92%; }
    .rates-online-banking .fhl-title-content-btn-wrapper .d-flex {
      flex-direction: column; }
      .rates-online-banking .fhl-title-content-btn-wrapper .d-flex a {
        margin-left: 0;
        margin-top: 0.9375rem; }
  .fhl-rates-page-wrapper .dynamic-rates-cntr .rates-button-wrapper {
    flex-direction: column; }
    .fhl-rates-page-wrapper .dynamic-rates-cntr .rates-button-wrapper .rates_download_icon {
      background-size: 70%; } }

@media only screen and (min-width: 992px) {
  .fhl-rates-page-wrapper .dynamic-rates-cntr .rates-button-wrapper {
    max-width: 100%; }
  .fhl-rates-page-wrapper .dynamic-rates-cntr .rates-page table {
    width: 100%; }
  .fhl-rates-page-wrapper .dynamic-rates-cntr .rates-button-wrapper .rates_download_icon {
    background-size: 72%; }
  .rates-mobile-button-wrapper .col-6 {
    max-width: 50%;
    margin-bottom: 0px; }
  .rates-mobile-button-wrapper {
    flex-direction: row; }
  .fhl-rates-page-wrapper {
    margin-left: 0rem; }
  .rates-tab .nav-tabs {
    margin-left: 0.5625rem; }
  .rates-online-banking {
    margin-left: 0.625rem !important; }
    .rates-online-banking .fhl-title-content-btn-wrapper {
      max-width: 97%; }
      .rates-online-banking .fhl-title-content-btn-wrapper .d-flex {
        flex-direction: row; }
        .rates-online-banking .fhl-title-content-btn-wrapper .d-flex a {
          margin-top: 0;
          margin-left: 1.25rem; }
  .fhl-rates-page-wrapper .dynamic-rates-cntr .rates-button-wrapper {
    flex-direction: row; }
    .fhl-rates-page-wrapper .dynamic-rates-cntr .rates-button-wrapper button {
      margin-bottom: 0rem; }
    .fhl-rates-page-wrapper .dynamic-rates-cntr .rates-button-wrapper button:last-child {
      margin-bottom: 0px; }
  .rates-button-error-wrapper {
    margin: 25px 0px -135px -16px;
    width: 111%; }
    .rates-button-error-wrapper .error-message-wrapper {
      max-width: 100%; }
      .rates-button-error-wrapper .error-message-wrapper .errormsg-wrapper .alert-labeled-row .alert-body {
        padding-left: 19px; } }

@media only screen and (min-width: 1200px) {
  .fhl-rates-page-wrapper {
    margin-left: -0.5rem; }
    .fhl-rates-page-wrapper .dynamic-rates-cntr .rates-button-wrapper {
      max-width: auto; }
    .fhl-rates-page-wrapper .dynamic-rates-cntr .rates-page table {
      width: 45.625rem; }
  .rates-tab .nav-tabs {
    margin-left: 0rem; }
  .rates-online-banking {
    margin-left: 0px !important; }
    .rates-online-banking .fhl-title-content-btn-wrapper {
      max-width: 735px; }
  .rates-button-error-wrapper {
    width: 103%; } }

.forms-media-wrapper {
  padding: 1.25rem 0 0 !important; }
  .forms-media-wrapper #forms-results .error-message-wrapper {
    margin: 0px auto; }
  .forms-media-wrapper .forms-results-wrapper {
    overflow: visible;
    /* Hide the browser's default checkbox */
    margin: 0.3125rem 0 2.5rem; }
    .forms-media-wrapper .forms-results-wrapper .forms-result-page {
      min-height: 47.8125rem; }
    .forms-media-wrapper .forms-results-wrapper .forms-result-title {
      font-size: 1rem;
      font-family: "HarmoniaSansW05-Regular";
      color: #004987;
      line-height: 1.2rem;
      letter-spacing: 0.125rem;
      display: table;
      padding-bottom: 1.125rem; }
    .forms-media-wrapper .forms-results-wrapper ul li {
      list-style-type: none; }

@media only screen and (max-width: 767px) {
  .forms-media-wrapper {
    padding: 1.875rem 1rem !important; }
    .forms-media-wrapper .narrow-list-wrapper {
      max-width: 100%;
      margin-bottom: 1.875rem; }
    .forms-media-wrapper .forms-results-wrapper {
      padding: 0px 0.9375rem; }
      .forms-media-wrapper .forms-results-wrapper .forms-result-page {
        min-height: 6.25rem; }
        .forms-media-wrapper .forms-results-wrapper .forms-result-page .col-md-6 {
          padding: 0;
          margin-bottom: 3.25rem; }
        .forms-media-wrapper .forms-results-wrapper .forms-result-page .col-md-12 {
          padding: 0; }
        .forms-media-wrapper .forms-results-wrapper .forms-result-page .col-md-3 {
          margin-bottom: 0.625rem; }
    .forms-media-wrapper .forms-result-list-view {
      margin-bottom: 6.25rem;
      margin-top: 2.6875rem; } }

@media only screen and (min-width: 768px) {
  .forms-media-wrapper .row .col-md-3 {
    max-width: 35%; }
    .forms-media-wrapper .row .col-md-3 .narrow-list-wrapper {
      width: 225px; }
  .forms-media-wrapper .row .col-md-9 {
    max-width: 65%; }
    .forms-media-wrapper .row .col-md-9 .forms-result-page .col-md-3 {
      margin-bottom: 0.625rem; }
    .forms-media-wrapper .row .col-md-9 .forms-result-page .col-md-3,
    .forms-media-wrapper .row .col-md-9 .forms-result-page .col-md-9 {
      max-width: 100%;
      flex: 0 0 100%; }
  .forms-media-wrapper .forms-result-list-view {
    margin-bottom: 3.75rem; } }

@media only screen and (min-width: 992px) {
  .forms-media-wrapper .row .col-md-3 {
    max-width: 25%;
    padding: 0 0 0 15px;
    flex: 0 0 25%; }
  .forms-media-wrapper .row .col-md-9 {
    max-width: 75%;
    padding: 0px 15px 0px 0px;
    flex: 0 0 75%; }
    .forms-media-wrapper .row .col-md-9 .forms-result-page .col-md-3 {
      max-width: 25%;
      flex: 0 0 25%; }
    .forms-media-wrapper .row .col-md-9 .forms-result-page .col-md-9 {
      max-width: 75%;
      flex: 0 0 75%; }
  .forms-media-wrapper .forms-result-list-view {
    margin-bottom: 4.0625rem; } }

@media only screen and (min-width: 1200px) {
  .forms-media-wrapper .row .col-md-9 .forms-result-page .col-md-3 {
    max-width: 25%;
    flex: 0 0 25%; }
  .forms-media-wrapper .row .col-md-9 .forms-result-page .col-md-9 {
    max-width: 75%;
    flex: 0 0 75%; } }

.historical-title {
  margin-top: 1.5625rem; }
  .historical-title .fhl-richtext-wrapper {
    font-family: "OpenSans-Semibold";
    font-weight: 300;
    font-style: normal;
    font-size: 1rem;
    color: #00833c;
    line-height: 1.3125rem; }

.fhl-historical-page-wrapper {
  width: 100%; }
  .fhl-historical-page-wrapper p {
    font-family: "Opensans-Regular";
    color: #002a3a;
    line-height: 1.3125rem;
    font-size: 0.875rem;
    margin-bottom: 1.5625rem; }
  .fhl-historical-page-wrapper .historical-rates-cntr .historical-rates-button-wrapper {
    display: flex; }
    .fhl-historical-page-wrapper .historical-rates-cntr .historical-rates-button-wrapper .historical-picker {
      margin-right: 1.25rem;
      margin-left: -0.625rem;
      max-width: 10.3125rem; }
    .fhl-historical-page-wrapper .historical-rates-cntr .historical-rates-button-wrapper .calendar-picker-placeholder {
      font-family: "HarmoniaSansW05-Regular";
      margin-right: 1.25rem;
      margin-left: -0.625rem;
      max-width: 10.3125rem;
      margin-top: -10px;
      margin-bottom: 5px;
      font-size: 12px;
      color: #58585a; }
    .fhl-historical-page-wrapper .historical-rates-cntr .historical-rates-button-wrapper > button:focus {
      outline: 0;
      border: 0;
      box-shadow: 0 0 0 0 transparent !important; }
    .fhl-historical-page-wrapper .historical-rates-cntr .historical-rates-button-wrapper .rates_download_icon {
      background-size: 74%; }
    .fhl-historical-page-wrapper .historical-rates-cntr .historical-rates-button-wrapper .rates_download {
      background-size: 70%; }
    .fhl-historical-page-wrapper .historical-rates-cntr .historical-rates-button-wrapper .rates_download_icon,
    .fhl-historical-page-wrapper .historical-rates-cntr .historical-rates-button-wrapper .rates_download {
      background-image: url("../assets/images/icon_download.svg");
      background-repeat: no-repeat;
      margin-left: 0.625rem;
      min-width: 1.4375rem;
      height: 1.25rem; }
    .fhl-historical-page-wrapper .historical-rates-cntr .historical-rates-button-wrapper > button:disabled {
      background-color: #767676 !important;
      color: #fff !important;
      cursor: not-allowed; }
    .fhl-historical-page-wrapper .historical-rates-cntr .historical-rates-button-wrapper .historical-rates-btn,
    .fhl-historical-page-wrapper .historical-rates-cntr .historical-rates-button-wrapper .download-rates-btn {
      border-radius: 0.25rem;
      font-family: "HarmoniaSansW05-Regular";
      font-size: 1rem;
      color: #ffffff;
      letter-spacing: 0;
      text-align: center;
      padding: 5px 25px;
      height: 2.25rem;
      display: flex;
      justify-content: center;
      margin-right: 1.25rem; }
    .fhl-historical-page-wrapper .historical-rates-cntr .historical-rates-button-wrapper .historical-rates-btn {
      background: #004987;
      width: 9.8125rem; }
    .fhl-historical-page-wrapper .historical-rates-cntr .historical-rates-button-wrapper .download-rates-btn {
      background: #00833c;
      width: 100%; }
    .fhl-historical-page-wrapper .historical-rates-cntr .historical-rates-button-wrapper .download-rates-btn:focus {
      outline: 0;
      border: 0;
      box-shadow: 0 0 0 0.1rem #2b9be0 !important; }
    .fhl-historical-page-wrapper .historical-rates-cntr .historical-rates-button-wrapper input.selection:focus {
      box-shadow: 0 0 0 0.1rem #2b9be0 !important; }
    .fhl-historical-page-wrapper .historical-rates-cntr .historical-rates-button-wrapper .btnpicker.btnpickerenabled:focus {
      box-shadow: 0 0 0 0.1rem #2b9be0 !important; }

@media only screen and (max-width: 767px) {
  .fhl-historical-page-wrapper {
    margin-left: -0.375rem; }
    .fhl-historical-page-wrapper .historical-rates-cntr .historical-rates-button-wrapper {
      flex-direction: column; }
      .fhl-historical-page-wrapper .historical-rates-cntr .historical-rates-button-wrapper > button {
        max-width: 270px;
        margin-bottom: 1.5625rem;
        margin-left: -0.625rem; }
      .fhl-historical-page-wrapper .historical-rates-cntr .historical-rates-button-wrapper > button:last-child {
        margin-bottom: 2.5rem; }
      .fhl-historical-page-wrapper .historical-rates-cntr .historical-rates-button-wrapper .historical-picker {
        margin-bottom: 20px; }
    .fhl-historical-page-wrapper .rates-button-error-wrapper {
      margin: 0px; }
      .fhl-historical-page-wrapper .rates-button-error-wrapper .errormsg-wrapper {
        padding: 0; }
        .fhl-historical-page-wrapper .rates-button-error-wrapper .errormsg-wrapper .col-lg-12 {
          padding: 0; } }

@media only screen and (min-width: 768px) {
  .fhlb-table-styles .col-md-12.table-top-border-green {
    margin-left: 2%; }
  .fhl-historical-page-wrapper {
    margin-left: -0.375rem; }
    .fhl-historical-page-wrapper .historical-rates-cntr .historical-rates-button-wrapper {
      flex-direction: column; }
      .fhl-historical-page-wrapper .historical-rates-cntr .historical-rates-button-wrapper > button {
        max-width: 270px;
        margin-bottom: 1.5625rem;
        margin-left: -0.625rem; }
      .fhl-historical-page-wrapper .historical-rates-cntr .historical-rates-button-wrapper > button:last-child {
        margin-bottom: 2.5rem; }
      .fhl-historical-page-wrapper .historical-rates-cntr .historical-rates-button-wrapper .historical-picker {
        margin-bottom: 20px; }
      .fhl-historical-page-wrapper .historical-rates-cntr .historical-rates-button-wrapper .rates_download_icon {
        background-size: 70%; }
    .fhl-historical-page-wrapper .rates-button-error-wrapper {
      margin: 30px 0px 30px -15px;
      width: 110%; } }

@media only screen and (min-width: 992px) {
  .historical-title {
    margin-left: 0.375rem; }
  .fhlb-table-styles .col-md-12.table-top-border-green {
    margin-left: 1%; }
  .fhl-historical-page-wrapper {
    margin-left: 0rem; }
    .fhl-historical-page-wrapper .historical-rates-cntr .historical-rates-button-wrapper {
      max-width: 100%;
      flex-direction: row; }
      .fhl-historical-page-wrapper .historical-rates-cntr .historical-rates-button-wrapper > button {
        margin-bottom: 0rem;
        margin-left: 0rem;
        max-width: 100%; }
      .fhl-historical-page-wrapper .historical-rates-cntr .historical-rates-button-wrapper > button:last-child {
        margin-bottom: 0rem;
        margin-right: 0.875rem; }
      .fhl-historical-page-wrapper .historical-rates-cntr .historical-rates-button-wrapper .rates_download_icon {
        background-size: 72%; }
    .fhl-historical-page-wrapper .rates-button-error-wrapper {
      margin: 0px 0px -60px -25px;
      width: 108%; }
      .fhl-historical-page-wrapper .rates-button-error-wrapper .error-message-wrapper {
        max-width: 100%; }
        .fhl-historical-page-wrapper .rates-button-error-wrapper .error-message-wrapper .errormsg-wrapper .alert-labeled-row .alert-body {
          padding-left: 19px; } }

@media only screen and (min-width: 1200px) {
  .historical-title {
    margin-left: 0rem; }
  .fhl-historical-page-wrapper {
    margin-left: -0.5rem; }
    .fhl-historical-page-wrapper .rates-button-error-wrapper {
      width: 50.4375rem; } }

.fhl-investor-page-wrapper {
  width: 100%; }
  .fhl-investor-page-wrapper p {
    font-family: "Opensans-Regular";
    color: #002a3a;
    line-height: 1.3125rem;
    font-size: 0.875rem;
    margin-bottom: 1.5625rem; }
  .fhl-investor-page-wrapper .dynamic-investor-cntr p {
    font-family: "Opensans-Regular";
    font-size: 0.875rem;
    color: #002a3a;
    line-height: 1.3125rem;
    letter-spacing: 0rem; }
  .fhl-investor-page-wrapper .dynamic-investor-cntr .investor-block-header {
    font-family: "HarmoniaSansW05-Regular";
    font-size: 1.125rem;
    color: #002a3a;
    letter-spacing: 0rem;
    line-height: 1.575rem; }
  .fhl-investor-page-wrapper .dynamic-investor-cntr .investor-page {
    padding: 0rem; }
    .fhl-investor-page-wrapper .dynamic-investor-cntr .investor-page table {
      border: 0px;
      width: 100%;
      table-layout: fixed; }
    .fhl-investor-page-wrapper .dynamic-investor-cntr .investor-page caption {
      caption-side: top; }
    .fhl-investor-page-wrapper .dynamic-investor-cntr .investor-page .col-header-th-form {
      font-family: "HarmoniaSansW05-Semibold";
      font-size: 0.875rem;
      color: #00833c;
      letter-spacing: 0.125rem;
      text-align: center;
      height: 2.1875rem;
      padding-top: 10px;
      width: auto; }
    .fhl-investor-page-wrapper .dynamic-investor-cntr .investor-page .col-header-th-quarter {
      font-family: "HarmoniaSansW05-Semibold";
      font-size: 0.875rem;
      color: #002a3a;
      letter-spacing: 0.125rem;
      text-align: center;
      height: 2.1875rem;
      padding-top: 10px;
      width: auto; }
    .fhl-investor-page-wrapper .dynamic-investor-cntr .investor-page .header-gray-border {
      border-bottom: 1px solid #d8d8d8; }
    .fhl-investor-page-wrapper .dynamic-investor-cntr .investor-page .header-green-border {
      border-bottom: 1px solid #00833c; }
    .fhl-investor-page-wrapper .dynamic-investor-cntr .investor-page .col-value-td {
      font-family: "Opensans-Regular";
      font-size: 0.75rem;
      color: #58585a;
      letter-spacing: 0.106875rem;
      text-align: center;
      line-height: 0.9375rem;
      width: auto;
      padding: 0.625rem 0rem; }
      .fhl-investor-page-wrapper .dynamic-investor-cntr .investor-page .col-value-td a {
        color: #58585a; }
      .fhl-investor-page-wrapper .dynamic-investor-cntr .investor-page .col-value-td .zip-button {
        cursor: pointer;
        background: none;
        border: 0;
        color: #58585a; }
      .fhl-investor-page-wrapper .dynamic-investor-cntr .investor-page .col-value-td .zip-button:hover {
        text-decoration: underline; }

@media only screen and (max-width: 767px) {
  .fhl-investor-page-wrapper .dynamic-investor-cntr .investor-page {
    overflow-x: auto;
    overflow-y: visible; }
    .fhl-investor-page-wrapper .dynamic-investor-cntr .investor-page table {
      min-width: 31.25rem; } }

@media only screen and (min-width: 992px) {
  .fhl-investor-page-wrapper .dynamic-investor-cntr .investor-page table {
    width: 100%; }
  .fhl-investor-page-wrapper {
    margin-left: 0rem; } }

/*************************************************
 FHL Application Browser SCSS
 ************************************************/
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  header .header-bar ul li {
    display: inline-block; }
    header .header-bar ul li .search-filter-container {
      margin-bottom: -7px; }
  .promo-col2 a.promo-tag-link i {
    width: 23px !important;
    font-size: 19px !important; }
  .aside-title-text-image-container {
    float: none; }
  .aside-title-text-image-container a,
  .title-text-image-container a {
    height: 100%; }
  a.left-arrow-icon i {
    line-height: 16px !important;
    padding: 4px 0px 0px 1px;
    width: 23px;
    font-size: 19px !important; }
  .image-header-content-anchor-link a.left-arrow-icon i {
    width: auto !important;
    margin-left: 0.625rem; }
  .classic-advance-promo-wrapper {
    align-items: stretch; }
    .classic-advance-promo-wrapper h1 {
      margin-top: 9.375rem; }
  .fhl-rates-promo-wrapper {
    align-items: stretch; }
  .fhl-rates-dynamic-promo-wrapper {
    min-height: 18.75rem; }
  .com-adv-ie-wrapper a.left-arrow-icon i {
    padding: 2px 3px 2px 1px !important; }
  .compare-advances-products-wrapper .product-top-title {
    align-items: flex-start; }
    .compare-advances-products-wrapper .product-top-title .removeCompareList {
      margin-top: 3px; }
  .accordion-content-box .card-body .filter-list label .checkmarkradio:after {
    top: 2.5px !important;
    left: 2px !important; }
  .secondary-header .dropdown-menu .container .row {
    float: none; }
  .fhlb-split-content-left-area td span a {
    display: none !important; }
  .events-article-content-block .events-right-section {
    margin-left: 70px !important; }
  .fhlb-split-content-right-section .fhlb-split-content-right-area .fhl-button-content-container i.material-icons {
    padding: 2px; }
  .fhlb-split-si-right-section a.left-arrow-icon .fhl-button-content-container .right-section i {
    padding: 2px 0px 2px 1px; }
  .news-events-detail-content-right-section a.left-arrow-icon .fhl-button-content-container .right-section i {
    line-height: 15px;
    padding: 2px 0 2px 1px; }
  .news-article-wrapper h2,
  .events-article-wrapper h2,
  .partnership-content-article .partership-bottom-container .partnership-column1 .partnership-content-contentbody,
  .partnership-content-article .partership-bottom-container .partnership-column2 .partnership-content-contentbody,
  .partnership-content-article .partnership-content-title,
  .partnership-content-article .partership-bottom-container .partnership-column1 h4,
  .partnership-content-article .partership-bottom-container .partnership-column2 h4,
  .news-article-wrapper .news-article-content-block h4,
  .events-article-wrapper .events-article-content-block .events-right-section h3,
  .membership-content-article,
  .pl-carousel-slide .title-text-article-container .title-text-right-section .title-text-article-title,
  .pl-carousel-slide .title-text-article-container .title-text-right-section .title-text-article-content,
  .pl-carousel-slide .title-text-article-container .title-text-right-section p a.title-text-anchor {
    word-wrap: break-word !important; }
  .pl-carousel-slide .title-text-article-container.w100pr.d-flex {
    display: block !important; }
  .pl-carousel-slide .title-text-article-container .title-text-right-section p a.title-text-anchor i {
    width: auto !important; }
  .aside-title-text-image-container p,
  .aside-title-text-image-container h1,
  .aside-title-text-image-container h2,
  .aside-title-text-image-container h3,
  .title-text-image-container p,
  .title-text-image-container h1,
  .title-text-image-container h2,
  .title-text-image-container h3,
  .fhl-medium-title-para-text-wrapper p,
  .fhl-medium-title-para-text-wrapper h1,
  .fhl-medium-title-para-text-wrapper h2,
  .fhl-medium-title-para-text-wrapper h3 {
    word-wrap: break-word !important; }
  .contacts-rhs-container .contacts-rhs-select-content .feature-list .list-content img {
    width: auto !important;
    height: auto !important; }
  .contacts-lhs-container .contacts-slot img {
    height: auto !important; }
  .imgcontent-layout-last-layer .imgcontent-layout-index-link .left-arrow-icon i,
  .imgcontent-layout-last-layer .imgcontent-layout-index-link .card-layout-anchor i {
    padding: 1px 0px 0px 1px !important; }
  .fhl-promo-header-content-wrapper,
  .fhl-promo-header-content-wrapper div {
    display: block !important; }
  .investor-relations-left-section .fhl-title-content-btn-wrapper,
  .investor-relations-left-section .fhl-title-content-btn-wrapper div,
  .rates-left-section .fhl-title-content-btn-wrapper,
  .rates-left-section .fhl-title-content-btn-wrapper div {
    display: block !important; }
  .img-header-content-container p.image-header-content-anchor-link,
  .image-header-content-link-container p.image-header-content-anchor-link {
    display: flex !important; }
  .search-results-wrapper button[type="reset"] {
    line-height: 19px !important;
    padding-left: 1px; }
  .search-results-wrapper .search-wrapper input[type="search"]::-ms-clear,
  .search-filter-container input[type="search"]::-ms-clear {
    display: none; }
  .advance-media-login-wrapper .fhl-title-content-btn-wrapper div a.left-arrow-icon i.material-icons {
    width: auto !important; }
  .rates-online-banking .fhl-title-content-btn-wrapper div {
    max-width: 100%;
    word-wrap: break-word; }
  .fhl-historical-page-wrapper .historical-rates-cntr .historical-rates-button-wrapper .calendar-picker-placeholder {
    margin-left: -8px !important; } }

@media only screen and (-ms-high-contrast: active) and (max-width: 767px), only screen and (-ms-high-contrast: none) and (max-width: 767px) {
  .rates-online-banking .fhl-title-content-btn-wrapper div a {
    display: flex;
    max-width: 100%; }
  .fhl-rates-page-wrapper .dynamic-rates-cntr .rates-button-wrapper .rates_download_icon {
    background-size: 70%; }
  .fhl-rates-page-wrapper .dynamic-rates-cntr .rates-button-wrapper {
    display: block; }
  .rates-mobile-button-wrapper .col-6 {
    flex: auto; }
  .rates-right-section {
    justify-content: flex-start; }
  .search-results-wrapper button[type="reset"] i {
    left: 0.0625rem;
    position: absolute;
    top: 1px; }
  .advance-media-login-wrapper .fhl-title-content-btn-wrapper div,
  .compare-advance-mobile-wrapper .fhl-title-content-btn-wrapper div {
    align-items: flex-start; }
  .contactus-left-section .fhl-title-content-btn-wrapper div,
  .contactus-right-section .fhl-title-content-btn-wrapper div {
    align-items: flex-start !important; }
  .advance-editable-templates-2 .aside-title-text-image-container {
    margin-top: 2.5rem !important; }
  .classic-advance-promo-wrapper h1 {
    margin-top: 0; }
  .fhlb-split-content-left-area .fhl-title-content-btn-wrapper div {
    flex-direction: row; } }

@media only screen and (-ms-high-contrast: active) and (min-width: 992px), only screen and (-ms-high-contrast: none) and (min-width: 992px) {
  table.compare-advance-table th,
  table.compare-advance-table td {
    min-width: 150px;
    max-width: inherit; }
  .secondary-header .navbar ul.navbar-nav li.main-menu a.nav-link {
    padding-bottom: 1.125rem; }
  .secondary-header .header-login-dropdown-content {
    margin-top: 0.625rem !important; }
  .compare-advances-products-wrapper .product-top-title {
    width: 150px; }
  .advance-feature-left {
    max-width: 76% !important; }
  .advance-feature-right {
    max-width: 225px !important;
    padding: 0px !important; }
  .fhl-rates-promo-wrapper {
    padding-top: 6.25rem; }
  .fhl-rates-dynamic-promo-wrapper {
    margin-top: 6.25rem; }
  .general-email-IE-browser .contacts-slot {
    margin-top: 0.625rem; } }

@media only screen and (-ms-high-contrast: active) and (max-width: 991px), only screen and (-ms-high-contrast: none) and (max-width: 991px) {
  .fhl-rates-page-wrapper .dynamic-rates-cntr .rates-button-wrapper {
    display: block; }
  .rates-mobile-button-wrapper .col-6 {
    flex: auto; }
  .promo-content-container .promo-main-content .promo-col1,
  .promo-content-container .promo-main-content .promo-col2,
  .promo-content-container .promo-main-without-content .promo-col1,
  .promo-content-container .promo-main-without-content .promo-col2,
  .promo-content-without-title-container .promo-main-content .promo-col1,
  .promo-content-without-title-container .promo-main-content .promo-col2,
  .promo-content-without-title-container .promo-main-without-content .promo-col1,
  .promo-content-without-title-container .promo-main-without-content .promo-col2 {
    flex: auto; }
  .promo-content-container .promo-main-content .promo-col2 a.promo-tag-link,
  .promo-content-container .promo-main-without-content .promo-col2 a.promo-tag-link,
  .promo-content-without-title-container .promo-main-content .promo-col2 a.promo-tag-link,
  .promo-content-without-title-container .promo-main-without-content .promo-col2 a.promo-tag-link {
    margin-top: 5px; } }

@media only screen and (-ms-high-contrast: active) and (min-width: 768px) and (max-width: 991px), only screen and (-ms-high-contrast: none) and (min-width: 768px) and (max-width: 991px) {
  .forms-media-wrapper .forms-result-page {
    margin: 0px 0px 0px 40px; }
  .fhlb-split-content-right-section .fhlb-split-content-right-area .aside-title-text-image-container h1 {
    line-height: 30px; }
  .fhl-rates-promo-wrapper {
    padding-top: 6.25rem; }
  .fhl-rates-dynamic-promo-wrapper {
    margin-top: 6.25rem; }
  .rates-online-banking .fhl-title-content-btn-wrapper .d-flex {
    align-items: flex-start; }
  .rates-online-banking .fhl-title-content-btn-wrapper div p {
    margin-bottom: 0px; }
  .rates-online-banking .fhl-title-content-btn-wrapper .d-flex a {
    display: flex;
    max-width: 100%; } }

@media only screen and (-ms-high-contrast: active) and (min-width: 992px) and (max-width: 1190px), only screen and (-ms-high-contrast: none) and (min-width: 992px) and (max-width: 1190px) {
  .classic-advance-promo-wrapper h1 {
    margin-top: 5.625rem; } }

@media only screen and (-ms-high-contrast: active) and (min-width: 1200px), only screen and (-ms-high-contrast: none) and (min-width: 1200px) {
  .fhl-rates-page-wrapper {
    margin-left: -0.5rem; }
  .compare-advances-products-wrapper .product-top-title {
    width: 180px; }
  .advance-feature-left {
    max-width: 80% !important; }
  .rates-online-banking .fhl-title-content-btn-wrapper div p {
    max-width: 21.875rem; } }

@supports (-ms-ime-align: auto) {
  .accordion-content-box .card-body .filter-list label .checkmarkradio:after {
    top: 2.5px !important;
    left: 3px !important; }
  .different-option-container {
    display: table; }
  a.left-arrow-icon {
    display: flex !important; }
  .com-adv-button-wrapper .fhl-button-content-container,
  .com-adv-button-wrapper a.left-arrow-icon {
    display: inline-block !important; }
  @media only screen and (min-width: 1200px) {
    .fhl-rates-page-wrapper {
      margin-left: -0.5rem; } } }

@-moz-document url-prefix() {
  .narrow-list-wrapper .accordion-content-box .card-body .filter-list label .checkmarkradio::after {
    left: 2px !important; }
  .secondary-header .mob-logo img {
    width: 11.5625rem; }
  .different-option-container {
    display: table; }
  .search-results-wrapper button[type="reset"] i {
    position: absolute;
    left: 0.09375rem;
    top: 1px;
    font-size: 14px; }
  .yearcell.tablesingleyear {
    vertical-align: middle; }
  .fhlb-calendar-wrapper .mydp .yearcell.tablesingleyear.selectedyear,
  .fhlb-calendar-wrapper .mydp .monthcell.selectedmonth {
    background-color: #00833c; }
  @media only screen and (max-width: 767px) {
    .search-results-wrapper button[type="reset"] i {
      left: 0.0625rem; } }
  @media only screen and (min-width: 1200px) {
    .fhl-rates-page-wrapper {
      margin-left: -0.5rem; } }
  @media only screen and (min-width: 992px) {
    table.compare-advance-table th,
    table.compare-advance-table td {
      min-width: 150px;
      max-width: inherit; } } }

@font-face {
  font-family: "Opensans-Regular";
  src: url("../assets/fonts/Opensans/Opensans-Regular.ttf") format("truetype"), url("../assets/fonts/Opensans/Opensans-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "OpenSans-Italic";
  src: url("../assets/fonts/Opensans/OpenSans-Italic.ttf") format("truetype"), url("../assets/fonts/Opensans/OpenSans-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: "OpenSans-Light";
  src: url("../assets/fonts/OpenSans/OpenSans-Light.ttf") format("truetype"), url("../assets/fonts/OpenSans/OpenSans-Light.woff") format("woff");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: "OpenSans-LightItalic";
  src: url("../assets/fonts/Opensans/OpenSans-LightItalic.ttf") format("truetype"), url("../assets/fonts/Opensans/OpenSans-LightItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic; }

@font-face {
  font-family: "OpenSans-Semibold";
  src: url("../assets/fonts/OpenSans/OpenSans-Semibold.ttf") format("truetype"), url("../assets/fonts/OpenSans/OpenSans-Semibold.woff") format("woff");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "OpenSans-SemiboldItalic";
  src: url("../assets/fonts/Opensans/OpenSans-SemiboldItalic.ttf") format("truetype"), url("../assets/fonts/Opensans/OpenSans-SemiboldItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: "OpenSans-Bold";
  src: url("../assets/fonts/OpenSans/OpenSans-Bold.ttf") format("truetype"), url("../assets/fonts/OpenSans/OpenSans-Bold.woff") format("woff");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: "OpenSans-BoldItalic";
  src: url("../assets/fonts/Opensans/OpenSans-BoldItalic.ttf") format("truetype"), url("../assets/fonts/Opensans/OpenSans-BoldItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic; }

@font-face {
  font-family: "OpenSans-ExtraBold";
  src: url("../assets/fonts/OpenSans/OpenSans-ExtraBold.ttf") format("truetype"), url("../assets/fonts/OpenSans/OpenSans-ExtraBold.woff") format("woff");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: "OpenSans-ExtraBoldItalic";
  src: url("../assets/fonts/Opensans/OpenSans-ExtraBoldItalic.ttf") format("truetype"), url("../assets/fonts/Opensans/OpenSans-ExtraBoldItalic.woff") format("woff");
  font-weight: 700;
  font-style: italic; }

@font-face {
  font-family: "HarmoniaSansW05-Regular";
  src: url("../assets/fonts/Harmoniasans/HarmoniaSansW05-Regular.ttf") format("truetype"), url("../assets/fonts/Harmoniasans/HarmoniaSansW05-Regular.woff2") format("woff2"), url("../assets/fonts/Harmoniasans/HarmoniaSansW05-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "HarmoniaSans-Bold";
  src: url("../assets/fonts/Harmoniasans/HarmoniaSansW05-Bold.ttf") format("truetype"), url("../assets/fonts/Harmoniasans/HarmoniaSansW05-Bold.woff2") format("woff2"), url("../assets/fonts/Harmoniasans/HarmoniaSansW05-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: "HarmoniaSans-LightItalic";
  src: url("../assets/fonts/Harmoniasans/HarmoniaSansW05-LightItalic.ttf") format("truetype"), url("../assets/fonts/Harmoniasans/HarmoniaSansW05-LightItalic.woff2") format("woff2"), url("../assets/fonts/Harmoniasans/HarmoniaSansW05-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: "HarmoniaSans-BoldItalic";
  src: url("../assets/fonts/Harmoniasans/HarmoniaSansW05-BoldItalic.ttf") format("truetype"), url("../assets/fonts/Harmoniasans/HarmoniaSansW05-BoldItalic.woff2") format("woff2"), url("../assets/fonts/Harmoniasans/HarmoniaSansW05-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic; }

@font-face {
  font-family: "HarmoniaSans-Black";
  src: url("../assets/fonts/Harmoniasans/HarmoniaSansW05-Black.ttf") format("truetype"), url("../assets/fonts/Harmoniasans/HarmoniaSansW05-Black.woff2") format("woff2"), url("../assets/fonts/Harmoniasans/HarmoniaSansW05-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: "HarmoniaSans-BlackItalic";
  src: url("../assets/fonts/Harmoniasans/HarmoniaSansW05-BlackItalic.ttf") format("truetype"), url("../assets/fonts/Harmoniasans/HarmoniaSansW05-BlackItalic.woff2") format("woff2"), url("../assets/fonts/Harmoniasans/HarmoniaSansW05-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic; }

@font-face {
  font-family: "HarmoniaSans-Condensed";
  src: url("../assets/fonts/Harmoniasans/HarmoniaSansW05-Condensed.ttf") format("truetype"), url("../assets/fonts/Harmoniasans/HarmoniaSansW05-Condensed.woff2") format("woff2"), url("../assets/fonts/Harmoniasans/HarmoniaSansW05-Condensed.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "HarmoniaSansW05-Semibold";
  src: url("../assets/fonts/Harmoniasans/HarmoniaSansW05-Semibold.ttf") format("truetype"), url("../assets/fonts/Harmoniasans/HarmoniaSansW05-Semibold.woff2") format("woff2"), url("../assets/fonts/Harmoniasans/HarmoniaSansW05-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: "HarmoniaSans-SemiboldIt";
  src: url("../assets/fonts/Harmoniasans/HarmoniaSansW05-SemiboldIt.ttf") format("truetype"), url("../assets/fonts/Harmoniasans/HarmoniaSansW05-SemiboldIt.woff2") format("woff2"), url("../assets/fonts/Harmoniasans/HarmoniaSansW05-SemiboldIt.woff") format("woff");
  font-weight: 600;
  font-style: italic; }

@font-face {
  font-family: "HarmoniaSans-SemiBoldCn";
  src: url("../assets/fonts/Harmoniasans/HarmoniaSansW05-SemiBoldCn.ttf") format("truetype"), url("../assets/fonts/Harmoniasans/HarmoniaSansW05-SemiBoldCn.woff2") format("woff2"), url("../assets/fonts/Harmoniasans/HarmoniaSansW05-SemiBoldCn.woff") format("woff");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: "HarmoniaSans-BoldCond";
  src: url("../assets/fonts/Harmoniasans/HarmoniaSansW05-BoldCond.ttf") format("truetype"), url("../assets/fonts/Harmoniasans/HarmoniaSansW05-BoldCond.woff2") format("woff2"), url("../assets/fonts/Harmoniasans/HarmoniaSansW05-BoldCond.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: "HarmoniaSansW05-Italic";
  src: url("../assets/fonts/Harmoniasans/HarmoniaSansW05-Italic.ttf") format("truetype"), url("../assets/fonts/Harmoniasans/HarmoniaSansW05-Italic.woff2") format("woff2"), url("../assets/fonts/Harmoniasans/HarmoniaSansW05-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: "HarmoniaSans-Light";
  src: url("../assets/fonts/Harmoniasans/HarmoniaSansW05-Light.ttf") format("truetype"), url("../assets/fonts/Harmoniasans/HarmoniaSansW05-Light.woff2") format("woff2"), url("../assets/fonts/Harmoniasans/HarmoniaSansW05-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal; }

:root {
  --light-blue: #74c0fc;
  --blue: #00569c;
  --indigo: #4259c5;
  --purple: #883d9a;
  --pink: #d24887;
  --red: #ba293a;
  --orange: #e8590c;
  --yellow: #ffd075;
  --green: #00884b;
  --lime: #94c052;
  --tango: #fd7e14;
  --teal: #12b886;
  --cyan: #2b9be0;
  --white: #fff;
  --gray: #8c9194;
  --gray-dark: #002a3a;
  --primary: #00569c;
  --secondary: #dde4e9;
  --success: #00884b;
  --info: #1971c2;
  --warning: #f1be3c;
  --danger: #ba293a;
  --light: #f8fbfe;
  --dark: #55585a;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "HarmoniaSansW05-Regular", "Helvetica Neue", Arial, sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport {
  width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "HarmoniaSansW05-Regular", sans-serif;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.4;
  color: #002a3a;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 500; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #00569c;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #004285;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #8c9194;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: "HarmoniaSansW05-Regular", "Helvetica Neue", Arial, sans-serif;
  font-weight: 500;
  line-height: 1.2;
  color: #002a3a; }

h1, .h1 {
  font-size: 2.625rem; }

h2, .h2 {
  font-size: 2.1875rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.53125rem; }

h5, .h5 {
  font-size: 1.3125rem; }

h6, .h6 {
  font-size: 1.203125rem; }

.lead {
  font-size: 1.09375rem;
  font-weight: 700; }

.display-1 {
  font-size: 6rem;
  font-weight: 300; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 0.7109375rem;
  font-weight: normal; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014 \00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code,
kbd,
pre,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 500; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -16px;
  margin-left: -16px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 16px;
  padding-left: 16px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%; }

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%; }

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%; }

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%; }

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333333%; }

.offset-2 {
  margin-left: 16.66666667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333333%; }

.offset-5 {
  margin-left: 41.66666667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333333%; }

.offset-8 {
  margin-left: 66.66666667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333333%; }

.offset-11 {
  margin-left: 91.66666667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%; }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%; }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%; }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%; }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333333%; }
  .offset-sm-2 {
    margin-left: 16.66666667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333333%; }
  .offset-sm-5 {
    margin-left: 41.66666667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333333%; }
  .offset-sm-8 {
    margin-left: 66.66666667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333333%; }
  .offset-sm-11 {
    margin-left: 91.66666667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%; }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%; }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%; }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%; }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333333%; }
  .offset-md-2 {
    margin-left: 16.66666667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333333%; }
  .offset-md-5 {
    margin-left: 41.66666667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333333%; }
  .offset-md-8 {
    margin-left: 66.66666667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333333%; }
  .offset-md-11 {
    margin-left: 91.66666667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%; }
  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%; }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%; }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%; }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333333%; }
  .offset-lg-2 {
    margin-left: 16.66666667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333333%; }
  .offset-lg-5 {
    margin-left: 41.66666667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333333%; }
  .offset-lg-8 {
    margin-left: 66.66666667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333333%; }
  .offset-lg-11 {
    margin-left: 91.66666667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%; }
  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%; }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%; }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%; }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333333%; }
  .offset-xl-2 {
    margin-left: 16.66666667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333333%; }
  .offset-xl-5 {
    margin-left: 41.66666667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333333%; }
  .offset-xl-8 {
    margin-left: 66.66666667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333333%; }
  .offset-xl-11 {
    margin-left: 91.66666667%; } }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }
  .table .table {
    background-color: #fff; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #e6eef5; }

.table-hover .table-primary:hover {
  background-color: #d4e2ee; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #d4e2ee; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #fcfcfd; }

.table-hover .table-secondary:hover {
  background-color: #ededf3; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #ededf3; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #e6f3ed; }

.table-hover .table-success:hover {
  background-color: #d5ebe1; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #d5ebe1; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #e8f1f9; }

.table-hover .table-info:hover {
  background-color: #d4e5f4; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #d4e5f4; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fef9ec; }

.table-hover .table-warning:hover {
  background-color: #fdf1d4; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #fdf1d4; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f8eaeb; }

.table-hover .table-danger:hover {
  background-color: #f2d7d9; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f2d7d9; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #feffff; }

.table-hover .table-light:hover {
  background-color: #e5ffff; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #e5ffff; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #eeeeef; }

.table-hover .table-dark:hover {
  background-color: #e1e1e3; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #e1e1e3; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #212529; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #32383e; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #f1f6fb;
    opacity: 1; }

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px); }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.09375rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.765625rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-sm > .input-group-append > .form-control-plaintext.btn, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-lg > .input-group-append > .form-control-plaintext.btn {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px); }

.form-control-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px); }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #8c9194; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip, .was-validated
  .custom-select:valid ~ .valid-feedback,
  .was-validated
  .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #71dd8a; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }
  .was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #efa2a9; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }
  .was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 0 solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 10rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .btn:hover, .btn:focus {
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem #2b9be0; }
  .btn.disabled, .btn:disabled {
    opacity: 0.3; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }
  .btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
    background-image: none; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #00569c;
  border-color: #00569c; }
  .btn-primary:hover {
    color: #fff;
    background-color: #004176;
    border-color: #003a69; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 86, 156, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #00569c;
    border-color: #00569c; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #003a69;
    border-color: #00335c; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 86, 156, 0.5); }

.btn-secondary {
  color: #002a3a;
  background-color: #dde4e9;
  border-color: #dde4e9; }
  .btn-secondary:hover {
    color: #002a3a;
    background-color: #c6d2da;
    border-color: #becbd5; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(221, 228, 233, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #002a3a;
    background-color: #dde4e9;
    border-color: #dde4e9; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #002a3a;
    background-color: #becbd5;
    border-color: #b6c5d0; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(221, 228, 233, 0.5); }

.btn-success {
  color: #fff;
  background-color: #00884b;
  border-color: #00884b; }
  .btn-success:hover {
    color: #fff;
    background-color: #006236;
    border-color: #00552f; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 136, 75, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #00884b;
    border-color: #00884b; }
  .btn-success:not(:disabled):not(.disabled):active,
  .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #00552f;
    border-color: #004828; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 136, 75, 0.5); }

.btn-info {
  color: #fff;
  background-color: #1971c2;
  border-color: #1971c2; }
  .btn-info:hover {
    color: #fff;
    background-color: #155da0;
    border-color: #135795; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(25, 113, 194, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #1971c2;
    border-color: #1971c2; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #135795;
    border-color: #12508a; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(25, 113, 194, 0.5); }

.btn-warning {
  color: #002a3a;
  background-color: #f1be3c;
  border-color: #f1be3c; }
  .btn-warning:hover {
    color: #002a3a;
    background-color: #eeb218;
    border-color: #e9ac11; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(241, 190, 60, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #002a3a;
    background-color: #f1be3c;
    border-color: #f1be3c; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #002a3a;
    background-color: #e9ac11;
    border-color: #dda310; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(241, 190, 60, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #ba293a;
  border-color: #ba293a; }
  .btn-danger:hover {
    color: #fff;
    background-color: #9b2230;
    border-color: #90202d; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(186, 41, 58, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #ba293a;
    border-color: #ba293a; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #90202d;
    border-color: #861d2a; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(186, 41, 58, 0.5); }

.btn-light {
  color: #002a3a;
  background-color: #f8fbfe;
  border-color: #f8fbfe; }
  .btn-light:hover {
    color: #002a3a;
    background-color: #d7e8f9;
    border-color: #cbe2f8; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 251, 254, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #002a3a;
    background-color: #f8fbfe;
    border-color: #f8fbfe; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #002a3a;
    background-color: #cbe2f8;
    border-color: #c0dbf6; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 251, 254, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #55585a;
  border-color: #55585a; }
  .btn-dark:hover {
    color: #fff;
    background-color: #424546;
    border-color: #3c3e40; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(85, 88, 90, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #55585a;
    border-color: #55585a; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #3c3e40;
    border-color: #363839; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(85, 88, 90, 0.5); }

.btn-outline-primary {
  color: #00569c;
  background-color: transparent;
  background-image: none;
  border-color: #00569c; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #00569c;
    border-color: #00569c; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 86, 156, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #00569c;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #00569c;
    border-color: #00569c; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 86, 156, 0.5); }

.btn-outline-secondary {
  color: #dde4e9;
  background-color: transparent;
  background-image: none;
  border-color: #dde4e9; }
  .btn-outline-secondary:hover {
    color: #002a3a;
    background-color: #dde4e9;
    border-color: #dde4e9; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(221, 228, 233, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #dde4e9;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #002a3a;
    background-color: #dde4e9;
    border-color: #dde4e9; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(221, 228, 233, 0.5); }

.btn-outline-success {
  color: #00884b;
  background-color: transparent;
  background-image: none;
  border-color: #00884b; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #00884b;
    border-color: #00884b; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 136, 75, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #00884b;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #00884b;
    border-color: #00884b; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 136, 75, 0.5); }

.btn-outline-info {
  color: #1971c2;
  background-color: transparent;
  background-image: none;
  border-color: #1971c2; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #1971c2;
    border-color: #1971c2; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(25, 113, 194, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #1971c2;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #1971c2;
    border-color: #1971c2; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(25, 113, 194, 0.5); }

.btn-outline-warning {
  color: #f1be3c;
  background-color: transparent;
  background-image: none;
  border-color: #f1be3c; }
  .btn-outline-warning:hover {
    color: #002a3a;
    background-color: #f1be3c;
    border-color: #f1be3c; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(241, 190, 60, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #f1be3c;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #002a3a;
    background-color: #f1be3c;
    border-color: #f1be3c; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(241, 190, 60, 0.5); }

.btn-outline-danger {
  color: #ba293a;
  background-color: transparent;
  background-image: none;
  border-color: #ba293a; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #ba293a;
    border-color: #ba293a; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(186, 41, 58, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #ba293a;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #ba293a;
    border-color: #ba293a; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(186, 41, 58, 0.5); }

.btn-outline-light {
  color: #f8fbfe;
  background-color: transparent;
  background-image: none;
  border-color: #f8fbfe; }
  .btn-outline-light:hover {
    color: #002a3a;
    background-color: #f8fbfe;
    border-color: #f8fbfe; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 251, 254, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8fbfe;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #002a3a;
    background-color: #f8fbfe;
    border-color: #f8fbfe; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 251, 254, 0.5); }

.btn-outline-dark {
  color: #55585a;
  background-color: transparent;
  background-image: none;
  border-color: #55585a; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #55585a;
    border-color: #55585a; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(85, 88, 90, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #55585a;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #55585a;
    border-color: #55585a; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(85, 88, 90, 0.5); }

.btn-link {
  font-weight: normal;
  color: #00569c;
  background-color: transparent; }
  .btn-link:hover {
    color: #004285;
    text-decoration: none;
    background-color: transparent;
    border-color: transparent; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: none;
    border-color: transparent;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 10rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  line-height: 1.5;
  border-radius: 10rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  opacity: 0;
  transition: opacity 0.15s linear; }
  .fade.show {
    opacity: 1; }

.collapse {
  display: none; }
  .collapse.show {
    display: block; }

tr.collapse.show {
  display: table-row; }

tbody.collapse.show {
  display: table-row-group; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }

.dropup,
.dropdown {
  position: relative; }

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: #002a3a;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: normal;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.765625rem;
  color: #6c757d;
  white-space: nowrap; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 0 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }
  .btn-group .btn + .btn,
  .btn-group .btn + .btn-group,
  .btn-group .btn-group + .btn,
  .btn-group .btn-group + .btn-group,
  .btn-group-vertical .btn + .btn,
  .btn-group-vertical .btn + .btn-group,
  .btn-group-vertical .btn-group + .btn,
  .btn-group-vertical .btn-group + .btn-group {
    margin-left: 0; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:first-child {
  margin-left: 0; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after {
    margin-left: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical .btn,
  .btn-group-vertical .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn + .btn,
  .btn-group-vertical > .btn + .btn-group,
  .btn-group-vertical > .btn-group + .btn,
  .btn-group-vertical > .btn-group + .btn-group {
    margin-top: 0;
    margin-left: 0; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control:focus,
    .input-group > .custom-select:focus,
    .input-group > .custom-file:focus {
      z-index: 3; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::before {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label,
    .input-group > .custom-file:not(:first-child) .custom-file-label::before {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.4rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    background-color: #007bff; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-control-input:active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  margin-bottom: 0; }
  .custom-control-label::before {
    position: absolute;
    top: 0.2rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    user-select: none;
    background-color: #dee2e6; }
  .custom-control-label::after {
    position: absolute;
    top: 0.2rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #007bff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(128, 189, 255, 0.5); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    opacity: 0; }

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%; }

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-control {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-file-input:focus ~ .custom-file-control::before {
      border-color: #80bdff; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(calc(2.25rem + 2px) - 1px * 2);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: 1px solid #ced4da;
    border-radius: 0 0.25rem 0.25rem 0; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #8c9194; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: transparent; }
    .nav-tabs .nav-link.disabled {
      color: #8c9194;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0;
  padding-bottom: 0;
  margin-right: 1rem;
  font-size: 0.875rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }
  .navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; }
    .navbar-expand-sm .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; }
    .navbar-expand-md .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; }
    .navbar-expand-lg .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; }
    .navbar-expand-xl .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .dropdown-menu-right {
      right: 0;
      left: auto; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }
  .navbar-expand .dropup .dropdown-menu {
    top: auto;
    bottom: 100%; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:first-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:first-child .card-img-top,
          .card-group > .card:first-child .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:first-child .card-img-bottom,
          .card-group > .card:first-child .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:last-child {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:last-child .card-img-top,
          .card-group > .card:last-child .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:last-child .card-img-bottom,
          .card-group > .card:last-child .card-footer {
            border-bottom-left-radius: 0; }
        .card-group > .card:only-child {
          border-radius: 0.25rem; }
          .card-group > .card:only-child .card-img-top,
          .card-group > .card:only-child .card-header {
            border-top-left-radius: 0.25rem;
            border-top-right-radius: 0.25rem; }
          .card-group > .card:only-child .card-img-bottom,
          .card-group > .card:only-child .card-footer {
            border-bottom-right-radius: 0.25rem;
            border-bottom-left-radius: 0.25rem; }
        .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
          border-radius: 0; }
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
            border-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  color: #6c757d;
  content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .page-link:not(:disabled):not(.disabled) {
    cursor: pointer; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.09375rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25rem 0.4rem;
  font-size: 0.625rem;
  font-weight: normal;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.4rem;
  padding-left: 0.4rem;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #00569c; }
  .badge-primary[href]:hover, .badge-primary[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #003a69; }

.badge-secondary {
  color: #002a3a;
  background-color: #dde4e9; }
  .badge-secondary[href]:hover, .badge-secondary[href]:focus {
    color: #002a3a;
    text-decoration: none;
    background-color: #becbd5; }

.badge-success {
  color: #fff;
  background-color: #00884b; }
  .badge-success[href]:hover, .badge-success[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #00552f; }

.badge-info {
  color: #fff;
  background-color: #1971c2; }
  .badge-info[href]:hover, .badge-info[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #135795; }

.badge-warning {
  color: #002a3a;
  background-color: #f1be3c; }
  .badge-warning[href]:hover, .badge-warning[href]:focus {
    color: #002a3a;
    text-decoration: none;
    background-color: #e9ac11; }

.badge-danger {
  color: #fff;
  background-color: #ba293a; }
  .badge-danger[href]:hover, .badge-danger[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #90202d; }

.badge-light {
  color: #002a3a;
  background-color: #f8fbfe; }
  .badge-light[href]:hover, .badge-light[href]:focus {
    color: #002a3a;
    text-decoration: none;
    background-color: #cbe2f8; }

.badge-dark {
  color: #fff;
  background-color: #55585a; }
  .badge-dark[href]:hover, .badge-dark[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #3c3e40; }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 500; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #00223e;
  background-color: white;
  border-color: #e6eef5; }
  .alert-primary hr {
    border-top-color: #d4e2ee; }
  .alert-primary .alert-link {
    color: #00060b; }

.alert-secondary {
  color: #585b5d;
  background-color: white;
  border-color: #fcfcfd; }
  .alert-secondary hr {
    border-top-color: #ededf3; }
  .alert-secondary .alert-link {
    color: #3f4143; }

.alert-success {
  color: #00361e;
  background-color: white;
  border-color: #e6f3ed; }
  .alert-success hr {
    border-top-color: #d5ebe1; }
  .alert-success .alert-link {
    color: #000302; }

.alert-info {
  color: #0a2d4e;
  background-color: white;
  border-color: #e8f1f9; }
  .alert-info hr {
    border-top-color: #d4e5f4; }
  .alert-info .alert-link {
    color: #041321; }

.alert-warning {
  color: #604c18;
  background-color: white;
  border-color: #fef9ec; }
  .alert-warning hr {
    border-top-color: #fdf1d4; }
  .alert-warning .alert-link {
    color: #372c0e; }

.alert-danger {
  color: #4a1017;
  background-color: white;
  border-color: #f8eaeb; }
  .alert-danger hr {
    border-top-color: #f2d7d9; }
  .alert-danger .alert-link {
    color: #20070a; }

.alert-light {
  color: #636466;
  background-color: white;
  border-color: #feffff; }
  .alert-light hr {
    border-top-color: #e5ffff; }
  .alert-light .alert-link {
    color: #4a4b4c; }

.alert-dark {
  color: #222324;
  background-color: white;
  border-color: #eeeeef; }
  .alert-dark hr {
    border-top-color: #e1e1e3; }
  .alert-dark .alert-link {
    color: #090a0a; }

@keyframes progress-bar-stripes {
  from {
    background-position: 0.5rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 0.5rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9eff4;
  border-radius: 1rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  background-color: #2b9be0;
  transition: width 0.6s ease;
  border-radius: 1rem; }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 0.5rem 0.5rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item:hover, .list-group-item:focus {
    z-index: 1;
    text-decoration: none; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0; }

.list-group-item-primary {
  color: #00223e;
  background-color: #e6eef5; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #00223e;
    background-color: #d4e2ee; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #00223e;
    border-color: #00223e; }

.list-group-item-secondary {
  color: #585b5d;
  background-color: #fcfcfd; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #585b5d;
    background-color: #ededf3; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #585b5d;
    border-color: #585b5d; }

.list-group-item-success {
  color: #00361e;
  background-color: #e6f3ed; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #00361e;
    background-color: #d5ebe1; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #00361e;
    border-color: #00361e; }

.list-group-item-info {
  color: #0a2d4e;
  background-color: #e8f1f9; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0a2d4e;
    background-color: #d4e5f4; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0a2d4e;
    border-color: #0a2d4e; }

.list-group-item-warning {
  color: #604c18;
  background-color: #fef9ec; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #604c18;
    background-color: #fdf1d4; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #604c18;
    border-color: #604c18; }

.list-group-item-danger {
  color: #4a1017;
  background-color: #f8eaeb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #4a1017;
    background-color: #f2d7d9; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #4a1017;
    border-color: #4a1017; }

.list-group-item-light {
  color: #636466;
  background-color: #feffff; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #636466;
    background-color: #e5ffff; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #636466;
    border-color: #636466; }

.list-group-item-dark {
  color: #222324;
  background-color: #eeeeef; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #222324;
    background-color: #e1e1e3; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #222324;
    border-color: #222324; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover, .close:focus {
    color: #000;
    text-decoration: none;
    opacity: .75; }
  .close:not(:disabled):not(.disabled) {
    cursor: pointer; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none; }

.modal-open {
  overflow: hidden; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -25%); }
  .modal.show .modal-dialog {
    transform: translate(0, 0); }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2)); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1.5rem 1.5rem 1rem 1.5rem;
  border-bottom: 0 solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 1.5rem 1.5rem 1rem 1.5rem;
    margin: -1.5rem -1.5rem -1.5rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1.5rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1.5rem;
  border-top: 0 solid #e9ecef; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 600px;
    margin: 1.75rem auto; }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2)); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "HarmoniaSansW05-Regular", sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 400px;
  font-family: "HarmoniaSansW05-Regular", sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 13px;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    border-width: 0.5rem 0.5rem 0; }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
    bottom: 0;
    border-top-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    bottom: 1px;
    border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    border-width: 0.5rem 0.5rem 0.5rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
    left: 0;
    border-right-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    left: 1px;
    border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
    top: calc((0.5rem + 1px) * -1); }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-width: 0 0.5rem 0.5rem 0.5rem; }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
    top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    top: 1px;
    border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    border-width: 0.5rem 0 0.5rem 0.5rem; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
    right: 0;
    border-left-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    right: 1px;
    border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  transition: transform 0.6s ease;
  backface-visibility: hidden;
  perspective: 1000px; }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0; }

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
      transform: translate3d(0, 0, 0); } }

.carousel-item-next,
.active.carousel-item-right {
  transform: translateX(100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next,
    .active.carousel-item-right {
      transform: translate3d(100%, 0, 0); } }

.carousel-item-prev,
.active.carousel-item-left {
  transform: translateX(-100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-prev,
    .active.carousel-item-left {
      transform: translate3d(-100%, 0, 0); } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5; }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    position: relative;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    background-color: rgba(255, 255, 255, 0.5); }
    .carousel-indicators li::before {
      position: absolute;
      top: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
    .carousel-indicators li::after {
      position: absolute;
      bottom: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
  .carousel-indicators .active {
    background-color: #fff; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #00569c !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #003a69 !important; }

.bg-secondary {
  background-color: #dde4e9 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #becbd5 !important; }

.bg-success {
  background-color: #00884b !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #00552f !important; }

.bg-info {
  background-color: #1971c2 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #135795 !important; }

.bg-warning {
  background-color: #f1be3c !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #e9ac11 !important; }

.bg-danger {
  background-color: #ba293a !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #90202d !important; }

.bg-light {
  background-color: #f8fbfe !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #cbe2f8 !important; }

.bg-dark {
  background-color: #55585a !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #3c3e40 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #00569c !important; }

.border-secondary {
  border-color: #dde4e9 !important; }

.border-success {
  border-color: #00884b !important; }

.border-info {
  border-color: #1971c2 !important; }

.border-warning {
  border-color: #f1be3c !important; }

.border-danger {
  border-color: #ba293a !important; }

.border-light {
  border-color: #f8fbfe !important; }

.border-dark {
  border-color: #55585a !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714286%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 100 !important; }

.font-weight-normal {
  font-weight: normal !important; }

.font-weight-bold {
  font-weight: 500 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #00569c !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #003a69 !important; }

.text-secondary {
  color: #dde4e9 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #becbd5 !important; }

.text-success {
  color: #00884b !important; }

a.text-success:hover, a.text-success:focus {
  color: #00552f !important; }

.text-info {
  color: #1971c2 !important; }

a.text-info:hover, a.text-info:focus {
  color: #135795 !important; }

.text-warning {
  color: #f1be3c !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #e9ac11 !important; }

.text-danger {
  color: #ba293a !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #90202d !important; }

.text-light {
  color: #f8fbfe !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbe2f8 !important; }

.text-dark {
  color: #55585a !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #3c3e40 !important; }

.text-muted {
  color: #8c9194 !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important; } }

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url("vendor/material-design-icons/iconfont/MaterialIcons-Regular.eot");
  /* For IE6-8 */
  src: local("vendor/material-design-icons/iconfont/Material Icons"), local("vendor/material-design-icons/iconfont/MaterialIcons-Regular"), url("vendor/material-design-icons/iconfont/MaterialIcons-Regular.woff2") format("woff2"), url("vendor/material-design-icons/iconfont/MaterialIcons-Regular.woff") format("woff"), url("vendor/material-design-icons/iconfont/MaterialIcons-Regular.ttf") format("truetype"); }

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }

/*
//Encapsulated WC2 styles -- *Remove if only using WC3*
[class$="-ng"] {
    @import "vendor/backbase-wc2/base/base";
    // @import "vendor/backbase-wc2/helpers/helpers";
    // @import "vendor/backbase-wc2/backbase-wc2";
}
*/
/*@import "vendor/backbase-wc2/components/bootstrap/global";*/
