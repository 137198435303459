.fhl-content-wrapper {
    border-bottom: convert-unit(1px, "rem") solid $border-dark-line;
    margin-bottom: convert-unit(20px, "rem");
    padding-bottom: convert-unit(10px, "rem");
    margin-top: convert-unit(10px, "rem");
    h3 {
        font-size: convert-unit(24px, "rem");
        //color: get("color.primary-green.100");
        line-height: convert-unit(28.8px, "rem");
        font-weight: normal;
        font-style: normal;
        color: inherit;
    }
    p {
        font-family: "Opensans-Regular";
        font-weight: normal;
        font-style: normal;
        font-size: convert-unit(14px, "rem");
        color: inherit;
        line-height: convert-unit(21px, "rem");
        a {
            text-decoration: underline;
            font-family: "OpenSans-Semibold";
            font-weight: 300;
            font-style: normal;
            color: inherit;
        }
        a:visited,
        a:focus,
        a:hover,
        a:active {
            color: inherit;
        }
    }
}
.fhl-lists-wrapper,
.fhl-richtext-wrapper,
.fhl-article-content-richtext-wrapper {
    font-size: convert-unit(16px, "rem");
    line-height: convert-unit(22.4px, "rem");
    a {
        text-decoration: underline;
        font-weight: 300;
        font-style: normal;
        color: inherit;
    }
    a:visited,
    a:focus,
    a:hover,
    a:active {
        color: inherit;
    }
}
.fhl-lists-wrapper {
    font-family: "OpenSans-Semibold";
    p {
        margin: 0;
    }
    a {
        margin-bottom: convert-unit(5px, "rem");
        display: table;
    }
}
.bio-table {
    .fhl-richtext-wrapper {
        td:first-child {
            min-width: auto;
            width: 20%;
            font-weight: 300;
            font-style: normal;
            font-family: "OpenSans-Semibold";
        }
        td:last-child {
            width: 80%;
        }
    }
}
.fhl-richtext-wrapper {
    word-break: break-word;
    word-wrap: break-word;
    font-family: "OpenSans-Semibold";
    a {
        margin-bottom: convert-unit(0px, "rem");
        display: block !important;
    }
    td:first-child {
        font-weight: bold;
        min-width: convert-unit(140px, "rem");
    }
}

.fhl-article-content-richtext-wrapper {
    font-family: "OpenSans-Regular";
    padding-top: convert-unit(20px, "rem");
    padding-bottom: convert-unit(20px, "rem");
    a {
        margin-bottom: convert-unit(10px, "rem");
        display: block !important;
    }
}

.fhl-article-richtext-wrapper {
    font-size: convert-unit(36px, "rem");
    line-height: convert-unit(43.2px, "rem");
    padding-bottom: convert-unit(30px, "rem");
    padding-top: convert-unit(30px, "rem");
    margin-bottom: 0px;
    blockquote {
        margin-bottom: 0px;
        display: block;
    }
    p {
        border-left: 6px solid #76bc21;
        padding-left: convert-unit(20px, "rem");
        font-family: "HarmoniaSansW05-Italic";
        font-weight: normal;
        font-style: italic;
        color: inherit;
    }
}

.fhl-si-video-richtext-wrapper {
    font-family: "HarmoniaSansW05-Regular";
    font-size: convert-unit(16px, "rem");
    line-height: convert-unit(22.4px, "rem");
    border-width: 1px;
    border-style: solid;
    border-color: $border-dark-line;
    padding: convert-unit(20px, "rem") convert-unit(35px, "rem");
    margin-bottom: convert-unit(96px, "rem");
    overflow: auto;
    height: convert-unit(325px, "rem");
}
