.membership-content-article {
    flex-direction: column;
    word-break: break-word;
    .membership-content-title {
        margin: 0px;
        color: inherit;
        font-size: convert-unit(32px, "rem");
        line-height: convert-unit(38.4px, "rem");
    }
    .membership-content-contentbody {
        padding-right: 35px;
        margin-top: convert-unit(40px, "rem");
        font-size: convert-unit(18px, "rem");
        font-weight: 500;
        font-style: normal;
        line-height: convert-unit(25.2px, "rem");
        color: inherit;
        @include fontfamilyHarmoniaSans;
        p {
            a {
                text-decoration: underline;
            }
        }
    }
}
@media only screen and (max-width: 767px) {
    .membership-content-article {
        margin: 0px;
        .membership-content-title {
            margin-top: 0;
            font-size: convert-unit(28px, "rem");
        }
        .membership-content-contentbody {
            padding-right: 0;
            margin-top: convert-unit(15px, "rem");
            margin-bottom: convert-unit(15px, "rem");
        }
    }

    .ahp-content-wrapper {
        .membership-content-article {
            .membership-content-title {
                margin-top: convert-unit(25px, "rem");
                margin-bottom: convert-unit(15px, "rem");
                font-size: convert-unit(32px, "rem");
            }
        }
    }
}
@media only screen and (min-width: 768px) {
    .membership-content-article {
        margin: 0px;
        .membership-content-contentbody {
            padding-right: 8%;
            margin-top: convert-unit(40px, "rem");
        }
    }
    .ahp-content-wrapper {
        .membership-content-article {
            .membership-content-contentbody {
                margin-top: convert-unit(32px, "rem");
            }
        }
    }
}
@media only screen and (min-width: 992px) {
    .membership-content-article {
        .membership-content-title {
            font-size: convert-unit(32px, "rem");
            line-height: convert-unit(38.4px, "rem");
        }
    }
}
