.fhl-select {
    position: absolute;
    width: 70%;
    label {
        position: absolute;
        z-index: -5;
        color: #f3f3f3;
    }
    select {
        border: solid convert-unit(1px, "rem") get("color.gray.200");
        border-radius: convert-unit(4px, "rem");
        font-size: convert-unit(14px, "rem");
        color: get("color.gray.50");
        height: convert-unit(42px, "rem");
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        line-height: convert-unit(22.4px, "rem") !important;

        &:focus {
            color: #495057;
            background-color: #fff;
            border-color: #212223;
            outline: 0;
            box-shadow: 0 0 0 0.1rem #58585a;
        }
    }

    .select-side {
        &:before {
            border-left: solid convert-unit(1px, "rem") #c8c8cc;
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            height: 100%;
            width: convert-unit(1px, "rem"); /* or 100px */
        }

        width: convert-unit(32px, "rem");
        position: absolute;
        top: 0px;
        background-color: get("color.white");
        height: 100%;
        right: 0px;
        border-radius: 0px convert-unit(4px, "rem") convert-unit(4px, "rem") 0px;
        border-right: solid convert-unit(1px, "rem") get("color.gray.200");
        border-top: solid convert-unit(1px, "rem") get("color.gray.200");
        border-bottom: solid convert-unit(1px, "rem") get("color.gray.200");
        pointer-events: none;
        line-height: convert-unit(50px, "rem");
        text-align: center;
        i {
            &.blue {
                color: #004987;
            }

            left: 30%;
            top: convert-unit(12px, "rem");
            color: $dark-blue;
        }
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    .fhl-select {
        width: 100%;
    }
}
