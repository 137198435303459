.si-video-wrapper {
    background-color: $white;
    .video-embed-container{
        height: 0;
        padding-top: 0px;
        padding-bottom: 56.25%;
        margin-bottom: 60px;
        position: relative;
        overflow: hidden;
        }
        
        .video-embed-container.widescreen {
            padding-bottom: 57.25%;
          }
          
          .video-embed-container.vimeo {
            padding-top: 0;
          }

          .video-embed-container iframe {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
    }
}
/*
@media only screen and (max-width: 575px) {
    .si-video-wrapper {
        object {
            height: convert-unit(195px, "rem") !important;
        }
        
        iframe{
            height: convert-unit(195px, "rem") !important;
        }
    }
}
@media only screen and (max-width: 767px) {
    .si-video-wrapper {
        object {
            height: convert-unit(270px, "rem");
        }
        iframe{
            height: convert-unit(270px, "rem");
        }
    }
}
@media only screen and (min-width: 768px) {
    .si-video-wrapper {
        object {
            height: convert-unit(320px, "rem");
        }
        iframe {
            height: convert-unit(320px, "rem");
        }
    }
}
@media only screen and (min-width: 992px) {
    .si-video-wrapper {
        object {
            height: convert-unit(605px, "rem");
        }
        iframe {
            height: convert-unit(605px, "rem");
        }
    }
}
*/