header {
    .header-bar {
        height: convert-unit(35px, "rem");
        max-height: convert-unit(35px, "rem");
        background-color: get("color.primary-green.100");
        ul {
            margin: 0;
            white-space: nowrap;
            li:after {
                content: "|";
                margin-left: convert-unit(15px, "rem");
            }
            li:last-child:after {
                content: "";
                margin: 0;
            }
            li:nth-last-child(2):after {
                content: "";
                margin: 0;
            }
            li {
                display: inline;
                margin-left: convert-unit(15px, "rem");
                font-size: convert-unit(14px, "rem");
                color: get("color.white");
                a {
                    color: #fafafa;
                }
                span {
                    display: inline-block;
                    position: relative;
                    span {
                        display: inline;
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 992px) {
    header {
        .header-bar {
            ul {
                margin-right: convert-unit(-21px, "rem");
            }
        }
    }
}
@media only screen and (min-width: 1200px) {
    header {
        .header-bar {
            ul {
                margin-right: convert-unit(-15px, "rem");
            }
        }
    }
}
