//Main Entry Point

//How-To: Adding Custom Overrides
//If you are overriding existing helper variables & mixins/functions, they must be imported AFTER the 'vendor/...' of each STAGE_BLOCK
//This entry file uses a last-in-first-out load order,
//Where the final overrides take precedence over previous

//**********************************
// [STAGE 1]: Base Helper Definitions
// Reserved for: Root base definitions of variables, which are first-initialized prior to further abstractions or references downstream // (e.g. $abstracted_var: $root_var)
// Additional Note: 'Base' files define the global variables that is available as public API to modify the theme
// Recommended Usage: $my-base-var: 1000px;
//**********************************

//==================================
// Vendor Base Helper Definitions
// Includes imports of BS4 functions, mixins, variables
//==================================
@import "vendor/bootstrap/bootstrap-reboot";
@import "vendor/@material/animation/variables";
@import "vendor/@material/animation/functions";

//==================================
// Backbase Baseline Definitions
//==================================
@import "vendor/backbase/base/base";

//==================================
// [STAGE 1 OVERRIDES]: Your Custom Base Definitions (INSERT HERE)
// Correct Usage: $my-base-var: 150px;
// Incorrect Usage: $my-second-var: $my-base-var;
// Variables should be unique (undefined prior to this point)
//==================================
// @import "base/base";
@import "base/reset";
@import "shared";
@import "app-components";
@import "root-overrides";

//@Font-face Definitions
//Customizable: This is where to define your theme fonts and icons
@import "fonts/fonts";

//**********************************
// [STAGE 2]: Abstractions from Base Variables
// (e.g. $abstract-variable: $another-base-variable)
//**********************************

//==================================
// Core Abstraction Layer -- from global base variables
//==================================
// @import "vendor/backbase/helpers/helpers";

//==================================
// [STAGE 2 OVERRIDES]: Your Custom Helper Definitions (INSERT HERE)
// (See 'helpers/helpers')
//==================================
@import "helpers/helpers";

//**********************************
// [STAGE 3]: Style Loading -- Code that outputs CSS
//**********************************

//==================================
// Bootstrap 4 Loader
// selectively import/remove for needed Sass partials
//==================================
@import "vendor/bootstrap/root";
@import "vendor/bootstrap/reboot";
@import "vendor/bootstrap/type";
@import "vendor/bootstrap/images";
@import "vendor/bootstrap/code";
@import "vendor/bootstrap/grid";
@import "vendor/bootstrap/tables";
@import "vendor/bootstrap/forms";
@import "vendor/bootstrap/buttons";
@import "vendor/bootstrap/transitions";
@import "vendor/bootstrap/dropdown";
@import "vendor/bootstrap/button-group";
@import "vendor/bootstrap/input-group";
@import "vendor/bootstrap/custom-forms";
@import "vendor/bootstrap/nav";
@import "vendor/bootstrap/navbar";
@import "vendor/bootstrap/card";
@import "vendor/bootstrap/breadcrumb";
@import "vendor/bootstrap/pagination";
@import "vendor/bootstrap/badge";
@import "vendor/bootstrap/jumbotron";
@import "vendor/bootstrap/alert";
@import "vendor/bootstrap/progress";
@import "vendor/bootstrap/media";
@import "vendor/bootstrap/list-group";
@import "vendor/bootstrap/close";
@import "vendor/bootstrap/modal";
@import "vendor/bootstrap/tooltip";
@import "vendor/bootstrap/popover";
@import "vendor/bootstrap/carousel";
@import "vendor/bootstrap/utilities";
@import "vendor/bootstrap/print";

//Auxiliary Theme Vendor Styles
@import "vendor/material-design-icons/iconfont/material-icons";

//==================================
// Backbase Theme Loader -- All sass helpers gets computed here
//==================================
// @import "vendor/backbase/backbase";

/*
//Encapsulated WC2 styles -- *Remove if only using WC3*
[class$="-ng"] {
    @import "vendor/backbase-wc2/base/base";
    // @import "vendor/backbase-wc2/helpers/helpers";
    // @import "vendor/backbase-wc2/backbase-wc2";
}
*/

//Including WC2 styles that aren't wrapped inside a widget (mainly uib)
/*@import "vendor/backbase-wc2/components/bootstrap/global";*/

//==================================
// [STAGE 3 OVERRIDES]: Custom Styles Loader (INSERT HERE)
// Your project's CSS Output Instructions
//==================================
