@font-face {
    font-family: "Opensans-Regular";
    src: url("#{$fonts-path}/Opensans/Opensans-Regular.ttf") format("truetype"),
        url("#{$fonts-path}/Opensans/Opensans-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "OpenSans-Italic";
    src: url("#{$fonts-path}/Opensans/OpenSans-Italic.ttf") format("truetype"),
        url("#{$fonts-path}/Opensans/OpenSans-Italic.woff") format("woff");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "OpenSans-Light";
    src: url("#{$fonts-path}/OpenSans/OpenSans-Light.ttf") format("truetype"),
        url("#{$fonts-path}/OpenSans/OpenSans-Light.woff") format("woff");
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: "OpenSans-LightItalic";
    src: url("#{$fonts-path}/Opensans/OpenSans-LightItalic.ttf") format("truetype"),
        url("#{$fonts-path}/Opensans/OpenSans-LightItalic.woff") format("woff");
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: "OpenSans-Semibold";
    src: url("#{$fonts-path}/OpenSans/OpenSans-Semibold.ttf") format("truetype"),
        url("#{$fonts-path}/OpenSans/OpenSans-Semibold.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "OpenSans-SemiboldItalic";
    src: url("#{$fonts-path}/Opensans/OpenSans-SemiboldItalic.ttf") format("truetype"),
        url("#{$fonts-path}/Opensans/OpenSans-SemiboldItalic.woff") format("woff");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "OpenSans-Bold";
    src: url("#{$fonts-path}/OpenSans/OpenSans-Bold.ttf") format("truetype"),
        url("#{$fonts-path}/OpenSans/OpenSans-Bold.woff") format("woff");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "OpenSans-BoldItalic";
    src: url("#{$fonts-path}/Opensans/OpenSans-BoldItalic.ttf") format("truetype"),
        url("#{$fonts-path}/Opensans/OpenSans-BoldItalic.woff") format("woff");
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: "OpenSans-ExtraBold";
    src: url("#{$fonts-path}/OpenSans/OpenSans-ExtraBold.ttf") format("truetype"),
        url("#{$fonts-path}/OpenSans/OpenSans-ExtraBold.woff") format("woff");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "OpenSans-ExtraBoldItalic";
    src: url("#{$fonts-path}/Opensans/OpenSans-ExtraBoldItalic.ttf") format("truetype"),
        url("#{$fonts-path}/Opensans/OpenSans-ExtraBoldItalic.woff") format("woff");
    font-weight: 700;
    font-style: italic;
}
