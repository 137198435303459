.completer-dropdown-holder {
    text-align: left !important;
    word-break: break-word;
    word-wrap: break-word;
    .completer-dropdown {
        .completer-row-wrapper,
        .completer-row,
        .completer-selected-row,
        .completer-list-item {
            word-break: break-word;
        }
    }
}

.search-media-wrapper {
    height: 0px;
}
.search-results-main-wrapper .search-media-wrapper {
    height: auto;
}
.search-results-wrapper {
    ul{
        li{
            list-style-type: none;
        }
    }
    .search-header a {
        font-size: convert-unit(24px, "rem");
        color: get("color.primary-green.100");
        padding-top: convert-unit(20px, "rem");
        padding-bottom: convert-unit(20px, "rem");
        line-height: convert-unit(28.8px, "rem");
    }
    .search-description {
        font-family: "Opensans-Regular";
        font-size: convert-unit(16px, "rem");
        color: $footer-base;
        padding-bottom: convert-unit(30px, "rem");
        padding-top: convert-unit(10px, "rem");
        line-height: convert-unit(22.4px, "rem");
    }
    .search-breadcrumb a {
        font-size: convert-unit(14px, "rem");
        color: get("color.primary-green.100");
        letter-spacing: convert-unit(1px, "rem");
        line-height: convert-unit(16.8px, "rem");
        text-decoration: none;
    }
    hr {
        border-bottom: 1px solid $border-dark-line;
        margin: convert-unit(30px, "rem") 0 convert-unit(24px, "rem");
        border-top: 0;
    }
    .search-result-container {
        margin-top: convert-unit(-10px, "rem");
        .pagenation-content {
            margin-top: convert-unit(50px, "rem");
        }
    }
    .search-wrapper {
        input[type="search"] {
            width: 100%;
            height: convert-unit(35px, "rem");
            padding: convert-unit(0px, "rem") convert-unit(40px, "rem") convert-unit(0px, "rem")
                convert-unit(10px, "rem");
            margin-top: convert-unit(1px, "rem");
            font-size: convert-unit(14px, "rem");
            border: 1px solid get("color.gray.200");
            border-radius: convert-unit(4px, "rem");
            color: get("color.gray.50");
            line-height: convert-unit(22.4px, "rem");
        }
        .search-box {
            .completer-holder {
                position: relative;
            }
            .completer-dropdown-holder {
                position: absolute;
                top: convert-unit(42px, "rem");
            }
        }
    }

    button[type="submit"] {
        width: 100%;
        background: get("color.primary-green.100");
        border-radius: convert-unit(4px, "rem");
        font-size: convert-unit(16px, "rem");
        color: get("color.white");
        text-align: center;
    }

    button[type="reset"] {
        border: convert-unit(1px, "rem") solid $border-dark-line;
        background-color: transparent;
        position: absolute;
        top: convert-unit(10px, "rem");
        right: convert-unit(10px, "rem");
        color: $border-dark-line;
        cursor: pointer;
        height: convert-unit(18px, "rem");
        width: convert-unit(18px, "rem");
        padding: convert-unit(0px, "rem");
        border-radius: 50%;
        line-height: convert-unit(20px, "rem");
        i {
            //opacity: 0.7;
            font-size: convert-unit(14px, "rem");
            color: $default-prime-gray;
        }
    }
    button[type="reset"]:hover,
    button[type="reset"]:focus,
    button[type="reset"]:active {
        outline: 0;
        border: 1px solid get("color.primary-green.100");
    }
    .search-btn-wrapper {
        padding-right: 0;
        padding-left: convert-unit(32px, "rem");
    }
    .search-box:not(:valid) ~ .close-icon {
        display: none;
    }
    em {
        font-weight: bold;
    }
}
@media only screen and (max-width: 767px) {
    .search-result-main-title-wrapper {
        .fhl-medium-titlebar-wrapper {
            h2 {
                text-align: left;
                font-size: convert-unit(30px, "rem");
            }
        }
    }
    .search-results-wrapper {
        .search-wrapper {
            input[type="search"] {
                margin-bottom: convert-unit(25px, "rem");
            }
        }
        .search-btn-wrapper {
            padding-left: 0px;
            button {
                width: convert-unit(150px, "rem");
            }
        }
        .search-result-container .pagenation-content {
            margin: convert-unit(50px, "rem") 0px !important;
        }
    }
}
