.fhlb-calendar-wrapper {
  min-width: convert-unit(165px, "rem");
  .mydp {
    border: 0px !important;
    .selection {
      border: 1px solid get("color.gray.50");
    }
    .selector:focus {
      border: 0px;
    }
    .monthlabel,
    .weekdaytitle,
    .headertodaybtn,
    .yearlabel,
    .datevalue,
    .yearvalue,
    .selection {
      font-family: "HarmoniaSansW05-Regular" !important;
      font-weight: normal;
      font-style: normal;
    }
    .yearcell .yearvalue,
    .monthcell .monthvalue,
    .daycell .datevalue span {
      font-family: "OpenSans-Semibold" !important;
      font-weight: 300;
      font-style: normal;
      font-size: 11px;
      color: #58585a;
      letter-spacing: 0;
    }
    .invaliddate {
      background-color: transparent;
    }
    .header {
      height: 40px;
      background-color: get("color.primary-green.100");
      border-radius: 6px 6px 0 0;
    }
    .caltable {
      height: calc(100% - 40px);
      border-radius: 0 0 6px 6px;
    }
    .headerbtn:focus,
    .monthlabel:focus,
    .yearchangebtn:focus,
    .yearlabel:focus {
      outline: 1px;
      color: #19ea9f !important;
    }
    button.headerbtn.mydpicon.icon-mydpleft.headerbtnenabled:focus,
    button.headerbtn.mydpicon.icon-mydpright.headerbtnenabled:focus,
    button.yearchangebtn.mydpicon.icon-mydpup.yearchangebtnenabled:focus,
    button.yearchangebtn.mydpicon.icon-mydpdown.yearchangebtnenabled:focus {
      border-right: 1px solid;
      border-bottom: 1px solid;
    }
    .selectorarrow:after {
      border-bottom-color: get("color.primary-green.100");
    }
    .caltable,
    .daycell,
    .monthcell,
    .monthtable,
    .weekdaytitle,
    .yearcell,
    .yeartable {
      color: $footer-base;
    }
    .headerbtncell,
    .yearchangebtncell {
      background: get("color.primary-green.100");
    }
    .yearchangebtncell {
      padding: 0px !important;
      height: 30px;
    }
    .headerbtn,
    .headerlabelbtn,
    .yearchangebtn {
      background: get("color.primary-green.100");
    }
    .btnpicker {
      background: transparent;
    }
    .daycell .datevalue {
      padding-top: 10px;
      padding-bottom: 14px;
    }
    .daycell,
    .monthcell,
    .weekdaytitle,
    .yearcell {
      // border: 1px solid get("color.gray.50");
      padding: 0px;
    }
    .monthcell {
      vertical-align: middle;
      overflow: visible;
    }
    th.weekdaytitle {
      padding: 7px 0px !important;
      font-family: "OpenSans-Semibold" !important;
      font-weight: 300;
      font-style: normal;
      font-size: 14px;
      color: #00833c;
      letter-spacing: 0;
      line-height: 21px;
    }
    .yearvalue {
      padding: 6px 0px 4px;
    }
    .yearcell.disabled .yearvalue,
    .yearcell .yearvalue {
      padding: 6px 0px 4px;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .yearcell:hover {
      .yearvalue {
        color: white;
      }
    }
    .yearcell.disabled:hover {
      .yearvalue {
        color: white;
      }
    }
    td.yearcell.tablesingleyear.disabled:hover .yearvalue,
    td.monthcell.tablesinglemonth.disabled:hover .monthvalue {
      color: #58585a !important;
    }

    // .daycell:focus,
    .monthcell:focus,
    .yearcell:focus {
      outline: #00833c solid 0px !important;
      background-color: #00833c;
    }

    .headermonthtxt .monthlabel,
    .headeryeartxt .yearlabel {
      font-size: 18px;
      color: #ffffff;
      letter-spacing: 0;
      line-height: 21.6px;
    }
    .selectedday .datevalue {
      border-radius: 50%;
      width: 100% !important;
      height: 100% !important;
      color: $white;
      display: inline-block;
      background-color: get("color.primary-green.100");
      font-family: "OpenSans-Semibold" !important;
      font-weight: 300;
      font-style: normal;
      font-size: 11px;
      span {
        color: #ffffff;
        float: left;
        margin-top: 3px;
        width: 100%;
        text-align: center;
        margin-bottom: -2px;
      }
    }
    .selectedmonth .monthvalue,
    .selectedyear .yearvalue {
      background-color: get("color.primary-green.100");
      width: 100%;
      height: 100%;
      color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "OpenSans-Semibold" !important;
      font-weight: 300;
      font-style: normal;
      font-size: 11px;
    }
    .icon-mydpdown,
    .icon-mydpleft,
    .icon-mydpright,
    .icon-mydpup,
    .headeryeartxt .yearlabel,
    .headermonthtxt .monthlabel {
      color: white;
    }
    .currmonth,
    .weekdaytitle {
      background-color: white;
      border-radius: 50%;
    }
    .tablesingleday:hover,
    .tablesinglemonth:hover,
    .tablesingleyear:hover,
    .tablesingleday:focus,
    .tablesinglemonth:focus,
    .tablesingleyear:focus {
      background-color: get("color.primary-green.100");
      div {
        color: white;
      }
    }
    .daycell:focus,
    .daycell:focus div {
      outline: 0;
      background-color: #00833c;
      border-radius: 50%;
      color: white;
      span {
        color: white;
      }
    }
    .tablesingleday:hover {
      span {
        color: #ffffff;
      }
    }
    .selector {
      min-width: convert-unit(285px, "rem") !important;
      box-shadow: 0 2px 23px 0px #c4c3c3;
      border: 0px;
      z-index: 1;
    }
    .icon-mydpcalendar:before,
    .icon-mydptoday:before,
    .icon-mydpdown:before,
    .icon-mydpup:before {
      display: none;
    }
    .icon-mydpcalendar {
      width: 20px;
      height: 24px;
      background-image: url("../assets/images/calendar_Icon.svg");
      background-repeat: no-repeat;
      background-size: 100%;
      background-color: transparent;
      display: inline-block;
    }
    .monthlabel:hover,
    .yearlabel:hover {
      color: $white;
    }
    .headerbtnenabled:hover,
    .yearchangebtnenabled:hover {
      color: get("color.secondary-green.100");
    }
    .btnpicker {
      margin-right: convert-unit(7px, "rem");
    }
    .btnpickerenabled:hover {
      background-color: transparent;
    }
    .btnpicker:focus {
      background-color: transparent;
      span {
        box-shadow: 1px 1px 4px #454545;
      }
    }
    .btnclear {
      display: none;
    }
    .headerbtn,
    .yearchangebtn {
      border: solid white;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 2px;
      width: 12px;
      height: 12px;
    }
    .icon-mydpup {
      transform: rotate(225deg);
      -webkit-transform: rotate(225deg);
      margin-top: 10px;
    }
    .icon-mydpdown {
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      margin-top: 5px;
    }
    .icon-mydpleft {
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
      margin-left: 10px;
    }
    .icon-mydpright {
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      margin-right: 10px;
    }
    .icon-mydpleft:before,
    .icon-mydpright:before {
      display: none;
    }
    .datevalue.prevmonth {
      opacity: 0.5;
    }
    .datevalue.nextmonth {
      opacity: 0.5;
    }
    .disabled {
      opacity: 0.5;
      font-family: "OpenSans-Semibold" !important;
      font-weight: 300;
      font-style: normal;
      font-size: 11px;
      color: #58585a !important;
      letter-spacing: 0;
      background-color: transparent !important;
    }
    span.dimday {
      opacity: 1 !important;
    }
  }
}
