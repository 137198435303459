body {
  background-color: get("color.white");
  font-size: convert-unit(16px, "rem") !important;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  .fhl-content-box {
    min-height: calc(100vh - 530px);
  }
  a:hover {
    text-decoration: underline !important;
  }
}
html {
  overflow-x: hidden;
  font-size: 100%;
}
p {
  color: inherit;
  font-size: inherit;
}
.white-bg,
.gray-bg,
.smokyWhite-bg {
  padding: convert-unit(50px, "rem");
  position: relative;
}
.white-bg {
  background-color: $white;
}
.gray-bg {
  background-color: $lightGray;
}
.simple-empty-grey-banner {
  background-color: $lightGray;
  padding: 0px 40px;
  min-height: 525px;
  display: block;
}
.w100pr {
  width: 100%;
}
.sticky {
  position: fixed;
  top: 0;
  z-index: 2;
  width: 100%;
}
.classic-advance-feature-edit-wrapper,
.advance-media-login-wrapper {
  padding: 0 !important;
}
.template-mpf-bdr,
.template-classic-advance-bdr,
.template-grey-verticle-line-bdr,
.template-employee-bio-bdr {
  border-bottom: convert-unit(1px, "rem") solid $border-dark-line;
  margin: convert-unit(30px, "rem") convert-unit(0px, "rem")
    convert-unit(24px, "rem");
}
.template-mpf-bdr {
  margin: convert-unit(50px, "rem") convert-unit(0px, "rem")
    convert-unit(55px, "rem");
}
.redirectLink {
  display: flex;
}
.secondary-header {
  border-bottom: 1px solid #e2e2e2;
  min-height: convert-unit(72px, "rem");
  background-color: get("color.white");
  .fhl-logo {
    width: auto;
    margin-top: convert-unit(15px, "rem");
    text-align: left;
    img {
      width: convert-unit(225px, "rem");
      height: convert-unit(36px, "rem");
      max-width: 100%;
    }
  }
}
.header-login-btn {
  background-color: get("color.primary-green.100") !important;
  max-width: convert-unit(105px, "rem");
  width: 100%;
  height: convert-unit(28px, "rem");
  line-height: convert-unit(16px, "rem") !important;
  color: get("color.white") !important;
  font-size: convert-unit(14px, "rem") !important;
  margin: convert-unit(21px, "rem") convert-unit(-25px, "rem") 0 0;
  border-radius: convert-unit(4px, "rem") !important;
}
.header-login-btn:focus {
  outline: 0;
  border: 0;
}
.header-login-btn::after {
  display: none !important;
}
ul.header-login-dropdown-content {
  padding: convert-unit(20px, "rem") convert-unit(20px, "rem")
    convert-unit(0px, "rem") convert-unit(20px, "rem");
  margin: convert-unit(10px, "rem") 0 0 convert-unit(15px, "rem");
  border-radius: convert-unit(4px, "rem");
  border: convert-unit(1px, "rem") solid get("color.primary-green.100");
  li {
    margin-bottom: convert-unit(23px, "rem");
    a {
      border-radius: convert-unit(4px, "rem");
      font-size: convert-unit(16px, "rem");
      color: get("color.white");
      width: convert-unit(192px, "rem");
    }
  }
}
.content-widget-test-image-template img {
  max-width: 100%;
}
.ax-hidden {
  position: absolute;
  height: 1px;
  width: 1px;
  clip: rect(1px, 1px, 1px, 1px);
  border: 0;
  overflow: hidden;
}
.seperator {
  border-left: 1px solid get("color.gray.100");
}

.divider {
  border-bottom: 1px solid get("color.gray.100");
}
.min-H100 {
  min-height: 100px;
}
.text-center {
  text-align: center !important;
}
.text-right {
  text-align: right !important;
}
.text-left {
  text-align: left !important;
}
.float-right {
  float: right !important;
}
.float-left {
  float: left !important;
}
.hidden {
  display: none;
}
.bor-bot-0 {
  border-bottom: convert-unit(0px, "rem") !important;
}
.redirectLink:not(*:root) {
  width: fit-content;
}
_::-moz-progress-bar,
body:last-child .redirectLink {
  width: fit-content;
}
em {
  font-style: italic !important;
}
ul {
  list-style-type: disc;
  padding-left: convert-unit(15px, "rem");
}
ol {
  list-style-type: decimal;
  padding-left: convert-unit(15px, "rem");
}
table,
th,
td {
  border: 1px solid inherit;
}
table {
  border-collapse: collapse;
}
.text-link {
  text-decoration: underline;
}
