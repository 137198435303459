table.compare-advance-table {
  width: 100%;
  th {
    padding: convert-unit(10px, "rem") convert-unit(15px, "rem");
    border-bottom: 1px solid $shadow-smokywhite-color;
    vertical-align: top;
  }
  td {
    border-bottom: 1px solid $shadow-smokywhite-color;
    padding: 0 convert-unit(15px, "rem");
  }
  tr:last-child td {
    border: 0px;
  }
}
.compare-advances-products-wrapper {
  text-align: left;
  .greyhorizontalline {
    border-bottom: 1px solid $shadow-smokywhite-color;
    margin: convert-unit(10px, "rem") 0;
    border-top: 0px;
    width: 100%;
  }
  .product-top-title {
    font-family: "HarmoniaSansW05-Semibold";
    font-weight: 600;
    font-style: normal;
    font-size: convert-unit(18px, "rem");
    color: get("color.primary-green.100");
    line-height: convert-unit(21.6px, "rem");
    justify-content: flex-start;
    align-items: self-start;
    max-width: 190px;
    flex-direction: row-reverse;
    .removeCompareList {
      color: transparent;
      cursor: pointer;
      background: none;
      border: none;
      padding: 0;
      outline: inherit;
      margin-right: convert-unit(0px, "rem");
      margin-left: convert-unit(5px, "rem");
      position: relative;
      width: 30px;
      height: 22px;
      font-size: 2px;
      i {
        color: get("color.gray.100");
        font-size: convert-unit(22px, "rem") !important;
        position: absolute;
        left: 0px;
        top: 0px;
      }
    }
    .removeCompareList:focus {
      box-shadow: 0 0 0 0.1rem #2b9be0 !important;
    }
  }
  .product-left-title {
    padding-left: 0px !important;
    padding-right: 0px !important;
    vertical-align: inherit;
    h3 {
      font-family: "OpenSans-Semibold";
      font-weight: 300;
      font-style: normal;
      font-size: convert-unit(16px, "rem");
      color: get("color.primary-green.100");
      line-height: convert-unit(21px, "rem");
      padding: convert-unit(15px, "rem") convert-unit(15px, "rem")
        convert-unit(15px, "rem") 0px;
      min-width: 200px;
    }
  }
  ul.product-list-desc {
    margin: 0;
    padding: 0;
    min-width: convert-unit(110px, "rem");
    li {
      font-family: "Opensans-Regular";
      font-size: convert-unit(12px, "rem");
      color: $footer-base;
      list-style-type: disc;
    }
  }
  .product-desc {
    font-family: "Opensans-Regular";
    font-size: convert-unit(12px, "rem");
    color: $footer-base;
  }
  .product-learnMore-btn {
    width: convert-unit(128px, "rem");
    border-radius: convert-unit(4px, "rem") !important;
    font-weight: normal;
    font-style: normal;
    font-family: "HarmoniaSansW05-Regular";
    font-size: convert-unit(16px, "rem");
  }
}
@media only screen and (min-width: 320px) {
  table.compare-advance-table th,
  table.compare-advance-table td {
    min-width: 250px;
    max-width: 250px;
  }

  .compare-advances-products-wrapper {
    .product-learnMore-btn {
      margin-bottom: 0px !important;
    }
  }
}

@media only screen and (min-width: 768px) {
  .compare-advances-products-wrapper {
    width: 100%;
    .product-desc {
      margin-right: convert-unit(1px, "rem");
    }
  }
}
@media only screen and (min-width: 992px) {
  table.compare-advance-table th,
  table.compare-advance-table td {
    min-width: 150px;
    max-width: 150px;
  }
}
