.news-events-results-wrapper {
  padding: 0 0 0 !important;
}
.news-events-media-left-section {
  word-break: break-word;
  word-wrap: break-word;
}
.news-events-results-wrapper {
  .news-events-result-page {
    min-height: convert-unit(765px, "rem");
    .pagenation-content {
      margin-top: convert-unit(20px, "rem");
    }
    ul{
      li{
        list-style-type: none;
      }
    }
  }
  overflow: visible;
  /* Hide the browser's default checkbox */
  hr {
    border-bottom: 1px solid $border-dark-line;
    margin: convert-unit(30px, "rem") 0 convert-unit(25px, "rem");
    border-top: 0;
  }
  .news-events-result-card-box {
    margin: convert-unit(5px, "rem") 0 convert-unit(40px, "rem");
    .content-description {
      font-family: "OpenSans-Regular";
      color: $footer-base;
      font-size: convert-unit(16px, "rem");
      letter-spacing: convert-unit(0px, "rem");
      line-height: convert-unit(22.4px, "rem");
    }
    img {
      width: 100%;
    }


    h2 {
      font-size: convert-unit(32px, "rem");
      color: get("color.primary-green.100");
      line-height: convert-unit(38.4px, "rem");
      letter-spacing: convert-unit(0px, "rem");
      margin-bottom: convert-unit(20px, "rem");
      font-family: "HarmoniaSansW05-Regular";
      a {
        color: $dark-blue !important;
      }
    }
    .events-date {
      border-radius: convert-unit(4px, "rem");
      border: 1px solid #98989a;
      color: #007cad;
      width: convert-unit(60px, "rem");
      height: convert-unit(60px, "rem");
      text-transform: uppercase;

      .events-formatted-month {
        font-size: convert-unit(12px, "rem");
        color: $footer-primary;
        letter-spacing: 0;
        text-align: center;
        line-height: convert-unit(18px, "rem");
        padding-bottom: convert-unit(10px, "rem");
      }
      .events-formatted-date {
        font-size: convert-unit(28px, "rem");
        color: #007cad;
        letter-spacing: 0;
        text-align: center;
        line-height: convert-unit(18px, "rem");
      }
    }
    .news-date {
      font-size: convert-unit(14px, "rem");
      color: get("color.gray.50");
      line-height: convert-unit(18px, "rem");
      letter-spacing: convert-unit(2.33px, "rem");
      text-transform: uppercase;
    }
    a.news-events-result-title,
    a {
      font-size: convert-unit(32px, "rem");
      color: get("color.primary-green.100");
      line-height: convert-unit(38.4px, "rem");
      letter-spacing: convert-unit(0px, "rem");
      display: table;
      span {
        display: inline-block;
        float: right;
      }
    }
    a.learn-more-link {
      font-size: convert-unit(14px, "rem");
      color: get("color.primary-green.100");
      letter-spacing: convert-unit(1px, "rem");
      padding-top: convert-unit(20px, "rem");
      line-height: convert-unit(16.8px, "rem");
      span {
        margin-top: -3px;
      }
    }
  }
  .news-events-main-section-img {
    margin: convert-unit(20px, "rem") 0px convert-unit(30px, "rem");
  }
}
@media only screen and (max-width: 767px) {
  .news-events-result-card-box {
    margin-bottom: 0px !important;
    .events-date {
      margin-bottom: convert-unit(15px, "rem");
    }
  }
  .news-events-main-header {
    padding-left: convert-unit(15px, "rem") !important;
  }
  .news-events-media-wrapper {
    .row {
      .col-md-3,
      .col-md-9 {
        .narrow-list-wrapper {
          max-width: 100%;
          margin-bottom: convert-unit(15px, "rem");
        }
      }
    }
  }
  .news-events-results-wrapper {
    .news-events-result-page {
      min-height: 100%;
      .pagenation-content {
        margin-top: convert-unit(15px, "rem") !important;
      }
    }
  }
  .news-events-static-page-wrapper {
    .news-events-media-left-section,
    .news-events-media-right-section {
      padding: 0;
    }
    .news-events-media-right-section {
      margin-bottom: 25px;
    }
  }
}
.news-events-media-right-section {
  a.left-arrow-icon {
    width: 100%;
  }
}
@media only screen and (min-width: 768px) {
  .news-events-main-header {
    padding-left: convert-unit(0px, "rem") !important;
  }
  .events-result-content-section {
    display: flex;
    flex-direction: row;
    .events-date {
      min-width: convert-unit(60px, "rem");
      min-height: convert-unit(60px, "rem");
      margin-right: convert-unit(30px, "rem");
    }
  }
  .news-events-media-wrapper {
    .news-events-media-left-section {
      margin-right: 0px;
    }
    .row {
      .col-md-3 {
        max-width: 33%;
        .narrow-list-wrapper {
          width: 225px;
        }
      }
      .col-md-9 {
        max-width: 67%;
        .news-events-result-page {
          .pagenation-content {
            margin-top: convert-unit(15px, "rem") !important;
          }
          .news-events-result-card-box {
            .col-md-3 {
              margin-bottom: convert-unit(10px, "rem");
            }
            .col-md-3,
            .col-md-9 {
              max-width: 100%;
              flex: 0 0 100%;
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .news-events-media-wrapper {
    .news-events-result-page {
      .pagenation-content {
        margin-left: convert-unit(-15px, "rem") !important;
      }
    }
  }
  .news-events-results-display-wrapper,
  .events-result-content-section {
    padding-left: 0 !important;
  }
  .news-events-results-wrapper {
    hr {
      margin-left: convert-unit(-15px, "rem") !important;
    }
  }
  .events-mapping {
    margin-top: -80px;
  }
  .news-events-static-page-wrapper {
    .news-events-media-left-section {
      padding-left: 0;
    }
  }
}
@media only screen and (min-width: 992px) {
  .events-page-main-content-section {
    padding: 0 !important;
  }
  .news-events-media-wrapper {
    .news-events-media-left-section {
      margin-right: 100px;
    }
    .row {
      .col-md-3 {
        max-width: 225px;
        padding: 0 0 0 15px;
        flex: 0 0 30%;
      }
      .col-md-9 {
        max-width: 65%;
        padding: 0px 15px 0px 0px;
        flex: 0 0 65%;
        .news-events-result-page {
          .news-events-result-card-box {
            .col-md-3 {
              max-width: 30%;
              flex: 0 0 30%;
            }
            .col-md-9 {
              max-width: 70%;
              flex: 0 0 70%;
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 1200px) {
  .news-events-media-wrapper {
    .row {
      .col-md-9 {
        max-width: 70%;
        flex: 0 0 70%;
        .news-events-result-page {
          .news-events-result-card-box {
            .col-md-3 {
              max-width: 25%;
              flex: 0 0 25%;
            }
            .col-md-9 {
              max-width: 75%;
              flex: 0 0 75%;
            }
          }
        }
      }
    }
  }
}
