@-moz-document url-prefix() {
  .narrow-list-wrapper {
    .accordion-content-box {
      .card-body {
        .filter-list {
          label {
            .checkmarkradio::after {
              left: 2px !important;
            }
          }
        }
      }
    }
  }
  .secondary-header .mob-logo img {
    width: convert-unit(185px, "rem");
  }
  .different-option-container {
    display: table;
  }
  .search-results-wrapper button[type="reset"] i {
    position: absolute;
    left: convert-unit(1.5px, "rem");
    top: 1px;
    font-size: 14px;
  }
  .yearcell.tablesingleyear {
    vertical-align: middle;
  }
  .fhlb-calendar-wrapper .mydp .yearcell.tablesingleyear.selectedyear,
  .fhlb-calendar-wrapper .mydp .monthcell.selectedmonth {
    background-color: get("color.primary-green.100");
  }
  @media only screen and (max-width: 767px) {
    .search-results-wrapper button[type="reset"] i {
      left: convert-unit(1px, "rem");
    }
  }
  @media only screen and (min-width: 1200px) {
    .fhl-rates-page-wrapper {
      margin-left: convert-unit(-8px, "rem");
    }
  }
  @media only screen and (min-width: 992px) {
    table.compare-advance-table th,
    table.compare-advance-table td {
      min-width: 150px;
      max-width: inherit;
    }
  }
}
