@import "../helpers/mixins";

.contacts-page-header-wrapper {
  display: flex;
  .fhl-header-leadpara-wrapper {
    margin-top: convert-unit(30px, "rem");
    h1 {
      font-size: convert-unit(42px, "rem");
      line-height: convert-unit(50.4px, "rem");
      color: inherit;
      @include fontfamilyHarmoniaSans;
    }
    p {
      color: inherit;
      line-height: convert-unit(33.6px, "rem");
      @include fontfamilyHarmoniaSans;
      font-size: convert-unit(24px, "rem");
    }
  }
}
.contactus-left-section
  .col-lg-4.col-md-6:last-child
  .contacts-slot:last-child {
  margin-top: convert-unit(10px, "rem");
  img {
    height: convert-unit(20px, "rem");
    width: convert-unit(39px, "rem");
  }
}
.contacts-lhs-container {
  margin-bottom: convert-unit(27px, "rem");
  h3 {
    font-family: "HarmoniaSansW05-Semibold";
    font-weight: 600;
    font-style: normal;
    font-size: convert-unit(21px, "rem");
    color: inherit;
    line-height: convert-unit(25.2px, "rem");
  }
  .contacts-slot {
    // min-height: convert-unit(37px, "rem");
    height: auto;
    word-break: break-all;
    align-items: center;
    .contactsLHS-link {
      margin-left: convert-unit(23px, "rem");
      align-self: center;
      font-size: convert-unit(18px, "rem");
      line-height: convert-unit(25.2px, "rem");
      color: inherit;
      p {
        margin-bottom: 0;
        color: inherit;
        a {
          color: inherit;
        }
      }
    }
    img {
      max-width: convert-unit(37px, "rem");
      height: convert-unit(37px, "rem");
    }
  }
}
.contacts-rhs-container {
  border: convert-unit(1px, "rem") solid #979797;
  padding: convert-unit(15px, "rem");
  border-radius: convert-unit(4px, "rem");
  margin: convert-unit(20px, "rem") 0px;
  h2 {
    font-size: convert-unit(32px, "rem");
    line-height: convert-unit(38.4px, "rem");
  }
  .contacts-image {
    width: 100%;
    margin-bottom: convert-unit(20px, "rem");
    margin-top: convert-unit(4px, "rem");
  }
  .contacts-rhs-content {
    font-family: "OpenSans-Regular";
    font-size: convert-unit(14px, "rem");
    color: inherit;
    line-height: convert-unit(21px, "rem");
  }
  .contact-select-title {
    p {
      margin-bottom: 0;
    }
  }
  .contact-select-phoneno {
    font-family: "HarmoniaSansW05-Semibold";
    font-weight: 600;
    font-style: normal;
    font-size: convert-unit(24px, "rem");
    line-height: convert-unit(28.8px, "rem");
    word-break: break-all;
    margin-bottom: convert-unit(15px, "rem");
  }
  h5, .contact-select-title  {
    font-size: convert-unit(18px, "rem");
    line-height: convert-unit(21.6px, "rem");
    margin-bottom: convert-unit(8px, "rem");
  }

  .contacts-rhs-select-content {
    .feature-list {
      font-family: "OpenSans-Regular";
      font-style: normal;
      margin: convert-unit(8px, "rem") 0px;
      .list-content {
        display: flex;
        align-items: center;
        img {
          width: convert-unit(24px, "rem");
          height: convert-unit(24px, "rem");
        }
        div {
          font-size: convert-unit(12px, "rem");
          color: inherit;
          margin-left: convert-unit(11px, "rem");
          p {
            margin-bottom: 0;
          }
          a {
            font-family: "OpenSans-Semibold";
            font-weight: 300;
            font-style: normal;
            text-decoration: underline;
            color: $footer-base;
          }
        }
      }
    }
  }
}
.contactus-left-section {
  .fhl-richtext-wrapper {
    font-family: "OpenSans-Regular";
    font-style: normal;
    div {
      p {
        line-height: convert-unit(22.4px, "rem");
        margin-bottom: convert-unit(20px, "rem");
      }
    }
  }
  .fhl-h3-content-wrapper {
    margin-bottom: 0 !important;
    flex-direction: column;
    h3 {
      font-family: "HarmoniaSansW05-Semibold";
      font-weight: 600;
      font-style: normal;
      font-size: convert-unit(21px, "rem");
      line-height: convert-unit(25.2px, "rem");
      margin-bottom: 0px;
    }
    div {
      line-height: convert-unit(21px, "rem");
      font-size: convert-unit(14px, "rem");
      color: inherit;
      @include fontfamilyOpenSans;
    }
  }

  .fhl-small-titlebar-wrapper {
    h2 {
      font-size: convert-unit(24px, "rem");
      line-height: convert-unit(28.8px, "rem");
    }
  }
  .fhl-medium-titlebar-wrapper {
    h2 {
      font-family: "HarmoniaSansW05-Semibold";
      font-weight: 600;
      font-style: normal;
      font-size: convert-unit(28px, "rem");
      line-height: convert-unit(33.6px, "rem");
    }
  }
  .fhl-extrasmall-titlebar-wrapper {
    h3 {
      font-family: "OpenSans-Semibold";
      font-weight: 300;
      font-style: normal;
      line-height: convert-unit(21px, "rem");
    }
  }
}
.contactus-left-section-secondary-header {
  .fhl-small-titlebar-wrapper {
    h2 {
      font-size: convert-unit(28px, "rem");
      line-height: convert-unit(33.6px, "rem");
      font-family: "HarmoniaSansW05-Semibold";
      font-weight: 600;
      font-style: normal;
    }
  }
}
.contactus-right-section {
  .fhl-title-content-btn-wrapper {
    border-bottom: 1px solid $borderLine;
    padding-bottom: convert-unit(8px, "rem");
    h2 {
      font-size: convert-unit(24px, "rem");
      line-height: convert-unit(28.8px, "rem");
    }
    div {
      line-height: convert-unit(21px, "rem");
      word-break: break-all;
      @include fontfamilyOpenSans;
    }
    p > a,
    a {
      padding: 0px;
      line-height: convert-unit(21px, "rem");
      display: table;
      border: 0px;
      margin: 0px;
      box-shadow: none;
      border-radius: 0;
      color: inherit;
    }
  }
}
@media only screen and (max-width: 767px) {
  .contacts-rhs-container {
    width: 80%;
    margin: auto;
  }
  .contactus-left-section {
    .fhl-extrasmall-titlebar-wrapper {
      h3 {
        margin-bottom: convert-unit(34px, "rem");
        margin-top: convert-unit(16px, "rem");
      }
    }
    .fhl-medium-titlebar-wrapper {
      h2 {
        text-align: left !important;
      }
    }
  }
}
@media only screen and (min-width: 768px) {
  .contacts-lhs-container {
    h3 {
      font-size: convert-unit(20px, "rem");
    }
  }
  .contactus-right-section-top-margin {
    margin-top: convert-unit(70px, "rem");
  }
}
@media only screen and (min-width: 992px) {
  .contacts-page-header-wrapper {
    .fhl-title-subTitle-wrapper {
      width: 100% !important;
    }
  }
  .contacts-lhs-container {
    h3 {
      font-size: convert-unit(21px, "rem");
    }
  }
  .contactus-right-section-top-margin {
    margin-right: convert-unit(-7px, "rem");
    margin-top: convert-unit(110px, "rem");
  }
}
@media screen and (min-width: 0px) and (max-width: 767px) {
    .show-on-mobile {
        display: block;
    } /* show it on small screens */
    .hide-on-mobile {
        display: none;
    }
}

@media screen and (min-width: 768px) and (max-width: 3024px) {
    .show-on-mobile {
        display: none;
    } /* hide it elsewhere */
    .hide-on-mobile {
        display: block;
    }
}