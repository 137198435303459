@font-face {
    font-family: "HarmoniaSansW05-Regular";
    src: url("#{$fonts-path}/Harmoniasans/HarmoniaSansW05-Regular.ttf") format("truetype"),
        url("#{$fonts-path}/Harmoniasans/HarmoniaSansW05-Regular.woff2") format("woff2"),
        url("#{$fonts-path}/Harmoniasans/HarmoniaSansW05-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "HarmoniaSans-Bold";
    src: url("#{$fonts-path}/Harmoniasans/HarmoniaSansW05-Bold.ttf") format("truetype"),
        url("#{$fonts-path}/Harmoniasans/HarmoniaSansW05-Bold.woff2") format("woff2"),
        url("#{$fonts-path}/Harmoniasans/HarmoniaSansW05-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "HarmoniaSans-LightItalic";
    src: url("#{$fonts-path}/Harmoniasans/HarmoniaSansW05-LightItalic.ttf") format("truetype"),
        url("#{$fonts-path}/Harmoniasans/HarmoniaSansW05-LightItalic.woff2") format("woff2"),
        url("#{$fonts-path}/Harmoniasans/HarmoniaSansW05-LightItalic.woff") format("woff");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "HarmoniaSans-BoldItalic";
    src: url("#{$fonts-path}/Harmoniasans/HarmoniaSansW05-BoldItalic.ttf") format("truetype"),
        url("#{$fonts-path}/Harmoniasans/HarmoniaSansW05-BoldItalic.woff2") format("woff2"),
        url("#{$fonts-path}/Harmoniasans/HarmoniaSansW05-BoldItalic.woff") format("woff");
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: "HarmoniaSans-Black";
    src: url("#{$fonts-path}/Harmoniasans/HarmoniaSansW05-Black.ttf") format("truetype"),
        url("#{$fonts-path}/Harmoniasans/HarmoniaSansW05-Black.woff2") format("woff2"),
        url("#{$fonts-path}/Harmoniasans/HarmoniaSansW05-Black.woff") format("woff");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "HarmoniaSans-BlackItalic";
    src: url("#{$fonts-path}/Harmoniasans/HarmoniaSansW05-BlackItalic.ttf") format("truetype"),
        url("#{$fonts-path}/Harmoniasans/HarmoniaSansW05-BlackItalic.woff2") format("woff2"),
        url("#{$fonts-path}/Harmoniasans/HarmoniaSansW05-BlackItalic.woff") format("woff");
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: "HarmoniaSans-Condensed";
    src: url("#{$fonts-path}/Harmoniasans/HarmoniaSansW05-Condensed.ttf") format("truetype"),
        url("#{$fonts-path}/Harmoniasans/HarmoniaSansW05-Condensed.woff2") format("woff2"),
        url("#{$fonts-path}/Harmoniasans/HarmoniaSansW05-Condensed.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "HarmoniaSansW05-Semibold";
    src: url("#{$fonts-path}/Harmoniasans/HarmoniaSansW05-Semibold.ttf") format("truetype"),
        url("#{$fonts-path}/Harmoniasans/HarmoniaSansW05-Semibold.woff2") format("woff2"),
        url("#{$fonts-path}/Harmoniasans/HarmoniaSansW05-Semibold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "HarmoniaSans-SemiboldIt";
    src: url("#{$fonts-path}/Harmoniasans/HarmoniaSansW05-SemiboldIt.ttf") format("truetype"),
        url("#{$fonts-path}/Harmoniasans/HarmoniaSansW05-SemiboldIt.woff2") format("woff2"),
        url("#{$fonts-path}/Harmoniasans/HarmoniaSansW05-SemiboldIt.woff") format("woff");
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: "HarmoniaSans-SemiBoldCn";
    src: url("#{$fonts-path}/Harmoniasans/HarmoniaSansW05-SemiBoldCn.ttf") format("truetype"),
        url("#{$fonts-path}/Harmoniasans/HarmoniaSansW05-SemiBoldCn.woff2") format("woff2"),
        url("#{$fonts-path}/Harmoniasans/HarmoniaSansW05-SemiBoldCn.woff") format("woff");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "HarmoniaSans-BoldCond";
    src: url("#{$fonts-path}/Harmoniasans/HarmoniaSansW05-BoldCond.ttf") format("truetype"),
        url("#{$fonts-path}/Harmoniasans/HarmoniaSansW05-BoldCond.woff2") format("woff2"),
        url("#{$fonts-path}/Harmoniasans/HarmoniaSansW05-BoldCond.woff") format("woff");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "HarmoniaSansW05-Italic";
    src: url("#{$fonts-path}/Harmoniasans/HarmoniaSansW05-Italic.ttf") format("truetype"),
        url("#{$fonts-path}/Harmoniasans/HarmoniaSansW05-Italic.woff2") format("woff2"),
        url("#{$fonts-path}/Harmoniasans/HarmoniaSansW05-Italic.woff") format("woff");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "HarmoniaSans-Light";
    src: url("#{$fonts-path}/Harmoniasans/HarmoniaSansW05-Light.ttf") format("truetype"),
        url("#{$fonts-path}/Harmoniasans/HarmoniaSansW05-Light.woff2") format("woff2"),
        url("#{$fonts-path}/Harmoniasans/HarmoniaSansW05-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}
