@media only screen and (max-width: 992px) {
    .glb-header-webbasic-patch {
        margin-top: -4.75rem;
    }
}    
@media only screen and (min-width: 320px) and (max-width: 767px) {
    .fhl-medium-titlebar-wrapper {
        h2 {
            text-align: center;
        }
    }
    .si-promo-mobile-wrapper
    .fhl-large-titlebar-wrapper {
        margin-top: convert-unit(0px, "rem");
    }
    .simple-empty-grey-banner {
        margin-top: convert-unit(74px, "rem");
    }

     .search-result-main-title-wrapper {
        margin-top: convert-unit(90px, "rem");
     }

    .fundingGoal-container,
    .fhl-title-subTitle-wrapper {
        width: 100%;
    }
    .classic-advance-promo-wrapper {
        max-width: 100% !important;
        min-height: 100% !important;
        margin: convert-unit(30px, "rem") 0 !important;
        h1 {
            font-size: convert-unit(36px, "rem") !important;
            line-height: convert-unit(40.4px, "rem");
        }
        p {
            font-size: convert-unit(16px, "rem");
        }
    }
    // .classic-advance-sidebar,
    .advance-sidebar-mapping {
        margin-top: 0 !important;
        padding: 0 !important;
    }
    .classic-advance-feature-wrapper,
    .classic-advance-feature-edit-wrapper {
        .advance-sub-header {
            margin-bottom: convert-unit(15px, "rem");
        }
    }
    .fhl-rates-promo-wrapper {
        min-height: convert-unit(320px, "rem") !important;
        margin-bottom: convert-unit(30px, "rem") !important;
    }
    .mob-top-30 {
        margin-top: convert-unit(30px, "rem");
    }
    .horizontal-sep {
        display: none;
    }
    .white-bg,
    .smokyWhite-bg {
        padding: convert-unit(24px, "rem") convert-unit(16px, "rem") !important;
    }
    .gray-bg {
        padding: convert-unit(16px, "rem") !important;
    }
    .fhl-small-titlebar-wrapper {
        margin-left: 0px;
    }
    .compare-advance-mobile-wrapper {
        .fhl-large-titlebar-wrapper {
            margin: 0px 15px 0px !important;
        }
        .left-arrow-icon {
            margin: 0px 20px;
            width: 100%;
        }
        .fhl-title-content-btn-wrapper {
            margin: 0px 15px !important;
            .left-arrow-icon {
                width: auto;
                margin-left: 0;
            }
        }
    }
    .compare-advance-mobile-wrapper:last-child {
        margin-bottom: 10px;
    }
    .advance-details-mobile-wrapper {
        margin-bottom: 50px;
        .fhl-title-content-btn-wrapper {
            div {
                a {
                    margin-left: 0px;
                }
            }
        }
        .aside-title-text-image-container {
            margin: 0;
        }
    }
    .news-article-content-block div p {
        margin-bottom: 10px !important;
    }
    .content-widget-test-image-template {
        margin: 10px 0px;
        img {
            width: 100%;
        }
    }
    .aboutus-card-layout {
        margin-bottom: convert-unit(-15px, "rem") !important;
    }
    .aboutus-smallpromo-layout {
        margin-top: convert-unit(100px, "rem");
    }
    .aboutus-headerplaceholder-aligner {
        margin-bottom: convert-unit(30px, "rem");
    }
    .careers-headerplaceholder-aligner {
        margin-bottom: convert-unit(15px, "rem");
    }
    .aboutus-pagebottom-spacer {
        margin-bottom: convert-unit(50px, "rem");
    }
    .footer-copyright {
        span {
            ul {
                padding: 0;
            }
        }
    }
}

@media only screen and (min-width: 768px) {
    .fhl-large-titlebar-wrapper {
    margin-top: convert-unit(90px, "rem");
    }
    .si-promo-mobile-wrapper
    .fhl-large-titlebar-wrapper {
        margin-top: convert-unit(0px, "rem");
    }
 .simple-empty-grey-banner{
        margin-top: convert-unit(54px, "rem");
    }
    .search-result-main-title-wrapper {
            margin-top: convert-unit(90px, "rem");
    }

     .glb-header-patch {
        margin-top: convert-unit(60px, "rem");
     }
    .news-events-media-right-section {
        .fhl-content-wrapper,
        a.left-arrow-icon {
            min-width: convert-unit(225px, "rem") !important;
        }
    }
    .fhl-medium-titlebar-wrapper {
        h2 {
            text-align: left;
        }
    }
    .fundingGoal-container,
    .fhl-title-subTitle-wrapper {
        width: 100%;
    }
    .white-bg.extra-space-whiteBg {
        padding: 0 35px;
        margin-top: -50px;
    }
    .secondary-header {
        width: 768px;
        .container {
            .col-md-3 {
                display: none !important;
            }
            .col-md-2 {
                display: none !important;
            }
            .col-md-7 {
                max-width: 100% !important;
                flex: 1;
            }
        }
    }
    .news-article-wrapper {
        h2 {
            margin: 0px 0px convert-unit(15px, "rem");
        }
    }
    .events-section-bdr {
        border-right: 1px solid $borderBlue;
        border-bottom: 0;
    }
    .advance_top_row {
        padding: convert-unit(20px, "rem") 0 0 convert-unit(15px, "rem");
    }
    .large-screen-container {
        padding-right: convert-unit(0px, "rem") !important;
        padding-left: convert-unit(0px, "rem") !important;
    }
    .page-right-filler-md {
        .advance-feature-right {
            margin-left: convert-unit(-10px, "rem");
        }
    }
    .aboutus-headerplaceholder-aligner {
        margin-bottom: convert-unit(65px, "rem");
    }
    .aboutus-pagebottom-spacer {
        margin-bottom: convert-unit(5px, "rem");
    }
    .careers-pagebottom-spacer {
        margin-bottom: convert-unit(35px, "rem");
    }
    .aboutus-smallpromo-layout {
        margin-top: convert-unit(10px, "rem");
    }
}
@media only screen and (min-width: 992px) {
 .simple-empty-grey-banner,.search-result-main-title-wrapper, .glb-header-patch {
        margin-top: convert-unit(0px, "rem");
    }
      .fhl-large-titlebar-wrapper {
         margin-top: convert-unit(0px, "rem");
      }
    .bio-table {
        margin-left: -60px;
    }
    .secondary-header {
        top: 36px;
        height: auto !important;
        .container {
            .col-md-3,
            .col-md-2 {
                display: block !important;
            }
            .col-md-2 {
                padding-right: 0;
            }
            .col-md-7 {
                flex: 0 0 58.33333333% !important;
                max-width: 58.33333333% !important;
            }
        }
    }
    .aboutus-smallpromo-layout {
        margin-top: convert-unit(0px, "rem");
    }
    .desktop-page-bottom-spacing {
        margin-bottom: convert-unit(100px, "rem") !important;
    }
    .aboutus-headerplaceholder-aligner {
        margin-bottom: convert-unit(96px, "rem");
    }
    .aboutus-pagebottom-spacer {
        margin-bottom: convert-unit(48px, "rem");
    }
    .large-screen-container {
        max-width: convert-unit(990px, "rem") !important;
        padding-right: convert-unit(0px, "rem") !important;
    }
    .compare-advance-mobile-wrapper:last-child {
        margin-bottom: 50px;
    }
    .white-bg.extra-space-whiteBg {
        max-width: convert-unit(992px, "rem");
    }
    .card-layout-media-wrapper {
        .col-md-4 {
            margin: 0;
        }
    }
    .fundingGoal-container {
        .fhl-select {
            width: 100%;
        }
    }
    .advance-feature-left {
        flex: 0 0 76% !important;
        max-width: 76% !important;
    }
    .advance-feature-right {
        flex: 0 0 25% !important;
        max-width: 225px !important;
        padding: 0 !important;
    }
    .fundingGoal-container,
    .fhl-title-subTitle-wrapper {
        width: 70% !important;
        margin: 0px auto;
        float: none;
    }
    .fhlb-split-content-left-section {
        flex: 0 0 65% !important;
        max-width: 65% !important;
        margin-right: 100px;
    }
    .fhlb-split-content-right-section {
        flex: 0 0 25% !important;
        max-width: 225px !important;
        padding: 0 !important;
    }
    .com-adv-button-wrapper {
        text-align: right;
        padding-right: 30px;
    }

    .com-adv-button-wrapper .fhl-button-content-container,
    .com-adv-button-wrapper a {
        width: convert-unit(325px, "rem");
        display: inline-block;
        margin-right: convert-unit(-5px, "rem");
    }
    .com-adv-title-content-wrapper .fhl-title-content-btn-wrapper div a {
        margin-left: 48px;
    }
    .compare-advance-mobile-wrapper {
        .fhl-title-content-btn-wrapper {
            margin-left: 215px;
        }
    }
    .advance-wrapper {
        margin-right: convert-unit(90px, "rem") !important;
    }
    html {
        overflow-y: auto !important;
    }
    .si-promo-mobile-wrapper {
        margin-top: convert-unit(-250px, "rem") !important;
        padding-bottom: 5px !important;
    }
}
@media only screen and (min-width: 1200px) {
    .bio-table {
        margin-left: -80px;
    }
    .large-screen-container {
        max-width: convert-unit(1200px, "rem") !important;
        padding-left: convert-unit(30px, "rem") !important;
        padding-right: convert-unit(20px, "rem") !important;
    }
    .header-login-btn {
        margin-right: convert-unit(-40px, "rem") !important;
    }
    .card-layout-media-wrapper .col-md-4 {
        max-width: 32% !important;
    }
    .fundingGoal-container,
    .fhl-title-subTitle-wrapper {
        float: left;
        .fhl-select {
            width: 95%;
        }
    }
    .advance-editable-templates-1 {
        padding-right: convert-unit(100px, "rem") !important;
    }
    .fhl-content-wrapper {
        max-width: convert-unit(225px, "rem");
    }
    .advance-feature-left {
        flex: 0 0 80% !important;
        max-width: 80% !important;
    }
    .fhlb-split-content-left-section {
        flex: 0 0 70% !important;
        max-width: 70% !important;
    }
    .fhlb-split-content-right-section {
        flex: 0 0 25% !important;
        max-width: 225px !important;
    }
    .com-adv-button-wrapper {
        text-align: right;
        padding-right: 30px !important;
    }
    .com-adv-title-content-wrapper .fhl-title-content-btn-wrapper div a {
        margin-left: 48px;
    }
    .advance-wrapper {
        margin-right: convert-unit(100px, "rem") !important;
    }
}
@media only screen and (max-width: 360px) {
    .card-layout-container {
        width: convert-unit(275px, "rem") !important;
    }
    .com-adv-button-wrapper {
        .fhl-button-content-container {
            width: convert-unit(234px, "rem") !important;
        }
    }
}
@media only screen and (max-width: 767px) {
    .glb-header-patch {
        margin-top: convert-unit(60px, "rem");
     }
     .fhl-large-titlebar-wrapper {
     margin-top: convert-unit(90px, "rem");
     }
    .error-message-wrapper {
        margin-left: convert-unit(15px, "rem");
    }
    .events-section-bdr {
        border-right: 0;
    }
    .events-article-wrapper {
        border-bottom: 1px solid $borderBlue;
    }
    .products-promo-media-wrapper {
        max-width: convert-unit(330px, "rem") !important;
    }
    .card-layout-container {
        width: convert-unit(320px, "rem");
        margin-left: auto !important;
        margin-right: auto !important;
    }
    .com-adv-title-content-wrapper {
        .fhl-title-content-btn-wrapper {
            h2 {
                line-height: convert-unit(30px, "rem");
            }
        }
    }
}
@media only screen and (max-height: 768px) {
    .secondary-header {
        ul.navbar-nav {
            li.mob-nav-link:last-child {
                margin-bottom: convert-unit(40px, "rem");
            }
        }
    }
}
@media only screen and (max-width: 991px) {
    .mpf-fhl-select {
        height: convert-unit(40px, "rem");
    }
    .collapse.show {
        height: 100%;
        background-color: $white;
        overflow-y: auto;
        overflow-x: hidden;
    }
    body .fhl-content-box {
        padding-top: 72px !important;
    }
    ul.navbar-nav li.nav-column a .nav-dropbox span {
        color: #002a3a !important;
    }
    header .header-bar {
        display: none;
    }
    .secondary-header {
        position: fixed;
        z-index: 3;
        left: 0;
        top: 0;
        box-shadow: 0 4px 3px -2px rgba(0, 0, 0, 0.18) !important;
        ul.navbar-nav {
            max-height: 85vh;
            padding-bottom: convert-unit(45px, "rem") !important;
            li.main-menu {
                .dropdown-nav {
                    .dropdown-menu {
                        .nav {
                            li.nav-column:last-child {
                                border-bottom: 0px;
                                margin-bottom: convert-unit(-10px, "rem");
                            }
                        }
                    }
                }
            }
        }
    }
    .advance_top_row .col-md-9 {
        display: none;
    }
    .advance-feature-title,
    .advance-feature-content {
        flex: auto !important;
    }
    .advance-features-content-wrapper .row {
        margin-bottom: 10px;
    }
    .advance-feature-title {
        max-width: 100% !important;
    }
    .advance-feature-content {
        max-width: 100% !important;
    }
    .si-promo-mobile-wrapper {
        margin-top: 0px !important;
        padding: 0px !important;
    }
}
