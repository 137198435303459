.forms-media-wrapper {
    padding: convert-unit(20px, "rem") 0 0 !important;
    #forms-results {
        .error-message-wrapper {
            margin: 0px auto;
        }
    }
    .forms-results-wrapper {
        .forms-result-page {
            min-height: convert-unit(765px, "rem");
        }
        overflow: visible;
        /* Hide the browser's default checkbox */
        margin: convert-unit(5px, "rem") 0 convert-unit(40px, "rem");

        .forms-result-title {
            font-size: convert-unit(16px, "rem");
            font-family: "HarmoniaSansW05-Regular";
            color: $dark-blue;
            line-height: convert-unit(19.2px, "rem");
            letter-spacing: convert-unit(2px, "rem");
            display: table;
            padding-bottom: convert-unit(18px, "rem");
        }
        ul{
            li{
                list-style-type: none;
            }
        }
    }
}
@media only screen and (max-width: 767px) {
    .forms-media-wrapper {
        padding: convert-unit(30px, "rem") convert-unit(16px, "rem") !important;
        .narrow-list-wrapper {
            max-width: 100%;
            margin-bottom: convert-unit(30px, "rem");
        }
        .forms-results-wrapper {
            padding: 0px convert-unit(15px, "rem");
            .forms-result-page {
                min-height: convert-unit(100px, "rem");
                .col-md-6 {
                    padding: 0;
                    margin-bottom: convert-unit(52px, "rem");
                }
                .col-md-12 {
                    padding: 0;
                }
                .col-md-3 {
                    margin-bottom: convert-unit(10px, "rem");
                }
            }
        }
        .forms-result-list-view {
            margin-bottom: convert-unit(100px, "rem");
            margin-top: convert-unit(43px, "rem");
        }
    }
}
@media only screen and (min-width: 768px) {
    .forms-media-wrapper {
        .row {
            .col-md-3 {
                max-width: 35%;
                .narrow-list-wrapper {
                    width: 225px;
                }
            }
            .col-md-9 {
                max-width: 65%;
                .forms-result-page {
                    .col-md-3 {
                        margin-bottom: convert-unit(10px, "rem");
                    }
                    .col-md-3,
                    .col-md-9 {
                        max-width: 100%;
                        flex: 0 0 100%;
                    }
                }
            }
        }
        .forms-result-list-view {
            margin-bottom: convert-unit(60px, "rem");
        }
    }
}
@media only screen and (min-width: 992px) {
    .forms-media-wrapper {
        .row {
            .col-md-3 {
                max-width: 25%;
                padding: 0 0 0 15px;
                flex: 0 0 25%;
            }
            .col-md-9 {
                max-width: 75%;
                padding: 0px 15px 0px 0px;
                flex: 0 0 75%;
                .forms-result-page {
                    .col-md-3 {
                        max-width: 25%;
                        flex: 0 0 25%;
                    }
                    .col-md-9 {
                        max-width: 75%;
                        flex: 0 0 75%;
                    }
                }
            }
        }
        .forms-result-list-view {
            margin-bottom: convert-unit(65px, "rem");
        }
    }
}
@media only screen and (min-width: 1200px) {
    .forms-media-wrapper {
        .row {
            .col-md-9 {
                .forms-result-page {
                    .col-md-3 {
                        max-width: 25%;
                        flex: 0 0 25%;
                    }
                    .col-md-9 {
                        max-width: 75%;
                        flex: 0 0 75%;
                    }
                }
            }
        }
    }
}
