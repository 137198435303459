.rates-tab {
    .nav-tabs {
        border-bottom: 0px;
        .nav-link {
            color: #00833c !important;
        }
    }
    .nav-item {
        display: block;
        padding: 0.5rem 0.5rem;
    }
    .nav-link {
        display: block;
        padding: 0px;
    }
    .nav-tabs .nav-link {
        border: 0px;
    }
    .nav-tabs .nav-link.active,
    .nav-tabs .nav-item.show .nav-link {
        border-bottom: convert-unit(2px, "rem") solid get("color.primary-green.100");
        padding-bottom: convert-unit(2px, "rem");
    }
    .tab-content {
        padding-top: convert-unit(25px, "rem");
    }
}
.fhl-rates-page-wrapper {
    width: 100%;
    p {
        font-family: "Opensans-Regular";
        color: $footer-base;
        line-height: convert-unit(21px, "rem");
        font-size: convert-unit(14px, "rem");
        margin-bottom: convert-unit(25px, "rem");
    }

    .dynamic-rates-cntr {
        p {
            font-family: "Opensans-Regular";
            font-size: convert-unit(14px, "rem");
            color: $footer-base;
            line-height: convert-unit(21px, "rem");
            letter-spacing: convert-unit(0px, "rem");
        }
        .rates-block-header {
            font-family: "HarmoniaSansW05-Regular";
            font-size: convert-unit(18px, "rem");
            color: $footer-base;
            letter-spacing: convert-unit(0px, "rem");
            line-height: convert-unit(25.2px, "rem");
        }
        .rates-page {
            table {
                border: 0px;
                width: 100%;
                table-layout: fixed;
            }
            caption {
                caption-side: top;
            }
            .col-header-th {
                font-family: "HarmoniaSansW05-Semibold";
                border-bottom: 1px solid $borderLine;
                font-size: convert-unit(14px, "rem");
                color: $footer-base;
                letter-spacing: convert-unit(2px, "rem");
                text-align: center;
                border-top: 1px solid get("color.primary-green.100");
                height: convert-unit(35px, "rem");
                padding-top: 10px;
                width: auto;
            }
            .col-value-td {
                border-bottom: 1px solid $borderLine;
                font-family: "Opensans-Regular";
                font-size: convert-unit(12px, "rem");
                color: get("color.gray.50");
                letter-spacing: convert-unit(1.71px, "rem");
                text-align: center;
                line-height: convert-unit(40px, "rem");
                width: auto;
            }
        }
        .rates-button-wrapper {
            margin-top: convert-unit(50px, "rem");
            display: flex;
            button:focus {
                box-shadow: 0 0 0 0.1rem #2b9be0 !important;
            }

            .rates_download_icon {
                background-size: 74%;
            }
            .rates_download {
                background-size: 70%;
            }

            .rates_download_icon,
            .rates_download {
                background-image: url("../assets/images/icon_download.svg");
                background-repeat: no-repeat;
                margin-left: convert-unit(10px, "rem");
                min-width: convert-unit(23px, "rem");
                height: convert-unit(20px, "rem");
            }
        }
        button:disabled {
            background-color: $btn-disabled !important;
            color: $white !important;
            cursor: not-allowed;
        }
        .historical-rates-btn,
        .download-rates-btn {
            border-radius: convert-unit(4px, "rem");
            font-family: "HarmoniaSansW05-Regular";
            font-size: convert-unit(16px, "rem");
            color: #ffffff;
            letter-spacing: 0;
            text-align: center;
            padding: 5px 25px;
            height: convert-unit(35px, "rem");
            display: flex;
            justify-content: center;
            margin-right: convert-unit(20px, "rem");
        }
        .historical-rates-btn {
            background: $dark-blue;
            width: convert-unit(157px, "rem");
        }
        .download-rates-btn {
            background: get("color.primary-green.100");
            width: 100%;
        }
        .download-rates-btn:focus {
            outline: 0;
            border: 0;
            box-shadow: 0 0 0 0.1rem #2b9be0 !important;    
          }
    }
}
.dynamic-rate-block {
    display: flex;
    .dynamic-rate-block button {
        margin-right: convert-unit(20px, "rem");
    }
}

@media only screen and (max-width: 767px) {
    .rates-mobile-section {
        padding: 0 !important;
    }
    .fhl-rates-page-wrapper {
        margin-left: convert-unit(-9px, "rem");
        .dynamic-rates-cntr {
            .rates-button-wrapper .col-9 {
                max-width: 100%;
                flex: 0 0 100%;
            }
            .rates-page {
                overflow-x: auto;
                table {
                    min-width: convert-unit(500px, "rem");
                }
            }
        }
    }
    .fhl-rates-page-wrapper {
        .dynamic-rates-cntr {
            .rates-button-wrapper {
                flex-direction: column;
            }
        }
    }
    .rates-online-banking {
        .fhl-title-content-btn-wrapper {
            max-width: 100%;
            h2 {
                font-size: convert-unit(32px, "rem");
                line-height: convert-unit(38.4px, "rem");
                margin-bottom: convert-unit(0px, "rem");
            }
            div {
                p {
                    margin-right: 0 !important;
                    margin-bottom: convert-unit(20px, "rem") !important;
                }
            }
        }
    }
    .rates-mobile-button-wrapper .col-6 {
        max-width: 270px;
        margin-bottom: 25px;
    }
    .rates-mobile-button-wrapper {
        flex-direction: column;
    }
    .rates-button-error-wrapper {
        margin: 30px 0px -135px 0px;
        .errormsg-wrapper {
            padding: 0;
            .col-lg-12 {
                padding: 0;
            }
        }
    }
}
@media only screen and (min-width: 768px) {
    .rates-mobile-button-wrapper .col-6 {
        max-width: 270px;
        margin-bottom: 25px;
    }
    .rates-mobile-button-wrapper {
        flex-direction: column;
    }
    .rates-button-error-wrapper {
        margin: 30px 0px -140px -15px;
        width: 110%;
    }
    .fhl-rates-page-wrapper {
        margin-left: convert-unit(-9px, "rem");
    }
    .advance-media-login-wrapper.rates-online-banking .fhl-title-content-btn-wrapper div a.left-arrow-icon {
        display: inline-block !important;
    }
    .rates-online-banking .fhl-title-content-btn-wrapper div a.left-arrow-icon i {
        margin-top: convert-unit(0px, "rem");
    }
    .rates-online-banking {
        .fhl-title-content-btn-wrapper {
            max-width: 92%;
            .d-flex {
                flex-direction: column;
                a {
                    margin-left: 0;
                    margin-top: convert-unit(15px, "rem");
                }
            }
        }
    }
    .fhl-rates-page-wrapper {
        .dynamic-rates-cntr {
            .rates-button-wrapper {
                flex-direction: column;
                .rates_download_icon {
                    background-size: 70%;
                }
            }
        }
    }
}
@media only screen and (min-width: 992px) {
    .fhl-rates-page-wrapper {
        .dynamic-rates-cntr {
            .rates-button-wrapper {
                max-width: 100%;
            }
            .rates-page {
                table {
                    width: 100%;
                }
            }
            .rates-button-wrapper {
                .rates_download_icon {
                    background-size: 72%;
                }
            }
        }
    }
    .rates-mobile-button-wrapper .col-6 {
        max-width: 50%;
        margin-bottom: 0px;
    }
    .rates-mobile-button-wrapper {
        flex-direction: row;
    }
    .fhl-rates-page-wrapper {
        margin-left: convert-unit(0px, "rem");
    }
    .rates-tab {
        .nav-tabs {
            margin-left: convert-unit(9px, "rem");
        }
    }
    .rates-online-banking {
        margin-left: convert-unit(10px, "rem") !important;
        .fhl-title-content-btn-wrapper {
            max-width: 97%;
            .d-flex {
                flex-direction: row;
                a {
                    margin-top: 0;
                    margin-left: convert-unit(20px, "rem");
                }
            }
        }
    }
    .fhl-rates-page-wrapper {
        .dynamic-rates-cntr {
            .rates-button-wrapper {
                flex-direction: row;
                button {
                    margin-bottom: convert-unit(0px, "rem");
                }
                button:last-child {
                    margin-bottom: 0px;
                }
            }
        }
    }
    .rates-button-error-wrapper {
        margin: 25px 0px -135px -16px;
        width: 111%;
        .error-message-wrapper {
            max-width: 100%;
            .errormsg-wrapper .alert-labeled-row .alert-body {
                padding-left: 19px;
            }
        }
    }
}
@media only screen and (min-width: 1200px) {
    .fhl-rates-page-wrapper {
        margin-left: convert-unit(-8px, "rem");
        .dynamic-rates-cntr {
            .rates-button-wrapper {
                max-width: auto;
            }
            .rates-page {
                table {
                    width: convert-unit(730px, "rem");
                }
            }
        }
    }
    .rates-tab {
        .nav-tabs {
            margin-left: convert-unit(0px, "rem");
        }
    }
    .rates-online-banking {
        margin-left: 0px !important;
        .fhl-title-content-btn-wrapper {
            max-width: 735px;
        }
    }
    .rates-button-error-wrapper {
        width: 103%;
    }
}
