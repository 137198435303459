@import "../helpers/mixins";

.rates-left-section {
  word-break: break-word;
  word-wrap: break-word;
  display: block;
  margin: auto 0px;
  min-height: convert-unit(525px, "rem") !important;
}
.rates-banner-section {
  min-height: convert-unit(525px, "rem");
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.rates-promo-right-wrapper {
  word-break: break-word;
  word-wrap: break-word;
  justify-content: center;
  max-width: convert-unit(225px, "rem");
  .smalltext {
    font-size: convert-unit(16px, "rem");
    color: inherit;
    letter-spacing: convert-unit(2px, "rem");
    line-height: convert-unit(19.2px, "rem");
    margin-bottom: 0px;
  }
  h2 {
    font-size: convert-unit(32px, "rem");
    color: inherit;
    line-height: convert-unit(38.4px, "rem");
    border-bottom: 1px solid inherit;
    width: 100%;
    margin-bottom: convert-unit(25px, "rem");
  }
  .rates-promo-right-wrapper-lead-para {
    font-family: "Opensans-Regular";
    @include fontfamilyOpenSans;
    color: inherit;
    line-height: convert-unit(21px, "rem");
    font-size: convert-unit(14px, "rem");
    margin-bottom: convert-unit(25px, "rem");
  }
  img {
    width: convert-unit(220px, "rem");
    height: convert-unit(150px, "rem");
  }
}
.fhlb-split-content-left-area {
  .rates-tab {
    margin-left: convert-unit(-5px, "rem");
    margin-top: convert-unit(20px, "rem");
  }
}
@media only screen and (max-width: 767px) {
  .rates-left-section {
    min-height: auto !important;
  }
  .rates-banner-section {
  margin-top:convert-unit(74px, "rem");
     }
  .rates-right-section {
    display: flex;
    justify-content: left;
    margin: convert-unit(48px, "rem") 0 convert-unit(48px, "rem") 5%;
  }
  .rates-alert-wrapper {
    margin: 0px convert-unit(-10px, "rem");
  }
}
@media only screen and (min-width: 768px) {
  .rates-alert-wrapper {
    margin-left: convert-unit(5px, "rem");
    width: 100%;
  }
  .rates-banner-section {
    margin-top:convert-unit(50px, "rem");
    }
}
@media only screen and (min-width: 992px) {
    .rates-banner-section {
      margin-top:convert-unit(0px, "rem");
         }
  .rates-alert-wrapper {
    margin-left: convert-unit(20px, "rem");
    width: 105%;
    .error-message-wrapper .errormsg-wrapper .alert-labeled-row .alert-body {
      padding-left: 19px;
      padding-right: 10px;
    }
  }
}
@media only screen and (min-width: 1200px) {
  .rates-alert-wrapper {
    margin-left: convert-unit(10px, "rem");
    width: 98%;
    margin-right: -10px;
    .error-message-wrapper {
      max-width: 100%;
      .errormsg-wrapper .alert-labeled-row .alert-body {
        padding: convert-unit(24px, "rem");
      }
    }
  }
}
