.classic-advance-promo-wrapper {
    flex-direction: column;
    overflow: visible;
    align-items: flex-start;
    min-height: convert-unit(525px, "rem");
    justify-content: center;
    h1 {
        font-size: convert-unit(42px, "rem");
        color: get("color.primary-green.100");
        line-height: convert-unit(50.4px, "rem");
    }
    p {
        font-size: convert-unit(18px, "rem");
        color: $footer-base;
        line-height: convert-unit(25.2px, "rem");
    }
    h1,
    p {
        margin-bottom: convert-unit(15px, "rem");
    }
}
.classic-advance-feature-wrapper {
    overflow: visible;
    height: auto;
    margin: convert-unit(50px, "rem") 0 convert-unit(30px, "rem");
    h2 {
        color: get("color.primary-green.100");
        font-family: "HarmoniaSansW05-Semibold";
        font-weight: 600;
        font-style: normal;
        font-size: convert-unit(28px, "rem");
        line-height: convert-unit(33.6px, "rem");
    }
    .advance-sub-header {
        color: get("color.primary-green.100");
        font-family: "OpenSans-Semibold";
        font-weight: 300;
        font-style: normal;
        font-size: convert-unit(16px, "rem");
        line-height: convert-unit(21px, "rem");
    }
    hr {
        border-bottom: 1px solid $border-dark-line;
        margin: convert-unit(30px, "rem") 0 convert-unit(25px, "rem");
        border-top: 0;
    }
    .advance-features-content-wrapper {
        ul {
            padding-left: 0;
            list-style-type: none;
        }
    }
    ul.funding-goals {
        margin-bottom: convert-unit(15px, "rem");
        padding: 0;
        li {
            list-style-type: disc;
            font-family: "Opensans-Regular";
            font-size: convert-unit(16px, "rem");
            color: $footer-base;
            line-height: convert-unit(22.4px, "rem");
            text-align: left;
            margin-left: convert-unit(20px, "rem");
        }
    }
    img {
        width: convert-unit(80px, "rem");
        height: convert-unit(80px, "rem");
        margin: 0px convert-unit(15px, "rem") convert-unit(15px, "rem") 0px;
    }
}
.advance-feature-title {
    font-weight: 300;
    font-style: normal;
    font-size: convert-unit(16px, "rem");
    font-family: "OpenSans-Semibold";
    color: $footer-base;
}
.advance-feature-content {
    font-family: "Opensans-Regular";
    font-size: convert-unit(16px, "rem");
    color: $footer-base;
}
.advance-editable-templates-2 {
    margin-top: convert-unit(50px, "rem");
}
.advance-editable-templates-2,
.advance-sidebar-mapping {
    overflow: visible;
    .aside-title-text-image-container {
        margin-bottom: -10px;
    }
}
.advance-editable-templates-2 {
    .different-option-container {
        .fhl-select label {
            float: left;
        }
    }
}
@media only screen and (max-width: 767px) {
    .advance-editable-templates-2 {
        margin-top: convert-unit(-50px, "rem");
    }
    .advance-media-login-wrapper {
        .fhl-title-content-btn-wrapper div a {
            margin-left: 0px;
        }
    }
    .advance-feature-left,
    .advance-feature-right {
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }
}
@media only screen and (min-width: 768px) {
    .classic-advance-promo-wrapper {
        max-width: 80%;
    }
    .advance-editable-templates-2 {
        margin-top: convert-unit(50px, "rem");
    }
    .classic-advance-feature-wrapper,
    .classic-advance-feature-edit-wrapper {
        overflow: visible;
        .advance-sub-header,
        .advance-features-content-wrapper {
            flex: auto;
        }
        .advance-sub-header {
            max-width: 35% !important;
        }
        .advance-features-content-wrapper {
            max-width: 65% !important;
            .row {
                margin-bottom: 10px;
            }
        }
    }
    .fhl-title-content-btn-wrapper div a {
        height: convert-unit(35px, "rem");
        i {
            margin-top: 0;
        }
    }
    .advance-media-login-wrapper {
        .fhl-title-content-btn-wrapper div a {
            padding-top: 7px;
            height: auto;
        }
        .fhl-title-content-btn-wrapper div a.left-arrow-icon {
            display: flex !important;
            align-items: center;
            i {
                margin-top: convert-unit(-10px, "rem");
            }
        }
    }
}
@media only screen and (min-width: 992px) {
    .classic-advance-promo-wrapper {
        max-width: 65%;
    }
    .advance-editable-templates-2 {
        margin-top: convert-unit(50px, "rem");
    }
    .advance-media-login-wrapper {
        .fhl-title-content-btn-wrapper div {
            p {
                margin-right: convert-unit(25px, "rem");
            }
            a {
                min-height: convert-unit(35px, "rem");
                i {
                    margin-top: convert-unit(0px, "rem");
                }
            }
            a.left-arrow-icon {
                display: inline-block !important;
                i {
                    margin-top: convert-unit(0px, "rem");
                }
            }
        }
    }
    .fhl-title-content-btn-wrapper div a {
        height: auto;
        i {
            margin-top: convert-unit(0px, "rem");
        }
    }
    .classic-advance-feature-wrapper,
    .classic-advance-feature-edit-wrapper {
        overflow: visible;
        .advance-sub-header,
        .advance-features-content-wrapper {
            flex: auto;
        }
        .advance-sub-header {
            max-width: 25% !important;
        }
        .advance-features-content-wrapper {
            max-width: 72% !important;
            .row {
                margin-bottom: 0px;
            }
        }
    }
}
@media only screen and (min-width: 1200px) {
    .advance-media-login-wrapper {
        .fhl-title-content-btn-wrapper div {
            p {
                margin-right: convert-unit(45px, "rem");
            }
            /*  a {
                height: convert-unit(35px, "rem");
            } */
        }
    }
}
